import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MANAGETIP_APIS_CRUD } from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'

const appTooltipsSlice = createSlice({
  name: 'appTooltips',
  initialState: {
    tooltips: [],
    status: 'idle',
    error: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppTooltips.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAppTooltips.fulfilled, (state, action) => {
        state.status = 'succeeded'
        if (!action?.payload?.error && action?.payload) {
          state.tooltips = action.payload.data?.records || []
        } else {
          state.tooltips = []
        }
      })
      .addCase(getAppTooltips.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || ''
      })
  },
})

export default appTooltipsSlice

export const getAppTooltips = createAsyncThunk('appTooltips/getAppTooltips', async () => {
  const requestedData: any = MANAGETIP_APIS_CRUD.GET_TIP()
  const data: any = await mainApiService(requestedData)
  return data
})
