const EditIcon: React.FC = () => {
  return (
    <svg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.164062' width='28' height='28' rx='4' fill='#F3F2F1' />
      <g clipPath='url(#clip0_3283_21771)'>
        <path
          d='M17.3826 8.96384C17.1694 8.76571 16.8867 8.65959 16.5957 8.66849C16.3048 8.67738 16.0291 8.80057 15.8284 9.01135L9.04152 15.7982L8.83113 19.0967C8.82914 19.1284 8.83392 19.1601 8.84516 19.1899C8.85639 19.2196 8.87382 19.2466 8.89629 19.269C8.91875 19.2915 8.94574 19.3089 8.97547 19.3202C9.00519 19.3314 9.03696 19.3362 9.06867 19.3342L12.3671 19.1238L19.154 12.3369C19.3648 12.1362 19.4879 11.8605 19.4968 11.5696C19.5057 11.2787 19.3996 10.9959 19.2015 10.7827L17.3826 8.96384Z'
          stroke='#402080'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3283_21771'>
          <rect width='16' height='16' fill='white' transform='translate(6.16406 6)' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default EditIcon

export const CloseIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_4445_19297)'>
        <path
          d='M-1 1V15C-1 16.1 -0.1 17 1 17H15C16.1 17 17 16.1 17 15V1C17 -0.1 16.1 -1 15 -1H1C-0.1 -1 -1 -0.1 -1 1ZM12.3 12.29C11.91 12.68 11.28 12.68 10.89 12.29L8 9.41L5.11 12.3C4.72 12.69 4.09 12.69 3.7 12.3C3.31 11.91 3.31 11.28 3.7 10.89L6.59 8L3.7 5.11C3.31 4.72 3.31 4.09 3.7 3.7C4.09 3.31 4.72 3.31 5.11 3.7L8 6.59L10.89 3.71C11.28 3.32 11.91 3.32 12.3 3.71C12.69 4.1 12.69 4.73 12.3 5.12L9.41 8L12.3 10.88C12.68 11.27 12.68 11.91 12.3 12.29Z'
          fill='#D4351C'
        />
      </g>
      <defs>
        <clipPath id='clip0_4445_19297'>
          <rect width='24' height='24' fill='white' transform='translate(-4 -4)' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const SolidEditIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_4445_19300)'>
        <path
          d='M2 11.6397V13.6664C2 13.853 2.14667 13.9997 2.33333 13.9997H4.36C4.44667 13.9997 4.53333 13.9664 4.59333 13.8997L11.8733 6.62638L9.37333 4.12638L2.1 11.3997C2.03333 11.4664 2 11.5464 2 11.6397ZM13.8067 4.69305C14.0667 4.43305 14.0667 4.01305 13.8067 3.75305L12.2467 2.19305C11.9867 1.93305 11.5667 1.93305 11.3067 2.19305L10.0867 3.41305L12.5867 5.91305L13.8067 4.69305Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_4445_19300'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const PauseVideoIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        d='M7.16667 5.6669V10.3336C7.16667 10.6069 7.48 10.7669 7.7 10.6002L10.8133 8.2669C10.9933 8.13356 10.9933 7.8669 10.8133 7.73356L7.7 5.40023C7.48 5.23356 7.16667 5.39356 7.16667 5.6669ZM7.83334 2.15356C7.83334 1.7269 7.44 1.40023 7.02667 1.49356C6.28 1.6669 5.57334 1.96023 4.94667 2.36023C4.59334 2.5869 4.54 3.09356 4.84 3.39356C5.05334 3.6069 5.39334 3.66023 5.64667 3.50023C6.16 3.17356 6.72667 2.93356 7.34 2.80023C7.63334 2.73356 7.83334 2.46023 7.83334 2.15356ZM3.9 4.34023C3.59334 4.04023 3.09334 4.0869 2.86667 4.4469C2.46667 5.07356 2.17334 5.78023 2 6.5269C1.90667 6.94023 2.22667 7.33356 2.65334 7.33356C2.95334 7.33356 3.23334 7.13356 3.29334 6.84023C3.42667 6.23356 3.67334 5.66023 3.99334 5.15356C4.16667 4.89356 4.11334 4.55356 3.9 4.34023ZM2.65334 8.6669C2.22667 8.6669 1.9 9.06023 1.99334 9.47356C2.16667 10.2202 2.46 10.9202 2.86 11.5536C3.08667 11.9136 3.59334 11.9602 3.89334 11.6602C4.10667 11.4469 4.16 11.1069 3.99334 10.8536C3.66667 10.3469 3.42667 9.78023 3.29334 9.1669C3.23334 8.8669 2.96 8.6669 2.65334 8.6669ZM4.94667 13.6336C5.58 14.0336 6.28 14.3269 7.02667 14.5002C7.44 14.5936 7.83334 14.2669 7.83334 13.8469C7.83334 13.5469 7.63334 13.2669 7.34 13.2069C6.73334 13.0736 6.16 12.8269 5.65334 12.5069C5.39334 12.3469 5.06 12.3936 4.84667 12.6136C4.54 12.9069 4.58667 13.4069 4.94667 13.6336ZM15.1667 8.00023C15.1667 11.1536 12.9667 13.8069 10.0133 14.4936C9.6 14.5936 9.2 14.2669 9.2 13.8402C9.2 13.5336 9.40667 13.2669 9.7 13.1936C12.0667 12.6469 13.8333 10.5269 13.8333 8.00023C13.8333 5.47356 12.0667 3.35356 9.7 2.8069C9.40667 2.74023 9.2 2.4669 9.2 2.16023C9.2 1.73356 9.6 1.4069 10.0133 1.5069C12.9667 2.19356 15.1667 4.8469 15.1667 8.00023Z'
        fill='#402080'
      />
    </svg>
  )
}
