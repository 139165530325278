import { Fade, Menu, MenuItem } from '@mui/material'
import React from 'react'
import { ChipButtonModal } from '../../../Models'

const ChipButton: React.FC<ChipButtonModal> = ({
  type = 'primary',
  Icon,
  label,
  suffix,
  dropdown,
  options,
  value = 'INACTIVE',
  action,
  style,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (item: any) => {
    if (action) action(item.type)
    setAnchorEl(null)
  }

  const showIcon = suffix ? true : false

  return !dropdown && !options ? (
    <div className={`chip__button ${type}`} style={style}>
      {!showIcon && Icon && (
        <div className='chip__button-icon'>
          <Icon />
        </div>
      )}
      <div className='chip__button-text'>{label}</div>
      {showIcon && Icon && (
        <div className='chip__button-icon'>
          <Icon />
        </div>
      )}
    </div>
  ) : (
    <React.Fragment>
      <div
        className={`chip__button ${type}`}
        id='fade-button'
        data-testid='active__status-dd'
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={style}
      >
        {!showIcon && Icon && (
          <div className='chip__button-icon'>
            <Icon />
          </div>
        )}
        <div className='chip__button-text'>{label}</div>
        {showIcon && Icon && (
          <div className='chip__button-icon'>
            <Icon />
          </div>
        )}
      </div>
      <Menu
        className='c-active-inactive-dropdown'
        id='fade-menu'
        data-testid='chip__button-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {options
          ?.filter((item) => item.value !== value)
          .map((item) => (
            <MenuItem key={item.label} onClick={() => handleClose(item)}>
              {item.label}
            </MenuItem>
          ))}
      </Menu>
    </React.Fragment>
  )
}
export default ChipButton
