function MigrateIcon() {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='28' height='28' rx='4' fill='#F3F2F1' />
      <g clipPath='url(#clip0_8961_32832)'>
        <path
          d='M9.68656 12.5469C11.5503 12.5469 13.0606 12.9924 13.0606 13.5416C13.0606 14.0917 11.5503 14.5364 9.68656 14.5364C7.82281 14.5364 6.3125 14.0917 6.3125 13.5416C6.3125 12.9924 7.82281 12.5469 9.68656 12.5469Z'
          stroke='#402080'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.3125 13.5469V15.5594C6.3125 16.1086 7.82281 16.5542 9.68656 16.5542C11.5503 16.5542 13.0606 16.1086 13.0606 15.5594V13.5469'
          stroke='#402080'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.3125 15.5547V17.5664C6.3125 18.1165 7.82281 18.562 9.68656 18.562C11.5503 18.562 13.0606 18.1165 13.0606 17.5664V15.5547'
          stroke='#402080'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.3125 17.5625V19.5751C6.3125 20.1243 7.82281 20.5698 9.68656 20.5698C11.5503 20.5698 13.0606 20.1243 13.0606 19.5751V17.5625'
          stroke='#402080'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.3125 12.5469C20.1763 12.5469 21.6875 12.9924 21.6875 13.5416C21.6875 14.0917 20.1763 14.5364 18.3125 14.5364C16.4497 14.5364 14.9385 14.0917 14.9385 13.5416C14.9385 12.9924 16.4497 12.5469 18.3125 12.5469Z'
          stroke='#402080'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.9385 13.5469V15.5594C14.9385 16.1086 16.4497 16.5542 18.3125 16.5542C20.1763 16.5542 21.6875 16.1086 21.6875 15.5594V13.5469'
          stroke='#402080'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.9385 15.5547V17.5664C14.9385 18.1165 16.4497 18.562 18.3125 18.562C20.1763 18.562 21.6875 18.1165 21.6875 17.5664V15.5547'
          stroke='#402080'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.9385 17.5625V19.5751C14.9385 20.1243 16.4497 20.5698 18.3125 20.5698C20.1763 20.5698 21.6875 20.1243 21.6875 19.5751V17.5625'
          stroke='#402080'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.42871 9.28906L9.68659 10.5469L10.9444 9.28906'
          stroke='#402080'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.68652 10.3591V8.12859C9.68652 7.74416 10.001 7.42969 10.3854 7.42969H17.6145C17.999 7.42969 18.3126 7.74416 18.3126 8.12859V10.3591'
          stroke='#402080'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_8961_32832'>
          <rect width='16' height='16' fill='white' transform='translate(6 6)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MigrateIcon
