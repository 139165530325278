import React from 'react'

const PlusIcon: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <rect width='16' height='16' fill='white' />
      <path
        d='M12.0007 8.66732H8.66732V12.0007C8.66732 12.3673 8.36732 12.6673 8.00065 12.6673C7.63398 12.6673 7.33398 12.3673 7.33398 12.0007V8.66732H4.00065C3.63398 8.66732 3.33398 8.36732 3.33398 8.00065C3.33398 7.63398 3.63398 7.33398 4.00065 7.33398H7.33398V4.00065C7.33398 3.63398 7.63398 3.33398 8.00065 3.33398C8.36732 3.33398 8.66732 3.63398 8.66732 4.00065V7.33398H12.0007C12.3673 7.33398 12.6673 7.63398 12.6673 8.00065C12.6673 8.36732 12.3673 8.66732 12.0007 8.66732Z'
        fill='#402080'
      />
    </svg>
  )
}

export default PlusIcon
