const ReplaceAssetsIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        className='svg-icon'
        d='M4.66729 4.66679H11.334V5.86012C11.334 6.16012 11.694 6.30679 11.9006 6.09346L13.7606 4.23346C13.894 4.10012 13.894 3.89346 13.7606 3.76012L11.9006 1.90012C11.694 1.69346 11.334 1.84012 11.334 2.14012V3.33346H4.00063C3.63396 3.33346 3.33396 3.63346 3.33396 4.00012V6.66679C3.33396 7.03346 3.63396 7.33346 4.00063 7.33346C4.36729 7.33346 4.66729 7.03346 4.66729 6.66679V4.66679ZM11.334 11.3335H4.66729V10.1401C4.66729 9.84012 4.30729 9.69346 4.10063 9.90679L2.24063 11.7668C2.10729 11.9001 2.10729 12.1068 2.24063 12.2401L4.10063 14.1001C4.30729 14.3068 4.66729 14.1601 4.66729 13.8601V12.6668H12.0006C12.3673 12.6668 12.6673 12.3668 12.6673 12.0001V9.33346C12.6673 8.96679 12.3673 8.66679 12.0006 8.66679C11.634 8.66679 11.334 8.96679 11.334 9.33346V11.3335Z'
        fill='#402080'
      />
    </svg>
  )
}

export default ReplaceAssetsIcon
