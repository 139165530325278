import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { WIDGET_DEFINITION_CRUD } from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

const initialState: any = {
  tollgatesList: [],
  parametersList: [],
  selectedParametersList: {},
  showRightDrawer: false,
  drawerTitle: '',
  rightDrawerParameterData: {},
  rightDrawerParameterDataIndex: 0,
  widgetDefinitionValidationValues: [],
}

const widgetDefinitionSlice = createSlice({
  name: 'widgetDefinition',
  initialState,
  reducers: {
    resetInitialState: (state) => {
      state.tollgatesList = []
      state.parametersList = []
      state.widgetDefinitionViewData = {}
      state.selectedParametersList = {}
      state.showRightDrawer = false
      state.drawerTitle = ''
      state.rightDrawerParameterData = {}
      state.widgetDefinitionValidationValues = []
    },
    setTollgatesList: (state, action: PayloadAction<any>) => {
      const { data = [] } = action.payload || {}
      state.tollgatesList = data
    },
    setParametersList: (state, action: PayloadAction<any>) => {
      const { data = {} } = action.payload || {}
      const { records = [] } = data
      state.parametersList = records.filter((e: any) => e.status === 'ACTIVE')
    },
    setWidgetDefinitionViewState: (state, action: PayloadAction<any>) => {
      const { data = {} } = action.payload || {}
      state.widgetDefinitionViewData = data
    },
    setSelectedParametersList: (state, action: PayloadAction<any>) => {
      state.selectedParametersList = { ...state.selectedParametersList, ...action.payload }
    },
    clearSetSelectedParametersList: (state) => {
      state.selectedParametersList = []
    },
    setShowRightDrawer: (state, action: PayloadAction<any>) => {
      state.showRightDrawer = action.payload
    },
    setRightDrawerParameterData: (state, action: PayloadAction<any>) => {
      state.rightDrawerParameterData = action.payload
    },
    setRightDrawerTitle: (state, action: PayloadAction<any>) => {
      state.drawerTitle = action.payload
    },
    setRightDrawerParameterDataIndex: (state, action: PayloadAction<any>) => {
      state.rightDrawerParameterDataIndex = action.payload
    },
    setwidgetDefinitionValidationValues: (state, action: PayloadAction<any>) => {
      state.widgetDefinitionValidationValues = action.payload
    },
    setWidgetStatus: (state, action: PayloadAction<any>) => {
      const { data = {} } = action.payload || {}
      state.widgetStatus = data
    },
  },
})

export const {
  resetInitialState,
  setTollgatesList,
  setParametersList,
  setWidgetDefinitionViewState,
  setSelectedParametersList,
  clearSetSelectedParametersList,
  setShowRightDrawer,
  setRightDrawerParameterData,
  setRightDrawerTitle,
  setRightDrawerParameterDataIndex,
  setwidgetDefinitionValidationValues,
  setWidgetStatus,
} = widgetDefinitionSlice.actions

const widgetDefinitionSelector = (state: { widgetDefinition: any }) => state.widgetDefinition

export const selectTollgatesList = createSelector(
  widgetDefinitionSelector,
  (state) => state.tollgatesList,
)

export const selectParametersList = createSelector(
  widgetDefinitionSelector,
  (state) => state.parametersList,
)
export const selectwidgetDefinitionViewData = createSelector(
  widgetDefinitionSelector,
  (state) => state.widgetDefinitionViewData,
)

export const selectSelectedParametersList = createSelector(
  widgetDefinitionSelector,
  (state) => state.selectedParametersList,
)

export const selectShowRightDrawer = createSelector(
  widgetDefinitionSelector,
  (state) => state.showRightDrawer,
)

export const selectRightDrawerTitle = createSelector(
  widgetDefinitionSelector,
  (state) => state.drawerTitle,
)

export const selectRightDrawerParameterDataIndex = createSelector(
  widgetDefinitionSelector,
  (state) => state.rightDrawerParameterDataIndex,
)

export const selectRightDrawerParameterData = createSelector(
  widgetDefinitionSelector,
  (state) => state.rightDrawerParameterData,
)

export const selectWidgetStatus = createSelector(
  widgetDefinitionSelector,
  (state) => state.widgetStatus,
)

export default widgetDefinitionSlice

export const getwidgetDefinitionsListAPICall = createAsyncThunk(
  'widgetDefinition/getwidgetDefinitionsList',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = WIDGET_DEFINITION_CRUD.LISTING(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const getTollgatesListAPICall = createAsyncThunk(
  'widgetDefinition/getTollgatesList',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = WIDGET_DEFINITION_CRUD.TOLLGATES_LISTING(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setTollgatesList(data))
    dispatch(setLoader(false))
    return data
  },
)

export const getParametersListAPICall = createAsyncThunk(
  'widgetDefinition/getParametersList',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = WIDGET_DEFINITION_CRUD.PARAMETER_LISTING(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setParametersList(data))
    dispatch(setLoader(false))
    return data
  },
)

export const createwidgetDefinitionAPICall = createAsyncThunk(
  'widgetDefinition/createwidgetDefinition',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = WIDGET_DEFINITION_CRUD.CREATE(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setParametersList(data))
    dispatch(setLoader(false))
    return data
  },
)

export const updatewidgetDefinitionAPICall = createAsyncThunk(
  'widgetDefinition/updatewidgetDefinition',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = WIDGET_DEFINITION_CRUD.UPDATE(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const getwidgetDefinitionByIdAPICall = createAsyncThunk(
  'widgetDefinition/getwidgetDefinitionById',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = WIDGET_DEFINITION_CRUD.GET(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setWidgetDefinitionViewState(data))
    dispatch(setLoader(false))
    return data
  },
)

export const updatewidgetDefinitionStatusAPICall = createAsyncThunk(
  'widgetDefinition/updatewidgetDefinitionStatus',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = WIDGET_DEFINITION_CRUD.UPDATE_STATUS(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
