function CopyClipboardIcon() {
  return (
    <svg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='29' height='28' rx='4' fill='#ECE9F2' />
      <g clipPath='url(#clip0_4455_25542)'>
        <path
          d='M15.9999 6.6665H8.66659C7.93325 6.6665 7.33325 7.2665 7.33325 7.99984V16.6665C7.33325 17.0332 7.63325 17.3332 7.99992 17.3332C8.36659 17.3332 8.66659 17.0332 8.66659 16.6665V8.6665C8.66659 8.29984 8.96659 7.99984 9.33325 7.99984H15.9999C16.3666 7.99984 16.6666 7.69984 16.6666 7.33317C16.6666 6.9665 16.3666 6.6665 15.9999 6.6665ZM18.6666 9.33317H11.3333C10.5999 9.33317 9.99992 9.93317 9.99992 10.6665V19.9998C9.99992 20.7332 10.5999 21.3332 11.3333 21.3332H18.6666C19.3999 21.3332 19.9999 20.7332 19.9999 19.9998V10.6665C19.9999 9.93317 19.3999 9.33317 18.6666 9.33317ZM17.9999 19.9998H11.9999C11.6333 19.9998 11.3333 19.6998 11.3333 19.3332V11.3332C11.3333 10.9665 11.6333 10.6665 11.9999 10.6665H17.9999C18.3666 10.6665 18.6666 10.9665 18.6666 11.3332V19.3332C18.6666 19.6998 18.3666 19.9998 17.9999 19.9998Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_4455_25542'>
          <rect width='16' height='16' fill='white' transform='translate(6 6)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CopyClipboardIcon
