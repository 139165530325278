import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../Constants'
import { USER_MANAGEMENT_CRUD } from '../../../Constants/ApiDefinations'
import { combineErrorMessage, objectToQueryString } from '../../../Helpers/Util'
import {
  BreadcrumsModel,
  UserListResponseModal,
  UserListingModal,
  viewRoleAPIDefinationModal,
} from '../../../Models'
import style from '../../../Pages/ManageUser/ManageUser.module.scss'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import ListingComponent from '../../Molecule/ListingComponent'
import { BackButton, Breadcrumbs } from '../../Shared'

const RoleUsers = () => {
  const { rolePageType, roleId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [listData, setlistData] = useState<UserListResponseModal[]>([])
  const [dataCount, setdataCount] = useState(0)
  const getUserListFunction = async (options: UserListingModal) => {
    const paramsData = objectToQueryString(options)

    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal = USER_MANAGEMENT_CRUD.LIST_USER(paramsData)
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      setdataCount(result?.data?.count)
      const tempData: UserListResponseModal[] = []
      result?.data?.records?.forEach((ele: UserListResponseModal) => {
        tempData.push({
          _id: ele?._id,
          roleName: ele?.roles?.roleName,
          userName: ele?.userName,

          userEmail: ele?.userEmail,
          createdAt: format(new Date(ele?.createdAt?.split('T')[0]), 'dd/MM/yyyy'),
          status: (
            <Typography
              className={ele?.status === 'ACTIVE' ? style?.statusActive : style?.statusInactive}
            >
              {ele.status === 'ACTIVE' ? 'Active' : 'In Active'}
            </Typography>
          ),
        })
      })
      setlistData(tempData)
      dispatch(setLoader(false))
    } else {
      dispatch(showAlert([true, combineErrorMessage(result), 'error']))
      dispatch(setLoader(false))
    }
  }
  return (
    <div className='p-16'>
      <div className='p-8'>
        <div className='p-8'>
          <Breadcrumbs
            breadcrums={BREADCRUMS.VIEW_USER_ROLE(rolePageType, roleId) as BreadcrumsModel[]}
          />
        </div>
        <BackButton
          label={'Associated users'}
          className='col73 p-8'
          handleOnClick={(e: any) => {
            e.preventDefault()
            navigate(`/${PAGES_ROUTES.RolesPage}/${rolePageType}/${roleId}`)
          }}
        />
      </div>
      <div>
        {listData.length ? (
          <div className='associated-users-count'>
            <h6> Role Name</h6>
            <p>{listData[0]?.roleName}</p>
          </div>
        ) : (
          <></>
        )}

        <div className='associated-users-custom-table'>
          <ListingComponent
            columns={CONSTANTS.ROLE_USER_LISTING_COLUMNS}
            showCreateButton={false}
            showSearchField={false}
            tableRows={listData}
            dataCount={dataCount}
            searchFunction={(search, sorttype, coltype, filter, pagenumber, pagecount) => {
              getUserListFunction({
                sortBy: coltype,
                filterCondition: 'isEqualTo',
                filterValue: `${roleId}`,
                filterBy: 'roleId',
                sortType: sorttype,
                page: pagenumber,
                limit: pagecount,
              })
            }}
            paginationOption={[
              { value: 10, label: 10 },
              { value: 20, label: 20 },
              { value: 50, label: 50 },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default RoleUsers
