/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SEGMENT_MANAGEMENT_CRUD } from '../../Constants/ApiDefinations'
import { CreateSegmentRequestModal, SegmentCRUDAPIDefinitionModal } from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface SegmentDataModal {
  _id: string
  segmentName: string
  description: string
  abSegments: any
  publishingSegments: any
  countries?: any
  platform?: any
  createdAt: string
  updatedAt: string
}

interface SegmentState {
  segment: string
  authConfig?: any
  segmentData?: SegmentDataModal | null
}

const initialState: SegmentState = {
  segment: '',
  authConfig: null,
  segmentData: null,
}

const segmentSlice = createSlice({
  name: 'segment',
  initialState,
  reducers: {
    setSegment: (state, action: PayloadAction<string>) => {
      state.segment = action.payload
    },
    setAuthConfig: (state, action: PayloadAction<string>) => {
      state.authConfig = action.payload
    },
    setSegmentData: (state, action) => {
      if (Object.keys(action?.payload?.data)) state.segmentData = action.payload.data
    },
  },
})

export const { setSegment, setSegmentData, setAuthConfig } = segmentSlice.actions

export const createSegmentService = createAsyncThunk(
  'segment/createSegment',
  async (payload: CreateSegmentRequestModal, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: SegmentCRUDAPIDefinitionModal =
      SEGMENT_MANAGEMENT_CRUD.CREATE_SEGMENT(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)

export const updateSegmentService = createAsyncThunk(
  'segment/updateSegment',
  async (payload: CreateSegmentRequestModal, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: SegmentCRUDAPIDefinitionModal =
      SEGMENT_MANAGEMENT_CRUD.UPDATE_SEGMENT(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)

export const viewSegmentService = createAsyncThunk(
  'segment/viewSegment',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = SEGMENT_MANAGEMENT_CRUD.VIEW(id)
    const data = await mainApiService(requestedData)

    dispatch(setSegmentData(data))
    dispatch(setLoader(false))

    return data
  },
)

export const deleteSegment = createAsyncThunk(
  'segment/deleteSegment',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: SegmentCRUDAPIDefinitionModal = SEGMENT_MANAGEMENT_CRUD.DELETE_SEGMENT(id)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)

const segmentSelector = (state: { segment: SegmentState }) => state.segment
export const selectAuthConfig = createSelector(segmentSelector, (state) => state.authConfig)
export const selectSegment = createSelector(segmentSelector, (state) => state.segment)
export const selectSegmentData = createSelector(segmentSelector, (state) => state.segmentData)

export default segmentSlice
