import CloseIcon from '@mui/icons-material/Close'
import { AlertIconModal } from '../../../Models'
import './Alert.scss'

const Alert: React.FC<AlertIconModal> = ({
  Icon,
  type = 'success',
  content,
  handleClose,
}: AlertIconModal) => {
  return (
    <div className={`alert ${type}`}>
      <div>{Icon && <Icon />}</div>
      <div>{content}</div>
      <div className='mb-auto' data-testid='alert-close__testid' onClick={handleClose}>
        <CloseIcon />
      </div>
    </div>
  )
}

export default Alert
