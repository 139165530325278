function AccordianOpenIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
      <g path='url(#clip0_8281_16676)'>
        <path
          d='M16.7068 8.5974L11.993 13.3708L7.27927 8.5974C6.80547 8.1176 6.04009 8.1176 5.56629 8.5974C5.09249 9.0772 5.09249 9.85227 5.56629 10.3321L11.1426 15.979C11.6164 16.4588 12.3818 16.4588 12.8556 15.979L18.4319 10.3321C18.9057 9.85227 18.9057 9.0772 18.4319 8.5974C17.9581 8.1299 17.1806 8.1176 16.7068 8.5974Z'
          fill='#858789'
        />
      </g>
      <defs>
        <clipPath id='clip0_8281_16676'>
          <rect width='24' height='24.3038' fill='white' transform='translate(0 0.13916)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AccordianOpenIcon
