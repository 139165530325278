import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  AUDIT_LOGS,
  CONTAINER_MANAGEMENT_CRUD,
  SITE_NAVIGATION_CRUD,
  WIDGET_MANAGEMENT_CRUD,
} from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'
import {
  ViewAuditLogDetailRequestModal,
  ViewAuditLogRequestModal,
} from '../../Models/AuditLogsServiceModal'

interface logState {
  logData?: ViewAuditLogRequestModal | null
  widgetData?: ViewAuditLogDetailRequestModal | null
  navigationData?: ViewAuditLogDetailRequestModal | null
  containerData?: ViewAuditLogDetailRequestModal | null
}

const initialState: logState = {
  logData: null,
  widgetData: null,
  navigationData: null,
  containerData: null,
}

const auditLogsSlice = createSlice({
  name: 'auditLogs',
  initialState,
  reducers: {
    setAuditLogsList: (state, action) => {
      if (action?.payload?.data?.records?.[0]) state.logData = action.payload.data.records[0]
    },
    setAuditWidgetDetail: (state, action) => {
      if (action?.payload?.data) state.widgetData = action?.payload?.data
    },
    setAuditNavigationDetail: (state, action) => {
      if (action?.payload?.data) state.navigationData = action.payload.data
    },
    setAuditContainerDataDetail: (state, action) => {
      if (action?.payload?.data) state.containerData = action.payload.data
    },
  },
})

export const {
  setAuditLogsList,
  setAuditWidgetDetail,
  setAuditNavigationDetail,
  setAuditContainerDataDetail,
} = auditLogsSlice.actions

export const getLogListData = createAsyncThunk(
  'auditLogs/getlogData',
  async (id: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = AUDIT_LOGS.VIEW(id)
    const data = await mainApiService(requestedData)
    dispatch(setAuditLogsList(data))
    dispatch(setLoader(false))
    return data
  },
)

export const getWidgetData = createAsyncThunk(
  'auditLogs/getwidgetData',
  async (id: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = WIDGET_MANAGEMENT_CRUD.GET_WIDGET_DETAILS_BY_ID(id)
    const data = await mainApiService(requestedData)
    dispatch(setAuditWidgetDetail(data))
    dispatch(setLoader(false))
    return data
  },
)
export const getNavigationData = createAsyncThunk(
  'auditLogs/getNavigationData',
  async (id: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = SITE_NAVIGATION_CRUD.GET_DETAIL_BY_ID(id)
    const data = await mainApiService(requestedData)
    dispatch(setAuditNavigationDetail(data))
    dispatch(setLoader(false))
    return data
  },
)
export const getContainerData = createAsyncThunk(
  'auditLogs/getContainerData',
  async (id: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = CONTAINER_MANAGEMENT_CRUD.GET_CONTAINER_DETAIL_BY_ID(id)
    const data = await mainApiService(requestedData)
    dispatch(setAuditContainerDataDetail(data))
    dispatch(setLoader(false))
    return data
  },
)
const auditLogsSelector = (state: { auditLogs: logState }) => state.auditLogs

export const selectAuditLogsList = createSelector(auditLogsSelector, (state) => state.logData)
export const selectwidgetData = createSelector(auditLogsSelector, (state) => state.widgetData)
export const selectNavigationData = createSelector(
  auditLogsSelector,
  (state) => state.navigationData,
)
export const selectContainerData = createSelector(auditLogsSelector, (state) => state.containerData)

export default auditLogsSlice
