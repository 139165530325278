import { getStatus } from '../../Helpers/Util'
import css from './AssetStateLabel.module.scss'

/**
 * AssetStateLabel Component
 * @component
 * @param {string} props.state - Content state as DRAFT or DRAFT_IN_PROGRESS etc.
 * @returns {React.JSX.Element} JSX Element representing the AssetStateLabel component.
 */

const AssetStateLabel = ({ state }: { state: string }): React.JSX.Element => {
  return (
    <span
      className={`AssetStateLabel ${getStatus(state)} ${css.contentState} ${
        css[state?.toLowerCase().replace(/\s/g, '') || '']
      }`}
    >
      {getStatus(state)}
    </span>
  )
}

export default AssetStateLabel
