/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { USER_MANAGEMENT_CRUD } from '../../Constants/ApiDefinations'
import {
  CreateUserRequestModal,
  UserCRUDAPIDefinitionModal,
  userStatusChangeDefinitionModal,
} from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface UserDataModal {
  _id: string
  userName: string
  userEmail: string
  cyberarkId: string
  roleId: string
  status: string
  createdAt: string
  updatedAt: string
}

interface UserState {
  user: string
  authConfig?: any
  userData?: UserDataModal | null
}

const initialState: UserState = {
  user: '',
  authConfig: null,
  userData: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<string>) => {
      state.user = action.payload
    },
    setAuthConfig: (state, action: PayloadAction<string>) => {
      state.authConfig = action.payload
    },
    setUserData: (state, action) => {
      if (action?.payload?.data?.records?.[0]) state.userData = action.payload.data.records[0]
    },
  },
})

export const { setUser, setUserData, setAuthConfig } = userSlice.actions

export const createUserService = createAsyncThunk(
  'role/createUser',
  async (payload: CreateUserRequestModal, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: UserCRUDAPIDefinitionModal = USER_MANAGEMENT_CRUD.CREATE_USER(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)

export const updateUserService = createAsyncThunk(
  'role/updateUser',
  async (payload: CreateUserRequestModal, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: UserCRUDAPIDefinitionModal = USER_MANAGEMENT_CRUD.UPDATE_USER(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)

export const changeUserStatus = createAsyncThunk(
  'role/changeStatus',
  async (data: { userId: string; userStatus: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: userStatusChangeDefinitionModal = USER_MANAGEMENT_CRUD.CHANGE_STATUS(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return result
  },
)

export const viewUserService = createAsyncThunk(
  'role/viewUser',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = USER_MANAGEMENT_CRUD.VIEW_USER(id)
    const data = await mainApiService(requestedData)

    dispatch(setUserData(data))
    dispatch(setLoader(false))

    return data
  },
)

export const getAssociatedUsers = createAsyncThunk(
  'role/getAssociatedUsers',
  async (paramsData: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData = USER_MANAGEMENT_CRUD.LIST_USER(paramsData)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

const userSelector = (state: { user: UserState }) => state.user

export const selectAuthConfig = createSelector(userSelector, (state) => state.authConfig)
export const selectUser = createSelector(userSelector, (state) => state.user)
export const selectUserData = createSelector(userSelector, (state) => state.userData)

export default userSlice
