const EditButtonIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        d='M10.2179 1.96238C10.0047 1.76425 9.722 1.65813 9.43107 1.66702C9.14014 1.67591 8.86444 1.7991 8.66373 2.00989L1.87685 8.79677L1.66645 12.0952C1.66447 12.1269 1.66925 12.1587 1.68048 12.1884C1.69172 12.2181 1.70915 12.2451 1.73161 12.2676C1.75408 12.29 1.78107 12.3075 1.81079 12.3187C1.84051 12.3299 1.87228 12.3347 1.904 12.3327L5.20242 12.1223L11.9893 5.33546C12.2001 5.13474 12.3233 4.85904 12.3322 4.56812C12.3411 4.27719 12.2349 3.99448 12.0368 3.78126L10.2179 1.96238Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default EditButtonIcon
