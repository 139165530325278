import React from 'react'

const DragAndDropIcon: React.FC = () => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='fi_2956800'>
        <path
          id='Vector'
          d='M29.6874 13.8048C29.1472 11.6453 27.901 9.72814 26.1466 8.3578C24.3923 6.98747 22.2304 6.24251 20.0043 6.24121C17.7782 6.23992 15.6155 6.98237 13.8596 8.35067C12.1037 9.71897 10.8552 11.6347 10.3124 13.7936C7.75224 14.0339 5.38298 15.2512 3.69681 17.1926C2.01065 19.1341 1.13718 21.6505 1.25785 24.2191C1.37852 26.7877 2.48405 29.2112 4.3448 30.986C6.20554 32.7608 8.67849 33.7506 11.2499 33.7498H14.9999C15.3315 33.7498 15.6494 33.6181 15.8838 33.3837C16.1183 33.1493 16.2499 32.8314 16.2499 32.4998C16.2499 32.1683 16.1183 31.8504 15.8838 31.616C15.6494 31.3815 15.3315 31.2498 14.9999 31.2498H11.2499C10.265 31.2527 9.2892 31.0616 8.37816 30.6873C7.46712 30.313 6.63871 29.763 5.94024 29.0686C4.52962 27.6662 3.73388 25.7608 3.72808 23.7717C3.72227 21.7826 4.50689 19.8726 5.90931 18.462C7.31173 17.0514 9.21708 16.2556 11.2062 16.2498C11.5269 16.274 11.8452 16.1781 12.0993 15.9809C12.3533 15.7836 12.5251 15.4991 12.5812 15.1823C12.8357 13.3974 13.7257 11.7642 15.0876 10.5828C16.4495 9.40127 18.192 8.75079 19.9949 8.75079C21.7979 8.75079 23.5404 9.40127 24.9023 10.5828C26.2642 11.7642 27.1542 13.3974 27.4087 15.1823C27.4741 15.4881 27.6435 15.7616 27.8881 15.9563C28.1328 16.151 28.4374 16.2547 28.7499 16.2498C30.7391 16.2498 32.6467 17.04 34.0532 18.4465C35.4598 19.8531 36.2499 21.7607 36.2499 23.7498C36.2499 25.739 35.4598 27.6466 34.0532 29.0531C32.6467 30.4597 30.7391 31.2498 28.7499 31.2498H24.9999C24.6684 31.2498 24.3505 31.3815 24.1161 31.616C23.8816 31.8504 23.7499 32.1683 23.7499 32.4998C23.7499 32.8314 23.8816 33.1493 24.1161 33.3837C24.3505 33.6181 24.6684 33.7498 24.9999 33.7498H28.7499C31.3026 33.7231 33.7484 32.7212 35.5861 30.9493C37.4239 29.1775 38.5144 26.7699 38.6343 24.2199C38.7541 21.6699 37.8942 19.1707 36.2308 17.2342C34.5674 15.2978 32.2263 14.0709 29.6874 13.8048Z'
          fill='#383F43'
        />
        <path
          id='Vector_2'
          d='M25.3663 24.634C25.602 24.8617 25.9178 24.9877 26.2455 24.9848C26.5733 24.982 26.8868 24.8505 27.1185 24.6187C27.3503 24.387 27.4818 24.0735 27.4846 23.7457C27.4875 23.418 27.3615 23.1022 27.1338 22.8665L20.8838 16.6165C20.6494 16.3821 20.3315 16.2505 20 16.2505C19.6686 16.2505 19.3507 16.3821 19.1163 16.6165L12.8663 22.8665C12.6386 23.1022 12.5126 23.418 12.5154 23.7457C12.5183 24.0735 12.6497 24.387 12.8815 24.6187C13.1133 24.8505 13.4268 24.982 13.7545 24.9848C14.0823 24.9877 14.398 24.8617 14.6338 24.634L18.75 20.5177V36.2502C18.75 36.5817 18.8817 36.8997 19.1161 37.1341C19.3506 37.3685 19.6685 37.5002 20 37.5002C20.3315 37.5002 20.6495 37.3685 20.8839 37.1341C21.1183 36.8997 21.25 36.5817 21.25 36.2502V20.5177L25.3663 24.634Z'
          fill='#383F43'
        />
      </g>
    </svg>
  )
}

export default DragAndDropIcon
