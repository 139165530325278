import React, { ReactNode, createContext, useContext, useState } from 'react'
import ConfirmDialog, { ConfirmDialogProps } from './ConfirmDialog' // Adjust the path based on your file structure

interface DialogProps {
  open: boolean
  type: string | null
  props: Record<string, any>
}

interface DialogContextType {
  showDialog: (type: string, props?: Record<string, any>) => void
}

const DialogContext = createContext<DialogContextType | undefined>(undefined)

export const useDialog = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider')
  }
  return context.showDialog
}

interface DialogProviderProps {
  children: ReactNode
}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const [dialog, setDialog] = useState<DialogProps>({
    open: false,
    type: null,
    props: {},
  })

  const showDialog = (type: string, props: Record<string, any> = {}) => {
    setDialog({
      open: true,
      type,
      props,
    })
  }

  const hideDialog = () => {
    setDialog({
      open: false,
      type: null,
      props: {},
    })
  }

  const DialogComponent: ConfirmDialogProps | any = dialog.type === 'confirm' ? ConfirmDialog : null

  return (
    <DialogContext.Provider value={{ showDialog }}>
      {children}
      {DialogComponent && (
        <DialogComponent open={dialog.open} onCloseDialog={hideDialog} {...dialog.props} />
      )}
    </DialogContext.Provider>
  )
}
