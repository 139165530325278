import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import styles from './BackButton.module.scss'

interface BackButtonProps {
  className?: string
  handleOnClick?: any
  label?: string
}

const BackButton = ({ className, handleOnClick, label }: BackButtonProps) => {
  return (
    <div className={`${styles.backBtnWrapper} ${className}`} data-testid='backBtnWrapper'>
      <div data-testid='t-click-btn' className={`${styles.leftAerrow}`} onClick={handleOnClick}>
        <KeyboardBackspaceIcon />
      </div>
      <p className={`${className} capitalize`}>{label}</p>
    </div>
  )
}

export default BackButton
