import AuditTrails from '../../Components/Icons/AuditTrailsIcon'
import {
  ManageWidgetIcon,
  SegmentManagementIcon,
  UserManagementIcon,
} from '../../Components/Shared/MUI-Drawer/MUI-Drawer.svgIcons'
import { PAGES_ROUTES } from '../PagesRoutes'

const { ManageWidget, ManageUser, ManageSegment } = PAGES_ROUTES

export const BOLT_MODULE = {
  key: 'BOLT_MODULE',
  displayName: 'Bolt Module',
  ROLE_AND_USER_MANAGEMENT: {
    key: 'ROLE_AND_USER_MANAGEMENT',
    displayName: 'Role And User Management',
    permission: {
      canAccess: true,
    },
  },
  AUDIT_TRAILS: {
    key: 'AUDIT_TRAILS',
    displayName: 'Audit Logs',
    permission: {
      canAccess: true,
    },
  },
  MANAGE_WIDGETS: {
    key: 'MANAGE_WIDGETS',
    displayName: 'Manage Widgets',
    permission: {
      canAccess: true,
    },
  },
  SITE_NAVIGATION: {
    key: 'SITE_NAVIGATION',
    displayName: 'Site Navigation',
    permission: {
      canAccess: true,
    },
  },
  MANAGE_CONTAINERS: {
    key: 'MANAGE_CONTAINERS',
    displayName: 'Manage Containers',
    permission: {
      canAccess: true,
    },
  },
  REPORTING: {
    key: 'REPORTING',
    displayName: 'Reporting',
    permission: {
      canAccess: true,
    },
  },
  MANAGE_CONTENT: {
    key: 'MANAGE_CONTENT',
    displayName: 'Manage Content',
    permission: {
      canAccess: true,
    },
  },
  BOLT_MASTER: {
    key: 'BOLT_MASTER',
    displayName: 'Bolt Master',
    permission: {
      canAccess: true,
    },
  },
  MASTERS: {
    key: 'MASTERS',
    displayName: 'Masters',
    permission: {
      canAccess: true,
    },
  },
  PARAMETER_REPOSITORY: {
    key: 'PARAMETER_REPOSITORY',
    displayName: 'Parameter Repository',
    permission: {
      canAccess: true,
    },
  },
  MANAGE_SEGMENTS: {
    key: 'MANAGE_SEGMENTS',
    displayName: 'Manage Segments',
    permission: {
      canAccess: true,
    },
  },
  CONTAINER_DEFINITION_MANAGEMENT: {
    key: 'CONTAINER_DEFINITION_MANAGEMENT',
    displayName: 'Container Definition',
    permission: {
      canAccess: true,
    },
  },
  WIDGET_DEFINITION_MANAGEMENT: {
    key: 'WIDGET_DEFINITION_MANAGEMENT',
    displayName: 'Widget Definition',
    permission: {
      canAccess: true,
    },
  },
}

export const META_CATEGORIES = {
  key: 'META_CATEGORIES',
  displayName: 'Meta Categories',
  DESCRIPTIVE_METADATA: {
    key: 'DESCRIPTIVE_METADATA',
    displayName: 'Descriptive Metadata',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  RIGHTS_AND_AVAILABILITY: {
    key: 'RIGHTS_AND_AVAILABILITY',
    displayName: 'Rights And Availability',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  SEO_DETAILS: {
    key: 'SEO_DETAILS',
    displayName: 'SEO Details',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  ADVERTISEMENT: {
    key: 'ADVERTISEMENT',
    displayName: 'Advertisement',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  BUSINESS_RULES: {
    key: 'BUSINESS_RULES',
    displayName: 'Business Rules',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  TECHNICAL_METADATA: {
    key: 'TECHNICAL_METADATA',
    displayName: 'Technical Metadata',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  CONTENT_MEDIA_IMAGE: {
    key: 'CONTENT_MEDIA_IMAGE',
    displayName: 'Content Media: Image',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  CONTENT_MEDIA_VIDEO: {
    key: 'CONTENT_MEDIA_VIDEO',
    displayName: 'Content Media: Video',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  TRANSLATIONS: {
    key: 'TRANSLATIONS',
    displayName: 'Translations',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  CHECKLIST: {
    key: 'CHECKLIST',
    displayName: 'Checklist',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  DISCOVERY: {
    key: 'DISCOVERY',
    displayName: 'Discovery',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  SPORTS: {
    key: 'SPORTS',
    displayName: 'Sports',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
  OTHERS: {
    key: 'OTHERS',
    displayName: 'Others',
    permission: {
      canRead: true,
      canWrite: true,
    },
  },
}

export const TOLLGATES = {
  key: 'TOLLGATES',
  displayName: 'Tollgates',
  DESCRIPTIVE_METADATA: {
    key: 'DESCRIPTIVE_METADATA',
    displayName: 'Descriptive Metadata',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  RIGHTS_AND_AVAILABILITY: {
    key: 'RIGHTS_AND_AVAILABILITY',
    displayName: 'Rights And Availability',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  SEO_DETAILS: {
    key: 'SEO_DETAILS',
    displayName: 'SEO Details',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  ADVERTISEMENT: {
    key: 'ADVERTISEMENT',
    displayName: 'Advertisement',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  BUSINESS_RULES: {
    key: 'BUSINESS_RULES',
    displayName: 'Business Rules',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  TECHNICAL_METADATA: {
    key: 'TECHNICAL_METADATA',
    displayName: 'Technical Metadata',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  CONTENT_MEDIA_IMAGE: {
    key: 'CONTENT_MEDIA_IMAGE',
    displayName: 'Content Media: Image',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  CONTENT_MEDIA_VIDEO: {
    key: 'CONTENT_MEDIA_VIDEO',
    displayName: 'Content Media: Video',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  TRANSLATIONS: {
    key: 'TRANSLATIONS',
    displayName: 'Translations',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  CHECKLIST: {
    key: 'CHECKLIST',
    displayName: 'Checklist',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  DISCOVERY: {
    key: 'DISCOVERY',
    displayName: 'Discovery',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  SPORTS: {
    key: 'SPORTS',
    displayName: 'Sports',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  OTHERS: {
    key: 'OTHERS',
    displayName: 'Others',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  QC_PROCESS: {
    key: 'QC_PROCESS',
    displayName: 'QC Process',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  PUBLISHING: {
    key: 'PUBLISHING',
    displayName: 'Publishing',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  RIGHTS_EXPIRED: {
    key: 'RIGHTS_EXPIRED',
    displayName: 'Rights Expired',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
  DEPUBLISH: {
    key: 'DEPUBLISH',
    displayName: 'Depublish',
    permission: {
      canStateTransition: true,
      canSkipValidation: true,
    },
  },
}

export const QUICK_LINKS = [
  {
    icon: <ManageWidgetIcon />,
    name: 'Manage Widget',
    route: `/${ManageWidget}`,
  },
  {
    icon: <SegmentManagementIcon />,
    name: 'Manage Segment',
    route: `/${ManageSegment}`,
  },
  {
    icon: <AuditTrails />,
    name: 'Audit Trails',
    route: `/${ManageWidget}`,
  },
  {
    icon: <UserManagementIcon />,
    name: 'User Management',
    route: `/${ManageUser}`,
  },
]
