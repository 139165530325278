const VersionIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_10412_24003)'>
        <g mask='url(#mask0_10412_24003)'>
          <path
            d='M11.5145 4.98448C10.9597 4.98448 10.5103 4.53489 10.5103 3.98033V1.97148C10.5103 1.70539 10.4049 1.45008 10.2166 1.2618C10.0283 1.07352 9.77228 0.967828 9.50616 0.967828H6.49366C5.93888 0.967828 5.4895 1.41742 5.4895 1.97198V11.0095C5.4895 11.564 5.93888 12.0137 6.49366 12.0137H13.5228C13.7889 12.0137 14.045 11.908 14.2333 11.7195C14.4215 11.5312 14.527 11.2759 14.527 11.0095V5.98864C14.527 5.7223 14.4215 5.46698 14.2333 5.2787C14.045 5.09017 13.7889 4.98448 13.5228 4.98448H11.5145Z'
            stroke='#402080'
            strokeWidth='1.17187'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M12.5187 12.0131H6.49367C6.22758 12.0131 5.97151 11.9075 5.78326 11.7189C5.59495 11.5306 5.48951 11.2753 5.48951 11.009V2.97564H4.48536C4.21923 2.97564 3.9632 3.08133 3.77492 3.26986C3.58664 3.45814 3.4812 3.71345 3.4812 3.9798V13.0173C3.4812 13.5719 3.93058 14.0215 4.48536 14.0215H11.5145C12.0693 14.0215 12.5187 13.5719 12.5187 13.0173V12.0131Z'
            stroke='#402080'
            strokeWidth='1.17187'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M10.5104 14.021H4.48537C4.21928 14.021 3.96321 13.9153 3.77496 13.7267C3.58665 13.5385 3.48121 13.2831 3.48121 13.0168V4.98345H2.47706C2.21093 4.98345 1.9549 5.08914 1.76659 5.27767C1.57834 5.46595 1.4729 5.72127 1.4729 5.98761V15.0251C1.4729 15.5797 1.92228 16.0293 2.47706 16.0293H9.50621C10.061 16.0293 10.5104 15.5797 10.5104 15.0251V14.021Z'
            stroke='#402080'
            strokeWidth='1.17187'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M14.5269 5.98828C14.5269 5.34538 14.2709 4.72881 13.8165 4.27416C13.086 3.54262 11.9512 2.40894 11.2207 1.67741C10.7663 1.22275 10.1488 0.967437 9.5061 0.967437C9.77223 0.967437 10.0283 1.07313 10.2165 1.26141C10.4048 1.44972 10.5103 1.70503 10.5103 1.97113V3.97994C10.5103 4.5345 10.9596 4.98413 11.5144 4.98413H13.5228C13.7889 4.98413 14.0449 5.08981 14.2332 5.27809C14.4215 5.46638 14.5269 5.72169 14.5269 5.98778V5.98828Z'
            stroke='#402080'
            strokeWidth='1.17187'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M8.50195 8.49766L9.50614 10.0039L11.5145 6.99141'
            stroke='#402080'
            strokeWidth='1.17187'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  )
}
export default VersionIcon
