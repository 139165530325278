import React, { useCallback, useEffect, useState } from 'react'
import { useForm, Controller, Control, SubmitHandler, Resolver } from 'react-hook-form'
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Typography,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  MUIAutocompleteMultiSelect,
  MUIBox,
  MUIButton,
  MUIField,
  MUIMultiselectAsset,
  MUISelect,
} from '../../Shared'
import MagnifyGlass from '../../Icons/MagnifyGlass'
import CONSTANTS, {
  PAGES_ROUTES,
  PARAMETER_VALIDATION_SCHEMA,
  RegexPatterns,
  VALIDATIONS,
} from '../../../Constants'
import {
  FormValues,
  ParameterListResponse,
  createParameterReqData,
  editParameterReqData,
} from '../../../Models'
import {
  createParameterService,
  updateParameterService,
  viewParameterService,
} from '../../../Store/Slice/parameter.slice'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { unwrapResult } from '@reduxjs/toolkit'
import { combineErrorMessage, handleResponse, trimStrings } from '../../../Helpers/Util'
import { useNavigate } from 'react-router-dom'
import { setLoader } from '../../../Store/Slice/loader.slice'
import { PARAMETER_MANAGEMENT_CRUD, SYSTEM_CONFIG_APIS } from '../../../Constants/ApiDefinations'
import mainApiService from '../../../Services'
import useFetchConfigurationData from '../../../Hooks/useFetchConfigurationData'
interface ParameterFormProps {
  parameterid?: string
  getParameterData: CallableFunction
  parameterPageType: 'create' | 'edit' | 'view'
  parameterDetail: FormValues
}
const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: '#D4351C' },
      },
    },
  },
})
interface SegmentationMaster {
  [key: string]: string | boolean
  label: string
  value: string
  id: string
}
interface ParameterMaster {
  parameterName: string
  _id?: string
}
const ParameterForm: React.FC<ParameterFormProps> = ({
  parameterid,
  parameterPageType,
  parameterDetail,
  getParameterData,
}) => {
  const [loading, setLoading] = useState(true)
  const [parameterListData, setParameterListData] = useState<ParameterMaster[]>([])
  const [selectedParameterListData, setSelectedParameterListData] = useState<[]>([])
  const [initialValues, setInitialValues] = useState<FormValues>(parameterDetail)
  const resolver: Resolver<any> = yupResolver(PARAMETER_VALIDATION_SCHEMA)
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setError,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: resolver,
    defaultValues: initialValues,
    mode: 'onChange',
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { VIEW, CREATE, EDIT } = CONSTANTS.PAGE_TYPE
  const { state, fetchConfigurationData } = useFetchConfigurationData()
  const watchAllFields: any = watch()
  useEffect(() => {
    fetchConfigurationData('MASTER')
    fetchConfigurationData('DATA_TYPE')
    fetchConfigurationData('FIELD_TYPE')
  }, [fetchConfigurationData])
  const getParameterListFunction = async () => {
    dispatch(setLoader(true))
    const requestedData: any = PARAMETER_MANAGEMENT_CRUD.COMPLEX_PARAMETER_LIST()
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      const tempData: ParameterMaster[] = []
      result?.data?.records?.forEach((ele: ParameterListResponse) => {
        tempData.push({
          parameterName: ele?.parameterName,
          _id: ele?._id,
        })
      })
      setParameterListData(tempData)
      dispatch(setLoader(false))
    } else {
      dispatch(showAlert([true, combineErrorMessage(result), 'error']))
      dispatch(setLoader(false))
    }
  }
  const saveParameter = async (data: any) => {
    const reqData: createParameterReqData & { parameterRepositoryId?: string } = {
      parameterName: data?.parameterName,
      status: data?.status?.toLowerCase() === 'active' ? 'ACTIVE' : 'INACTIVE',
      fieldName: data?.fieldName,
      fieldType: data?.fieldType,
      master: data?.master,
      multiSelect: data?.multiSelect?.toLowerCase() === 'no' ? false : true,
      purpose: data?.purpose,
      defaultValue: data?.defaultValue,
      esField: data?.esField,
      mandatory: data?.mandatory?.toLowerCase() === 'no' ? false : true,
      addMore: data?.addMore?.toLowerCase() === 'no' ? false : true,
      placeholder: data?.placeholder,
      dataType: data?.dataType,
    }
    if (parameterPageType === EDIT && parameterid) {
      reqData.parameterRepositoryId = parameterid
    }
    const service = parameterPageType === EDIT ? updateParameterService : createParameterService
    const result = await dispatch(service(reqData) as any)
    handleResult(result)
    const { message, error, responseCode } = handleResponse(result)
    dispatch(showAlert([true, message, error ? 'error' : 'success']))
  }
  const handleResult = (result: any) => {
    const unwrappableRes = unwrapResult(result)
    if (unwrappableRes?.error) {
      if (unwrappableRes?.error?.errors?.length || unwrappableRes?.error?.data?.length) {
        const parameternameExist: string =
          unwrappableRes?.error?.errors?.find(
            (err: string) =>
              err.includes(VALIDATIONS.PARAMETERNAME_ALREADY_EXIST) ||
              err.includes('Entered Parameter name already exists!'),
          ) ||
          '' ||
          unwrappableRes?.error?.data?.find(
            (err: string) =>
              err.includes(VALIDATIONS.PARAMETERNAME_ALREADY_EXIST) ||
              err.includes('Entered Parameter name already exists!'),
          ) ||
          ''
        const fieldNameExist: string =
          unwrappableRes?.error?.errors?.find((err: string) =>
            err.includes(VALIDATIONS.FIELDNAME_ALREADY_EXIST),
          ) ||
          '' ||
          unwrappableRes?.error?.data?.find((err: string) =>
            err.includes('cyberarkId is having invalid value.'),
          ) ||
          ''
        const masterInvalid: string =
          unwrappableRes?.error?.errors?.find((err: string) =>
            err.includes(VALIDATIONS.MASTER_INVALID_VALUE),
          ) ||
          '' ||
          unwrappableRes?.error?.data?.find((err: string) =>
            err.includes(VALIDATIONS.MASTER_INVALID_VALUE),
          ) ||
          ''
        if (parameternameExist) {
          setError('parameterName', {
            type: 'manual',
            message: VALIDATIONS.PARAMETERNAME_ALREADY_EXIST,
          })
        }
        if (fieldNameExist) {
          setError('fieldName', {
            type: 'manual',
            message: VALIDATIONS.FIELDNAME_ALREADY_EXIST,
          })
        }
        if (masterInvalid) {
          setError('master', {
            type: 'manual',
            message: VALIDATIONS.MASTER_INVALID_VALUE,
          })
        }
        return
      } else {
        dispatch(showAlert([true, unwrappableRes?.error?.message, 'error']))
        return
      }
    }
    dispatch(showAlert([true, unwrappableRes?.message, 'success']))
    if (parameterPageType === CREATE) {
      navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ParameterRepository}`)
    }
    if (!(parameterPageType === VIEW || parameterPageType === CREATE)) {
      getParameterData(parameterid)
      navigate(
        `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ParameterRepository}/${PAGES_ROUTES.PageTypeView}/${parameterid}`,
      )
    }
  }
  const onSubmit = (data: FormValues) => {
    const trimmedData = trimStrings(data)
    saveParameter(trimmedData)
  }
  useEffect(() => {
    if (parameterPageType === EDIT && parameterid) {
      const trimmedParameterDetail = trimStrings(parameterDetail)
      setInitialValues(trimmedParameterDetail)
      reset(trimmedParameterDetail)
    } else {
      setLoading(false)
    }
  }, [parameterid, parameterPageType, reset])
  const fieldType = watch('fieldType')
  useEffect(() => {
    if (fieldType.toLowerCase() === 'complextype') getParameterListFunction()
  }, [fieldType])

  return (
    <MUIBox>
      <div className='heading-6 d-flex text-left rolebox__header-padding'>
        Parameter Information
      </div>
      <div className={'hr'}></div>
      <ThemeProvider theme={theme}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' style={{ padding: 20 }}>
          <Grid container columnSpacing={10} rowSpacing={4}>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <MUIField
                name='parameterName'
                label='Parameter Name'
                required
                type='text'
                control={control as any | undefined}
                error={errors}
                maxLength={100}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <MUIField
                name='fieldName'
                label='Field Name'
                required
                type='text'
                control={control as any | undefined}
                error={errors}
                maxLength={100}
              />
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                name='fieldType'
                lable={'Field Type'}
                required
                control={control as any | undefined}
                error={errors}
                options={state['FIELD_TYPE']}
              />
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                name='dataType'
                lable='Data Type'
                required
                control={control as any | undefined}
                error={errors}
                options={state['DATA_TYPE']}
              />
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                name='master'
                lable='Master'
                control={control as any | undefined}
                error={errors}
                required
                options={state['MASTER']}
              />
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                name='multiSelect'
                lable='Multi Select'
                required
                control={control as any}
                error={errors}
                defaultValueIndex={0}
                rules={{
                  required: VALIDATIONS.REQUIRED,
                }}
                options={CONSTANTS.COMMON_YES_NO}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <MUIField
                name='defaultValue'
                label='Default Value'
                type='text'
                control={control as any | undefined}
                error={errors}
                maxLength={100}
              />
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                name='status'
                lable={'Status'}
                required
                control={control as any}
                error={errors}
                defaultValueIndex={0}
                rules={{ required: VALIDATIONS.REQUIRED }}
                options={CONSTANTS.ROLE_STATUS}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <MUIField
                name='esField'
                label='Es Field'
                type='text'
                control={control as any | undefined}
                error={errors}
                maxLength={100}
              />
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                name='mandatory'
                lable='Mandatory'
                required
                control={control as any}
                error={errors}
                rules={{ required: VALIDATIONS.REQUIRED }}
                options={CONSTANTS.COMMON_YES_NO}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <MUIField
                name='placeholder'
                label='Placeholder'
                type='text'
                required
                control={control as any | undefined}
                error={errors}
                maxLength={300}
              />
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                name='addMore'
                lable={'Add More'}
                control={control as any}
                error={errors}
                rules={{ required: VALIDATIONS.REQUIRED }}
                options={CONSTANTS.COMMON_YES_NO}
              />
            </Grid>

            {fieldType.toLowerCase() === 'complextype' && (
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUIMultiselectAsset
                  label={'Parameters'}
                  options={parameterListData || ['']}
                  labelKey={'parameterName'}
                  valueKey={'_id'}
                  multiple={true}
                  required={true}
                  onInputChange={(e: any, value: any) => {
                    setSelectedParameterListData(value)
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <MUIField
                name='purpose'
                label='Purpose'
                required
                type='text'
                control={control as any | undefined}
                error={errors}
                maxLength={100}
              />
            </Grid>
            <Grid item xs={12} className='d-flex justify-content-end'>
              <MUIButton
                size='large'
                disabled={!isValid}
                type={'submit'}
                label={'Save'}
                variant='contained'
                width={200}
                className={isValid ? 'c-btn' : ''}
              />
            </Grid>
          </Grid>
        </form>
      </ThemeProvider>
    </MUIBox>
  )
}
export default ParameterForm
