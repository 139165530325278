import Pagination from '@mui/material/Pagination'
import { styled } from '@mui/system'
import React from 'react'
import { PaginationProps } from '../../../Models'
const StyledPagination = styled(Pagination)(() => ({
  '& .MuiPaginationItem-page': {
    color: '#402080',
    fontSize: '14px',
    fontWeight: '550',
    '&.Mui-selected': {
      background: '#E2DEEC',
    },
  },
}))

const MUIPagination: React.FC<PaginationProps> = ({ onChange, count = 30, page = 1 }) => {
  return (
    <StyledPagination
      className='bg-col70 pagination-container'
      count={count}
      data-testid={'pagination'}
      shape='rounded'
      onChange={onChange}
      page={page}
      siblingCount={0}
    />
  )
}
export default MUIPagination
