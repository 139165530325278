import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FC, ReactNode } from 'react'

export interface selectOptionModal {
  label: string | number
  value: string | number
}
interface SimpleDropdownModal {
  value: string | number
  handleChange?: (event: SelectChangeEvent<string | number>, child: ReactNode) => void
  options: selectOptionModal[]
  fullWidth?: boolean
  height?: string
  selectId?: string
  label?: string
  minWidth?: string
}
export const SimpleDropdown: FC<SimpleDropdownModal> = ({
  value,
  handleChange,
  options = [],
  fullWidth = false,
  height,
  selectId = 'select',
  label = '',
  minWidth = '',
}) => {
  return (
    <>
      <FormControl sx={{ minWidth: fullWidth ? '100%' : minWidth }}>
        <InputLabel className='input-label-class' htmlFor={selectId}>
          {label}
        </InputLabel>
        <Select
          sx={{ minWidth: 100, height: { height }, borderRadius: '6px' }}
          className='bg-col70 c-drop-down-pagination'
          value={value}
          label={label}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label', id: 'page-dropdown' }}
          fullWidth={fullWidth}
          // id={selectId}
          data-testid={selectId}
          IconComponent={KeyboardArrowDownIcon}
        >
          {options.map((ele) => {
            return (
              <MenuItem key={ele.value} value={ele.value} sx={{ zIndex: 10000 }}>
                {ele.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  )
}

export default SimpleDropdown
