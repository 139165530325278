import { ThemeProvider } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import './App.scss'
import MUIAlert from './Components/Shared/MUI-Alert'
import { logger } from './Helpers'
import { MUITheme } from './Helpers/MUI'
import router from './Routes'

function App() {
  const state = useSelector((s: any) => s.alert)
  logger.info('Env: ', process.env.REACT_APP_ENV)

  return (
    <>
      <ThemeProvider theme={MUITheme()}>
        <RouterProvider router={router} />
        <MUIAlert state={state.alertProps} />
      </ThemeProvider>
    </>
  )
}

export default React.memo(App)
