import { combineReducers } from '@reduxjs/toolkit'
import alertSlice from '../Slice/alert.slice'
import appTooltipsSlice from '../Slice/app-tooltips.slice'
import auditLogsSlice from '../Slice/auditLogs.slice'
import containerDefinitionSlice from '../Slice/container-definition.slice'
import countriesSlice from '../Slice/countries.slice'
import dataEngineCategorySlice from '../Slice/data-engine-category.slice'
import loaderSlice from '../Slice/loader.slice'
import manageWidgetSlice from '../Slice/manage-widget.slice'
import { masterSlice } from '../Slice/masters.slice'
import orchestrationPersistSlice from '../Slice/orchestrationPersist.slice'
import roleSlice from '../Slice/role.slice'
import segmentSlice from '../Slice/segment.slice'
import systemConfigurationSlice from '../Slice/system-configuration.slice'
import userSlice from '../Slice/user.slice'
import widgetDefinitionSlice from '../Slice/widget-definition.slice'
import manageContainerSlice from '../Slice/manage-container.slice'
import siteNavigationSlice from '../Slice/site-navigation.slice'

export const rootReducer = combineReducers({
  [loaderSlice.name]: loaderSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [alertSlice.name]: alertSlice.reducer,
  [roleSlice.name]: roleSlice.reducer,
  [auditLogsSlice.name]: auditLogsSlice.reducer,
  [orchestrationPersistSlice.name]: orchestrationPersistSlice.reducer,
  [appTooltipsSlice.name]: appTooltipsSlice.reducer,
  [segmentSlice.name]: segmentSlice.reducer,
  [countriesSlice.name]: countriesSlice.reducer,
  [systemConfigurationSlice.name]: systemConfigurationSlice.reducer,
  [containerDefinitionSlice.name]: containerDefinitionSlice.reducer,
  [widgetDefinitionSlice.name]: widgetDefinitionSlice.reducer,
  [manageWidgetSlice.name]: manageWidgetSlice.reducer,
  [masterSlice.name]: masterSlice.reducer,
  [dataEngineCategorySlice.name]: dataEngineCategorySlice.reducer,
  [manageContainerSlice.name]: manageContainerSlice.reducer,
  [siteNavigationSlice.name]: siteNavigationSlice.reducer,
})
