function CloneListingIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g>
        <path
          d='M9.99967 0.666748H2.66634C1.93301 0.666748 1.33301 1.26675 1.33301 2.00008V10.6667C1.33301 11.0334 1.63301 11.3334 1.99967 11.3334C2.36634 11.3334 2.66634 11.0334 2.66634 10.6667V2.66675C2.66634 2.30008 2.96634 2.00008 3.33301 2.00008H9.99967C10.3663 2.00008 10.6663 1.70008 10.6663 1.33341C10.6663 0.966748 10.3663 0.666748 9.99967 0.666748ZM12.6663 3.33341H5.33301C4.59967 3.33341 3.99967 3.93341 3.99967 4.66675V14.0001C3.99967 14.7334 4.59967 15.3334 5.33301 15.3334H12.6663C13.3997 15.3334 13.9997 14.7334 13.9997 14.0001V4.66675C13.9997 3.93341 13.3997 3.33341 12.6663 3.33341ZM11.9997 14.0001H5.99967C5.63301 14.0001 5.33301 13.7001 5.33301 13.3334V5.33341C5.33301 4.96675 5.63301 4.66675 5.99967 4.66675H11.9997C12.3663 4.66675 12.6663 4.96675 12.6663 5.33341V13.3334C12.6663 13.7001 12.3663 14.0001 11.9997 14.0001Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_686_8938'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default CloneListingIcon
