/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  master: [],
  errors: null,
}
export const masterSlice = createSlice({
  name: 'master',
  initialState,
  reducers: {
    setMaster: (state, action: PayloadAction<string>) => {
      state.master = action.payload
    },
    setHookFormErrors: (state, action: PayloadAction<any>) => {
      state.errors = action.payload
    },
  },
})
export const { setMaster, setHookFormErrors } = masterSlice.actions
const masterSelector = (state: any) => state.master
export const selectMasterData = createSelector(masterSelector, (state) => state.master)
export const selectHookFormErrors = createSelector(masterSelector, (state) => state.errors)
