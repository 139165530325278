import {
  CreateMastersData,
  CreateSegmentRequestModal,
  CreateUserRequestModal,
  SegmentCRUDAPIDefinitionModal,
  UserCRUDAPIDefinitionModal,
  ViewMastersAPIDefinition,
  auditLogsApiModal,
  createRoleReqData,
  parameterCRUDAPIDefinitionModal,
  parameterStatusChangeDefinitionModal,
  roleCRUDAPIDefinitionModal,
  roleStatusChangeDefinitionModal,
  userStatusChangeDefinitionModal,
  viewParameterAPIDefinationModal,
  viewRoleAPIDefinationModal,
} from '../../Models'
import { categoryListApiDefinitionModal } from '../../Models/AssetDefinitionModal'
import { attributeCRUDAPIDefinitionModal } from '../../Models/AttributeModal'
import { WidgetCRUDAPIDefinitionModal } from '../../Store/Slice/manage-widget.slice'
import { CONSTANTS } from '../Constant'

export const AUTH_MANAGEMENT = {
  VALIDATE_CODE_LOCAL: (code: string) => ({
    url: '/sso/login',
    method: CONSTANTS.HTTP_METHOD.GET,
    params: {
      code: code,
    },
    headers: {
      'Dev-Mode': true,
    },
  }),
  VALIDATE_CODE: (code: string) => ({
    url: '/sso/login',
    method: CONSTANTS.HTTP_METHOD.GET,
    params: {
      code: code,
    },
  }),
  REFRESH_TOKEN: () => ({
    url: '/session/refreshToken',
    method: CONSTANTS.HTTP_METHOD.GET,
    params: {},
  }),
  RELEASE_INFO: () => ({
    url: '/application/build-version/get',
    method: CONSTANTS.HTTP_METHOD.GET,
    params: {},
  }),
  LOGOUT: () => ({
    url: '/session/logout',
    method: CONSTANTS.HTTP_METHOD.GET,
    params: {},
  }),
}
export const SYSTEM_CONFIG_APIS = {
  LIST_MASTERS: (key: string) =>
    ({
      url: '/system-configurations/list?keyName=' + key,
      method: 'get',
      data: {},
    }) as categoryListApiDefinitionModal,
}
export const ROLE_MANAGEMENT_CRUD = {
  CREATE_ROLE: (data: createRoleReqData) =>
    ({
      url: '/userRole/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as roleCRUDAPIDefinitionModal,
  VIEW_ROLE: (id: string) =>
    ({
      url: '/userRole/detail/' + id,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewRoleAPIDefinationModal,
  PERMISSIONS_LIST: () =>
    ({
      url: '/config/permissions',
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewRoleAPIDefinationModal,
  LIST_ROLE: (data: string) =>
    ({
      url: '/userRole/list' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewRoleAPIDefinationModal,
  GET_ROLES: () =>
    ({
      url: '/userrole/list',
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  EDIT_ROLE: (data: createRoleReqData) =>
    ({
      url: '/userRole/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as roleCRUDAPIDefinitionModal,
  DELETE_ROLE: (id: string) =>
    ({
      url: '/userRole/delete/' + id,
      method: CONSTANTS.HTTP_METHOD.DELETE,
      data: {},
    }) as roleCRUDAPIDefinitionModal,
  CHANGE_STATUS: (data: { status: string; roleId: string }) =>
    ({
      url: '/userRole/updateRoleStatus',
      method: CONSTANTS.HTTP_METHOD.PUT,
      data: data,
    }) as roleStatusChangeDefinitionModal,
}

export const USER_MANAGEMENT_CRUD = {
  CREATE_USER: (data: CreateUserRequestModal) =>
    ({
      url: '/user/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as UserCRUDAPIDefinitionModal,
  UPDATE_USER: (data: CreateUserRequestModal) =>
    ({
      url: '/user/update',
      method: 'patch',
      data: data,
    }) as UserCRUDAPIDefinitionModal,
  VIEW_USER: (id: string) =>
    ({
      url: `/user/list?page=1&filterBy=_id&filterValue=${id}&filterCondition=isEqualTo`,
      method: 'get',
      data: {},
    }) as roleCRUDAPIDefinitionModal,
  CHANGE_STATUS: (data: { userId: string; userStatus: string }) =>
    ({
      url: '/user/updateUserStatus',
      method: 'patch',
      data: data,
    }) as userStatusChangeDefinitionModal,
  LIST_USER: (data: string) =>
    ({
      url: '/user/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
}
export const PARAMETER_MANAGEMENT_CRUD = {
  CREATE_PARAMETER: (data: any) =>
    ({
      url: '/parameter-repository/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as parameterCRUDAPIDefinitionModal,
  CLONE_PARAMETER: (data: any, apiMidpoint: string | undefined) =>
    ({
      url: `/${apiMidpoint}/clone`,
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as parameterCRUDAPIDefinitionModal,
  UPDATE_PARAMETER: (data: any) =>
    ({
      url: '/parameter-repository/update',
      method: CONSTANTS.HTTP_METHOD.PUT,
      data: data,
    }) as parameterCRUDAPIDefinitionModal,

  // VIEW_PARAMETER: (id: string) =>
  //   ({
  //     url: `/parameter-repository/list?page=1&filterBy=_id&filterValue=${id}&filterCondition=isEqualTo`,
  //     method: 'get',
  //     data: {},
  //   }) as viewParameterAPIDefinationModal,
  VIEW_PARAMETER: (id: string) =>
    ({
      url: '/parameter-repository/detail/' + id,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewParameterAPIDefinationModal,

  LIST_PARAMETER: (data: string) =>
    ({
      url: '/parameter-repository/list' + '?' + data,
      //  url: '/parameter-repository/list'+'?'+ 'isComplexType=false',
      method: 'get',
      data: {},
    }) as viewParameterAPIDefinationModal,
  COMPLEX_PARAMETER_LIST: () =>
    ({
      // url: '/parameter-repository/list'+'?'+data,
      url: '/parameter-repository/list' + '?' + 'isComplexType=true',
      method: 'get',
      data: {},
    }) as viewParameterAPIDefinationModal,
  DELETE_PARAMETER: (id: string) =>
    ({
      url: '/parameter-repository/delete/' + id,
      method: CONSTANTS.HTTP_METHOD.DELETE,
      data: {},
    }) as parameterCRUDAPIDefinitionModal,
  PARAMETER_MASTER_LIST: () =>
    ({
      url: '/config/masterlist',
      // +data
      method: 'get',
      data: {},
    }) as viewParameterAPIDefinationModal,
  CHANGE_STATUS: (data: { parameterId: string; status: string }) =>
    ({
      url: '/parameter-repository/updateParameterStatus',
      method: CONSTANTS.HTTP_METHOD.PUT,
      data: data,
    }) as parameterStatusChangeDefinitionModal,
}

export const HOME_PAGE = {
  GET_HOME_PAGE: (data: string) =>
    ({
      url: '/dashboard/detail' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as auditLogsApiModal,
}
export const HOME = {
  RECENT_ACTIVITY: {
    url: '/homePage/recentActivity',
    method: CONSTANTS.HTTP_METHOD.GET,
    data: {},
  } as { url: string; method: string; data: unknown },
}

export const GLOBAL_SEARCH = {
  LIST: (data: string) =>
    ({
      url: '/global-search/list' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  ADVANCE_SEARCH: (data: any) =>
    ({
      url: '/global-search/list',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as attributeCRUDAPIDefinitionModal,
}

export const MANAGETIP_APIS_CRUD = {
  GET_TIP: () => ({
    url: '/tooltip/list',
    method: 'get',
    data: {},
  }),

  TIP_UPDATE: (data: any) => ({
    url: '/tooltip/update',
    method: CONSTANTS.HTTP_METHOD.PATCH,
    data: data,
  }),
}

// export const SYSTEM_CONFIG_APIS = {
//   LIST_MASTERS: (key: string) =>
//     ({
//       url: '/attributeRepository/systemConfigurations/list?keyName=' + key,
//       method: 'get',
//       data: {},
//     }) as categoryListApiDefinitionModal,
// }

export const SEGMENT_MANAGEMENT_CRUD = {
  CREATE_SEGMENT: (data: CreateSegmentRequestModal) =>
    ({
      url: '/segment/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as SegmentCRUDAPIDefinitionModal,
  UPDATE_SEGMENT: (data: CreateSegmentRequestModal) =>
    ({
      url: '/segment/update',
      method: 'patch',
      data: data,
    }) as SegmentCRUDAPIDefinitionModal,
  VIEW: (id: string) =>
    ({
      url: `/segment/detail/${id}`,
      method: 'get',
      data: {},
    }) as SegmentCRUDAPIDefinitionModal,
  LIST: (data: string) =>
    ({
      url: '/segment/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  DELETE_SEGMENT: (id: string) =>
    ({
      url: '/segment/delete/' + id,
      method: CONSTANTS.HTTP_METHOD.DELETE,
      data: {},
    }) as SegmentCRUDAPIDefinitionModal,
  GET_ALL_SEGMENT_NAME: () =>
    ({
      url: '/segment/getAllSegmentName',
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as SegmentCRUDAPIDefinitionModal,
}

export const MASTERS_MANAGEMENT = {
  MASTER: {
    LIST: () =>
      ({
        url: '/config/masterlist',
        method: 'get',
        data: {},
      }) as ViewMastersAPIDefinition,
  },
  COUNTRIES_CRUD: {
    LIST_COUNTRIES: (data: string) =>
      ({
        url: '/countries/list' + '?' + data,
        method: 'get',
        data: {},
      }) as ViewMastersAPIDefinition,
    UPDATE_COUNTRIES: (data: CreateMastersData) => ({
      url: '/countries/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data,
    }),
    CREATE_COUNTRIES: (data: CreateMastersData) => ({
      url: '/countries/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
  },
  SEGMENT_MASTER: {
    LIST: (apiMidpoint: string, paramsData: any) =>
      ({
        url: `/${apiMidpoint}/list` + '?' + paramsData,
        method: 'get',
        data: {},
      }) as ViewMastersAPIDefinition,

    CREATE: (apiMidpoint: string, data: any) => ({
      url: `/${apiMidpoint}/add`,
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
    UPDATE: (apiMidpoint: string, data: any) => ({
      url: `/${apiMidpoint}/update`,
      method: CONSTANTS.HTTP_METHOD.PUT,
      data,
    }),
  },
  SYSTEM_CONFIGURATION_CRUD: {
    LIST: (data: string) =>
      ({
        url: '/system-configurations/list' + '?' + data,
        method: 'get',
        data: {},
      }) as ViewMastersAPIDefinition,
    UPDATE: (data: any) => ({
      url: '/system-configurations/update',
      method: CONSTANTS.HTTP_METHOD.PUT,
      data,
    }),
    CREATE: (data: any) => ({
      url: '/system-configurations/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
  },
}

export const CONTAINER_DEFINITION_CRUD = {
  LISTING: (data: string) =>
    ({
      url: '/containerDefinition/list' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  TOLLGATES_LISTING: (data: string) =>
    ({
      url: '/system-configurations/list' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  PARAMETER_LISTING: (data: string) =>
    ({
      url: '/parameter-repository/list' + '?' + data,
      method: 'get',
      data: CONSTANTS.HTTP_METHOD.GET,
    }) as any,
  CREATE: (data: any) => ({
    url: '/containerDefinition/add',
    method: CONSTANTS.HTTP_METHOD.POST,
    data,
  }),
  UPDATE: (data: any) => ({
    url: '/containerDefinition/update',
    method: CONSTANTS.HTTP_METHOD.PATCH,
    data,
  }),
  GET: (data: any) => ({
    url: '/containerDefinition/detail/' + data,
    method: CONSTANTS.HTTP_METHOD.GET,
    data: {},
  }),
  UPDATE_STATUS: (data: any) => ({
    url: '/containerDefinition/updateStatus',
    method: CONSTANTS.HTTP_METHOD.PATCH,
    data,
  }),
}

export const WIDGET_DEFINITION_CRUD = {
  LISTING: (data: string) =>
    ({
      url: '/widgetDefinition/list' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  TOLLGATES_LISTING: (data: string) =>
    ({
      url: '/system-configurations/list' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  PARAMETER_LISTING: (data: string) =>
    ({
      url: '/parameter-repository/list' + '?' + data,
      method: 'get',
      data: CONSTANTS.HTTP_METHOD.GET,
    }) as any,
  CREATE: (data: any) => ({
    url: '/widgetDefinition/add',
    method: CONSTANTS.HTTP_METHOD.POST,
    data,
  }),
  UPDATE: (data: any) => ({
    url: '/widgetDefinition/update',
    method: CONSTANTS.HTTP_METHOD.PATCH,
    data,
  }),
  GET: (data: any) => ({
    url: '/widgetDefinition/detail/' + data,
    method: CONSTANTS.HTTP_METHOD.GET,
    data: {},
  }),
  UPDATE_STATUS: (data: any) => ({
    url: '/widgetDefinition/updateStatus',
    method: CONSTANTS.HTTP_METHOD.PATCH,
    data,
  }),
  VALIDAION_LISTING: () =>
    ({
      url: '/validation/list',
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
}

export const COUNTRIES_CRUD = {
  LIST_COUNTRY: (data: string) =>
    ({
      url: '/country/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
}

export const CLUSTER_CRUD = {
  LIST_CLUSTER: (data: string) =>
    ({
      url: '/cluster/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
}

export const PACK_INFO = {
  LIST_PACK: (data: string) =>
    ({
      url: '/pack/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
}

export const PLATFORM_CRUD = {
  LIST_PLATFORM: (data: string) =>
    ({
      url: '/platform/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
}

export const STATE_CRUD = {
  LIST_STATE: (data: string) =>
    ({
      url: '/state/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
}

export const AB_SEGMENT = {
  LIST_AB_SEGMENT: (data: string) =>
    ({
      url: '/ab-segment/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
}

export const PUBLISHING_SEGMENT_CRUD = {
  LIST_PUBLISHING_SEGMENT: (data: string) =>
    ({
      url: '/publishing-segment/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
}

export const WIDGET_MANAGEMENT_CRUD = {
  LAYOUT: {
    LIST: (query: string) =>
      ({
        url: `/config/widgetLayout?${query}`,
        method: 'get',
        data: {},
      }) as viewRoleAPIDefinationModal,
  },
  IMAGE_UPLOAD: (data: any) =>
    ({
      url: '/widget/image',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as WidgetCRUDAPIDefinitionModal,
  LISTING: (data: any) =>
    ({
      url: '/widget/list',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }) as any,
  ADD: (data: any) =>
    ({
      url: '/widget/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as WidgetCRUDAPIDefinitionModal,
  UPDATE: (data: any) =>
    ({
      url: '/widget/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as WidgetCRUDAPIDefinitionModal,
  ASSET_LISTING: (data: string) =>
    ({
      url: '/widget/assets' + '?' + data,
      method: 'get',
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  TYPES_LISTING: () =>
    ({
      url: '/widget/types',
      method: 'get',
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  LAYOUTS_LISTING: (widgetType: string) =>
    ({
      url: '/widget/layouts?widget_type=' + widgetType,
      method: 'get',
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,

  GET_ASSETS_DETAIL_BY_IDS: (ids: string[]) =>
    ({
      url: '/widget/assetMetadata?contentIds=' + ids?.join('%2C'),
      method: 'get',
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  GET_WIDGET_DEFINITION_BY_ID: (id: string) =>
    ({
      url: `/widget/widget-definition/${id}`,
      method: 'get',
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  GET_WIDGET_DETAILS_BY_ID: (id: string) =>
    ({
      url: `/widget/detail/${id}`,
      method: 'get',
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  GET_WIDGET_AUTOMATED_LISTS: (data: any) =>
    ({
      url: '/widget/automatedassets',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }) as WidgetCRUDAPIDefinitionModal,
  AUTOMATTED_ADD: (data: any) =>
    ({
      url: '/widget/automatedassets',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as any,
  WIDGET_AUTOMATTED_ADD: (data: any) =>
    ({
      url: '/widget/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as any,
  WIDGET_AUTOMATTED_UPDATE: (data: any) =>
    ({
      url: '/widget/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as any,
  MARK_AS_DONE: (data: any) =>
    ({
      url: '/widget/markDone',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data,
    }) as any,
  WIDGET_FEATURE: (data: any) =>
    ({
      url: '/widget/feature',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as any,
  WIDGET_UNFEATURE: (data: any) =>
    ({
      url: '/widget/unfeature',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as any,
  CONTENT_METADATA: (data: any) =>
    ({
      url: '/widget/contentmetadata',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as any,

  WIDGET_IMAGES_LIST: (widgetId: any, widgetState: any) =>
    ({
      url: `/widget/images?widgetId=${widgetId}&state=${widgetState}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  VERSION_LIST: (data: any) =>
    ({
      url: '/widget/versions',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as any,
  CHECK_DRAFT_VERSION: (id: string, widgetId: string) =>
    ({
      url: `/widget/checkDraftVersion/${id}/${widgetId}`,
      method: 'get',
      data: {},
    }) as any,
  CREATE_VERSION: (id: string) =>
    ({
      url: `/widget/createVersion/${id}`,
      method: 'get',
      data: {},
    }) as any,
}

export const CONTAINER_MANAGEMENT_CRUD = {
  LAYOUT: {
    LIST: () =>
      ({
        url: '/config/containerLayout',
        method: CONSTANTS.HTTP_METHOD.GET,
        data: {},
      }) as any,
  },
  WIDGET_META: (ids: string[]) =>
    ({
      url: '/widget/metadata?widgetIds=' + ids.join('%2C'),
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  LISTING: (data: any) =>
    ({
      url: '/container/list',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }) as any,
  TYPES: () =>
    ({
      url: '/container/types',
      method: 'get',
      data: {},
    }) as any,
  LAYOUTS: (types: string) =>
    ({
      url: '/container/layouts?container_type=' + types,
      method: 'get',
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  GET_CONTAINER_DEFINITION_DETAIL_BY_ID: (id: string) =>
    ({
      url: `/container/definition/${id}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  GET_CONTAINER_DETAIL_BY_ID: (id: string) =>
    ({
      url: `/container/detail/${id}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  ADD: (data: any) =>
    ({
      url: '/container/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as WidgetCRUDAPIDefinitionModal,
  UPDATE: (data: any) =>
    ({
      url: '/container/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as WidgetCRUDAPIDefinitionModal,
  IMAGE_UPLOAD: (data: any) =>
    ({
      url: '/container/image',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as WidgetCRUDAPIDefinitionModal,
  MARK_AS_DONE: (data: any) =>
    ({
      url: '/container/markDone',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data,
    }) as any,
  CONTAINER_FEATURE: (data: any) =>
    ({
      url: '/container/feature',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as any,
  CONTAINER_UNFEATURE: (data: any) =>
    ({
      url: '/container/unfeature',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as any,

  VERSION_LIST: (data: any) =>
    ({
      url: '/container/versions',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as any,
  CHECK_DRAFT_VERSION: (id: string, containerId: string) =>
    ({
      url: `/container/checkDraftVersion/${id}/${containerId}`,
      method: 'get',
      data: {},
    }) as any,
  CREATE_VERSION: (id: string) =>
    ({
      url: `/container/createVersion/${id}`,
      method: 'get',
      data: {},
    }) as any,
}

export const BLITZ_MASTER_CRUD = {
  LISTING: (key: string) =>
    ({
      url: `/blitz-master/list?keyName=${key}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
}

export const CATEGORY_CRUD = {
  LISTING: (data: { contentCategoryValue: string[]; assetCategoryValue: string[] }) =>
    ({
      url: '/category/list',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }) as any,
}

export const ASSETS_CRUD = {
  GENERATE_PRESIGNED_URL: (type: string, key: string) =>
    ({
      url: `/${type}/pre-signed-url?key=${key}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
}

export const SITE_NAVIGATION_CRUD = {
  LAYOUT: {
    LIST: () =>
      ({
        url: '/config/widgetLayout',
        method: CONSTANTS.HTTP_METHOD.GET,
        data: {},
      }) as any,
  },
  LISTING: (data: any) =>
    ({
      url: '/site-navigation/list',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }) as any,
  TYPES_LISTING: () =>
    ({
      url: '/site-navigation/types',
      method: 'get',
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  LAYOUTS_LISTING: (widgetType: string) =>
    ({
      url: '/site-navigation/layouts?type=' + widgetType,
      method: 'get',
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  ADD: (data: any) =>
    ({
      url: '/site-navigation/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as WidgetCRUDAPIDefinitionModal,
  UPDATE: (data: any) =>
    ({
      url: '/site-navigation/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as WidgetCRUDAPIDefinitionModal,
  GET_DETAIL_BY_ID: (id: string) =>
    ({
      url: `/site-navigation/detail/${id}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  GET_MODAL_PARAMETER_FORM: () =>
    ({
      url: '/site-navigation/customform',
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as WidgetCRUDAPIDefinitionModal,
  MARK_AS_DONE: (data: any) =>
    ({
      url: '/site-navigation/markDone',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data,
    }) as any,
  FEATURE: (data: any) =>
    ({
      url: '/site-navigation/feature',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as any,
  UNFEATURE: (data: any) =>
    ({
      url: '/site-navigation/unfeature',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as any,
  VERSION_LIST: (data: any) =>
    ({
      url: '/site-navigation/versions',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as any,
  CHECK_DRAFT_VERSION: (id: string, siteNavigationId: string) =>
    ({
      url: `/site-navigation/checkDraftVersion/${id}/${siteNavigationId}`,
      method: 'get',
      data: {},
    }) as any,
  CREATE_VERSION: (id: string) =>
    ({
      url: `/site-navigation/createVersion/${id}`,
      method: 'get',
      data: {},
    }) as any,
}
export const AUDIT_LOGS = {
  ITEM_LOG_LIST: (data: string) =>
    ({
      url: '/auditTrails/list' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  LIST: (contentId: any) =>
    ({
      url: `/auditTrails/list?contentId=${contentId}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  VIEW: (id: string) =>
    ({
      url: `/auditTrails/list?_id=${id}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  FEATURED: (contentId: string) =>
    ({
      url: `/auditTrails/list?event=Feature&contentId=${contentId}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
}
export const SCHEDULE_CRUD = {
  SCHEDULE_ADD: (data: any) =>
    ({
      url: '/schedule/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }) as any,
  SCHEDULE_UPDATE: (data: any) =>
    ({
      url: '/schedule/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data,
    }) as any,
  SCHEDULE_DRAFT_VERSION: (id: string, segmentid: string, containerId: string) =>
    ({
      url: `/container/checkDraftForScheduledVersion/${id}/${segmentid}/${containerId}`,
      method: CONSTANTS.HTTP_METHOD.GET,
    }) as any,
  SCHEDULE_DRAFT_VERSION_WIDGET: (id: string, containerId: string) =>
    ({
      url: `/widget/checkDraftForScheduledVersion/${id}/${containerId}`,
      method: CONSTANTS.HTTP_METHOD.GET,
    }) as any,
    SCHEDULE_DRAFT_VERSION_SITE_NAVIGATION: (id: string, siteNavigationId: string) =>
      ({
        url: `/site-navigation/checkDraftForScheduledVersion/${id}/${siteNavigationId}`,
        method: CONSTANTS.HTTP_METHOD.GET,
      }) as any,
  SCHEDULE_CHECK_VERSION: (data: any) =>
    ({
      url: '/schedule/checkScheduledVersion',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }) as any,

  DELETE_SCHEDULE: (id: string) =>
    ({
      url: `/schedule/delete/${id}`,
      method: CONSTANTS.HTTP_METHOD.DELETE,
    }) as any,
}
