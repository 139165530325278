import { CONSTANTS } from '..'
import { PAGES_ROUTES } from '../../PagesRoutes'

export const MASTER_MANAGEMENT_CONSTANTS: any = {
  // MASTERS_COLUMNS: [{ id: 'name', label: 'Name', sort: false }],
  MASTERS_COLUMNS: [{ id: 'value', label: 'Name', sort: false }],

  OTHER_MASTERS_LIST: [
    {
      key: 'Affiliate_Partners',
      name: 'Affiliate Partner',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.AffiliatePartners}`,
      sort: false,
    },
    {
      key: 'Languages',
      name: 'Language',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.Languages}`,
      sort: false,
    },
    {
      key: 'Countries',
      name: 'Country',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.Countries}`,
      sort: false,
    },
    {
      key: 'Downstream_Platforms',
      name: 'Downstream Platform',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.DownstreamPlatforms}`,
      sort: false,
    },
    {
      key: 'Entitlement_Packages',
      name: 'Entitlement Package',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.EntitlementPackages}`,
      sort: false,
    },
  ],
  SYSTEM_CONFIGURATION_LIST: [
    {
      key: 'ENTITLEMENT',
      name: 'Entitlement',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/entitlement`,
      sort: false,
    },
    {
      key: 'GENRE',
      name: 'Genre',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/genre`,
      sort: false,
    },
    {
      key: 'BROADCAST_CHANNEL',
      name: 'Broadcast Channel',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/broadcast-channel`,
      sort: false,
    },
    {
      key: 'CONTENT_PROVIDER',
      name: 'Content Provider',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/content-provider`,
      sort: false,
    },
    {
      key: 'MOOD',
      name: 'Mood',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/mood`,
      sort: false,
    },
    {
      key: 'SUB_GENRE',
      name: 'Sub Genre',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/sub-genre`,
      sort: false,
    },
    {
      key: 'THEME',
      name: 'Theme',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/theme`,
      sort: false,
    },
    {
      key: 'CONTENT_SUB_CATEGORY',
      name: 'Content Sub Category',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/content-sub-category`,
      sort: false,
    },
    {
      key: 'RATING',
      name: 'Rating',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/rating`,
      sort: false,
    },
    {
      key: 'WATERMARK_PARTNER',
      name: 'Watermark Partner',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/watermark-partner`,
      sort: false,
    },
    {
      key: 'MAXIMUM_VIDEO_QUALITY',
      name: 'Maximum Video Quality',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/maximum-video-quality`,
      sort: false,
    },
    {
      key: 'MAXIMUM_AUDIO_QUALITY',
      name: 'Maximum Audio Quality',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/maximum-audio-quality`,
      sort: false,
    },
    {
      key: 'MAXIMUM_RESOLUTION',
      name: 'Maximum Resolution',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/maximum-resolution`,
      sort: false,
    },
    {
      key: 'CHANNELS',
      name: 'Channels',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/channels`,
      sort: false,
    },
    {
      key: 'CONTENT_CATEGORY',
      name: 'Content Category',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/content-category`,
      sort: false,
    },
    {
      key: 'ASSET_CATEGORY',
      name: 'Asset Category',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/asset-category`,
      sort: false,
    },
    {
      key: 'ALLOWED_PARENTS',
      name: 'Allowed Parents',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/allowed-parents`,
      sort: false,
    },
    {
      key: 'SCOPE',
      name: 'Scope',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/scope`,
      sort: false,
    },
    {
      key: 'INHERITANCE_RULE',
      name: 'Inheritance Rule',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/inheritance-rule`,
      sort: false,
    },
    {
      key: 'STANDALONE_PUBLISHED',
      name: 'Standalone Published',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/standalone-published`,
      sort: false,
    },
    {
      key: 'FIELD_TYPE',
      name: 'Field Type',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/field-type`,
      sort: false,
    },
    {
      key: 'DAY',
      name: 'Day',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/day`,
      sort: false,
    },
    {
      key: 'ENCRYPTED',
      name: 'Encrypted',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/encrypted`,
      sort: false,
    },
    {
      key: 'CUE_POINT_TYPES',
      name: 'Cue Point Types',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/cue-point-types`,
      sort: false,
    },
    {
      key: 'CONTENT_CLUSTER',
      name: 'Content Cluster',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/content-cluster`,
      sort: false,
    },
    {
      key: 'SEGMENT_ID',
      name: 'Segment Id',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/segment-id`,
      sort: false,
    },
    {
      key: 'TOLLGATES',
      name: 'Tollgates',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/tollgates`,
      sort: false,
    },
    {
      key: 'ENCODING_PROFILE',
      name: 'Encoding Profile',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/encoding-profile`,
      sort: false,
    },
    {
      key: 'CAST_HIERARCHY',
      name: 'Cast Hierarchy',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/cast-hierarchy`,
      sort: false,
    },
    {
      key: 'CAST_ROLE',
      name: 'Cast Role',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/cast-role`,
      sort: false,
    },
    {
      key: 'MEDIA_PROFILE',
      name: 'Media Profile',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/media-profile`,
      sort: false,
    },
    {
      key: 'TRANSCODING_PROCESS_TYPE',
      name: 'Transcoding Process Type',
      url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/transcoding-process-type`,
      sort: false,
    },
  ],
  segmentFieldConfig: {
    country: [
      { label: 'Key', name: 'key', type: 'text', required: true },
      { label: 'Value', name: 'value', type: 'text', required: true },
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        options: CONSTANTS.ROLE_STATUS,
        required: true,
      },
    ],
    'ab-segment': [
      { label: 'Key', name: 'key', type: 'text', required: true },
      { label: 'Value', name: 'value', type: 'text', required: true },
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        options: CONSTANTS.ROLE_STATUS,
        required: true,
      },
    ],
    'publishing-segment': [
      { label: 'Key', name: 'key', type: 'text', required: true },
      { label: 'Value', name: 'value', type: 'text', required: true },
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        options: CONSTANTS.ROLE_STATUS,
        required: true,
      },
    ],
    platform: [
      { label: 'Name', name: 'name', type: 'text', required: true },
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        options: CONSTANTS.ROLE_STATUS,
        required: true,
      },
    ],
    cluster: [
      { label: 'Name', name: 'name', type: 'text', required: true },
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        options: CONSTANTS.ROLE_STATUS,
        required: true,
      },
    ],
    // Add other segments as needed
  },

  MASTER_COLUMNS: (masterType: 'system_configuration' | 'segment-master') => {
    if (masterType === 'segment-master') {
      return [
        // { id: 'key', label: 'Key', sort: true },
        { id: 'value', label: 'Value', sort: true },
        { id: 'createdBy', label: 'Created By', sort: false },
        { id: 'updatedBy', label: 'Updated By', sort: false },
        { id: 'status', label: 'Status', sort: true },
        { id: CONSTANTS.PAGE_TYPE.EDIT, label: 'Action', accessor: '_id' },
      ]
    } else if (masterType === 'system_configuration') {
      return [
        // { id: 'key', label: 'Key', sort: true },
        { id: 'value', label: 'Value', sort: false },
        { id: CONSTANTS.PAGE_TYPE.EDIT, label: 'Action', accessor: '_id' },
      ]
    }
  },

  AFFILIATE_PARTNERS: {
    affiliatePartnersListColumn: [
      { id: 'key', label: 'Key', sort: true },
      { id: 'value', label: 'Value', sort: true },
      { id: 'createdBy', label: 'Created By', sort: true },
      { id: 'updatedBy', label: 'Updated By', sort: true },
      { id: 'status', label: 'Status', sort: true },
      { id: CONSTANTS.PAGE_TYPE.EDIT, label: 'Action', accessor: '_id' },
    ],
  },
  LANGUAGES: {
    languagesListColumn: [
      { id: 'key', label: 'Key', sort: true },
      { id: 'value', label: 'Value', sort: true },
      { id: 'createdBy', label: 'Created By', sort: true },
      { id: 'updatedBy', label: 'Updated By', sort: true },
      { id: 'status', label: 'Status', sort: true },
      { id: CONSTANTS.PAGE_TYPE.EDIT, label: 'Action', accessor: '_id' },
    ],
  },
  ENTITLEMENT_PACKAGES: {
    entitlementPackagesListColumn: [
      { id: 'key', label: 'Key', sort: true },
      { id: 'value', label: 'Value', sort: true },
      { id: 'createdBy', label: 'Created By', sort: true },
      { id: 'updatedBy', label: 'Updated By', sort: true },
      { id: 'status', label: 'Status', sort: true },
      { id: CONSTANTS.PAGE_TYPE.EDIT, label: 'Action', accessor: '_id' },
    ],
  },
  DOWNSTREAM_PLATFORMS: {
    downstreamPlatformsListColumn: [
      { id: 'key', label: 'Key', sort: true },
      { id: 'value', label: 'Value', sort: true },
      { id: 'createdBy', label: 'Created By', sort: true },
      { id: 'updatedBy', label: 'Updated By', sort: true },
      { id: 'status', label: 'Status', sort: true },
      { id: CONSTANTS.PAGE_TYPE.EDIT, label: 'Action', accessor: '_id' },
    ],
  },
  COUNTRIES: {
    countriesListColumn: [
      { id: 'key', label: 'Key', sort: true },
      { id: 'value', label: 'Value', sort: true },
      { id: 'createdBy', label: 'Created By', sort: true },
      { id: 'updatedBy', label: 'Updated By', sort: true },
      { id: 'status', label: 'Status', sort: true },
      { id: CONSTANTS.PAGE_TYPE.EDIT, label: 'Action', accessor: '_id' },
    ],
  },
  MASTER_STATUS: [
    { value: 'ACTIVE', label: 'Active', type: 'status-active' },
    { value: 'INACTIVE', label: 'In Active', type: 'status-inactive' },
  ],
}

export const PAGINATION_OPTION = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
]

export const SYSTEM_CONFIGURATION_SEGMENT_KEY = 'SEGMENT_ID'

export const SINGLE_SELECT_BOOLEAN_OPTION = [
  // { key: 'Select', value: 'Select' },
  { key: 'True', value: 'True' },
  { key: 'False', value: 'False' },
]
