export const DescendingIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M3.08325 14.1667V12.5834L7.29159 7.33335H3.24992V5.83335H9.12492V7.41669L4.95825 12.6667H9.16659V14.1667H3.08325ZM7.49992 4.16669L9.99992 1.66669L12.4999 4.16669H7.49992ZM9.99992 18.3334L7.49992 15.8334H12.4999L9.99992 18.3334Z'
        fill='#3E3F40'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.8333 14.1666L13.9583 5.83331H15.7499L18.8749 14.1666H17.1666L16.4583 12.1666H13.2499L12.5833 14.1666H10.8333ZM13.7499 10.7499H15.9166L14.9166 7.6249H14.7916L13.7499 10.7499Z'
        fill='#3E3F40'
      />
    </svg>
  )
}
