import { TextField } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { MUIFieldModal } from '../../../Models'

const MUIField: React.FC<MUIFieldModal> = ({
  control,
  name,
  id,
  type,
  error,
  label,
  rules,
  required = false,
  readonly = false,
  disabled = false,
  startAdornment,
  endAdornment,
  onChange, // when ever you are passing onChange as props you will lose the control of react_hook_form
  // so only pass onChange when you are not using hook form
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const { onChange: fieldOnChange, ...fieldProps } = field
        return (
          <TextField
            label={
              <span>
                {label}
                {required && <span style={{ color: '#D4351C' }}>*</span>}
              </span>
            }
            // placeholder={label}
            variant='outlined'
            aria-label={name}
            data-testid={id ? id : name}
            inputProps={{ id: name, maxLength: props.maxLength, 'aria-label': name }}
            fullWidth
            InputProps={{
              readOnly: readonly,
              disabled: disabled,
              startAdornment: startAdornment,
              endAdornment: endAdornment,
            }}
            {...props}
            {...field}
            type={type}
            error={!!error?.[name]}
            onChange={(event) => {
              fieldOnChange(event)
              onChange?.(event)
            }}
            {...fieldProps}
            helperText={error?.[name]?.message as string}
          />
        )
      }}
    />
  )
}

export default MUIField
