import { Box } from '@mui/material'
import React, { ReactNode } from 'react'
import Lock from '../../Icons/Lock'

interface MUIBoxProps {
  children?: ReactNode
  borderRadius?: string
  border?: string
  locked?: string
}

const MUIBox: React.FC<MUIBoxProps> = ({
  children,
  borderRadius = '8px',
  border = '1px solid #b1b4b6',
  locked,
}) => {
  return (
    <div className='MUIBox__parent'>
      {locked && (
        <div className='MUIBox__locked'>
          <Lock />
          {locked}
        </div>
      )}
      <Box
        sx={{
          bgcolor: '#fff',
          border: locked ? '1px solid #d4351c' : border,
          borderRadius: borderRadius,
          textAlign: 'center',
          fontSize: '0.875rem',
          fontWeight: 700,
          flexGrow: 1,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {children}
      </Box>
    </div>
  )
}

export default MUIBox
