import { MoreVertOutlined, Search, SortByAlpha } from '@mui/icons-material'
import {
  Box,
  FormControlLabel,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getDateInFormat, getSubstring, replaceUnwanted } from '../../../Helpers/Util'
import useDebounce from '../../../Hooks/useDebounce'
import { UserMuiTableModal } from '../../../Models/ComponentModel'
import { BackButton, MUIButton, MUIGrid, MUIPagination, SimpleDropdown } from '../../Shared'
import { DescendingIcon } from '../../Shared/MUI-Table/MUI-Table.svgicons'
import { NoAssetIcon } from '../../../Components/Icons'
import CloneModal from '../../Shared/CloneModal'
import ListingFilter from '../../Atoms/ListingFilter'
import CONSTANTS from '../../../Constants'
import AssetStateLabel from '../../Labels/AssetStateLabel'
import mainApiService from '../../../Services'
import {
  CONTAINER_MANAGEMENT_CRUD,
  SITE_NAVIGATION_CRUD,
  WIDGET_MANAGEMENT_CRUD,
} from '../../../Constants/ApiDefinations'
import styles from '../../ContainerDefinition/ContainerDefinitionEditor.module.scss'
import VersionIcon from '../../../Assets/Images/svgs/version-icon'

/**
 * ListingComponent is a reusable component that displays a list of items.
 *
 * @param {Object} props - The props object for the component.
 * @param {Object} props.cloneProps - Properties for cloning functionality.
 * @param {boolean} props.cloneProps.isClonable - Whether the items can be cloned (true/false).
 * @param {string} props.cloneProps.cloneSegmentIdNode - ID node for clone API payload (e.g., 'parameterId').
 * @param {string} props.cloneProps.apiPayloadIdNode - Exact ID node name required in payload as per Swagger (e.g., 'parameterId', 'segmentId').
 * @param {string} props.cloneProps.apiMidpoint - API endpoint midpoint for cloning as per Swagger(e.g., 'parameter').
 * @param {string} props.cloneProps.redirectViewApiMidpoint - API endpoint midpoint for viewing cloned items (e.g., 'parameter').
 * @param {string} props.cloneProps.titleNode - Node name for title in API response as per Swagger  API response(e.g., 'parameterName', 'widgetTitle').
 */
const ListingComponent: React.FC<UserMuiTableModal> = ({
  showTable = true,
  columns,
  tableRows = [],
  tableTitle,
  headerBtnLable = 'Add new',
  headerBtnVarient = 'contained',
  createButtonLable = 'Create',
  createButtonClick,
  searchFunction,
  handleRowClick,
  paginationOption = [],
  dataCount = 0,
  searchPlaceholder = 'Search',
  showCreateButton = true,
  showSearchField = true,
  isRowClickable = true,
  isCellClickable = true,
  showBackButton = false,
  emptyStateText,
  cloneToDisable = '',
  cloneProps = {
    isClonable: false,
    cloneSegmemt: '',
    apiMidPoint: '',
    redirectViewAPiRouteMidPoint: '',
    titleNodeToDisplay: '',
    title: '',
  },
  handelBackButton,
  showHeaderInfo = [],
  searchLabel = '',
  showSearchIcon = true,
  searchFullWidth = false,
  showFilterOptions = false,
  filterFields = [],
  parentName = '',
  lastsearchFieldValue = '',
  setlastsearchFieldValue,
  searchClassName = '',
  searchBarSize = '',
  paramValue,
  showVersionColumn = false,
  entityType = 'widget',
}) => {
  const [pageData, setPageData] = useState({
    pageCount: 10,
    pageNumber: 1,
  })
  const [colData, setColData] = useState({
    selectedCol: '',
    selectedOptionType: 0,
  })
  const [anchorEl, setanchorEl] = useState(null)
  const [searchInput, setsearchInput] = useState<string>('')
  const [filterValue] = useState<{
    filterBY: string
    filterText: string
  }>({ filterBY: '', filterText: '' })
  const [mounted, setmounted] = useState(false)
  const [selectedId, setselectedId] = useState<number | string>('')
  const [selectedCol, setSelectedCol] = useState({
    selectedCol: '',
    selectedOptionType: 0,
  })
  const [selectedVersion, setSelectedVersion] = useState<any[]>([])
  const [selectedVersionId, setSelectedVersionId] = useState([])
  const [showVersions, setShowVersions] = useState(false)

  const handleSortIconClick = (event: React.MouseEvent<HTMLSpanElement>, colName: string) => {
    event.preventDefault()
    if (selectedCol.selectedCol !== colName) {
      setColData((pre) => ({
        ...pre,
        selectedCol: colName as string,
        selectedOptionType: 0,
      }))
      setanchorEl(event.currentTarget as any)
    } else {
      setColData(() => ({
        ...selectedCol,
        selectedCol: colName as string,
      }))
      setanchorEl(event.currentTarget as any)
    }
  }

  const onMenuClose = (clearCol = false) => {
    setanchorEl(null)
    if (clearCol) {
      setColData({
        selectedCol: '',
        selectedOptionType: 0,
      })
    }
  }
  const tablePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    event.preventDefault()
    setPageData((pre) => ({ ...pre, pageNumber: page }))
  }

  const handlePerPageView = (e: any) => {
    e.preventDefault()
    setPageData((pre) => ({ ...pre, pageNumber: 1, pageCount: e.target.value }))
  }

  const searchValue = useDebounce(searchInput, 1000)
  const [refreshPage, setRefreshPage] = useState(false)
  const handleRefresh = (value: boolean) => {
    setRefreshPage(value)
  }

  const [filters, setFilters] = useState<any[]>([])

  const handleFilterSubmit = (filter: any, id: string) => {
    if (Object.keys(filter).length === 0) {
      if (filters.length > 0) {
        const updatedFilters = filters.filter((filter) => !(filter.filterBy === id))
        setFilters(updatedFilters)
      }
    } else {
      if (filters.length === 0) {
        setFilters([filter])
      } else {
        let replaced = false
        const updatedFilters = filters.map((item) => {
          if (item.filterBy === id) {
            replaced = true
            return filter
          }
          return item
        })

        if (!replaced) {
          setFilters((prev) => [...prev, filter])
        } else {
          setFilters(updatedFilters)
        }
      }
    }
  }

  const handleVersionIconClick = async (row: any) => {
    if (selectedVersionId === row._id) {
      setShowVersions(!showVersions)
    } else {
      let payload, requestedData
      switch (entityType) {
        case 'widget':
          payload = { widgetId: row.widgetId, _id: row._id }
          requestedData = WIDGET_MANAGEMENT_CRUD.VERSION_LIST(payload)
          break
        case 'container':
          payload = { containerId: row.containerId, _id: row._id }
          requestedData = CONTAINER_MANAGEMENT_CRUD.VERSION_LIST(payload)
          break
        case 'siteNavigation':
          payload = { siteNavigationId: row.siteNavigationId, _id: row._id }
          requestedData = SITE_NAVIGATION_CRUD.VERSION_LIST(payload)
          break

        default:
          return
      }
      const result: any = await mainApiService(requestedData)

      const fieldTransformations: Record<string, (value: string) => string | JSX.Element> = {
        engine: (value: string) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
        createdAt: (value: string) =>
          getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
        updatedAt: (value: string) =>
          getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
        lastFeaturedDate: (value: string) =>
          getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
        state: (value: string) => <AssetStateLabel state={value} />,
      }
      // Function to apply transformations dynamically
      const transformData = (data: any) => {
        return Object.keys(data).reduce((acc: any, key: string) => {
          const value = key === 'state' ? data['stateDisplayName'] : data[key]
          // Apply transformation if a transformation function exists for the key
          acc[key] = fieldTransformations[key] ? fieldTransformations[key](value) : value
          return acc
        }, {})
      }
      const tempData: any[] = result?.data?.map((ele: any) => transformData(ele))

      setSelectedVersionId(row._id)
      setSelectedVersion(tempData)
    }
  }

  const showChildVersions = (rowURL: any, ind: any) => {
    return selectedVersion.map((versionData: any, index: number) => {
      return (
        <TableRow
          component={rowURL ? Link : TableRow}
          to={rowURL ? rowURL : ''}
          key={ind}
          onMouseEnter={() => {
            setselectedId(ind)
          }}
          onMouseLeave={() => {
            setselectedId('')
          }}
          onClick={(e: any) => {
            e.stopPropagation()
            if (handleRowClick) handleRowClick(versionData)
          }}
          data-testid='table-row-click'
          sx={{
            borderTop: index === 0 ? '12px solid #f5f4f4' : '',
            borderBottom: index === selectedVersion.length - 1 ? '12px solid #f5f4f4' : '',
          }}
        >
          <TableCell
            style={{ cursor: 'pointer' }}
            className={`dashLine-cell ${selectedId === ind ? 'bg-col4' : ''}`}
            align='left'
          >
            <span
              className={`${cloneProps?.isClonable ? '' : 'dashLine-clone'} ${
                index === 0 ? 'listing-dashLine-first' : 'listing-dashLine'
              }`}
            ></span>
          </TableCell>
          {columns?.map((column, index) => {
            const value: string | number | boolean | ReactNode = versionData[column.id]
            return (
              <TableCell key={index} className={selectedId === ind ? 'bg-col4' : ''}>
                <div
                  className={`${
                    isCellClickable ? 'cursor-pointer' : ''
                  } paragraph-regular-6 interFont`}
                  data-testid='tablerow'
                  title={
                    (typeof value === 'string' || Array?.isArray(value)) && value?.length > 40
                      ? value.toString()?.trim()
                      : ''
                  }
                >
                  {typeof value === 'string' || Array?.isArray(value)
                    ? getSubstring(value.toString(), 40)?.trim() || 'NA'
                    : value || 'NA'}
                </div>
              </TableCell>
            )
          })}
        </TableRow>
      )
    })
  }

  useEffect(() => {
    if (searchFunction && anchorEl === null) {
      if (showFilterOptions) {
        searchFunction(
          searchValue,
          selectedCol.selectedOptionType,
          selectedCol.selectedCol,
          filters,
          1,
          pageData.pageCount,
        )
      } else {
        searchFunction(
          searchValue,
          selectedCol.selectedOptionType,
          selectedCol.selectedCol,
          filterValue,
          1,
          pageData.pageCount,
        )
      }
      setPageData((pre) => ({ ...pre, pageNumber: 1 }))
    }
  }, [searchValue, selectedCol, filterValue, pageData.pageCount, filters, paramValue, refreshPage])

  useEffect(() => {
    if (searchFunction && mounted) {
      if (showFilterOptions) {
        searchFunction(
          searchValue,
          selectedCol.selectedOptionType,
          selectedCol.selectedCol,
          filters,
          pageData.pageNumber,
          pageData.pageCount,
        )
      } else {
        searchFunction(
          searchValue,
          selectedCol.selectedOptionType,
          selectedCol.selectedCol,
          filterValue,
          pageData.pageNumber,
          pageData.pageCount,
        )
      }
    }
    setmounted(true)
  }, [pageData.pageNumber, refreshPage])
  useEffect(() => {
    if (lastsearchFieldValue) {
      setsearchInput(lastsearchFieldValue)
    }
  }, [])

  useEffect(() => {
    if (selectedVersion.length > 0) {
      setShowVersions(true)
    }
  }, [selectedVersion])

  return (
    <MUIGrid
      className='interFont listing-container'
      style={{
        width: '100%',
        backgroundColor: '#f5f4f4',
        padding: '20px 24px',
      }}
    >
      <Grid className='interFont c-table'>
        <MUIGrid
          container
          className={`d-flex justify-content-between align-items-center ${
            searchFullWidth ? 'search-full-width' : ''
          }`}
        >
          <MUIGrid item md={6} lg={6}>
            {showBackButton && (
              <BackButton
                label={`${tableTitle}`}
                className='col73'
                handleOnClick={handelBackButton}
              />
            )}
            {!showBackButton && tableTitle && <p className='listing__title'>{tableTitle}</p>}
          </MUIGrid>

          <MUIGrid
            item
            md={searchFullWidth ? 12 : 6}
            lg={searchFullWidth ? 12 : 6}
            className={searchClassName ? searchClassName : ''}
          >
            <MUIGrid container className='d-flex justify-content-end align-items-center'>
              <MUIGrid direction='row' className='search-container' gap={3} display='flex'>
                {showSearchField && (
                  <TextField
                    className={`c-input listing-search ${searchBarSize === 'large' ? 'large' : ''}`}
                    id='outlined-search'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setsearchInput(e.target.value || lastsearchFieldValue)
                      if (setlastsearchFieldValue) {
                        setlastsearchFieldValue('')
                      }
                    }}
                    placeholder={searchPlaceholder}
                    label={searchLabel}
                    inputProps={{
                      'data-testid': replaceUnwanted(searchPlaceholder),
                    }}
                    autoComplete='off'
                    InputProps={{
                      id: 'searchId',
                      startAdornment: (
                        <>
                          {showSearchIcon && (
                            <InputAdornment position='start'>
                              <Search style={{ color: '#B1B4B6' }} />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                    type='search'
                  />
                )}
                {showCreateButton && showTable && (
                  <MUIButton
                    id={replaceUnwanted(
                      headerBtnLable === 'Add new' ? createButtonLable : headerBtnLable,
                    )}
                    className='create__btn'
                    variant={headerBtnVarient}
                    label={headerBtnLable === 'Add new' ? createButtonLable : headerBtnLable}
                    type='button'
                    data-testid='create-btn-click'
                    handleClickCB={createButtonClick}
                  />
                )}
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        {showHeaderInfo && (
          <>
            <MUIGrid container className='d-flex justify-content-between align-items-center'>
              {showHeaderInfo}
            </MUIGrid>
          </>
        )}

        {showFilterOptions && (
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: 3, paddingTop: '20px' }}>
            {filterFields.map((item, index) => (
              <ListingFilter
                key={index}
                searchable={item.searchable}
                clearAll={item.clearall}
                lable={item.lable}
                id={item.id}
                options={item.options ?? []}
                searchFunction={handleFilterSubmit}
              />
            ))}
          </Box>
        )}
        {showTable ? (
          <TableContainer
            className='border-6 mt-2 shadow-table'
            style={{ backgroundColor: '#ffff' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {showVersionColumn || cloneProps?.isClonable ? <TableCell>{''}</TableCell> : null}
                  {columns?.map((column: any, ind: any) => {
                    return (
                      <TableCell key={ind} align='center'>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'space-between',
                            fontSize: '12px',
                            fontWeight: 'bold',
                          }}
                        >
                          <div className='interFont'>{column.label}</div>
                          {column.sort && (
                            <div
                              data-testid='openfilter'
                              id='openFilter'
                              className='m-4 d-flex align-items-center cursor-pointer'
                              onClick={(event) => {
                                handleSortIconClick(event, column.id)
                              }}
                            >
                              <MoreVertOutlined
                                style={{
                                  fontSize: '18px',
                                  fill:
                                    column.id === selectedCol.selectedCol ? '#402080' : '#b1b4b6',
                                }}
                              />
                            </div>
                          )}
                          {column.id === colData.selectedCol && (
                            <Menu
                              className='border-12 c-sort'
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => onMenuClose(true)}
                              data-testid='sort-dialog'
                            >
                              <Typography>Sort By</Typography>
                              <RadioGroup
                                aria-label='options'
                                name='options'
                                value={colData.selectedOptionType}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  setColData({
                                    ...colData,

                                    selectedOptionType: parseInt(event.target.value),
                                  })
                                  setanchorEl(null)
                                  onMenuClose()
                                  setSelectedCol({
                                    ...colData,
                                    selectedOptionType: parseInt(event.target.value),
                                  })
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    paddingLeft: 0,
                                  }}
                                  className='d-flex justify-content-between align-items-center'
                                  onClick={() => {
                                    onMenuClose()
                                  }}
                                >
                                  <FormControlLabel
                                    labelPlacement='start'
                                    value={1}
                                    label={
                                      <div className='d-flex w-100 align-item-center justify-content-center'>
                                        <div className='d-flex align-items-center'>
                                          <SortByAlpha style={{ fontSize: '18px' }} />
                                        </div>
                                        <div className='ml-8p'>
                                          <span className='font-12p'>Ascending</span>
                                        </div>
                                      </div>
                                    }
                                    control={<Radio className='ml-auto' />}
                                  />
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    paddingLeft: 0,
                                  }}
                                  className='d-flex justify-content-between align-items-center'
                                  onClick={() => {
                                    onMenuClose()
                                  }}
                                >
                                  <FormControlLabel
                                    labelPlacement='start'
                                    value={-1}
                                    label={
                                      <div className='d-flex w-100 align-item-center justify-content-center'>
                                        <div className='d-flex align-items-center'>
                                          <DescendingIcon />
                                        </div>
                                        <div className='ml-8p'>
                                          <span className='font-12p'>Descending</span>
                                        </div>
                                      </div>
                                    }
                                    control={<Radio />}
                                  />
                                </MenuItem>
                              </RadioGroup>
                            </Menu>
                          )}
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableRows?.length > 0
                  ? tableRows?.map((row, ind) => {
                      // for masters URL is already available in CONSTANTS
                      const url: string = row?.url ?? ''
                      const rowToDisable =
                        row?.checkbox?.props?.disabled ||
                        row?.checkbox?.props?.children?.[0]?.props?.disabled
                      // for everything else passing from the parent component
                      const rowURL: string = row?.rowURL ?? url ?? ''
                      const isCloneDisabled = row[cloneToDisable] === 'NA'
                      return (
                        <>
                          <TableRow
                            component={rowURL ? Link : TableRow}
                            to={rowURL ? rowURL : ''}
                            key={ind}
                            className={
                              'table-link ' +
                              (isRowClickable ? 'cursor - pointer' : '') +
                              (rowToDisable ? 'disabled-row' : '')
                            }
                            onMouseEnter={() => {
                              setselectedId(ind)
                            }}
                            onMouseLeave={() => {
                              setselectedId('')
                            }}
                            onClick={(e: any) => {
                              e.stopPropagation()
                              if (handleRowClick) handleRowClick(row)
                            }}
                            data-testid='table-row-click'
                          >
                            {showVersionColumn || cloneProps?.isClonable ? (
                              <TableCell
                                style={{ cursor: 'pointer' }}
                                className={selectedId === ind ? 'bg-col4' : ''}
                                sx={{ paddingRight: '22px !important' }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className='version-clone-icons'>
                                  {showVersionColumn && row?.versionCount > 1 && (
                                    <div
                                      className='version-icon'
                                      data-testid='cloneBtn'
                                      onClick={() => {
                                        handleVersionIconClick(row)
                                      }}
                                    >
                                      <VersionIcon />
                                      <span className='version-count'>{row?.versionCount}</span>
                                    </div>
                                  )}
                                  {cloneProps?.isClonable && (
                                    <div className='clone-icons'>
                                      <CloneModal
                                        cloneProps={{
                                          redirectViewAPiRouteMidPoint:
                                            cloneProps.redirectViewAPiRouteMidPoint,
                                          cloneSegmemtIdNodeForApiPayload:
                                            cloneProps.cloneSegmemtIdNodeForApiPayload,
                                          apiMidPoint: cloneProps.apiMidPoint,
                                          titleNodeToDisplay: cloneProps.titleNodeToDisplay,
                                          title: cloneProps.title,
                                          isCloneDisabled: isCloneDisabled,
                                        }}
                                        id={row._id}
                                        onCancel={handleRefresh}
                                      />
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                            ) : null}

                            {columns?.map((column, index) => {
                              const value: string | number | boolean | ReactNode = row[column.id]
                              return (
                                <TableCell
                                  key={index}
                                  className={selectedId === ind ? 'bg-col4' : ''}
                                >
                                  <div
                                    className={`${
                                      isCellClickable ? 'cursor-pointer' : ''
                                    } paragraph-regular-6 interFont`}
                                    data-testid='tablerow'
                                    title={
                                      (typeof value === 'string' || Array?.isArray(value)) &&
                                      value?.length > 40
                                        ? value.toString()?.trim()
                                        : ''
                                    }
                                  >
                                    {typeof value === 'string' || Array?.isArray(value)
                                      ? getSubstring(value.toString(), 40)?.trim() || 'NA'
                                      : value || 'NA'}
                                  </div>
                                </TableCell>
                              )
                            })}
                          </TableRow>

                          {showVersions &&
                            selectedVersionId === row._id &&
                            showChildVersions(rowURL, ind)}
                        </>
                      )
                    })
                  : null}
              </TableBody>
            </Table>
            {!tableRows.length ? (
              parentName.length > 0 ? (
                searchValue?.trim()?.length > 0 ? (
                  <div
                    className={'d-flex justify-content-center align-items-center flex-column'}
                    style={{ paddingTop: '7rem', height: '100%', paddingBottom: '5rem' }}
                  >
                    <div>
                      <NoAssetIcon />
                    </div>
                    <Typography
                      className='d-flex justify-content-center'
                      style={{ padding: '0 50px' }}
                      data-testid={`div_${replaceUnwanted(tableTitle)}_no_data_found`}
                    >
                      No {parentName || 'Data'} Found
                    </Typography>
                  </div>
                ) : (
                  <div
                    className={'d-flex justify-content-center align-items-center flex-column'}
                    style={{ paddingTop: '7rem', height: '100%', paddingBottom: '5rem' }}
                  >
                    <div>
                      <NoAssetIcon />
                    </div>
                    <Typography
                      className='d-flex justify-content-center'
                      style={{ padding: '0 50px', fontWeight: 'bold' }}
                      data-testid={`div_${replaceUnwanted(tableTitle)}_no_data_found`}
                    >
                      {emptyStateText || 'Please initiate search to add.'}
                    </Typography>
                  </div>
                )
              ) : (
                <>
                  <Typography
                    className='d-flex justify-content-center'
                    style={{ padding: '50px 50px' }}
                    data-testid={`div_${replaceUnwanted(tableTitle)}_no_data_found`}
                  >
                    No Data Found
                  </Typography>
                </>
              )
            ) : null}
          </TableContainer>
        ) : (
          <></>
        )}

        {showTable ? (
          <MUIGrid
            container
            className='d-flex align-items-center justify-content-between mt-2 c-pagination'
          >
            {dataCount > 0 && (
              <MUIGrid item direction='row' className='d-flex align-items-center col1'>
                <SimpleDropdown
                  selectId='pageId'
                  options={paginationOption}
                  handleChange={handlePerPageView}
                  value={pageData.pageCount}
                />

                <Typography paddingX={3}>
                  Showing {pageData.pageCount > dataCount ? dataCount : tableRows.length} of{' '}
                  {dataCount} records
                </Typography>
              </MUIGrid>
            )}
            <MUIGrid item>
              {tableRows.length && dataCount > 0 ? (
                <MUIPagination
                  testId='userPagination'
                  onChange={tablePaginationChange}
                  count={Math.ceil(dataCount / Number(pageData.pageCount))}
                  page={pageData.pageNumber}
                />
              ) : (
                <></>
              )}
            </MUIGrid>
          </MUIGrid>
        ) : (
          <></>
        )}
      </Grid>
    </MUIGrid>
  )
}

export default ListingComponent
