const Lock = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        d='M3.49998 12.834C3.17915 12.834 2.90449 12.7197 2.67602 12.4913C2.44755 12.2628 2.33331 11.9882 2.33331 11.6673V5.83398C2.33331 5.51315 2.44755 5.2385 2.67602 5.01003C2.90449 4.78155 3.17915 4.66732 3.49998 4.66732H4.08331V3.50065C4.08331 2.69371 4.36769 2.00586 4.93644 1.43711C5.50519 0.868359 6.19303 0.583984 6.99998 0.583984C7.80692 0.583984 8.49477 0.868359 9.06352 1.43711C9.63227 2.00586 9.91665 2.69371 9.91665 3.50065V4.66732H10.5C10.8208 4.66732 11.0955 4.78155 11.3239 5.01003C11.5524 5.2385 11.6666 5.51315 11.6666 5.83398V11.6673C11.6666 11.9882 11.5524 12.2628 11.3239 12.4913C11.0955 12.7197 10.8208 12.834 10.5 12.834H3.49998ZM3.49998 11.6673H10.5V5.83398H3.49998V11.6673ZM6.99998 9.91732C7.32081 9.91732 7.59547 9.80308 7.82394 9.57461C8.05241 9.34614 8.16665 9.07148 8.16665 8.75065C8.16665 8.42982 8.05241 8.15517 7.82394 7.92669C7.59547 7.69822 7.32081 7.58398 6.99998 7.58398C6.67915 7.58398 6.40449 7.69822 6.17602 7.92669C5.94755 8.15517 5.83331 8.42982 5.83331 8.75065C5.83331 9.07148 5.94755 9.34614 6.17602 9.57461C6.40449 9.80308 6.67915 9.91732 6.99998 9.91732ZM5.24998 4.66732H8.74998V3.50065C8.74998 3.01454 8.57984 2.60135 8.23956 2.26107C7.89928 1.92079 7.48609 1.75065 6.99998 1.75065C6.51387 1.75065 6.10067 1.92079 5.7604 2.26107C5.42012 2.60135 5.24998 3.01454 5.24998 3.50065V4.66732Z'
        fill='#D4351C'
      />
    </svg>
  )
}
export default Lock
