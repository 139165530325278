import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'

interface OrchestrationPersisitState {
  existingId: any
}
const initialState: OrchestrationPersisitState = {
  existingId: {},
}
const orchestrationPersistSlice = createSlice({
  name: 'orchestrationPersist',
  initialState,
  reducers: {
    setSelectedGroupId: (state, action: PayloadAction<any>) => {
      state.existingId = action.payload
    },
  },
})
export const { setSelectedGroupId } = orchestrationPersistSlice.actions
const orchestrationPersistSelector = (state: {
  orchestrationPersist: OrchestrationPersisitState
}) => state.orchestrationPersist
export const selectSelectedId = createSelector(
  orchestrationPersistSelector,
  (state) => state.existingId,
)
export default orchestrationPersistSlice
