import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { MASTERS_MANAGEMENT } from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface SystemConfigurationState {
  systemConfigurationList: any
  editObj: any
}

const initialState: SystemConfigurationState = {
  systemConfigurationList: [],
  editObj: {},
}

const systemConfigurationSlice = createSlice({
  name: 'systemConfiguration',
  initialState,
  reducers: {
    setSystemConfigurationList: (state, action) => {
      state.systemConfigurationList = action?.payload ? action.payload : null
    },
    setEditObj: (state, action) => {
      state.editObj = {
        ...state.editObj,
        ...action?.payload,
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clearSystemConfigurationStore: (state: any, _action) => {
      state.systemConfigurationList = []
      state.editObj = {}
    },
  },
})

export const { setSystemConfigurationList, setEditObj, clearSystemConfigurationStore } =
  systemConfigurationSlice.actions
const systemConfigurationSelector = (state: any) => state.systemConfiguration

export const selectSystemConfigurationList = createSelector(
  systemConfigurationSelector,
  (state) => state.systemConfigurationList,
)
export const selectEditObj = createSelector(systemConfigurationSelector, (state) => state.editObj)

export const getMasterByKey = createAsyncThunk(
  'systemConfiguration/getMasterByKey',
  async (data: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = MASTERS_MANAGEMENT.SYSTEM_CONFIGURATION_CRUD.LIST(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return result
  },
)

export const createMasterByKey = createAsyncThunk(
  'systemConfiguration/createMasterByKey',
  async (data: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = MASTERS_MANAGEMENT.SYSTEM_CONFIGURATION_CRUD.CREATE(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return result
  },
)

export const updateMasterByKey = createAsyncThunk(
  'systemConfiguration/updateMasterByKey',
  async (data: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = MASTERS_MANAGEMENT.SYSTEM_CONFIGURATION_CRUD.UPDATE(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return result
  },
)

export default systemConfigurationSlice
