export const TIPS_MODULE = {
  MASTERS: 'MASTERS',
  MANAGE_ROLE: 'MANAGE_ROLE',
  MANAGE_USER: 'MANAGE_USER',
  MANAGE_SEGMENT: 'MANAGE_SEGMENT',
  CONTAINER_DEFINITION: 'CONTAINER_DEFINITION',
  WIDGET_DEFINITION: 'WIDGET_DEFINITION',
  MANAGE_WIDGET: 'MANAGE_WIDGET',
}

const TIPS_SELECTION: any = {
  MANAGE_ROLE: {
    MODULE: 'Role Management',
    VIEW: 'View Role',
    EDIT: 'Edit Role',
    CREATE: 'Create Role, View/Edit Role',
  },
  MANAGE_USER: {
    MODULE: 'User Management',
    VIEW: 'View User',
    EDIT: 'Edit User',
    CREATE: 'Create User',
  },
}

export const formatTootipData = (records: any = []) => {
  const tootipsData: any = {}
  records.forEach((record: any) => {
    const { module } = record
    if (tootipsData[module]) {
      tootipsData[module] = {
        module,
        pages: [
          ...tootipsData[module].pages,
          { ...record, tooltipMessages: record.tooltipMessages },
        ],
      }
    } else {
      tootipsData[module] = {
        module,
        pages: [{ ...record, tooltipMessages: record.tooltipMessages }],
      }
    }
  })
  return tootipsData
}

const formatViewTootipData = (records: any = []) => {
  const tootipsData: any = {}
  records.forEach((record: any) => {
    const { module, page } = record
    if (tootipsData[module]) {
      tootipsData[module] = {
        ...tootipsData[module],
        [page]: record.tooltipMessages,
      }
    } else {
      tootipsData[module] = {
        [page]: record.tooltipMessages,
      }
    }
  })
  return tootipsData
}

export const getTipsForPage = (
  tipsDataRes: any = [],
  module: string,
  pageType: string,
  page?: string,
) => {
  try {
    let tips = []
    const tipsData = formatViewTootipData(tipsDataRes)
    const tipsModule = TIPS_SELECTION[module.toUpperCase()]
    if (tipsModule?.MODULE && tipsData[tipsModule.MODULE]) {
      const dataMudule = tipsModule.MODULE
      if (page) {
        const dataPage = tipsModule[page.toUpperCase()]
        const dataPageType = dataPage[pageType.toUpperCase()]
        tips = tipsData[dataMudule][dataPageType] || []
      } else {
        const dataPageType = tipsModule[pageType.toUpperCase()]
        tips = tipsData[dataMudule][dataPageType] || []
      }
    }
    return tips
  } catch (_error) {
    return []
  }
}
