import Dialog from '@mui/material/Dialog'
import * as React from 'react'
import { MUIDialogModal } from '../../../Models'

const MUIDialog: React.FC<MUIDialogModal> = ({ className = '', open, handleClose, children }) => {
  return (
    <React.Fragment>
      <Dialog
        className={className}
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        {children}
      </Dialog>
    </React.Fragment>
  )
}

export default MUIDialog
