/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PARAMETER_MANAGEMENT_CRUD, USER_MANAGEMENT_CRUD } from '../../Constants/ApiDefinations'
import {
  createParameterReqData,
  parameterCRUDAPIDefinitionModal,
  parameterStateModal,
  parameterStatusChangeDefinitionModal,
} from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

const initialState: parameterStateModal = {
  parameter: '',
  // authConfig: null,
  parameterData: {},
}

const parameterSlice = createSlice({
  name: 'parameter',
  initialState,
  reducers: {
    setParameter: (state, action: PayloadAction<string>) => {
      state.parameter = action.payload
    },
    setParameterData: (state, action) => {
      if (action?.payload?.data?.records?.[0]) state.parameter = action.payload.data.records[0]
    },
  },
})

export const { setParameter, setParameterData } = parameterSlice.actions
export const deleteParameter = createAsyncThunk(
  'parameter/deleteParameter',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: parameterCRUDAPIDefinitionModal =
      PARAMETER_MANAGEMENT_CRUD.DELETE_PARAMETER(id)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)
export const createParameterService = createAsyncThunk(
  'parameter/createPrameter',
  async (payload: createParameterReqData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: parameterCRUDAPIDefinitionModal =
      PARAMETER_MANAGEMENT_CRUD.CREATE_PARAMETER(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)

export const updateParameterService = createAsyncThunk(
  'Parameter/updateParameter',
  async (payload: createParameterReqData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: parameterCRUDAPIDefinitionModal =
      PARAMETER_MANAGEMENT_CRUD.UPDATE_PARAMETER(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)

export const changeStatus = createAsyncThunk(
  'parameter/changeStatus',
  async (data: { parameterId: string; status: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: parameterStatusChangeDefinitionModal =
      PARAMETER_MANAGEMENT_CRUD.CHANGE_STATUS(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return result
  },
)

export const viewParameterService = createAsyncThunk(
  'parameter/viewParameter',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = PARAMETER_MANAGEMENT_CRUD.VIEW_PARAMETER(id)
    const data = await mainApiService(requestedData)

    dispatch(setParameterData(data))
    dispatch(setLoader(false))

    return data
  },
)

export const getParametersService = createAsyncThunk(
  'parameter/getParameters',
  async (paramsData: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData = PARAMETER_MANAGEMENT_CRUD.LIST_PARAMETER(paramsData)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

const parameterSelector = (state: { parameter: any }) => state.parameter

// export const selectAuthConfig = createSelector(parameterSelector, (state) => state.)
export const selectParameter = createSelector(parameterSelector, (state) => state.parameter)
export const selectParameterData = createSelector(parameterSelector, (state) => state.parameterData)

export default parameterSlice
