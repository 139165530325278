import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material'
import React from 'react'
import { checkboxModel } from '../../../Models'

const MUICheckbox: React.FC<checkboxModel> = ({
  id,
  label,
  checked,
  disabled,
  size = 'medium',
  handleChange,
  labelPlacement = 'end',
  required = false,
  name = '',
  error = {},
}: checkboxModel) => {
  return (
    <FormControl error={!!error?.[name]}>
      <FormControlLabel
        sx={{ margin: 0 }}
        disabled={disabled}
        labelPlacement={labelPlacement}
        checked={checked}
        control={
          <Checkbox
            data-testid={id ? id : label}
            sx={{ padding: 0 }}
            onChange={handleChange}
            checked={checked}
            disabled={disabled}
            size={size}
            inputProps={{ id: name }}
          />
        }
        label={
          <span className='ml-1'>
            {label}
            {required && <span style={{ color: '#D4351C' }}>*</span>}
          </span>
        }
      />
      {!!error?.[name] && <FormHelperText>{error?.[name]?.message}</FormHelperText>}
    </FormControl>
  )
}

export default MUICheckbox
