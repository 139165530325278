import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { FC } from 'react'

const MUISelectWithoutControl: FC<any> = ({
  options = [],
  error,
  lable,
  name,
  labelKey = 'label',
  valueKey = 'value',
  required = false,
  placeholder = '',
  onChange,
  value = '',
  id = '',
  disabled = false,
  disabledOptions = [],
}) => {
  return (
    <FormControl fullWidth error={!!error?.[name]} sx={{ textAlign: 'left' }}>
      <InputLabel id='demo-simple-select-label'>
        <span>
          {lable}
          {required && <span style={{ color: '#D4351C' }}>*</span>}
        </span>
      </InputLabel>
      <Select
        labelId='demo-simple-select-label'
        id={id ? id : lable}
        data-testid={id ? id : lable}
        value={value}
        label={lable}
        disabled={disabled}
        placeholder={placeholder ?? lable}
        inputProps={{ id: name }}
        onChange={(event: any, arr: any) => onChange(event?.target.value, arr)}
        IconComponent={KeyboardArrowDownIcon}
      >
        {options?.length &&
          options?.map((item: any) => (
            <MenuItem
              key={item[valueKey]}
              value={item[valueKey]}
              disabled={disabledOptions?.includes(item[valueKey])}
            >
              {item[labelKey]}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{error?.[name]?.message as string}</FormHelperText>
    </FormControl>
  )
}

export default MUISelectWithoutControl
