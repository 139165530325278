import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { MASTERS_MANAGEMENT } from '../../Constants/ApiDefinations'
import { CountriesListResponse, CreateMastersData, MastersUpdate } from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface CountriesState {
  countryData: CountriesListResponse | null
}

const initialState: CountriesState = {
  countryData: null,
}

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setCountriesData: (state, action) => {
      state.countryData = action?.payload ? action.payload : null
    },
  },
})

export const changeCountryStatus = createAsyncThunk(
  'countries/changeStatus',
  async (data: { id: string; status: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate = MASTERS_MANAGEMENT.COUNTRIES_CRUD.UPDATE_COUNTRIES(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return result
  },
)

export const { setCountriesData } = countriesSlice.actions

const countriesSelector = (state: any) => state.countries

export const selectCountryData = createSelector(countriesSelector, (state) => state.countryData)

export const createCountryService = createAsyncThunk(
  'countries/create',
  async (payload: CreateMastersData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate = MASTERS_MANAGEMENT.COUNTRIES_CRUD.CREATE_COUNTRIES(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const editCountryService = createAsyncThunk(
  'countries/editCountry',
  async (payload: CreateMastersData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate = MASTERS_MANAGEMENT.COUNTRIES_CRUD.UPDATE_COUNTRIES(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export default countriesSlice
