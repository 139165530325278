import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { rootReducer } from './RootReducer'

const persistConfig = {
  key: 'TG',
  storage,
  blacklist: [
    'user',
    'affiliatePartners',
    'languages',
    'countries',
    'downstreamPlatforms',
    'entitlementPackages',
    'systemConfigurations',
    'assetDefinition',
    'orchestration',
    'auditLogs',
    'asset',
    'assetConfig',
    'videoAssetGroup',
    'alert',
    'appTooltips',
  ],
}

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
