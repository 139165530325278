import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../../Constants'
import { MASTERS_MANAGEMENT } from '../../../../Constants/ApiDefinations'
import { getPageTitle, objectToQueryString } from '../../../../Helpers/Util'
import {
  BreadcrumsModel,
  CountriesListApiResponse,
  CountriesListResponse,
  CountriesListingQueryParams,
  CountriesPropsType,
  ViewMastersAPIDefinition,
} from '../../../../Models'
import mainApiService from '../../../../Services'
import { useAppDispatch, useAppSelector } from '../../../../Store/Hooks'
import MastersEditorLayout from '../Layouts/MastersEditorLayout'
import CountriesCreateEdit from './CountriesCreateEdit'
import { selectCountryData, setCountriesData } from '../../../../Store/Slice/countries.slice'

const COUNTRY_TEXT = 'Country'
const CountriesEditor = ({ mastersPageType, mastersId }: CountriesPropsType) => {
  const dispatch = useAppDispatch()
  const selectCountry = useAppSelector(selectCountryData)
  const selectedCountry: CountriesListResponse | null =
    mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE ? selectCountry : null
  const navigate = useNavigate()
  useEffect(() => {
    if (
      mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE &&
      (!selectedCountry?._id || selectedCountry?._id !== mastersId)
    ) {
      ;(async () => {
        // Call list API
        const listData = await getCountriesListingFunction({ page: 1, limit: 1000000 })
        // find selected record based on id
        if (mastersId) {
          const selectedCountryData = listData.find((item) => item?._id === mastersId)
          if (!selectedCountryData?._id) {
            // navigate to list
            navigate(
              `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.Countries}`,
            )
          } else {
            dispatch(setCountriesData(selectedCountryData))
          }
        }
      })()
    }
  }, [])

  const getCountriesListingFunction = async (options: CountriesListingQueryParams) => {
    const paramsData = objectToQueryString(options)
    const requestedData: ViewMastersAPIDefinition =
      MASTERS_MANAGEMENT.COUNTRIES_CRUD.LIST_COUNTRIES(paramsData)
    const result: any = await mainApiService(requestedData)
    const tempData: CountriesListResponse[] = []

    if (result?.responseCode === 200) {
      result?.data?.records?.forEach((ele: CountriesListApiResponse) => {
        const createdBy = ele?.createdBy?.userEmail
        const updatedBy = ele?.updatedBy?.userEmail
        tempData.push({
          ...ele,
          createdBy,
          updatedBy,
        })
      })
    }
    return tempData
  }

  const onClickBackNavigation = (e: { preventDefault: () => void }) => {
    e?.preventDefault()
    navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.Countries}`)
  }

  return (
    <MastersEditorLayout
      mastersPageType={mastersPageType}
      pageText={COUNTRY_TEXT}
      onClickBackNavigation={onClickBackNavigation}
      breadcrumbs={
        BREADCRUMS.CREATE_COUNTRIES(
          mastersPageType,
          getPageTitle(mastersPageType as string),
        ) as BreadcrumsModel[]
      }
    >
      {mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE && selectedCountry?._id && (
        <CountriesCreateEdit mastersPageType={mastersPageType} />
      )}
      {mastersPageType === CONSTANTS.PAGE_TYPE.CREATE && (
        <CountriesCreateEdit mastersPageType={mastersPageType} />
      )}
    </MastersEditorLayout>
  )
}

export default React.memo(CountriesEditor)
