import React from 'react'
import { useParams } from 'react-router-dom'
import { Countries as CountriesAddEditEditor } from '../../../../Components/Organisms/Masters'

const CountriesAddEditAddEdit = () => {
  const { countriesPageType, countriesId } = useParams()

  return <CountriesAddEditEditor mastersPageType={countriesPageType} mastersId={countriesId} />
}

export default React.memo(CountriesAddEditAddEdit)
