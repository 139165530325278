function AddAlarmIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <g>
        <path
          d='M7.49866 6.00007H6.49866V5.00007C6.49866 4.72507 6.27366 4.50007 5.99866 4.50007C5.72366 4.50007 5.49866 4.72507 5.49866 5.00007V6.00007H4.49866C4.22366 6.00007 3.99866 6.22507 3.99866 6.50007C3.99866 6.77507 4.22366 7.00007 4.49866 7.00007H5.49866V8.00007C5.49866 8.27507 5.72366 8.50007 5.99866 8.50007C6.27366 8.50007 6.49866 8.27507 6.49866 8.00007V7.00007H7.49866C7.77366 7.00007 7.99866 6.77507 7.99866 6.50007C7.99866 6.22507 7.77366 6.00007 7.49866 6.00007ZM10.5887 2.50507L9.04866 1.22507C8.83866 1.05007 8.52366 1.07507 8.34366 1.29007C8.16866 1.50007 8.19866 1.81507 8.40866 1.99507L9.94366 3.27507C10.1537 3.45007 10.4687 3.42507 10.6487 3.21007C10.8287 3.00007 10.7987 2.68507 10.5887 2.50507ZM2.04866 3.27507L3.58366 1.99507C3.79866 1.81507 3.82866 1.50007 3.64866 1.29007C3.47366 1.07507 3.15866 1.05007 2.94866 1.22507L1.40866 2.50507C1.19866 2.68507 1.16866 3.00007 1.34866 3.21007C1.52366 3.42507 1.83866 3.45007 2.04866 3.27507ZM5.99866 2.00007C3.51366 2.00007 1.49866 4.01507 1.49866 6.50007C1.49866 8.98507 3.51366 11.0001 5.99866 11.0001C8.48366 11.0001 10.4987 8.98507 10.4987 6.50007C10.4987 4.01507 8.48366 2.00007 5.99866 2.00007ZM5.99866 10.0001C4.06866 10.0001 2.49866 8.43007 2.49866 6.50007C2.49866 4.57007 4.06866 3.00007 5.99866 3.00007C7.92866 3.00007 9.49866 4.57007 9.49866 6.50007C9.49866 8.43007 7.92866 10.0001 5.99866 10.0001Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_5061_32041'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default AddAlarmIcon
