import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { combineErrorMessage } from '../Helpers/Util'
import { setLoader } from '../Store/Slice/loader.slice'
import { PARAMETER_MANAGEMENT_CRUD, SYSTEM_CONFIG_APIS } from '../Constants/ApiDefinations'
import { showAlert } from '../Store/Slice/alert.slice'
import mainApiService from '../Services'

interface SegmentationMaster {
  label: string
  value: string
  key: string
}
const useFetchConfigurationData = () => {
  const dispatch = useDispatch()
  const [lastFetchedNode, setLastFetchedNode] = useState('')
  const [state, setState] = useState<any>({
    loading: false,
    error: null,
  })

  const clearNodeData = () => {
    if (lastFetchedNode.toLowerCase() !== 'master') {
      const newdata = { ...state }
      delete newdata[lastFetchedNode]
      setState(newdata)
    }
  }
  const fetchConfigurationData = useCallback(
    async (configType: string) => {
      dispatch(setLoader(true))
      setState((prevState: any) => ({
        ...prevState,
        loading: true,
        error: null,
      }))
      let requestedData: any
      let datapath = ''
      if (configType === 'MASTER') {
        requestedData = PARAMETER_MANAGEMENT_CRUD.PARAMETER_MASTER_LIST()
        datapath = 'SystemConfiguration'
      } else {
        if (configType) {
          requestedData = SYSTEM_CONFIG_APIS.LIST_MASTERS(configType)
        }
      }
      try {
        const result: any = await mainApiService(requestedData)
        if (result?.responseCode === 200) {
          const tempData: SegmentationMaster[] = []
          const dataToProcess = datapath ? result?.data[datapath] : result?.data
          dataToProcess.forEach((ele: any) => {
            tempData.push({
              value: ele?.value,
              key: ele?.key,
              label: ele?.value,
            })
          })
          setLastFetchedNode(configType)

          setState((prevState: any) => ({
            ...prevState,
            [configType]: tempData,
            loading: false,
          }))
        } else {
          const error: any = combineErrorMessage(result)
          dispatch(showAlert([true, error, 'error']))
          setState((prevState: any) => ({
            ...prevState,
            loading: false,
            error,
          }))
        }
      } catch (error: any) {
        dispatch(showAlert([true, error.message, 'error']))
        setState((prevState: any) => ({
          ...prevState,
          loading: false,
          error: error.message,
        }))
      } finally {
        dispatch(setLoader(false))
      }
    },
    [dispatch],
  )
  return { state, fetchConfigurationData, clearNodeData }
}
export default useFetchConfigurationData
