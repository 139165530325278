const AuditTrails = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='19' viewBox='0 0 20 19' fill='none'>
      <path
        d='M10.4582 7.91667H2.41381C1.97137 7.91667 1.60938 8.27292 1.60938 8.70833C1.60938 9.14375 1.97137 9.5 2.41381 9.5H10.4582C10.9006 9.5 11.2626 9.14375 11.2626 8.70833C11.2626 8.27292 10.9006 7.91667 10.4582 7.91667ZM10.4582 4.75H2.41381C1.97137 4.75 1.60938 5.10625 1.60938 5.54167C1.60938 5.97708 1.97137 6.33333 2.41381 6.33333H10.4582C10.9006 6.33333 11.2626 5.97708 11.2626 5.54167C11.2626 5.10625 10.9006 4.75 10.4582 4.75ZM2.41381 12.6667H7.24042C7.68286 12.6667 8.04486 12.3104 8.04486 11.875C8.04486 11.4396 7.68286 11.0833 7.24042 11.0833H2.41381C1.97137 11.0833 1.60938 11.4396 1.60938 11.875C1.60938 12.3104 1.97137 12.6667 2.41381 12.6667ZM17.867 9.66625L17.9394 9.7375C18.2531 10.0462 18.2531 10.545 17.9394 10.8537L13.4507 15.2792C13.1369 15.5879 12.6301 15.5879 12.3164 15.2792L9.83071 12.8329C9.51698 12.5242 9.51698 12.0254 9.83071 11.7167L9.9031 11.6454C10.2168 11.3367 10.7236 11.3367 11.0374 11.6454L12.8876 13.4662L16.7328 9.67417C17.0384 9.3575 17.5533 9.3575 17.867 9.66625Z'
        fill='#3E3F40'
      />
    </svg>
  )
}

export default AuditTrails
