import { VALIDATIONS } from '../ValidationSchemas'

export const API_MOCKS = {
  AUTH_VALIDATING_API: {
    message: 'Data successfully fetched',
    responseCode: 200,
    data: {
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsic2Vzc2lvbklkIjoiMDU1MjIwMDItMDkwMi00MzQ1LTg5NjUtMTM1OTM0ZjA4OTkyIiwiZW1haWwiOiJ2YWliaGF2LnZlcm1hQGtlbGx0b24uY29tIiwidXNlcklkIjoiNjU5ZTQ4OWM4MWNhMzRkMjQ1MGIwNzU2Iiwicm9sZUlkIjoiNjUzYTNhNDJkM2Y2ZWFmNGE5Yzg4ZjE3IiwidXNlck5hbWUiOiJWYWliaGF2IFZlcm1hIiwicm9sZU5hbWUiOiJBZG1pbmlzdHJhdG9yIiwiYWNjZXNzVG9rZW4iOiJleUpoYkdjaU9pSlNVekkxTmlJc0luUjVjQ0k2SWtwWFZDSXNJbXRwWkNJNklqZ3hNems0TVRJeFFUbEVOalk0UmpBelF6WXdRalV6T0RsR05UVXlOVGt6T0RkRk56UTBNVFlpTENKNE5YUWlPaUpuVkcxQ1NXRnVWMkZRUVRoWlRGVTBiakZWYkdzMFptNVNRbGtpTENKaGNIQmZhV1FpT2lKQ2JHbDBlbDlVWlhOME1pSjkuZXlKaGRYUm9YM1JwYldVaU9qRTNNRGs0T0RZeE56VXNJbWx6Y3lJNkltaDBkSEJ6T2k4dllXRnhORGMzTUM1cFpDNWplV0psY21GeWF5NWpiRzkxWkM5Q2JHbDBlbDlVWlhOME1pOGlMQ0pwWVhRaU9qRTNNRGs0T0RZeE56Y3NJbUYxWkNJNkltRmhaRGxoT1dNMExXUTRNVFF0TkdJNU5DMDVNMk5sTFRKaFkyTTJZelJoTjJJNU5pSXNJbVZ0WVdsc0lqb2lkbUZwWW1oaGRpNTJaWEp0WVVCclpXeHNkRzl1TG1OdmJTSXNJblZ1YVhGMVpWOXVZVzFsSWpvaWRtRnBZbWhoZGk1MlpYSnRZVUJ6WlhScGJtUnBZUzVqYjIwdU9UY2lMQ0psZUhBaU9qRTNNRGs1TnpJMU56Y3NJbk4xWWlJNklqSm1aRE14WmpCakxUazFOVFV0TkdObFppMWlNekEyTFRObU0yUTBOV1poWkRkall5SXNJbk5qYjNCbElqb2liM0JsYm1sa0lHVnRZV2xzSWl3aVpXMWhhV3hmZG1WeWFXWnBaV1FpT25SeWRXVjkuVzI4cThzdThxQ2duTWppYkNHLU03TDRteC1SRXFlM1VPOWFEcTFJUXljNWhyVzJRcVIxY0tUdEpHcEc0TGNWTFJVUWVMUV9LR0s2YnJaZzF2MFdCYnc0VlFfUmZxaF9QQ0J1UURSaUdrWTNCSlo2UHRWblBya1hYZ25vR0syY1lkZkEzMFFscVJpNk90SEprSVkyUnZRRHpQZC1IenFCLVFwUklPRE5rZEVXOTVmbWZucEJDS3ptWVR2NElqU2t1R19xdnpnWFlEb2ZBMDhsd3BjNEM5ZVZhdkFQb1NHTFI5aGlidjI3c085NDBlai1ST2FaNkFoc3Vady1MRGVuTXU5dHFMZUlyZ1lxbUx3ekUyZEJnaUZVdFZoZGhNdmEwRThKRUlFbHpkeXVDMXdqTVpqR3VGRmZOMUJENDRFLVNESi00SGctdXhxOUw3aTBRVEtZbWFnIiwicmVmcmVzaFRva2VuIjoiRVVLeGt2d051N20tUXNGTUdza1JrYlZUUWZ0QXhTRkhyZEFqSWx1a0NiQTEifSwiaWF0IjoxNzA5ODg2MTc4LCJleHAiOjE3MDk5NzI1Nzd9.z_0cel0TtL3ClUGz9FgIBDm7HabEt3hst0hws-DBZ-o',
      user: {
        name: 'test',
      },
      permissions: {},
    },
    error: {},
  },
  LOGOUT_API: {
    message: 'Success',
    responseCode: 200,
    data: {},
  },
  PERMISSION_API: [
    {
      key: 'BOLT_MODULE',
      displayName: 'SOME DISPLAY NAME',
      selectAll: true,
      submodules: [
        {
          key: 'ROLE_AND_USER_MANAGEMENT',
          displayName: 'Role And User Management',
          permission: {
            canAccess: true,
          },
        },
        {
          key: 'AUDIT_TRAILS',
          displayName: 'Audit Trails',
          permission: {
            canAccess: true,
          },
        },
        {
          key: 'AUDIT_TRAILSs',
          displayName: 'Audit Trailss',
          permission: {
            canAccess: true,
          },
        },
      ],
    },
    {
      key: 'META_CATEGORIES',
      displayName: 'Meta Categories',
      selectAll: false,
      submodules: [
        {
          key: 'DESCRIPTIVE_METADATA',
          displayName: 'Descriptive Metadata',
          permission: {
            canRead: false,
            canWrite: false,
          },
        },
        {
          key: 'RIGHTS_AND_AVAILABILITY',
          displayName: 'Rights and Availability',
          permission: {
            canRead: false,
            canWrite: false,
          },
        },
      ],
    },
    {
      key: 'TOLLGATES',
      displayName: 'Tollgates',
      selectAll: false,
      submodules: [
        {
          key: 'DESCRIPTIVE_METADATA',
          displayName: 'Descriptive Metadata',
          permission: {
            canStateTransition: false,
            canSkipValidation: false,
          },
        },
        {
          key: 'RIGHTS_AND_AVAILABILITY',
          displayName: 'Rights and Availability',
          permission: {
            canStateTransition: false,
            canSkipValidation: false,
          },
        },
      ],
    },
  ],
  ROLE_LISTING_API: {
    message: 'Data successfully fetched',
    responseCode: 200,
    data: {
      count: 3,
      records: [
        {
          _id: '653a3a42d3f6eaf4a9c88f17',
          roleName: 'Administrator',
          status: 'ACTIVE',
          createdAt: '2023-11-07T11:51:34.765Z',
          updatedAt: '2023-11-07T11:51:34.765Z',
          associatedUsers: 3,
        },
        {
          _id: '654a24c6f43fc1fa6ab381d4',
          roleName: 'Content Creator',
          status: 'INACTIVE',
          createdAt: '2023-11-07T11:51:34.765Z',
          updatedAt: '2023-11-07T11:51:34.765Z',
          associatedUsers: 18,
        },
      ],
    },
  },
  ROLE_DATA_API_MOCK: {
    message: 'Data successfully fetched',
    responseCode: 200,
    data: {
      _id: '65f927bed51a6aa3ee68d06f',
      roleName: 'testOpheliaCustomerWebTechnician',
      permissions: [
        {
          key: 'BOLT_MODULE',
          displayName: 'Blitz Module',
          submodules: [
            {
              key: 'ROLE_AND_USER_MANAGEMENT',
              displayName: 'Role & User Management',
              permission: {
                canAccess: true,
              },
            },
          ],
        },
        {
          key: 'META_CATEGORIES',
          displayName: 'Meta Categories',
          submodules: [
            {
              key: 'DESCRIPTIVE_METADATA',
              displayName: 'Descriptive Metadata',
              permission: {
                canRead: true,
                canWrite: true,
              },
            },
          ],
        },
        {
          key: 'TOLLGATES',
          displayName: 'Tollgates',
          submodules: [],
        },
      ],
      createdBy: {
        userEmail: 'riya.kohli@kellton.com',
      },
      updatedBy: {
        userEmail: 'riya.kohli@kellton.com',
      },
      status: 'ACTIVE',
      createdAt: '2024-03-19T05:50:54.922Z',
      updatedAt: '2024-03-19T05:50:54.922Z',
    },
    error: null,
  },
  PARAMETER_LISTING_API: {
    message: 'Data successfully fetched',
    responseCode: 200,
    data: {
      count: 3,
      records: [
        {
          _id: '653a3a3ed3f6eaf4a9c88f0f',
          parameterName: 'Dummy Parameter',
          fieldName: 'string',
          fieldtype: 'string',
          datatype: 'string',
          defaultValue: 'string',
          esField: 'string',
          mandatory: false,
          placeholder: 'string',
          master: 'string',
          multiselect: false,
          purpose: 'string',
          status: 'ACTIVE',
          createdBy: '653a3a42df6eaf4a9c88f17',
          updatedBy: '653a3a42df6eaf4a9c88f17',
          id: '654de4f35623149023843083',
          createdAt: '2023-11-10T08:08:19.922Z',
          updatedAt: '2023-11-10T08:08:19.9227',
          v: 0,
        },
        {
          _id: '653a3a3ed3f6eaf4a9c88f0f',
          parameterName: 'string2',
          fieldName: 'string2',
          fieldtype: 'string2',
          datatype: 'string2',
          defaultValue: 'string2',
          esField: 'string2',
          mandatory: false,
          placeholder: 'string2',
          master: 'string2',
          multiselect: false,
          purpose: 'string2',
          status: 'ACTIVE',
          createdBy: '653a3a42df6eaf4a9c88f18',
          updatedBy: '653a3a42df6eaf4a9c88f18',
          id: '654de4f35623149023843083',
          createdAt: '2023-11-10T08:08:19.922Z',
          updatedAt: '2023-11-10T08:08:19.9227',
          v: 0,
        },
      ],
    },
  },
  GETPARAMETER_API_MOCK_INACTIVE: {
    message: 'Success',
    responseCode: 200,
    data: {
      records: [
        {
          parameterName: 'Example Parameter',
          fieldName: 'exampleField',
          fieldType: 'COMPLEXTYPE',
          dataType: 'NUMBER',
          master: 'Master1',
          multiSelect: false,
          defaultValue: 'Default Value',
          status: 'active',
          esField: 'exampleField',
          mandatory: true,
          placeholder: 'Enter value',
          parameters: ['test'],
          purpose: 'Example Purpose',
          _id: '66795616cd77f42f62aa2519',
          createdAt: '2023-01-01T00:00:00Z',
          updatedAt: '2023-01-02T00:00:00Z',
        },
      ],
    },
  },
  USER_LISTING_API: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '653a3a3ed3f6eaf4a9c88f0f',
          userName: 'App User',
          userEmail: 'ritu.arora@kellton.com',
          cyberarkId: 'ritu.arora@kellton.com',
          roleId: '653a3a42d3f6eaf4a9c88f17',
          status: 'ACTIVE',
          createdAt: '2023-11-15T17:51:34.884Z',
          updatedAt: '2023-11-17T12:14:56.155Z',
          roles: {
            roleName: 'Administrator',
          },
        },
      ],
    },
    error: null,
  },
  WIDGET_LISTING_API: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '653a3a3ed3f6eaf4a9c88f0f',
          widgetTitle: 'Title 1',
          widgetId: '1001',
          widgetType: 'Type1',
          widgetLayout: 'Layout 1',
          engine: 'Manual',
          state: 'DRAFT',
        },
      ],
    },
    error: null,
  },
  CONTAINER_LISTING_API: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '653a3a3ed3f6eaf4a9c88f0f',
          containerTitle: 'Title 1',
          containerId: '1001',
          type: 'Type1',
          layout: 'Layout 1',
          state: 'DRAFT',
          segment: 'Segment 1',
        },
      ],
    },
    error: null,
  },
  SITE_NAVIGATION_LISTING_API: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '653a3a3ed3f6eaf4a9c88f0f',
          siteNavigationTitle: 'Title 1',
          iteNavigationId: '1001',
          type: 'Type1',
          layout: 'Layout 1',
          segment: 'Segment 1',
          state: 'DRAFT',
          stateDisplayName: 'Draft',
        },
      ],
    },
    error: null,
  },
  MEDIA_INFO_LIST_DATA: [
    {
      action: {
        type: 'div',
        key: null,
        ref: null,
        props: {
          className: 'd-flex align-items-center justify-content-between',
          children: [
            {
              type: 'div',
              key: null,
              ref: null,
              props: {
                className:
                  'p-6 d-flex align-items-center justify-content-center cursor-pointer VideoComponent_view_icon_style__BKx-h\n              }',
                'data-testid': 'viewIcon0',
                children: {
                  key: null,
                  ref: null,
                  props: {},
                  _owner: null,
                  _store: {},
                },
              },
              _owner: null,
              _store: {},
            },
            {
              type: 'div',
              key: null,
              ref: null,
              props: {
                className:
                  'p-6 d-flex align-items-center justify-content-center\n               cursor-pointer VideoComponent_view_edit_style__vOMg2\n              }',
              },
              _owner: null,
              _store: {},
            },
            {
              type: 'div',
              key: null,
              ref: null,
              props: {
                className:
                  'p-6 d-flex align-items-center justify-content-center\n               cursor-pointer VideoComponent_view_icon_style__BKx-h\n              }',
                'data-testid': 'editIcon0',
                children: {
                  key: null,
                  ref: null,
                  props: {},
                  _owner: null,
                  _store: {},
                },
              },
              _owner: null,
              _store: {},
            },
          ],
        },
        _owner: null,
        _store: {},
      },
      videoUrl:
        'https://streaming.slivtest.com/DASH/ecc50ad0ece9468586107400b35877f8/1709120742/baseline-std-v4/TEST_GROUP_54_2110001303_eng_baseline-800300010.mpd',
      platform: [
        'ANDROID_PHONE',
        'ANDROID_TAB',
        'FIRE_TV',
        'JIO_ANDROID_TV',
        'WEB',
        'SAMSUNG_HTML_TV',
        'XIAOMI_ANDROID_TV',
        'SONY_ANDROID_TV',
        'TATA_SKY_BINGE',
        'MWEB',
      ],
      videoType: 'FULL_HD1',
      fps: '25',
      encodingProfile: 'baseline-800300010',
      audioCodec: 'AAC',
      drmId: 'NA',
      daiAssetKey: 'NA',
      status: 'ACTIVE',
      videoCodec: 'H264',
      additionalDataJson:
        '{"resolution":"FULL_HD","audio_quality":"STEREO","video_quality":"SDR","bitrate_ladder":{"240v1":{"resolution":"460x240","bitrate":191706,"video_codec":"avc1.4D4015","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":2219954,"audio":{"eng":1946581}}},"360v1":{"resolution":"640x360","bitrate":372515,"video_codec":"avc1.4D401E","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":4246240,"audio":{"eng":1946581}}},"540v1":{"resolution":"960x540","bitrate":681930,"video_codec":"avc1.4D401F","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":8184592,"audio":{"eng":1946581}}},"720v1":{"resolution":"1280x720","bitrate":1004401,"video_codec":"avc1.64001F","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":12058204,"audio":{"eng":1946581}}},"720v2":{"resolution":"1280x720","bitrate":1438872,"video_codec":"avc1.64001F","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":17198881,"audio":{"eng":1946581}}},"1080v1":{"resolution":"1920x1080","bitrate":2425687,"video_codec":"avc1.640028","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":29611807,"audio":{"eng":1946581}}},"1080v2":{"resolution":"1920x1080","bitrate":2902272,"video_codec":"avc1.640028","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":35347413,"audio":{"eng":1946581}}}},"enc_format":"DASH"}',
    },
  ],

  MEDIA_INFO_API: {
    message: 'Media info details fetched successfully!',
    responseCode: 200,
    data: {
      _id: '65a6274451e1690b5206b156',
      contentId: 1790003787,
      isEncrypted: 'Yes',
      duration: 2279,
      drmVideoKid: '387b0858209b411f9068a9c8e9850000',
      mssKid: 'WAh7OJsgH0GQaKnI6YUAAA==',
      mssChecksum: 'TpvPxYz0fk8=',
      maximumVideoQuality: 'DOLBY_VISION',
      maximumAudioQuality: 'DOLBY_ATMOS',
      maximumResolution: 'UHD',
      integratedSplashAvailable: 'Yes',
      spriteImageUrl: 'https://sprite.sonyliv.com/Img/0/ProdFTest_1_1_192_108_143.jpg',
      tvSpriteImageUrl: 'https://sprite.sonyliv.com/Img/0/ProdFTest_1_1_640_360_143.jpg',
      platformVariants: [
        {
          audioLanguages: [
            {
              audioLanguageName: 'TAM',
              audioId: '1',
              isPreferred: false,
            },
          ],
          videoUrl:
            'https://drmstreaming.sonyliv.com/MSS/15a317b3161e4da9851c2f0fe099e289/1691663763/baseline-std-v4/Por_Thozhil_tam_std_lrl-800300010.ism/manifest',
          videoType: 'FULL_HD1',
          fps: 24,
          encodingProfile: 'std_lrl-800300010',
          videoCodec: 'H264',
          audioCodec: 'AAC',
          additionalDataJson:
            '{"resolution": "FULL_HD", "audio_quality": "STEREO", "video_quality": "SDR", "bitrate_ladder": {"240v1": {"resolution": "426x240", "bitrate": 299968, "video_codec": "H264", "audio_codec": "AACL", "fps": 25, "size_on_disk": 254344647}, "360v1": {"resolution": "640x360", "bitrate": 600000, "video_codec": "H264", "audio_codec": "AACL", "fps": 25, "size_on_disk": 331115450}, "540v1": {"resolution": "960x540", "bitrate": 1099968, "video_codec": "H264", "audio_codec": "AACL"}',
          platform: ['sony_html_tv'],
        },
      ],
      createdBy: '659e489c81ca34d2450b0754',
      updatedBy: '659e489c81ca34d2450b0754',
      status: 'ACTIVE',
      createdAt: '2024-01-16T06:50:44.458Z',
      updatedAt: '2024-01-16T06:50:44.458Z',
      __v: 0,
    },
  },

  VIDEO_QUALITY_LIST: {
    message: 'System configuration fetched successfully',
    responseCode: 200,
    data: [
      {
        key: 'SDR',
        value: 'SDR',
        _id: '65a926e79c3149f1c8dae909',
      },
      {
        key: 'HDR',
        value: 'HDR',
        _id: '65a926e79c3149f1c8dae90a',
      },
      {
        key: 'DOLBY_VISION',
        value: 'DOLBY_VISION',
        _id: '65a926e79c3149f1c8dae90b',
      },
    ],
    error: null,
  },

  VIDEO_TYPE_LIST: {
    message: '',
    responseCode: 200,
    data: [
      {
        key: '4K',
        value: '4K',
        _id: '6607a2f60bb5722d659aee66',
      },
      {
        key: 'FULL_HD',
        value: 'FULL_HD',
        _id: '6607a2f60bb5722d659aee67',
      },
    ],
    error: null,
  },

  SUBMIT_MEDIAINFO_FAILED: {
    message: 'Media info platform add failed try again.',
    errors: ['mediaInfoId is required.'],
    responseCode: 422,
    blitzCode: 'BLITZ_19023',
  },
  SUBMIT_MEDIAINFO: {
    message: 'Media info updated successfully!',
    responseCode: 200,
    data: {
      _id: '65e7016e6631dc8b92121ba3',
      contentId: 1090516804,
      __v: 0,
      createdAt: '2024-03-05T11:26:38.677Z',
      createdBy: null,
      drmVideoKid: '',
      duration: 120,
      integratedSplashAvailable: 'true',
      isEncrypted: 'N',
      maximumAudioQuality: 'STEREO',
      maximumResolution: 'FULL_HD',
      maximumVideoQuality: 'SDR',
      mssChecksum: '',
      mssKid: '',
      platformVariants: [
        {
          platform: [
            'ANDROID_PHONE',
            'ANDROID_TAB',
            'FIRE_TV',
            'JIO_ANDROID_TV',
            'WEB',
            'SAMSUNG_HTML_TV',
            'XIAOMI_ANDROID_TV',
            'SONY_ANDROID_TV',
            'TATA_SKY_BINGE',
            'MWEB',
          ],
          audioLanguages: [
            {
              audioLanguageName: 'ENG',
              audioId: 1,
              isPreferred: false,
            },
          ],
          videoUrl:
            'https://streaming.slivtest.com/DASH/3c70c29efccc41e19ae3ca51b8d0f583/1709637970/baseline-std-v4/TEST_GROUP_109_2110001493_eng_baseline-800300010.mpd',
          videoType: 'FULL_HD1',
          fps: 25,
          encodingProfile: 'baseline-800300010',
          videoCodec: 'H264',
          audioCodec: 'AAC',
          drmId: null,
          daiAssetKey: null,
          additionalDataJson:
            '{"resolution":"FULL_HD","audio_quality":"STEREO","video_quality":"SDR","bitrate_ladder":{"240v1":{"resolution":"460x240","bitrate":191706,"video_codec":"avc1.4D4015","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":2219954,"audio":{"eng":1946581}}},"360v1":{"resolution":"640x360","bitrate":372515,"video_codec":"avc1.4D401E","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":4246240,"audio":{"eng":1946581}}},"540v1":{"resolution":"960x540","bitrate":681930,"video_codec":"avc1.4D401F","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":8184592,"audio":{"eng":1946581}}},"720v1":{"resolution":"1280x720","bitrate":1004401,"video_codec":"avc1.64001F","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":12058204,"audio":{"eng":1946581}}},"720v2":{"resolution":"1280x720","bitrate":1438872,"video_codec":"avc1.64001F","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":17198881,"audio":{"eng":1946581}}},"1080v1":{"resolution":"1920x1080","bitrate":2425687,"video_codec":"avc1.640028","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":29611807,"audio":{"eng":1946581}}},"1080v2":{"resolution":"1920x1080","bitrate":2902272,"video_codec":"avc1.640028","audio_codec":"mp4a.40.2","fps":25,"size_on_disk":{"video":35347413,"audio":{"eng":1946581}}}},"enc_format":"DASH"}',
        },
      ],
      spriteImageUrl:
        's3://sonyliv-mediaconvert-sprite-loadtest/Img/6/TEST_GROUP_109_2110001493_192_108_8.jpg',
      status: 'ACTIVE',
      subtitleLanguageFiles: [],
      tvSpriteImageUrl:
        's3://sonyliv-mediaconvert-sprite-loadtest/Img/6/TEST_GROUP_109_2110001493_640_360_8.jpg',
      updatedAt: '2024-03-05T11:27:26.282Z',
      updatedBy: '659e489c81ca34d2450b0754',
    },
    error: null,
  },
  PLATFORM_DATA_LIST: {
    message: 'Downstream Platform Data Fetched Successfully!',
    responseCode: 200,
    data: {
      count: 28,
      records: [
        {
          _id: '65a90f716cf6986d97b527d2',
          key: 'POKIMO',
          value: 'KSA',
          createdBy: {
            userEmail: 'devrath.rana@kellton.com',
          },
          updatedBy: {
            userEmail: 'devrath.rana@kellton.com',
          },
          status: 'ACTIVE',
          createdAt: '2024-01-18T11:45:53.982Z',
          updatedAt: '2024-01-18T11:45:58.910Z',
        },
        {
          _id: '659e489981ca34d2450b0357',
          key: 'SONY_HTML_TV',
          value: 'SONY_HTML_TV',
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          updatedBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          status: 'ACTIVE',
          createdAt: '2024-01-10T07:34:49.549Z',
          updatedAt: '2024-01-10T07:34:49.549Z',
        },
        {
          _id: '65b0b785a3458e2fd11c64aa',
          key: 'Zemlak, Hermann and Green',
          value: 'Brown, Ruecker and Hintz',
          createdBy: {
            userEmail: 'riya.kohli@kellton.com',
          },
          updatedBy: {
            userEmail: 'riya.kohli@kellton.com',
          },
          status: 'ACTIVE',
          createdAt: '2024-01-24T07:08:53.410Z',
          updatedAt: '2024-01-24T07:08:53.552Z',
        },
      ],
    },
  },
  ASSOCIATED_ASSET_LISTING_FAILED: {
    message: 'Associated Content get list failed try again!',
    errors: ['filterCondition is required if a filter key is selected'],
    responseCode: 422,
    blitzCode: 'BLITZ_14069',
  },
  ASSOCIATED_ASSET_LISTING: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '65d378f5a1101caa8c3e4135',
          assetType: 'Season',
          contentId: 1590000008,
          assetCategory: 'BUNDLE',
          contentCategory: 'TV_SHOW',
          state: 'DRAFT',
          createdAt: '2024-02-19T15:51:17.993Z',
          versionCount: 2,
          ContentMetaCategory: [
            {
              _id: '65d378f6a1101caa8c3e4137',
              contentId: '65d378f5a1101caa8c3e4135',
              metaCategory: 'DESCRIPTIVE_METADATA',
              markDoneStatus: 'NO_ACTION',
              attributes: {
                assetTitle: 'Hello',
                castAndCrew: [
                  {
                    castRole: null,
                    actualName: null,
                  },
                ],
                isAutoPlayTrailer: {
                  disabledPlatform: null,
                  disabledCountry: null,
                },
                season: '0',
                subCategory: 'None',
              },
              lockedBy: '65d35ca6a59838acfd34f5c1',
              lockedAt: '2024-02-19T15:51:31.607Z',
              createdBy: '65d35ca6a59838acfd34f5c1',
              updatedBy: '65d35ca6a59838acfd34f5c1',
              isDeleted: false,
              __v: 0,
              createdAt: '2024-02-19T15:51:18.060Z',
              updatedAt: '2024-02-19T15:51:31.608Z',
            },
            {
              _id: '65d378f6a1101caa8c3e4138',
              contentId: '65d378f5a1101caa8c3e4135',
              metaCategory: 'ADVERTISEMENT_METADATA',
              markDoneStatus: 'NO_ACTION',
              attributes: {},
              lockedBy: null,
              lockedAt: null,
              createdBy: '65d35ca6a59838acfd34f5c1',
              updatedBy: '65d35ca6a59838acfd34f5c1',
              isDeleted: false,
              __v: 0,
              createdAt: '2024-02-19T15:51:18.061Z',
              updatedAt: '2024-02-19T15:51:18.061Z',
            },
            {
              _id: '65d378f6a1101caa8c3e4139',
              contentId: '65d378f5a1101caa8c3e4135',
              metaCategory: 'TECHNICAL_METADATA',
              markDoneStatus: 'NO_ACTION',
              attributes: {
                parentId: '1790000002',
              },
              lockedBy: null,
              lockedAt: null,
              createdBy: '65d35ca6a59838acfd34f5c1',
              updatedBy: '65d35ca6a59838acfd34f5c1',
              isDeleted: false,
              __v: 0,
              createdAt: '2024-02-19T15:51:18.062Z',
              updatedAt: '2024-02-19T15:51:18.062Z',
            },
            {
              _id: '65d378f6a1101caa8c3e413a',
              contentId: '65d378f5a1101caa8c3e4135',
              metaCategory: 'SEO_METADATA',
              markDoneStatus: 'NO_ACTION',
              attributes: {},
              lockedBy: null,
              lockedAt: null,
              createdBy: '65d35ca6a59838acfd34f5c1',
              updatedBy: '65d35ca6a59838acfd34f5c1',
              isDeleted: false,
              __v: 0,
              createdAt: '2024-02-19T15:51:18.062Z',
              updatedAt: '2024-02-19T15:51:18.062Z',
            },
            {
              _id: '65d378f6a1101caa8c3e413b',
              contentId: '65d378f5a1101caa8c3e4135',
              metaCategory: 'BUSINESS_RULES',
              markDoneStatus: 'NO_ACTION',
              attributes: {},
              lockedBy: null,
              lockedAt: null,
              createdBy: '65d35ca6a59838acfd34f5c1',
              updatedBy: '65d35ca6a59838acfd34f5c1',
              isDeleted: false,
              __v: 0,
              createdAt: '2024-02-19T15:51:18.064Z',
              updatedAt: '2024-02-19T15:51:18.064Z',
            },
            {
              _id: '65d378f6a1101caa8c3e413c',
              contentId: '65d378f5a1101caa8c3e4135',
              metaCategory: 'CONTENT_MEDIA_IMAGE',
              markDoneStatus: 'NO_ACTION',
              attributes: {
                landscapeThumb: [
                  {
                    title: 'Image',
                    targeting: ['All'],
                    segmentId: '',
                    url: 'image_path/CONTENT_MEDIA_IMAGE_VARIANT_65d378f6a1101caa8c3e413c_landscapeThumb_65d378f5a1101caa8c3e4135_CONTENT_MEDIA_IMAGE_1708358558396_0_Landscape_Image.png',
                    imageKey:
                      'CONTENT_MEDIA_IMAGE_VARIANT_65d378f6a1101caa8c3e413c_landscapeThumb_65d378f5a1101caa8c3e4135_CONTENT_MEDIA_IMAGE_1708358558396_0_Landscape_Image.png',
                    aspectRatio: '16:9',
                    resolution: '1920*1080',
                    imageUploadType: 'UPLOAD',
                    variantId: '2d743710-0034-4465-922b-38b8a6904d45',
                    actualAspectRatio: '1.778',
                    actualResolution: '1920x1080',
                  },
                ],
              },
              lockedBy: '65d35ca6a59838acfd34f5c1',
              lockedAt: '2024-02-19T16:02:39.279Z',
              createdBy: '65d35ca6a59838acfd34f5c1',
              updatedBy: '65d35ca6a59838acfd34f5c1',
              isDeleted: false,
              __v: 0,
              createdAt: '2024-02-19T15:51:18.065Z',
              updatedAt: '2024-02-19T16:02:39.642Z',
            },
            {
              _id: '65d378f6a1101caa8c3e413d',
              contentId: '65d378f5a1101caa8c3e4135',
              metaCategory: 'MISCELLANEOUS_METADATA',
              markDoneStatus: 'NO_ACTION',
              attributes: {},
              lockedBy: null,
              lockedAt: null,
              createdBy: '65d35ca6a59838acfd34f5c1',
              updatedBy: '65d35ca6a59838acfd34f5c1',
              isDeleted: false,
              __v: 0,
              createdAt: '2024-02-19T15:51:18.065Z',
              updatedAt: '2024-02-19T15:51:18.065Z',
            },
            {
              _id: '65d378f6a1101caa8c3e413e',
              contentId: '65d378f5a1101caa8c3e4135',
              metaCategory: 'RIGHTS_AND_AVAILABILITY',
              markDoneStatus: 'NO_ACTION',
              attributes: {},
              lockedBy: null,
              lockedAt: null,
              createdBy: '65d35ca6a59838acfd34f5c1',
              updatedBy: '65d35ca6a59838acfd34f5c1',
              isDeleted: false,
              __v: 0,
              createdAt: '2024-02-19T15:51:18.066Z',
              updatedAt: '2024-02-19T15:51:18.066Z',
            },
          ],
          assetTitle: 'Hello',
        },
      ],
    },
    error: null,
  },
  ASSET_DETAILS: {
    message: 'Content Details Fetched Successfully',
    responseCode: 200,
    data: {
      _id: '659e7fec69eca328816196ba',
      assetType: 'Show',
      contentId: 1790000222,
      assetCategory: 'GOB',
      contentCategory: 'TV_SHOW',
      state: 'DRAFT',
      parentId: null,
      status: 'ACTIVE',
      createdAt: '2024-01-10T11:30:52.916Z',
      updatedAt: '2024-01-10T11:45:17.186Z',
      createdBy: 'satyendra.chouhan@kellton.com',
      updatedBy: 'satyendra.chouhan@kellton.com',
      ContentMetaCategory: [
        {
          _id: '659e7fec69eca328816196be',
          contentId: '659e7fec69eca328816196ba',
          metaCategory: 'RIGHTS_AND_AVAILABILITY',
          markDoneStatus: 'NO_ACTION',
          lockedBy: null,
          lockedAt: null,
          createdBy: '659e489c81ca34d2450b0750',
          updatedBy: '659e489c81ca34d2450b0750',
          attributes: {},
          __v: 0,
          createdAt: '2024-01-10T11:30:52.927Z',
          updatedAt: '2024-01-10T11:30:52.927Z',
        },
      ],
    },
    error: null,
  },
  ASSET_TYPE_DEFINITION_LISTING_API: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '655b7b3e21306f8a7a1aff4c',
          assetType: 'episode',
          assetCategory: 'Content Item',
          contentCategory: 'Series',
          haveAllowedParents: true,
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          modifiedBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          status: 'ACTIVE',
        },
      ],
    },
    error: null,
  },
  ATTRIBUTE_REPO_LISTING_API: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 201,
      records: [
        {
          _id: '655f1174846095a5cde6d8ee',
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'detailsLogoImage',
          isRequired: true,
          isMultiple: false,
          label: 'Details Logo Image',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          placeholder: '',
          description: 'Testing Purpose',
          master: '',
          options: [],
          sortOrder: 1,
          metaCategory: 'contentMediaImage',
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          modifiedBy: '653a3a3ed3f6eaf4a9c88f0f',
          status: 'ACTIVE',
          inheritanceRule: 'NONE',
          isMultilingual: false,
          __v: 0,
          createdAt: '2023-11-23T08:46:44.361Z',
          updatedAt: '2023-11-23T08:46:44.361Z',
        },
      ],
    },
    error: null,
  },
  PARAMETER_VIEW_API: {
    message: 'Success',
    data: {
      message: 'Success',
      responseCode: 200,
      data: {
        _id: '655c6240e56707ee482cae1d',
        fieldType: 'complexType',
        dataType: 'JSON',
        fieldName: 'containerName',
        isRequired: true,
        isMultiple: true,
        isMultilingual: false,
        label: 'containerName',
        isB2B: false,
        defaultValue: null,
        affiliatePartner: '',
        placeholder: '',
        description: 'testing purpose 2',
        master: 'SystemConfigurations/SCOPE',
        options: [],
        attributeRef: [
          {
            castRole: true,
            actualName: true,
            characterName: true,
            hierarchy: true,
          },
        ],
        metaCategory: 'descriptiveMetadata',
        createdBy: '5d7a1f3a59f60c7a88b5bea3',
        updatedBy: '655c6240e56707ee482cae1d',
        status: 'INACTIVE',
        createdAt: '2023-11-21T07:54:40.076Z',
        updatedAt: '2023-11-21T15:32:24.496Z',
        __v: 0,
      },
      error: null,
    },
  },
  CREATE_ROLE_API: {
    message: 'Create Role Successfull',
    data: {
      _id: 'some_id',
    },
  },
  CREATE_ROLE_API_ERROR: {
    error: {
      message: 'Create Role Successfull',
    },
  },
  GET_PERMISSIONS_API_URL: 'api/v1_0_0/config/permissions',
  ROLE_LIST_API_MOCK: {
    message: 'Data successfully fetched',
    responseCode: 200,
    data: {
      count: 3,
      records: [
        {
          _id: '653a3a42d3f6eaf4a9c88f17',
          roleName: 'Administrator',
          status: 'ACTIVE',
          createdAt: '2023-11-07T11:51:34.765Z',
          updatedAt: '2023-11-07T11:51:34.765Z',
          associatedUsers: 3,
        },
        {
          _id: '654a24c6f43fc1fa6ab381d4',
          roleName: 'Content Creator',
          status: 'INACTIVE',
          createdAt: '2023-11-07T11:51:34.765Z',
          updatedAt: '2023-11-07T11:51:34.765Z',
          associatedUsers: 18,
        },
      ],
    },
  },

  ADD_USER_API_MOCK: {
    message: 'Success',
    responseCode: 200,
    data: {
      firstName: 'test first_name',
      userName: 'test userName',
      lastName: 'test last name',
      userEmail: 'test@sonyliv.com',
      cyberarkId: 'testcyberarkid@sonyliv.com',
      roleId: '653a3a42d3f6eaf4a9c88f17',
      createdBy: '6540a4f657603ed58e2ed8c5',
      modifiedBy: '6540a4f657603ed58e2ed8c5',
      status: 'ACTIVE',
      _id: '654de4f35623149023843083',
      createdAt: '2023-11-10T08:08:19.922Z',
      updatedAt: '2023-11-10T08:08:19.922Z',
      __v: 0,
    },
    error: null,
  },
  ADD_USER_API_MOCK_ERROR: {
    error: {
      message: 'Internal Server Error',
      responseCode: 500,
      data: {},
      error: null,
    },
  },
  ADD_USER_API_MOCK_ERROR_EMAIL: {
    error: {
      message: 'Internal Server Error',
      responseCode: 422,
      errors: [VALIDATIONS.EMAILID_EXIST],
      error: null,
    },
  },
  ADD_USER_API_MOCK_ERROR_CYBERARKID: {
    error: {
      message: 'Internal Server Error',
      responseCode: 422,
      errors: [VALIDATIONS.CYBERARKID_EXIST],
      error: null,
    },
  },
  GETUSER_API_MOCK: {
    message: 'Success',
    responseCode: 200,
    data: {
      records: [
        {
          _id: '6555fb9fcb332b823ded0300',
          userName: 'New User',
          userEmail: 'satz9310@gmail.com',
          cyberarkId: 'satz9310@gmail.com',
          roleId: '653a3a42d3f6eaf4a9c88f17',
          status: 'ACTIVE',
          createdAt: '2023-11-16T11:23:11.440Z',
          updatedAt: '2023-11-17T06:15:34.872Z',
        },
        {
          _id: '6555fb9fcb332b823ded0300',
          userName: 'New User',
          userEmail: 'satz9310@gmail.com',
          cyberarkId: 'satz9310@gmail.com',
          roleId: '653a3a42d3f6eaf4a9c88f17',
          status: 'INACTIVE',
          createdAt: '2023-11-16T11:23:11.440Z',
          updatedAt: '2023-11-17T06:15:34.872Z',
        },
      ],
    },
  },
  GETUSER_API_MOCK_INACTIVE: {
    message: 'Success',
    responseCode: 200,
    data: {
      records: [
        {
          _id: '6555fb9fcb332b823ded0300',
          userName: 'New User',
          userEmail: 'satz9310@gmail.com',
          cyberarkId: 'satz9310@gmail.com',
          roleId: '654a24c6f43fc1fa6ab381d4',
          status: 'ACTIVE',
          createdAt: '2023-11-16T11:23:11.440Z',
          updatedAt: '2023-11-17T06:15:34.872Z',
        },
      ],
    },
  },
  ASSET_LISTING_API: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          contentId: '1500005807',
          assetType: 'MOVIE_BUNDLE',
          title: 'Love Life',
          assetCategory: 'BUNDLE',
        },
      ],
    },
    error: null,
  },
  GEO_POLICY_LISTING_API: {
    message: 'Geo policies fetched successfully!',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '6570243f2f60c9412b3b67b8',
          policyName: 'Geo_1',
          description: 'Geo policy description',
          timeDifference: 7200,
          countries: ['nepal', 'bangladesh'],
          days: ['fri', 'sat'],
          createdBy: '6565d5f7ce0f80ffbacf35ea',
          updatedBy: '6565d5f7ce0f80ffbacf35ea',
          status: 'ACTIVE',
          createdAt: '2023-12-06T07:35:27.534Z',
          updatedAt: '2023-12-06T07:35:27.534Z',
          __v: 0,
        },
      ],
    },
    error: null,
  },
  GEO_POLICY_VIEW_API: {
    message: 'Geo policy detail fetched succussfully!',
    responseCode: 200,
    data: {
      _id: '656d7cb7c737d64b63b1b715',
      policyName: 'Geo_1',
      description: 'Geo policy description',
      timeDifference: 7200,
      countries: ['nepal', 'bangladesh'],
      days: ['fri', 'sat'],
      status: 'ACTIVE',
    },
    error: null,
  },
  GET_ALL_COUNTRY_API: {
    message: 'Countries Data Fetched Successfully',
    responseCode: 200,
    data: {
      count: 247,
      records: [
        {
          _id: '6576b5f038fd83d2b4da6030',
          key: '_as',
          value: 'India',
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          updatedBy: {
            userEmail: 'devrath.rana@kellton.com',
          },
          status: 'INACTIVE',
          createdAt: '2023-12-11T07:10:40.651Z',
          updatedAt: '2023-12-12T06:14:48.300Z',
        },
      ],
    },
  },
  SYSTEM_CONFIGURATIONS_LISTING_API: {
    message: 'Data successfully fetched',
    responseCode: 200,
    data: {
      count: 3,
      records: [
        {
          _id: '668686686b7b43be1b27ddbc',
          name: 'Android',
          createdBy: {
            userEmail: 'sonylivblitz@setindia.com',
          },
          updatedBy: {
            userEmail: 'sonylivblitz@setindia.com',
          },
          status: 'ACTIVE',
          createdAt: '2024-07-04T11:24:24.419Z',
          updatedAt: '2024-07-04T11:24:24.419Z',
        },
        {
          _id: '668686686b7b43be1b27ddbd',
          name: 'IOS',
          createdBy: {
            userEmail: 'sonylivblitz@setindia.com',
          },
          updatedBy: {
            userEmail: 'sonylivblitz@setindia.com',
          },
          status: 'ACTIVE',
          createdAt: '2024-07-04T11:24:24.419Z',
          updatedAt: '2024-07-04T11:24:24.419Z',
        },
      ],
    },
    error: null,
  },
  AFFILIATE_PARTNERS_LISTING_API: {
    message: 'Affiliate Partner Data Fetched Successfully',
    responseCode: 200,
    data: {
      count: 33,
      records: [
        {
          _id: '657998fe55688ccbbe35a742',
          key: 'FLIPPER',
          value: 'Flippers',
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          updatedBy: {
            userEmail: 'devrath.rana@kellton.com',
          },
          status: 'INACTIVE',
          createdAt: '2023-12-11T07:10:39.022Z',
          updatedAt: '2023-12-14T04:57:32.207Z',
        },
        {
          _id: '6576b5ef38fd83d2b4da5e7b',
          key: 'MAKER',
          value: 'Maker',
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          updatedBy: {
            userEmail: 'devrath.rana@kellton.com',
          },
          status: 'ACTIVE',
          createdAt: '2023-13-11T07:10:39.022Z',
          updatedAt: '2023-13-14T04:57:32.207Z',
        },
      ],
    },
    error: null,
  },
  LANGUAGES_LISTING_API: {
    message: 'Language Data Fetched Successfully',
    responseCode: 200,
    data: {
      count: 22,
      records: [
        {
          _id: '657998fe55688ccbbe35a742',
          key: 'MAL',
          value: 'MALAYALAM',
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          updatedBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          status: 'ACTIVE',
          createdAt: '2023-12-11T07:10:41.495Z',
          updatedAt: '2023-12-11T07:10:41.495Z',
        },
        {
          _id: '6576b5f138fd83d2b4da614a',
          key: 'ASM',
          value: 'Test Language Value New',
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          updatedBy: {
            userEmail: 'devrath.rana@kellton.com',
          },
          status: 'INACTIVE',
          createdAt: '2023-12-11T07:10:41.495Z',
          updatedAt: '2023-12-12T05:33:23.675Z',
        },
      ],
    },
    error: null,
  },
  DOWNSTREAM_PLATFORMS_LISTING_API: {
    message: 'Downstream Platform Data Fetched Successfully',
    responseCode: 200,
    data: {
      count: 26,
      records: [
        {
          _id: '657998fe55688ccbbe35a742',
          key: 'FIRE_TV',
          value: 'FIRE TV JI',
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          updatedBy: {
            userEmail: 'devrath.rana@kellton.com',
          },
          status: 'ACTIVE',
          createdAt: '2023-12-11T07:10:41.137Z',
          updatedAt: '2023-12-13T11:40:28.840Z',
        },
        {
          _id: '6576b5f138fd83d2b4da612f',
          key: 'APPLE_TV',
          value: 'APPLE TV',
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          updatedBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          status: 'INACTIVE',
          createdAt: '2023-12-11T07:10:41.137Z',
          updatedAt: '2023-12-11T07:10:41.137Z',
        },
      ],
    },
    error: null,
  },
  ENTITLEMENT_PACKAGES_LISTING_API: {
    message: 'Entitlement Package Data Fetched Successfully',
    responseCode: 200,
    data: {
      count: 11,
      records: [
        {
          _id: '657998fe55688ccbbe35a742',
          key: 'LAMBDA',
          value: 'lambskin',
          createdBy: {
            userEmail: 'devrath.rana@kellton.com',
          },
          updatedBy: {
            userEmail: 'devrath.rana@kellton.com',
          },
          status: 'ACTIVE',
          createdAt: '2023-12-13T11:43:58.253Z',
          updatedAt: '2023-12-13T11:44:10.537Z',
        },
        {
          _id: '6579a8c955688ccbbe35ad3e',
          key: 'vauban',
          value: 'vauban value',
          createdBy: {
            userEmail: 'vaibhav.verma@kellton.com',
          },
          updatedBy: {
            userEmail: 'vaibhav.verma@kellton.com',
          },
          status: 'INACTIVE',
          createdAt: '2023-12-13T12:51:21.243Z',
          updatedAt: '2023-12-13T12:51:21.243Z',
        },
      ],
    },
    error: null,
  },
  COUNTRIES_LISTING_API: {
    message: 'Countries Data Fetched Successfully',
    responseCode: 200,
    data: {
      count: 251,
      records: [
        {
          _id: '657998fe55688ccbbe35a742',
          key: '_aw',
          value: 'Aruba',
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          updatedBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          status: 'ACTIVE',
          createdAt: '2023-12-11T07:10:40.651Z',
          updatedAt: '2023-12-11T07:10:40.651Z',
        },
        {
          _id: '6576b5f038fd83d2b4da6039',
          key: '_au',
          value: 'Australia',
          createdBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          updatedBy: {
            userEmail: 'ritu.arora@kellton.com',
          },
          status: 'INACTIVE',
          createdAt: '2023-12-11T07:10:40.651Z',
          updatedAt: '2023-12-11T07:10:40.651Z',
        },
      ],
    },
    error: null,
  },
  ADD_MASTERS_MOCK: {
    message: 'Success',
    responseCode: 200,
    data: {
      _id: '6579deadd730c6ffd0ba07ed',
      key: 'TEST',
      value: 'Test Partner Name DEC 13',
      createdBy: '6576b5f238fd83d2b4da64e8',
      updatedBy: '6576b82bd7939d36b27eb817',
      status: 'INACTIVE',
      createdAt: '2023-12-13T16:41:17.350Z',
      updatedAt: '2023-12-14T13:14:41.915Z',
      __v: 0,
    },
    error: null,
  },
  ADD_MASTERS_API_MOCK_ERROR: {
    error: {
      message: 'Internal Server Error',
      responseCode: 500,
      data: {},
      error: null,
    },
  },
  ADD_IMAGE_ASSET_MOCK_ERROR: {
    error: {
      message: 'Internal Server Error',
      responseCode: 500,
      data: {},
      error: null,
    },
  },
  ADD_IMAGE_ASSET_PAYLOAD: {
    contentMetaCategoryId: '659f7d0131f95a17989bc3d7',
    contentId: '659f7d0131f95a17989bc3d0',
    metaCategory: 'CONTENT_MEDIA_IMAGE',
    markDoneStatus: 'NO_ACTION',
    attributes: {
      landscapeThumb: [
        {
          title: 'Image',
          targeting: 'All Countries',
          segmentId: '',
          url: 's3://image_path/1920-1080-sample.jpg',
          aspectRatio: 'NA',
          resolution: 'NA',
          imageUploadType: 'URL',
          imageKey: '1920-1080-sample.jpg',
        },
      ],
    },
  },
  ADD_IMAGE_ASSET_SUCCESS: {
    message: 'Content image(s) created successfully!',
    responseCode: 200,
    data: {
      _id: '659f7d0131f95a17989bc3d7',
      contentId: '659f7d0131f95a17989bc3d0',
      metaCategory: 'CONTENT_MEDIA_IMAGE',
      markDoneStatus: 'NO_ACTION',
      lockedBy: null,
      lockedAt: null,
      createdBy: '659f79de31f95a17989bbf77',
      updatedBy: '659f79de31f95a17989bbf77',
      attributes: {
        landscapeThumb: [
          {
            title: 'Image',
            targeting: 'All Countries',
            segmentId: '',
            url: 's3://image_path/CONTENT_MEDIA_IMAGE_VARIANT_659f7d0131f95a17989bc3d7_landscapeThumb_659f7d0131f95a17989bc3d0_CONTENT_MEDIA_IMAGE_1704955308489_download-(2).jpeg',
            aspectRatio: 'NA',
            resolution: 'NA',
            imageUploadType: 'UPLOAD',
            imageKey:
              'CONTENT_MEDIA_IMAGE_VARIANT_659f7d0131f95a17989bc3d7_landscapeThumb_659f7d0131f95a17989bc3d0_CONTENT_MEDIA_IMAGE_1704955308489_download-(2).jpeg',
            variantId: '0f4a012e-9e30-4e67-a7a9-bf92f3725cae',
            actualAspectRatio: '1.000',
            actualResolution: '148x148',
          },
        ],
      },
      __v: 0,
      createdAt: '2024-01-11T05:30:41.947Z',
      updatedAt: '2024-01-11T06:41:52.657Z',
    },
    error: null,
  },
  API_SEGMENT_LIST_MASTERS_DATA: {
    message: 'System configuration fetched successfully',
    responseCode: 200,
    data: [
      {
        key: 'SEG_FIRST_OPEN',
        value: 'SEG_FIRST_OPEN',
        _id: '659e9feeeb3f791e8f246498',
      },
      {
        key: 'SEG_FIRST_OPEN_2',
        value: 'SEG_FIRST_OPEN_2',
        _id: '659e9feeeb3f791e8f246499',
      },
      {
        key: 'SEG_FIRST_OPEN_3',
        value: 'SEG_FIRST_OPEN_3',
        _id: '659e9feeeb3f791e8f24649a',
      },
      {
        key: 'SEG_FIRST_OPEN_4',
        value: 'SEG_FIRST_OPEN_4',
        _id: '659e9feeeb3f791e8f24649b',
      },
      {
        key: 'SPORTS_SEG_USERS',
        value: 'SPORTS_SEG_USERS',
        _id: '659e9feeeb3f791e8f24649c',
      },
      {
        key: 'SEG_COMEDY_USERS',
        value: 'SEG_COMEDY_USERS',
        _id: '659e9feeeb3f791e8f24649d',
      },
      {
        key: 'SEG_FANTASY_USERS',
        value: 'SEG_FANTASY_USERS',
        _id: '659e9feeeb3f791e8f24649e',
      },
      {
        key: 'SEG_DRAMA_USERS',
        value: 'SEG_DRAMA_USERS',
        _id: '659e9feeeb3f791e8f24649f',
      },
      {
        key: 'SEG_TAMIL_USERS_OVER2_VIEWS',
        value: 'SEG_TAMIL_USERS_OVER2_VIEWS',
        _id: '659e9feeeb3f791e8f2464a0',
      },
      {
        key: 'SEG_WHISTLEBLOWER_1',
        value: 'SEG_WHISTLEBLOWER_1',
        _id: '659e9feeeb3f791e8f2464a1',
      },
      {
        key: 'SEG_WHISTLEBLOWERIMAGE_WITHZIP',
        value: 'SEG_WHISTLEBLOWERIMAGE_WITHZIP',
        _id: '659e9feeeb3f791e8f2464a2',
      },
      {
        key: 'SEG_WHISTLEBLOWERIMAGE_WITHOUTZIP',
        value: 'SEG_WHISTLEBLOWERIMAGE_WITHOUTZIP',
        _id: '659e9feeeb3f791e8f2464a3',
      },
      {
        key: 'SEG_AVOD_USERS',
        value: 'SEG_AVOD_USERS',
        _id: '659e9feeeb3f791e8f2464a4',
      },
      {
        key: 'SEG_SVOD_USERS',
        value: 'SEG_SVOD_USERS',
        _id: '659e9feeeb3f791e8f2464a5',
      },
      {
        key: 'SEG_MALAYALAM_THUMBNAIL_WITHOUT_TEXT',
        value: 'SEG_MALAYALAM_THUMBNAIL_WITHOUT_TEXT',
        _id: '659e9feeeb3f791e8f2464a6',
      },
      {
        key: 'SEG_TELUGU_THUMBNAIL_WITHOUT_TEXT',
        value: 'SEG_TELUGU_THUMBNAIL_WITHOUT_TEXT',
        _id: '659e9feeeb3f791e8f2464a7',
      },
      {
        key: 'SEG_TAMIL_THUMBNAIL_WITHOUT_TEXT',
        value: 'SEG_TAMIL_THUMBNAIL_WITHOUT_TEXT',
        _id: '659e9feeeb3f791e8f2464a8',
      },
      {
        key: 'SEG_WITH_IMDB',
        value: 'SEG_WITH_IMDB',
        _id: '659e9feeeb3f791e8f2464a9',
      },
      {
        key: 'SEG_WITHOUT_IMDB',
        value: 'SEG_WITHOUT_IMDB',
        _id: '659e9feeeb3f791e8f2464aa',
      },
      {
        key: 'AB_ARS_IMAGE50',
        value: 'AB_ARS_IMAGE50',
        _id: '659e9feeeb3f791e8f2464ab',
      },
      {
        key: 'SEG_CWG_SPOTLIGHTTEST_OLD',
        value: 'SEG_CWG_SPOTLIGHTTEST_OLD',
        _id: '659e9feeeb3f791e8f2464ac',
      },
      {
        key: 'SEG_CWG_SPOTLIGHTTEST_NEW',
        value: 'SEG_CWG_SPOTLIGHTTEST_NEW',
        _id: '659e9feeeb3f791e8f2464ad',
      },
      {
        key: 'SEG_ACTION_WATCHERS',
        value: 'SEG_ACTION_WATCHERS',
        _id: '659e9feeeb3f791e8f2464ae',
      },
      {
        key: 'SEG_ROMANTIC_WATCHERS',
        value: 'SEG_ROMANTIC_WATCHERS',
        _id: '659e9feeeb3f791e8f2464af',
      },
      {
        key: 'SEG_TANAAV_1_VISIT',
        value: 'SEG_TANAAV_1_VISIT',
        _id: '659e9feeeb3f791e8f2464b0',
      },
      {
        key: 'SEG_TANAAV_2_VISIT',
        value: 'SEG_TANAAV_2_VISIT',
        _id: '659e9feeeb3f791e8f2464b1',
      },
      {
        key: 'SEG_TANAAV_3_VISIT',
        value: 'SEG_TANAAV_3_VISIT',
        _id: '659e9feeeb3f791e8f2464b2',
      },
      {
        key: 'SEG_TANAAV_4_VISIT',
        value: 'SEG_TANAAV_4_VISIT',
        _id: '659e9feeeb3f791e8f2464b3',
      },
    ],
    error: null,
  },
  USERS_ROLES_LOGS_LISTING_API: {
    message: 'Audit Trails fetched successfully!',
    responseCode: 200,
    data: {
      count: 25,
      records: [
        {
          _id: '659fec7b51cab494cb7a2ecb',
          blitzTransactionId: '2ecdfc91-3ccb-478b-ae83-7365dfbc71b4',
          type: 'user_role',
          subType: 'Roles',
          event: 'List Role',
          activity: 'Role Details fetched successfully.! For role : Test Administrator',
          message: 'Role Details fetched successfully.!',
          requestUri: '/api/v1/userRole/detail/659e86cc154b34e79a8dbfd7',
          responseStatus: 'Success',
          logLevel: 'Info',
          logCategory: 'Access',
          module: 'BOLT_MODULE',
          subModule: 'ROLE_AND_USER_MANAGEMENT',
          dataModel: 'Roles',
          documentId: '',
          userName: 'Vaibhav Verma',
          userId: '659e489c81ca34d2450b0756',
          roleName: 'Administrator',
          ipAddress: 'some ip adress',
          source: 'APP',
          userAgent:
            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36',
          createdAt: '2024-01-11T13:26:19.273Z',
          updatedAt: '2024-01-11T13:26:19.273Z',
          __v: 0,
        },
      ],
    },
  },
  MASTER_LOGS_LISTING_API: {
    message: 'Audit Trails fetched successfully!',
    responseCode: 200,
    data: {
      count: 6,
      records: [
        {
          _id: '659fa0a661f586f860d94d87',
          blitzTransactionId: '7640e53f-d505-401f-9896-c787305fa499',
          type: 'master',
          subType: 'Language',
          event: 'Create Master Management',
          activity: 'Master created successfully!',
          message: 'Language added successfully!',
          requestUri: '/api/v1/attributeRepository/languages/add',
          responseStatus: 'Success',
          logLevel: 'Info',
          logCategory: 'Change',
          module: 'BOLT_MODULE',
          subModule: 'META_MASTER',
          dataModel: 'Languages',
          documentId: '',
          newData: {
            key: 'TESTTT',
            value: 'TEST LANGIAGE',
            createdBy: '659e489c81ca34d2450b074e',
            updatedBy: '659e489c81ca34d2450b074e',
            status: 'ACTIVE',
            _id: '659fa0a661f586f860d94d85',
            createdAt: '2024-01-11T08:02:46.629Z',
            updatedAt: '2024-01-11T08:02:46.629Z',
            __v: 0,
          },
        },
      ],
    },
  },
  USER_MANAGELOGS_VIEW: {
    message: 'Audit Trails fetched successfully!',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '65dc3e6ec0969061775cbf6b',
          blitzTransactionId: '9805b71d-d463-449e-8776-e0fa9d2d9893',
          type: 'user_role',
          subType: 'User',
          event: 'Modification',
          activity: 'Modification Failed for Shannon Schmidt',
          message: 'INVALID USER',
          requestUri: '/api/v1/user/update',
          responseStatus: 'Failed',
          logLevel: 'Info',
          logCategory: 'Change',
          module: 'BOLT_MODULE',
          subModule: 'ROLE_AND_USER_MANAGEMENT',
          dataModel: 'Users',
          documentId: '',
          difference: '',
          userName: 'Riya Kohli',
          userId: '659e489c81ca34d2450b074e',
          roleName: 'Administrator',
          ipAddress: 'some_ip address',
          source: 'APP',
          userAgent: 'N/A',
          createdAt: '2024-02-26T07:31:58.691Z',
          updatedAt: '2024-02-26T07:31:58.691Z',
          __v: 0,
        },
      ],
    },
    error: null,
  },
  CONTENT_LOGS_LISTING_API: {
    message: 'Audit Trails fetched successfully!',
    responseCode: 200,
    data: {
      count: 15,
      records: [
        {
          _id: '659bd659fa6bfc947e08e386',
          blitzTransactionId: '9d0b343f-892b-47ca-8223-d225e0d10e0d',
          assetId: '657c48a67bb4d008212f26fe',
          difference: 'some content logs\n some other content logs',
          type: 'content',
          subType: 'Content',
          event: 'Unlink Content',
          activity: 'Could not unlink contnetwith parent Id 657c48a97bb4d008212f27ea',
          message: 'Content child Id or parent Id is invalid',
          requestUri: '/api/v1/content/associateContent/unlink',
          responseStatus: 'Failed',
          logLevel: 'Error',
          logCategory: 'Change',
          module: 'BOLT_MODULE',
          subModule: 'MANAGE_CONTENT',
          dataModel: 'Contents',
          documentId: '657c48a67bb4d008212f26fe',
          userName: 'Yash Shah',
          userId: '659bbb950d3f27463a3185d2',
          roleName: 'Administrator',
          ipAddress: 'some ip',
          source: 'APP',
          userAgent:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36',
          createdAt: '2024-01-08T11:02:49.894Z',
          updatedAt: '2024-01-08T11:02:49.894Z',
          __v: 0,
        },
      ],
    },
  },
  TRANSCODING_LOGS_LISTING_API: {
    message: 'Audit Trails fetched successfully!',
    responseCode: 200,
    data: {
      count: 6,
      records: [
        {
          _id: '659e916e5f309fdd5b4e5a08',
          blitzTransactionId: '67575216-4091-48c2-a5a9-8eb216521ca9',
          type: 'transcoding',
          subType: 'Transcoding',
          event: 'Initiate Video Transcoding',
          activity: 'Video transcoding initiated successfully!',
          message: 'Video transcoding initiated successfully!',
          requestUri: '/api/v1/attributeRepository/geoPolicy/list',
          responseStatus: 'Success',
          logLevel: 'Info',
          logCategory: 'Change',
          module: 'META_CATEGORIES',
          subModule: 'CONTENT_MEDIA_VIDEO',
          dataModel: 'MetaCategory',
          documentId: '',
          userName: 'Yash Shah',
          userId: '657ffa032ccbce5b25c604a2',
          roleName: 'Administrator',
          ipAddress: 'some ip',
          source: 'APP',
          userAgent:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36',
          createdAt: '2024-01-10T12:45:34.289Z',
          updatedAt: '2024-01-10T12:45:34.289Z',
          __v: 0,
          assetId: '1090000004',
        },
      ],
    },
  },

  SEGMENT_LISTING_API: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '668e5ae674eeb4617ece847a',
          segmentId: 102,
          segmentName: 'App Segment',
          description: 'Description for Segment 6',
          abSegments: [],
          publishingSegments: [],
          countries: [
            {
              countryId: '653a3b41d3f6eaf4a9c88e56',
              countryName: 'India',
              state: [
                {
                  id: '653a3b41d3f6eaf4a9c88f21',
                  name: 'State 1',
                },
                {
                  id: '653a3b41d3f6eaf4a9c88e29',
                  name: 'State 2',
                },
              ],
              cluster: [
                {
                  id: '653a3b41d3f6eaf4a9c88f22',
                  name: 'Cluster 1',
                },
                {
                  id: '6686f97b8a2816d208e298c6',
                  name: 'Cluster 2',
                },
              ],
              packFamily: [
                {
                  id: '6686f9788a2816d208e29889',
                  name: 'Test 1',
                },
                {
                  id: '653a3b41d3f6eaf4a9c88f23',
                  name: 'Test 2',
                },
              ],
            },
            {
              countryId: '653a3b41d3f6eaf4a9c88e40',
              countryName: 'US',
              state: [
                {
                  id: '653a3b41d3f6eaf4a9c88f22',
                  name: 'State 1',
                },
                {
                  id: '6686f97b8a2816d208e298c6',
                  name: 'State 2',
                },
              ],
              cluster: [
                {
                  id: '653a3b41d3f6eaf4a9c88f22',
                  name: 'Cluster 1',
                },
                {
                  id: '6686f97b8a2816d208e298c6',
                  name: 'Cluster 2',
                },
              ],
              packFamily: [
                {
                  id: '6686f9788a2816d208e29889',
                  name: 'Test 1',
                },
                {
                  id: '653a3b41d3f6eaf4a9c88f23',
                  name: 'Test 2',
                },
              ],
            },
          ],
          platform: [
            {
              platformId: '6686f9788a2816d208e2988a',
              platformName: 'Platform 1',
              minVersion: ['v1', 'v2'],
              maxVersion: ['v1.1', 'v2.1'],
              selectVersion: ['test 1', 'test 2'],
            },
            {
              platformId: '6686f9788a2816d208e2933b',
              platformName: 'Platform 2',
              minVersion: ['v1', 'v2'],
              maxVersion: ['v1.1', 'v2.1'],
              selectVersion: ['test 1', 'test 2'],
            },
          ],
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          createdAt: '2024-07-10T09:56:54.932Z',
          updatedAt: '2024-07-10T09:56:54.932Z',
          __v: 0,
        },
      ],
    },
    error: null,
  },
  ADD_SEGMENT_API_MOCK: {
    message: 'Success',
    responseCode: 200,
    data: {
      segmentName: 'test segmentName',
      description: 'test description',
      abSegment: 'test abSegment',
      publishSegments: 'test publishSegments',
      countries: 'test countries',
      cluster: 'test cluster',
      packFamily: 'test packFamily',
      packName: 'test packName',
      cyberarkId: 'testcyberarkid@sonyliv.com',
      roleId: '653a3a42d3f6eaf4a9c88f17',
      createdBy: '6540a4f657603ed58e2ed8c5',
      modifiedBy: '6540a4f657603ed58e2ed8c5',
      status: 'ACTIVE',
      _id: '654de4f35623149023843083',
      createdAt: '2023-11-10T08:08:19.922Z',
      updatedAt: '2023-11-10T08:08:19.922Z',
      __v: 0,
    },
    error: null,
  },
  SEGMENT_DETAILS_API_MOCK: {
    message: 'Success',
    responseCode: 200,
    data: {
      records: [
        {
          _id: '668e5ae674eeb4617ece847a',
          segmentId: 101,
          segmentName: 'Segment 6',
          description: 'Description for Segment 6',
          abSegments: [],
          publishingSegments: [],
          countries: [
            {
              countryId: '653a3b41d3f6eaf4a9c88e56',
              countryName: 'India',
              state: [
                {
                  id: '653a3b41d3f6eaf4a9c88f21',
                  name: 'State 1',
                },
                {
                  id: '653a3b41d3f6eaf4a9c88e29',
                  name: 'State 2',
                },
              ],
              cluster: [
                {
                  id: '653a3b41d3f6eaf4a9c88f22',
                  name: 'Cluster 1',
                },
                {
                  id: '6686f97b8a2816d208e298c6',
                  name: 'Cluster 2',
                },
              ],
              packFamily: [
                {
                  id: '6686f9788a2816d208e29889',
                  name: 'Test 1',
                },
                {
                  id: '653a3b41d3f6eaf4a9c88f23',
                  name: 'Test 2',
                },
              ],
            },
            {
              countryId: '653a3b41d3f6eaf4a9c88e40',
              countryName: 'US',
              state: [],
              cluster: [
                {
                  id: '653a3b41d3f6eaf4a9c88f22',
                  name: 'Cluster 1',
                },
                {
                  id: '6686f97b8a2816d208e298c6',
                  name: 'Cluster 2',
                },
              ],
              packFamily: [
                {
                  id: '6686f9788a2816d208e29889',
                  name: 'Test 1',
                },
                {
                  id: '653a3b41d3f6eaf4a9c88f23',
                  name: 'Test 2',
                },
              ],
            },
          ],
          platform: [
            {
              platformId: '6686f9788a2816d208e2988a',
              platformName: 'Platform 1',
              minVersion: ['v1', 'v2'],
              maxVersion: ['v1.1', 'v2.1'],
              selectVersion: ['test 1', 'test 2'],
            },
            {
              platformId: '6686f9788a2816d208e2933b',
              platformName: 'Platform 2',
              minVersion: ['v1', 'v2'],
              maxVersion: ['v1.1', 'v2.1'],
              selectVersion: ['test 1', 'test 2'],
            },
          ],
          createdBy: {
            userEmail: 'sonylivblitz@setindia.com',
          },
          updatedBy: {
            userEmail: 'sonylivblitz@setindia.com',
          },
          createdAt: '2024-07-10T09:56:54.932Z',
          updatedAt: '2024-07-10T09:56:54.932Z',
        },
      ],
    },
  },
  WIDGET_DEFINITION_LISTING_API: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '654de4f35623149023843083',
          widgetTypeTitle: 'Page 1',
          layout: 'Category Page',
          status: 'ACTIVE',
        },
      ],
    },
    error: null,
  },
  CONTAINER_DEFINITION_LISTING_API: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '654de4f35623149023843083',
          containerTypeTitle: 'Page 1',
          layout: 'Category Page',
          status: 'ACTIVE',
        },
      ],
    },
    error: null,
  },
}

export const API_MOCK_ASSET_DEFINITION = {
  ASSET_LIST_URL: '/attributeRepository/metaCategories/list',
  ATTRIBUTE_LIST_URL: '/attributeRepository/attributes/list?page=1',
  CATEGORYLIST: {
    message: 'Data successfully fetched',
    responseCode: 200,
    data: [
      {
        _id: '654dd4bfa90b1f0d0a1f33a6',
        categoryKey: 'DESCRIPTIVE_METADATA',
        categoryName: 'Descriptive Metadata',
        createdBy: {
          userEmail: 'ritu.arora@kellton.com',
        },
        modifiedBy: {
          userEmail: 'ritu.arora@kellton.com',
        },
        status: 'ACTIVE',
        subCategory: [],
        createdAt: '2023-11-10T06:59:11.156Z',
        updatedAt: '2023-11-10T06:59:11.156Z',
      },
      {
        _id: '654dd4bfa90b1f0d0a1f33a7',
        categoryKey: 'RIGHTS_AND_AVAILABILITY',
        categoryName: 'Rights and Availability',
        createdBy: {
          userEmail: 'ritu.arora@kellton.com',
        },
        modifiedBy: {
          userEmail: 'ritu.arora@kellton.com',
        },
        status: 'ACTIVE',
        subCategory: [],
        createdAt: '2023-11-10T06:59:11.157Z',
        updatedAt: '2023-11-10T06:59:11.157Z',
      },
    ],
    error: null,
  },
  ATTRIBUTELIST: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 3,
      records: [
        {
          _id: '655f1174846095a5cde6d8f0',
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadLogoLarge',
          isRequired: true,
          isMultiple: false,
          label: 'masthead Logo Large',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          placeholder: '',
          description: 'Testing Purpose',
          master: '',
          options: [],
          sortOrder: 1,
          metaCategory: 'contentMediaImage',
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          modifiedBy: '653a3a3ed3f6eaf4a9c88f0f',
          status: 'ACTIVE',
          inheritanceRule: 'NONE',
          isMultilingual: false,
          __v: 0,
          createdAt: '2023-11-23T08:46:44.361Z',
          updatedAt: '2023-11-23T08:46:44.361Z',
        },
        {
          _id: '655f1174846095a5cde6d8ef',
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadLogoSmall',
          isRequired: true,
          isMultiple: false,
          label: 'Metahead Logo Small',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          placeholder: '',
          description: 'Testing Purpose',
          master: '',
          options: [],
          sortOrder: 1,
          metaCategory: 'contentMediaImage',
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          modifiedBy: '653a3a3ed3f6eaf4a9c88f0f',
          status: 'INACTIVE',
          inheritanceRule: 'NONE',
          isMultilingual: false,
          __v: 0,
          createdAt: '2023-11-23T08:46:44.361Z',
          updatedAt: '2023-11-23T08:46:44.361Z',
        },
        {
          _id: '655f1174846095a5cde6d8f3',
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadLogoRectangular',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Logo Rectangular',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          placeholder: '',
          description: 'Testing Purpose',
          master: '',
          options: [],
          sortOrder: 1,
          metaCategory: 'contentMediaImage',
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          modifiedBy: '653a3a3ed3f6eaf4a9c88f0f',
          status: 'ACTIVE',
          inheritanceRule: 'NONE',
          isMultilingual: false,
          __v: 0,
          createdAt: '2023-11-23T08:46:44.361Z',
          updatedAt: '2023-11-23T08:46:44.361Z',
        },
      ],
    },
    error: null,
  },
  ASSET_DEFINITION_VIEW: {
    message: 'Data successfully fetched',
    responseCode: 200,
    data: {
      _id: '655b7b3e21306f8a7a1aff4c',
      version: '1',
      assetType: 'episode',
      assetCategory: 'GEC',
      contentCategory: 'CONTENT_TYPE',
      tollGate: {
        ADVERTISEMENT_METADATA: true,
        BUSINESS_RULES: true,
        CONTENT_MEDIA_IMAGE: true,
        CONTENT_MEDIA_VIDEO: true,
        DEPUBLISH: true,
        DESCRIPTIVE_METADATA: true,
        MISCELLANEOUS_METADATA: true,
        PUBLISHING: true,
        QC_PROCESS: true,
        RIGHTS_AND_AVAILABILITY: true,
        RIGHTS_EXPIRED: true,
        SEO_METADATA: true,
        SPORTS_METADATA: true,
        TECHNICAL_METADATA: true,
        TRANSLATIONS: true,
      },
      metaCategories: [
        'descriptiveMetadata',
        'rightsAvailability',
        'businessRules',
        'advertisementMetadata',
        'contentMediaImage',
        'contentMediaVideo',
        'miscellaneousMetadata',
        'seoMetadata',
        'sportsMetadata',
        'technicalMetadata',
      ],
      allowedParents: ['GOB'],
      isStandAlonePublishing: true,
      haveAllowedParents: true,
      attributes: [
        {
          metaCategory: 'DESCRIPTIVE_METADATA',
          metaAttributes: [
            {
              fieldType: 'file',
              dataType: 'string',
              fieldName: 'image600x900Clean',
              isRequired: true,
              isMultiple: false,
              label: 'Image 600x900 Clean',
              isB2B: false,
              defaultValue: null,
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: '',
              options: [],
              sortOrder: 1,
              createdBy: '6565d5f7ce0f80ffbacf35e6',
              modifiedBy: '6565d5f7ce0f80ffbacf35e6',
              metaCategory: 'contentMediaImage',
              isMultilingual: false,
              status: 'ACTIVE',
              _id: '6565d5f3ce0f80ffbacf34ef',
              createdAt: '2023-11-28T11:58:44.020Z',
              updatedAt: '2023-11-28T11:58:44.020Z',
            },
            {
              fieldType: 'file',
              dataType: 'string',
              fieldName: 'detailsLogoImage',
              isRequired: true,
              isMultiple: false,
              label: 'Details Logo Image',
              isB2B: false,
              defaultValue: null,
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: '',
              options: [],
              sortOrder: 1,
              createdBy: '6565d5f7ce0f80ffbacf35e6',
              modifiedBy: '6565d5f7ce0f80ffbacf35e6',
              metaCategory: 'contentMediaImage',
              isMultilingual: false,
              status: 'INACTIVE',
              _id: '6565d5f3ce0f80ffbacf34e3',
              createdAt: '2023-11-28T11:58:44.019Z',
              updatedAt: '2023-11-28T11:58:44.019Z',
            },
            {
              fieldType: 'file',
              dataType: 'string',
              fieldName: 'mastheadLogoLarge',
              isRequired: true,
              isMultiple: false,
              label: 'masthead Logo Large',
              isB2B: false,
              defaultValue: null,
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: '',
              options: [],
              sortOrder: 1,
              createdBy: '6565d5f7ce0f80ffbacf35e6',
              modifiedBy: '6565d5f7ce0f80ffbacf35e6',
              metaCategory: 'contentMediaImage',
              isMultilingual: false,
              status: 'ACTIVE',
              _id: '6565d5f3ce0f80ffbacf34e5',
              createdAt: '2023-11-28T11:58:44.019Z',
              updatedAt: '2023-11-28T11:58:44.019Z',
            },
          ],
          _id: '65681a6cb6e39265577ee186',
        },
        {
          metaCategory: 'RIGHTS_AND_AVAILABILITY',
          metaAttributes: [],
          _id: '65681a6cb6e39265577ee18a',
        },
        {
          metaCategory: 'CONTENT_MEDIA_IMAGE',
          metaAttributes: [],
          _id: '65681a6cb6e39265577ee18b',
        },
        {
          metaCategory: 'SPORTS_METADATA',
          metaAttributes: [],
          _id: '65681a6cb6e39265577ee18c',
        },
        {
          metaCategory: 'MISCELLANEOUS_METADATA',
          metaAttributes: [],
          _id: '65681a6cb6e39265577ee18d',
        },
        {
          metaCategory: 'SEO_METADATA',
          metaAttributes: [],
          _id: '65681a6cb6e39265577ee18e',
        },
        {
          metaCategory: 'ADVERTISEMENT_METADATA',
          metaAttributes: [],
          _id: '65681a6cb6e39265577ee18f',
        },
      ],
      createdBy: {
        userEmail: 'ritu.arora@kellton.com',
      },
      modifiedBy: {
        userEmail: 'ritu.arora@kellton.com',
      },
      status: 'ACTIVE',
      createdAt: '2023-11-23T08:46:43.264Z',
      updatedAt: '2023-11-23T08:46:43.264Z',
    },
    error: null,
  },
  SCOPE_LIST: [
    {
      key: 'PARENT',
      value: 'Parent',
      _id: '656889fc1d5ee4e30e8833e9',
    },
    {
      key: 'CHILD',
      value: 'Child',
      _id: '656889fc1d5ee4e30e8833ea',
    },
    {
      key: 'SELF',
      value: 'Self',
      _id: '656889fc1d5ee4e30e8833eb',
    },
    {
      key: 'GLOBAL',
      value: 'Global',
      _id: '656889fc1d5ee4e30e8833ec',
    },
    {
      key: 'NONE',
      value: 'None',
      _id: '656889fc1d5ee4e30e8833ed',
    },
  ],
  INHERITANCE_RULE_LIST: [
    {
      key: 'COPY_AND_CHANGE',
      value: 'copy and change',
      _id: '656889fc1d5ee4e30e8833ef',
    },
    {
      key: 'COPY_AND_REFERENCE',
      value: 'copy and reference',
      _id: '656889fc1d5ee4e30e8833f0',
    },
    {
      key: 'NONE',
      value: 'none',
      _id: '656889fc1d5ee4e30e8833f1',
    },
  ],
  ASSET_CATEGORY: [
    {
      key: 'GOB',
      value: 'GOB',
      _id: '656889fc1d5ee4e30e8833e5',
    },
    {
      key: 'BUNDLE',
      value: 'Bundle',
      _id: '656889fc1d5ee4e30e8833e6',
    },
    {
      key: 'CONTENT_ITEM',
      value: 'Content Item',
      _id: '656889fc1d5ee4e30e8833e7',
    },
  ],
  CONTENT_CATEGORY: [
    {
      key: 'TV_SHOW',
      value: 'TV Shows',
      _id: '656889fc1d5ee4e30e8833de',
    },
    {
      key: 'ORIGINALS',
      value: 'Originals',
      _id: '656889fc1d5ee4e30e8833df',
    },
    {
      key: 'MOVIES',
      value: 'Movies',
      _id: '656889fc1d5ee4e30e8833e0',
    },
    {
      key: 'SPORTS',
      value: 'Sports',
      _id: '656889fc1d5ee4e30e8833e1',
    },
    {
      key: 'INDEPENDENT_VOD_ASSETS',
      value: 'Independent VOD Assets',
      _id: '656889fc1d5ee4e30e8833e2',
    },
    {
      key: 'NON_VOD_ASSETS',
      value: 'Non VOD Assets',
      _id: '656889fc1d5ee4e30e8833e3',
    },
  ],
  STANDALONE_PUBLISED: [
    {
      key: 'TRUE',
      value: 'Yes',
      _id: '656889fc1d5ee4e30e8833f3',
    },
    {
      key: 'FALSE',
      value: 'No',
      _id: '656889fc1d5ee4e30e8833f4',
    },
  ],
  ASSET_DEFINITION_DETAILS_DATA: {
    message: 'Asset type detail fetched successfully!',
    responseCode: 200,
    data: {
      _id: '659d4d359ffb5c60868091a8',
      version: '1',
      assetType: 'episode2',
      assetCategory: 'GOB',
      contentCategory: 'TV_SHOW',
      tollGate: {
        DESCRIPTIVE_METADATA: true,
        RIGHTS_AND_AVAILABILITY: true,
        SEO_METADATA: true,
        ADVERTISEMENT_METADATA: true,
        BUSINESS_RULES: true,
        TECHNICAL_METADATA: true,
        CONTENT_MEDIA_IMAGE: true,
        CONTENT_MEDIA_VIDEO: true,
        TRANSLATIONS: null,
        SPORTS_METADATA: true,
        MISCELLANEOUS_METADATA: true,
        QC_PROCESS: true,
        PUBLISHING: true,
        RIGHTS_EXPIRED: true,
        DEPUBLISH: true,
      },
      metaCategories: [
        'DESCRIPTIVE_METADATA',
        'RIGHTS_AND_AVAILABILITY',
        'SEO_METADATA',
        'ADVERTISEMENT_METADATA',
        'BUSINESS_RULES',
        'TECHNICAL_METADATA',
        'CONTENT_MEDIA_IMAGE',
        'CONTENT_MEDIA_VIDEO',
        'SPORTS_METADATA',
        'MISCELLANEOUS_METADATA',
      ],
      allowedParents: [],
      isStandAlonePublishing: true,
      attributes: [
        {
          metaCategory: 'DESCRIPTIVE_METADATA',
          metaAttributes: [
            {
              fieldType: 'TEXT',
              dataType: 'STRING',
              fieldName: 'assetTitle',
              isRequired: true,
              isMultiple: false,
              addMore: false,
              label: 'Test',
              isB2B: false,
              defaultValue: '',
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: 'SystemConfigurations/SCOPE',
              options: [],
              validations: {},
              attributeRef: [],
              sortOrder: 1,
              createdBy: '6576b5f238fd83d2b4da64e2',
              updatedBy: '6576b5f238fd83d2b4da64e2',
              additionalProperties: {},
              metaCategory: 'descriptiveMetadata',
              isMultilingual: false,
              viewInlisting: 'HIDDEN',
              status: 'ACTIVE',
              esField: '',
              _id: '659d4d359ffb5c60868091aa',
              createdAt: '2024-01-09T13:42:14.031Z',
              updatedAt: '2024-01-09T13:42:14.031Z',
            },
          ],
          _id: '659d4d359ffb5c60868091a9',
        },
        {
          metaCategory: 'RIGHTS_AND_AVAILABILITY',
          metaAttributes: [
            {
              fieldType: 'SELECT',
              dataType: 'STRING',
              fieldName: 'applicableGeoPolicy',
              isRequired: true,
              isMultiple: true,
              addMore: false,
              label: 'Applicable Geo Policy',
              isB2B: false,
              defaultValue: '',
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: 'SystemConfigurations/SCOPE',
              options: [],
              validations: {},
              attributeRef: [],
              sortOrder: 1,
              createdBy: '6576b5f238fd83d2b4da64e2',
              updatedBy: '6576b5f238fd83d2b4da64e2',
              additionalProperties: {},
              metaCategory: 'rightsAvailability',
              isMultilingual: false,
              viewInlisting: 'HIDDEN',
              status: 'ACTIVE',
              esField: '',
              _id: '659d4d359ffb5c60868091ac',
              createdAt: '2024-01-09T13:42:14.032Z',
              updatedAt: '2024-01-09T13:42:14.032Z',
            },
          ],
          _id: '659d4d359ffb5c60868091ab',
        },
        {
          metaCategory: 'BUSINESS_RULES',
          metaAttributes: [
            {
              fieldType: 'SELECT',
              dataType: 'STRING',
              fieldName: 'entitlement',
              isRequired: true,
              isMultiple: false,
              addMore: false,
              label: 'entitlement',
              isB2B: true,
              defaultValue: '',
              affiliatePartner: [''],
              inheritanceRule: 'NONE',
              placeholder: 'Select Partners',
              description: 'Partner Holdback List',
              master: 'SystemConfigurations/SCOPE',
              options: [],
              validations: {},
              attributeRef: [],
              sortOrder: 1,
              createdBy: '6576b5f238fd83d2b4da64e2',
              updatedBy: '6576b5f238fd83d2b4da64e2',
              additionalProperties: {},
              metaCategory: 'businessRules',
              isMultilingual: false,
              viewInlisting: 'HIDDEN',
              status: 'ACTIVE',
              esField: '',
              _id: '659d4d359ffb5c60868091ae',
              createdAt: '2024-01-09T13:42:14.033Z',
              updatedAt: '2024-01-09T13:42:14.033Z',
            },
          ],
          _id: '659d4d359ffb5c60868091ad',
        },
        {
          metaCategory: 'SEO_METADATA',
          metaAttributes: [
            {
              fieldType: 'TEXTAREA',
              dataType: 'STRING',
              fieldName: 'seoDescription',
              isRequired: false,
              isMultiple: false,
              addMore: false,
              label: 'SEO Description',
              isB2B: false,
              defaultValue: '',
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: 'SystemConfigurations/SCOPE',
              options: [],
              validations: {},
              attributeRef: [],
              sortOrder: 1,
              createdBy: '6576b5f238fd83d2b4da64e2',
              updatedBy: '6576b5f238fd83d2b4da64e2',
              additionalProperties: {},
              metaCategory: 'seoMetadata',
              isMultilingual: false,
              viewInlisting: 'HIDDEN',
              status: 'ACTIVE',
              esField: '',
              _id: '659d4d359ffb5c60868091b0',
              createdAt: '2024-01-09T13:42:14.033Z',
              updatedAt: '2024-01-09T13:42:14.033Z',
            },
          ],
          _id: '659d4d359ffb5c60868091af',
        },
        {
          metaCategory: 'ADVERTISEMENT_METADATA',
          metaAttributes: [
            {
              fieldType: 'TEXT',
              dataType: 'STRING',
              fieldName: 'adsBannerVisibility',
              isRequired: false,
              isMultiple: false,
              addMore: false,
              label: 'Ads Banner Visibility',
              isB2B: false,
              defaultValue: '',
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: 'SystemConfigurations/SCOPE',
              options: [],
              validations: {},
              attributeRef: [],
              sortOrder: 1,
              createdBy: '6576b5f238fd83d2b4da64e2',
              updatedBy: '6576b5f238fd83d2b4da64e2',
              additionalProperties: {},
              metaCategory: 'advertisementMetadata',
              isMultilingual: false,
              viewInlisting: 'HIDDEN',
              status: 'ACTIVE',
              esField: '',
              _id: '659d4d359ffb5c60868091b2',
              createdAt: '2024-01-09T13:42:14.034Z',
              updatedAt: '2024-01-09T13:42:14.034Z',
            },
          ],
          _id: '659d4d359ffb5c60868091b1',
        },
        {
          metaCategory: 'TECHNICAL_METADATA',
          metaAttributes: [
            {
              fieldType: 'TEXT',
              dataType: 'STRING',
              fieldName: 'daiAssetKey',
              isRequired: false,
              isMultiple: false,
              addMore: false,
              label: 'Dai Asset Key',
              isB2B: false,
              defaultValue: '',
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: 'SystemConfigurations/SCOPE',
              options: [],
              validations: {},
              attributeRef: [],
              sortOrder: 1,
              createdBy: '6576b5f238fd83d2b4da64e2',
              updatedBy: '6576b5f238fd83d2b4da64e2',
              additionalProperties: {},
              metaCategory: 'technicalMetadata',
              isMultilingual: false,
              viewInlisting: 'HIDDEN',
              status: 'ACTIVE',
              esField: '',
              _id: '659d4d359ffb5c60868091b4',
              createdAt: '2024-01-09T13:42:14.034Z',
              updatedAt: '2024-01-09T13:42:14.034Z',
            },
          ],
          _id: '658176e1b7822280abe056b5',
        },
        {
          metaCategory: 'SPORTS_METADATA',
          metaAttributes: [
            {
              fieldType: 'TEXT',
              dataType: 'STRING',
              fieldName: 'homeTeam',
              isRequired: false,
              isMultiple: false,
              addMore: false,
              label: 'Home Team',
              isB2B: false,
              defaultValue: '',
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: 'SystemConfigurations/SCOPE',
              options: [],
              validations: {},
              attributeRef: [],
              sortOrder: 1,
              createdBy: '6576b5f238fd83d2b4da64e2',
              updatedBy: '6576b5f238fd83d2b4da64e2',
              additionalProperties: {},
              metaCategory: 'sportsMetadata',
              isMultilingual: false,
              viewInlisting: 'HIDDEN',
              status: 'ACTIVE',
              esField: '',
              _id: '659d4d359ffb5c60868091b6',
              createdAt: '2024-01-09T13:42:14.035Z',
              updatedAt: '2024-01-09T13:42:14.035Z',
            },
          ],
          _id: '659d4d359ffb5c60868091b5',
        },
        {
          metaCategory: 'MISCELLANEOUS_METADATA',
          metaAttributes: [
            {
              fieldType: 'TEXTAREA',
              dataType: 'JSON',
              fieldName: 'customBands',
              isRequired: false,
              isMultiple: false,
              addMore: false,
              label: 'Custom Bands',
              isB2B: false,
              defaultValue: '',
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: 'SystemConfigurations/SCOPE',
              options: [],
              validations: {},
              attributeRef: [],
              sortOrder: 1,
              createdBy: '6576b5f238fd83d2b4da64e2',
              updatedBy: '6576b5f238fd83d2b4da64e2',
              additionalProperties: {},
              metaCategory: 'miscellaneousMetadata',
              isMultilingual: false,
              viewInlisting: 'HIDDEN',
              status: 'ACTIVE',
              esField: '',
              _id: '659d4d359ffb5c60868091b8',
              createdAt: '2024-01-09T13:42:14.035Z',
              updatedAt: '2024-01-09T13:42:14.035Z',
            },
          ],
          _id: '659d4d359ffb5c60868091b7',
        },
        {
          metaCategory: 'CONTENT_MEDIA_IMAGE',
          metaAttributes: [
            {
              fieldType: 'FILE',
              dataType: 'STRING',
              fieldName: 'landscapeThumb',
              isRequired: true,
              isMultiple: false,
              addMore: false,
              label: 'Landscape Thumb',
              isB2B: false,
              defaultValue: '',
              affiliatePartner: [],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Testing Purpose',
              master: 'SystemConfigurations/SCOPE',
              options: [],
              validations: {},
              attributeRef: [],
              sortOrder: 1,
              createdBy: '6576b5f238fd83d2b4da64e2',
              updatedBy: '6576b5f238fd83d2b4da64e2',
              additionalProperties: {},
              metaCategory: 'contentMediaImage',
              isMultilingual: false,
              viewInlisting: 'HIDDEN',
              status: 'ACTIVE',
              esField: '',
              _id: '659d4d359ffb5c60868091ba',
              createdAt: '2024-01-09T13:42:14.036Z',
              updatedAt: '2024-01-09T13:42:14.036Z',
            },
          ],
          _id: '659d4d359ffb5c60868091b9',
        },
        {
          metaCategory: 'CONTENT_MEDIA_VIDEO',
          metaAttributes: [
            {
              fieldType: 'DATETIME',
              dataType: 'DATE',
              fieldName: 'cueEndDateTime',
              isRequired: true,
              isMultiple: false,
              addMore: false,
              label: 'Cue Point End Date Time',
              isB2B: true,
              defaultValue: '',
              affiliatePartner: [''],
              inheritanceRule: 'NONE',
              placeholder: '',
              description: 'Cue Point End Date Time for the content',
              master: 'SystemConfigurations/SCOPE',
              options: [],
              validations: {},
              attributeRef: [],
              sortOrder: 1,
              createdBy: '6576b5f238fd83d2b4da64e2',
              updatedBy: '6576b5f238fd83d2b4da64e2',
              additionalProperties: {},
              metaCategory: 'contentMediaVideo',
              isMultilingual: false,
              viewInlisting: 'HIDDEN',
              status: 'ACTIVE',
              esField: '',
              _id: '659d4d359ffb5c60868091bc',
              createdAt: '2024-01-09T13:42:14.036Z',
              updatedAt: '2024-01-09T13:42:14.036Z',
            },
          ],
          _id: '659d4d359ffb5c60868091bb',
        },
      ],
      createdBy: {
        userEmail: 'riya.kohli@kellton.com',
      },
      updatedBy: {
        userEmail: 'riya.kohli@kellton.com',
      },
      status: 'ACTIVE',
      createdAt: '2024-01-09T13:42:14.047Z',
      updatedAt: '2024-01-09T13:42:14.047Z',
      __v: 0,
    },
    error: null,
  },
}
export const API_MOCK_AUDITTRAILS_LIST_FAILED = {
  message: 'Audit trails get list failed try again.',
  errors: ['filterCondition is required if a filter key is selected'],
  responseCode: 422,
  blitzCode: 'BLITZ_15003',
}
export const API_MOCK_AUDITTRAILS_LIST = {
  message: 'Audit Trails fetched successfully!',
  responseCode: 200,
  data: {
    count: 1,
    records: [
      {
        _id: '65cdb7fbd4e148ac2baad229',
        blitzTransactionId: 'b83dd0e0-a241-444c-9b64-3c6e7ce2e3d1',
        assetId: '1590006907',
        type: 'content',
        subType: 'Content',
        event: 'Save',
        activity: 'Meta category: DESCRIPTIVE_METADATA Saved',
        message: 'Asset Saved',
        assetState: 'DRAFT',
        requestUri: '/api/v1/content/metaCategory/DESCRIPTIVE_METADATA',
        responseStatus: 'Success',
        logLevel: 'Info',
        logCategory: 'Change',
        module: 'BOLT_MODULE',
        subModule: 'MANAGE_CONTENT',
        dataModel: 'ContentMetaCategories',
        documentId: '65cdb7e6d4e148ac2baac29a',
        oldData: {
          _id: '65cdb7e6d4e148ac2baac29a',
          contentId: '65cdb7e6d4e148ac2baac294',
          metaCategory: '"DESCRIPTIVE_METADATA"',
          markDoneStatus: '"NO_ACTION"',
          lockedBy: 'null',
          lockedAt: 'null',
          createdBy: '659e489c81ca34d2450b0754',
          updatedBy: '659e489c81ca34d2450b0754',
          isDeleted: 'false',
          __v: '0',
          createdAt: '2024-02-15T07:06:14.772Z',
          updatedAt: '2024-02-15T07:06:14.772Z',
        },
        newData: {
          _id: '65cdb7e6d4e148ac2baac29a',
          contentId: '65cdb7e6d4e148ac2baac294',
          metaCategory: '"DESCRIPTIVE_METADATA"',
          markDoneStatus: '"NO_ACTION"',
          attributes: {
            assetTitle: '"parent test"',
            castAndCrew: [
              {
                castRole: 'null',
                actualName: 'null',
              },
            ],
            isAutoPlayTrailer: {
              disabledPlatform: 'null',
              disabledCountry: 'null',
            },
            subCategory: '"None"',
          },
          lockedBy: 'null',
          lockedAt: 'null',
          createdBy: '659e489c81ca34d2450b0754',
          updatedBy: '659e489c81ca34d2450b0754',
          isDeleted: 'false',
          __v: '0',
          createdAt: '2024-02-15T07:06:14.772Z',
          updatedAt: '2024-02-15T07:06:35.703Z',
        },
        difference: 'abcde',
        userName: 'Tanmoy Sen',
        userId: '659e489c81ca34d2450b0754',
        roleName: 'Administrator',
        ipAddress: 'some ip',
        source: 'APP',
        userAgent:
          'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36',
        createdAt: '2024-02-15T07:06:35.736Z',
        updatedAt: '2024-02-15T07:06:35.736Z',
        __v: 0,
      },
    ],
  },
  error: null,
}

export const attributeParentId = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    _id: '6582c44b8abe262c8562a526',
    sortOrder: 6,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'TEXT',
    dataType: 'STRING',
    fieldName: 'blitzParentContentId',
    isRequired: false,
    isMultiple: false,
    label: 'Parent ID',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'Parent ID',
    description: 'ContentId of the Live match which is added to the KM',
    options: [],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'parent_id',
    __v: 0,
    value: '22992233',
  },
  fieldName: 'parentId',
  fieldType: 'TEXT',
  label: 'Parent ID',
  sortOrder: 6,
  isRequired: false,
}

export const attributeSelectField = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'SELECT',
    dataType: 'BOOLEAN',
    fieldName: 'isOnAir',
    isRequired: true,
    isMultiple: false,
    label: 'On Air',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [
      { key: true, value: 'Yes' },
      { key: false, value: 'No' },
    ],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: true,
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

export const attributeMultiSelect = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'SELECT',
    dataType: 'BOOLEAN',
    fieldName: 'isOnAir',
    isRequired: true,
    isMultiple: true,
    label: 'On Air',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [
      { key: '1', value: 'one' },
      { key: '2', value: 'two' },
    ],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: ['1'],
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

export const attributeDateField = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'DATE',
    dataType: 'string',
    fieldName: 'date',
    isRequired: true,
    isMultiple: false,
    label: 'Date',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: '',
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

export const attributeTimeField = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'TIME',
    dataType: 'string',
    fieldName: 'date',
    isRequired: true,
    isMultiple: false,
    label: 'Date',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: '',
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

export const attributeDateTimeField = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'DATETIME',
    dataType: 'string',
    fieldName: 'date',
    isRequired: true,
    isMultiple: false,
    label: 'Date',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: '',
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

export const attributeTextField = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'TEXT',
    dataType: 'string',
    fieldName: 'text',
    isRequired: true,
    isMultiple: false,
    label: 'text',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: 'some value',
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

export const attributeJsonField = {
  attribute: {
    additionalProperties: {
      isJsonViewer: true,
    },
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'TEXT',
    dataType: 'string',
    fieldName: 'text',
    isRequired: true,
    isMultiple: false,
    label: 'text',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: 'some value',
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

export const attributeDurationField = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'TIME',
    dataType: 'NUMBER',
    fieldName: 'timeNumber',
    isRequired: true,
    isMultiple: false,
    label: 'timeNumber',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: '',
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

export const attributeSwitchField = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'SWITCH',
    dataType: 'BOOLEAN',
    fieldName: 'timeNumber',
    isRequired: true,
    isMultiple: false,
    label: 'timeNumber',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: '',
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

export const attributeCheckboxField = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'CHECKBOX',
    dataType: 'BOOLEAN',
    fieldName: 'timeNumber',
    isRequired: true,
    isMultiple: false,
    label: 'timeNumber',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: '',
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

export const attributeTagInput = {
  attribute: {
    inheritanceRule: 'NONE',
    validations: {},
    viewInListing: 'ADD_VISIBLE',
    _id: '658291808abe262c85629ab4',
    sortOrder: 0,
    createdAt: '2023-11-28T11:58:43.994Z',
    updatedAt: '2023-11-28T11:58:43.994Z',
    fieldType: 'TEXTAREA',
    dataType: 'STRING',
    additionalProperties: {
      tagInput: true,
    },
    fieldName: 'timeNumber',
    isRequired: true,
    isMultiple: false,
    label: 'timeNumber',
    isB2B: false,
    affiliatePartner: [],
    placeholder: 'On Air',
    description: 'This tag takes value Yes or No',
    options: [],
    attributeRef: [],
    metaCategory: 'NA',
    createdBy: '653a3a3ed3f6eaf4a9c88f0f',
    updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
    status: 'ACTIVE',
    isMultilingual: false,
    isDeleted: false,
    esField: 'isOnAir',
    __v: 0,
    value: '',
  },
  fieldName: 'isOnAir',
  fieldType: 'SELECT',
  label: 'On Air',
  sortOrder: 0,
  isRequired: true,
}

// export const API_MOCK_AUDIT_TRAIL = {
//   message: 'Audit Trails fetched successfully!',
//   responseCode: 200,
//   data: {
//     count: 4,
//     records: [
//       {
//         _id: '658e7b2c7b9f854bcc987944',
//         contentId: '',
//         assetId: '',
//         assetState: '',
//         requestId: 'bf6e05fb-72ee-48dd-82e2-3bb709626488',
//         type: 'User',
//         event: 'Create User A',
//         activity: 'User insert failed try again.',
//         requestAction: '/api/v1/user/add',
//         apiResponse: 'Failed',
//         level: 'Info',
//         logCategory: 'Change',
//         module: 'BOLT_MODULE',
//         subModule: 'ROLE_AND_USER_MANAGEMENT',
//         dataModel: 'Users',
//         dataModelId: '',
//         userName: 'Test User Name',
//         userId: '654dca4be34dab5b60111783',
//         oldData: {},
//         newData: {},
//         roleName: 'Test Role Name',
//         ip: '::1',
//         location: '::1',
//         source: 'APP',
//         userAgent:
//           'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
//         createdAt: '2023-12-29T07:54:20.324Z',
//         updatedAt: '2023-12-29T07:54:20.324Z',
//         __v: 0,
//       },
//       {
//         _id: '658e7b197b9f854bcc98793e',
//         contentId: '',
//         assetId: '',
//         assetState: '',
//         requestId: '5a7acb0f-7b55-4ed7-bff4-98c233e25123',
//         type: 'User',
//         event: 'Create User B',
//         activity: 'User insert failed try again.',
//         requestAction: '/api/v1/user/add',
//         apiResponse: 'Failed',
//         level: 'Info',
//         logCategory: 'Change',
//         module: 'BOLT_MODULE',
//         subModule: 'ROLE_AND_USER_MANAGEMENT',
//         dataModel: 'Users',
//         dataModelId: '',
//         userName: 'Test User Name',
//         oldData: {},
//         newData: {},
//         userId: '654dca4be34dab5b60111783',
//         roleName: 'Test Role Name',
//         ip: '::1',
//         location: '::1',
//         source: 'APP',
//         userAgent:
//           'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
//         createdAt: '2023-12-29T07:54:01.532Z',
//         updatedAt: '2023-12-29T07:54:01.532Z',
//         __v: 0,
//       },
//       {
//         _id: '658e7a747b9f854bcc987936',
//         contentId: '',
//         assetId: '',
//         assetState: '',
//         requestId: '445cf29d-4364-4164-a737-7020a86eb635',
//         type: 'User',
//         event: 'Create User C',
//         activity: 'User created successfully!',
//         requestAction: '/api/v1/user/add',
//         apiResponse: 'Success',
//         level: 'Info',
//         logCategory: 'Change',
//         module: 'BOLT_MODULE',
//         subModule: 'ROLE_AND_USER_MANAGEMENT',
//         dataModel: 'Users',
//         dataModelId: '658e7a747b9f854bcc987934',
//         oldData: {},
//         newData: {
//           firstName: 'DECTWONINE',
//           userName: 'DECTWONINE',
//           lastName: 'DECTWONINE',
//           userEmail: 'dejnjee@sonyliv.com',
//           cyberarkId: 'djdjjdd@sonyliv.com',
//           roleId: '653a3a42d3f6eaf4a9c88f17',
//           createdBy: '654dca4be34dab5b60111783',
//           updatedBy: '654dca4be34dab5b60111783',
//           status: 'ACTIVE',
//           _id: '658e7a747b9f854bcc987934',
//           createdAt: '2023-12-29T07:51:16.165Z',
//           updatedAt: '2023-12-29T07:51:16.165Z',
//           __v: 0,
//         },
//         userName: 'Test User Name',
//         userId: '654dca4be34dab5b60111783',
//         roleName: 'Test Role Name',
//         ip: '::1',
//         location: '::1',
//         source: 'APP',
//         userAgent:
//           'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
//         createdAt: '2023-12-29T07:51:16.409Z',
//         updatedAt: '2023-12-29T07:51:16.409Z',
//         __v: 0,
//       },
//       {
//         _id: '658e7a627b9f854bcc98792e',
//         contentId: '',
//         assetId: '',
//         assetState: '',
//         requestId: '8ffa8283-4441-46c4-8eac-c639b34c3277',
//         type: 'User',
//         event: 'Create User D',
//         activity: 'User insert failed try again.',
//         requestAction: '/api/v1/user/add',
//         apiResponse: 'Failed',
//         level: 'Info',
//         logCategory: 'Change',
//         module: 'BOLT_MODULE',
//         subModule: 'ROLE_AND_USER_MANAGEMENT',
//         oldData: {},
//         newData: {},
//         dataModel: 'Users',
//         dataModelId: '',
//         userName: 'Test User Name',
//         userId: '654dca4be34dab5b60111783',
//         roleName: 'Test Role Name',
//         ip: '::1',
//         location: '::1',
//         source: 'APP',
//         userAgent:
//           'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
//         createdAt: '2023-12-29T07:50:58.460Z',
//         updatedAt: '2023-12-29T07:50:58.460Z',
//         __v: 0,
//       },
//     ],
//   },
// }
export const API_MOCK_CREATE_ASSET = {
  MASTERS: {
    message: 'Geo policies fetched successfully!',
    responseCode: 200,
    data: {
      count: 2,
      records: [
        {
          _id: '656d865334e3ab85854b927f',
          key: 'some_key',
          value: 'some_value',
        },
        {
          _id: '656d8779ae387fa59f41c0c6',
          key: 'some_other_key',
          value: 'some_other_value',
        },
      ],
    },
    error: null,
  },
  VIEW_ASSET_ERROR: {
    error: {
      message: 'Content Details Fetched Successfully',
      responseCode: 400,
    },
  },
  VIEW_ASSET: {
    message: 'Content Details Fetched Successfully',
    responseCode: 200,
    data: {
      _id: '659cecd8d2eea5473edd5d25',
      assetType: 'Season',
      contentId: 1590002146,
      assetCategory: 'BUNDLE',
      contentCategory: 'TV_SHOW',
      state: 'DRAFT',
      parentId: null,
      status: 'ACTIVE',
      createdAt: '2024-01-09T06:51:04.343Z',
      updatedAt: '2024-01-09T06:51:16.815Z',
      createdBy: 'satyendra.chouhan@kellton.com',
      updatedBy: 'satyendra.chouhan@kellton.com',
      ContentMetaCategory: [
        {
          _id: '659cecd8d2eea5473edd5d2e',
          contentId: '659cecd8d2eea5473edd5d25',
          metaCategory: 'DESCRIPTIVE_METADATA',
          markDoneStatus: 'NO_ACTION',
          lockedBy: null,
          lockedAt: null,
          createdBy: '6576b5f238fd83d2b4da64e4',
          updatedBy: '6576b5f238fd83d2b4da64e4',
          attributes: {
            assetTitle: 'Some Title',
            castAndCrew1: { actualName: 'ActualName', cast: 'Producer' },
            castAndCrew2: [
              { actualName: 'ActualName', cast: 'Producer' },
              { actualName: 'ActualName', cast: 'Producer' },
            ],
            castAndCrew: { actualName: 'ActualName', cast: 'Producer' },
          },
          __v: 0,
          createdAt: '2024-01-09T06:51:04.349Z',
          updatedAt: '2024-01-09T06:51:04.349Z',
        },
      ],
    },
    error: null,
  },
  VIEW_MARK_DONE_STATUS: {
    message: 'Success',
    responseCode: 200,
    data: {
      metaCategories: [
        {
          _id: '659cecd8d2eea5473edd5d27',
          markDoneStatus: 'NO_ACTION',
          metaCategoryKey: 'ADVERTISEMENT_METADATA',
          metaCategoryName: 'Advertisement Metadata',
        },
        {
          _id: '659cecd8d2eea5473edd5d28',
          markDoneStatus: 'NO_ACTION',
          metaCategoryKey: 'BUSINESS_RULES',
          metaCategoryName: 'Business Rules',
        },
        {
          _id: '659cecd8d2eea5473edd5d29',
          markDoneStatus: 'NO_ACTION',
          metaCategoryKey: 'RIGHTS_AND_AVAILABILITY',
          metaCategoryName: 'Rights and Availability',
        },
        {
          _id: '659cecd8d2eea5473edd5d2a',
          markDoneStatus: 'NO_ACTION',
          metaCategoryKey: 'SEO_METADATA',
          metaCategoryName: 'SEO Metadata',
        },
        {
          _id: '659cecd8d2eea5473edd5d2b',
          markDoneStatus: 'NO_ACTION',
          metaCategoryKey: 'TECHNICAL_METADATA',
          metaCategoryName: 'Technical Metadata',
        },
        {
          _id: '659cecd8d2eea5473edd5d2c',
          markDoneStatus: 'NO_ACTION',
          metaCategoryKey: 'CONTENT_MEDIA_IMAGE',
          metaCategoryName: 'Content Media: Image',
        },
        {
          _id: '659cecd8d2eea5473edd5d2d',
          markDoneStatus: 'NO_ACTION',
          metaCategoryKey: 'CONTENT_MEDIA_VIDEO',
          metaCategoryName: 'Content Media: Video',
        },
        {
          _id: '659cecd8d2eea5473edd5d2e',
          markDoneStatus: 'NO_ACTION',
          metaCategoryKey: 'DESCRIPTIVE_METADATA',
          metaCategoryName: 'Descriptive Metadata',
        },
        {
          _id: '659cecd8d2eea5473edd5d2f',
          markDoneStatus: 'NO_ACTION',
          metaCategoryKey: 'SPORTS_METADATA',
          metaCategoryName: 'Sports Metadata',
        },
        {
          _id: '659cecd8d2eea5473edd5d30',
          markDoneStatus: 'NO_ACTION',
          metaCategoryKey: 'MISCELLANEOUS_METADATA',
          metaCategoryName: 'Miscellaneous Metadata',
        },
      ],
      tollGates: [],
    },
    error: null,
  },
  SYSTEM_CONFIGURATION: {
    message: 'System configuration fetched successfully',
    responseCode: 200,
    data: [
      {
        _id: '6582d5a42d0ed71a708dcc30',
        configKey: 'GENRE',
        configValue: [
          {
            key: '3X3_BASKETBALL',
            value: '3x3 basketball qa',
            _id: '6582d5a42d0ed71a708dcc31',
          },
          {
            key: 'ACTION',
            value: 'Action',
            _id: '6582d5a42d0ed71a708dcc32',
          },
          {
            key: 'ADVENTURE',
            value: 'Adventure',
            _id: '6582d5a42d0ed71a708dcc33',
          },
          {
            key: 'ADVENTURE_SPORT',
            value: 'Adventure Sport',
            _id: '6582d5a42d0ed71a708dcc34',
          },
          {
            key: 'ANIMATION',
            value: 'Animation',
            _id: '6582d5a42d0ed71a708dcc35',
          },
          {
            key: 'ANTHOLOGY',
            value: 'Anthology',
            _id: '6582d5a42d0ed71a708dcc36',
          },
          {
            key: 'AQUATICS',
            value: 'Aquatics',
            _id: '6582d5a42d0ed71a708dcc37',
          },
          {
            key: 'ARCHERY',
            value: 'Archery',
            _id: '6582d5a42d0ed71a708dcc38',
          },
          {
            key: 'ART_HOUSE',
            value: 'Art House',
            _id: '6582d5a42d0ed71a708dcc39',
          },
          {
            key: 'ARTISTIC_GYMNASTICS',
            value: 'Artistic Gymnastics',
            _id: '6582d5a42d0ed71a708dcc3a',
          },
          {
            key: 'ARTISTIC_SWIMMING',
            value: 'Artistic Swimming',
            _id: '6582d5a42d0ed71a708dcc3b',
          },
          {
            key: 'ATHLETICS',
            value: 'Athletics',
            _id: '6582d5a42d0ed71a708dcc3c',
          },
          {
            key: 'AUTOMOTIVE',
            value: 'Automotive',
            _id: '6582d5a42d0ed71a708dcc3d',
          },
          {
            key: 'AVANT_GARDE',
            value: 'Avant Garde',
            _id: '6582d5a42d0ed71a708dcc3e',
          },
          {
            key: 'BADMINTON',
            value: 'Badminton',
            _id: '6582d5a42d0ed71a708dcc3f',
          },
          {
            key: 'BASEBALL',
            value: 'Baseball',
            _id: '6582d5a42d0ed71a708dcc40',
          },
          {
            key: 'BASEBALL/SOFTBALL',
            value: 'Baseball/Softball',
            _id: '6582d5a42d0ed71a708dcc41',
          },
          {
            key: 'BASED_ON_BOOKS',
            value: 'Based on Books',
            _id: '6582d5a42d0ed71a708dcc42',
          },
          {
            key: 'BASED_ON_TRUE_EVENTS',
            value: 'Based on True Events',
            _id: '6582d5a42d0ed71a708dcc43',
          },
          {
            key: 'BASKETBALL',
            value: 'Basketball',
            _id: '6582d5a42d0ed71a708dcc44',
          },
          {
            key: 'BEACH_VOLLEYBALL',
            value: 'Beach volleyball',
            _id: '6582d5a42d0ed71a708dcc45',
          },
          {
            key: 'BIOGRAPHY',
            value: 'Biography',
            _id: '6582d5a42d0ed71a708dcc46',
          },
          {
            key: 'BIOPIC',
            value: 'Biopic',
            _id: '6582d5a42d0ed71a708dcc47',
          },
          {
            key: 'BOLLYWOOD',
            value: 'Bollywood',
            _id: '6582d5a42d0ed71a708dcc48',
          },
          {
            key: 'BOXING',
            value: 'Boxing',
            _id: '6582d5a42d0ed71a708dcc49',
          },
          {
            key: 'CANOE_SLALOM',
            value: 'Canoe Slalom',
            _id: '6582d5a42d0ed71a708dcc4a',
          },
          {
            key: 'CANOEING',
            value: 'Canoeing',
            _id: '6582d5a42d0ed71a708dcc4b',
          },
          {
            key: 'CELEBRATORY',
            value: 'Celebratory',
            _id: '6582d5a42d0ed71a708dcc4c',
          },
          {
            key: 'CELEBRITY',
            value: 'Celebrity',
            _id: '6582d5a42d0ed71a708dcc4d',
          },
          {
            key: 'CEREBRAL',
            value: 'Cerebral',
            _id: '6582d5a42d0ed71a708dcc4e',
          },
          {
            key: 'CEREMONY',
            value: 'Ceremony',
            _id: '6582d5a42d0ed71a708dcc4f',
          },
          {
            key: 'CHICK_FLICK',
            value: 'Chick Flick',
            _id: '6582d5a42d0ed71a708dcc50',
          },
          {
            key: 'CLASSIC_MUSICALS',
            value: 'Classic Musicals',
            _id: '6582d5a42d0ed71a708dcc51',
          },
          {
            key: 'COMEDY',
            value: 'Comedy',
            _id: '6582d5a42d0ed71a708dcc52',
          },
          {
            key: 'COMIC_BOOK',
            value: 'Comic Book',
            _id: '6582d5a42d0ed71a708dcc53',
          },
          {
            key: 'COMING_OF_AGE',
            value: 'Coming of Age',
            _id: '6582d5a42d0ed71a708dcc54',
          },
          {
            key: 'CONSPIRACY',
            value: 'Conspiracy',
            _id: '6582d5a42d0ed71a708dcc55',
          },
          {
            key: 'CONTROVERSY',
            value: 'Controversy',
            _id: '6582d5a42d0ed71a708dcc56',
          },
          {
            key: 'COOKERY',
            value: 'Cookery',
            _id: '6582d5a42d0ed71a708dcc57',
          },
          {
            key: 'CORRUPTION',
            value: 'Corruption',
            _id: '6582d5a42d0ed71a708dcc58',
          },
          {
            key: 'COURTROOM',
            value: 'Courtroom',
            _id: '6582d5a42d0ed71a708dcc59',
          },
          {
            key: 'CREATURE',
            value: 'Creature',
            _id: '6582d5a42d0ed71a708dcc5a',
          },
          {
            key: 'CRICKET',
            value: 'Cricket',
            _id: '6582d5a42d0ed71a708dcc5b',
          },
          {
            key: 'CRIME',
            value: 'Crime',
            _id: '6582d5a42d0ed71a708dcc5c',
          },
          {
            key: 'CRIME_DOCUMENTARIES',
            value: 'Crime Documentaries',
            _id: '6582d5a42d0ed71a708dcc5d',
          },
          {
            key: 'CRITICALLY_ACCLAIMED',
            value: 'Critically Acclaimed',
            _id: '6582d5a42d0ed71a708dcc5e',
          },
          {
            key: 'CULT',
            value: 'Cult',
            _id: '6582d5a42d0ed71a708dcc5f',
          },
          {
            key: 'CULTURAL',
            value: 'Cultural',
            _id: '6582d5a42d0ed71a708dcc60',
          },
          {
            key: 'CYCLING',
            value: 'Cycling',
            _id: '6582d5a42d0ed71a708dcc61',
          },
          {
            key: 'CYCLING_BMX_FREESTYLE',
            value: 'Cycling BMX Freestyle',
            _id: '6582d5a42d0ed71a708dcc62',
          },
          {
            key: 'CYCLING_BMX_RACING',
            value: 'Cycling BMX Racing',
            _id: '6582d5a42d0ed71a708dcc63',
          },
          {
            key: 'CYCLING_MOUNTAIN_BIKE',
            value: 'Cycling Mountain Bike',
            _id: '6582d5a42d0ed71a708dcc64',
          },
          {
            key: 'CYCLING_ROAD',
            value: 'Cycling Road',
            _id: '6582d5a42d0ed71a708dcc65',
          },
          {
            key: 'CYCLING_TRACK',
            value: 'Cycling Track',
            _id: '6582d5a42d0ed71a708dcc66',
          },
          {
            key: 'DANCE',
            value: 'Dance',
            _id: '6582d5a42d0ed71a708dcc67',
          },
          {
            key: 'DARK',
            value: 'Dark',
            _id: '6582d5a42d0ed71a708dcc68',
          },
          {
            key: 'DARK_COMEDIES',
            value: 'Dark Comedies',
            _id: '6582d5a42d0ed71a708dcc69',
          },
          {
            key: 'DEVOTIONAL',
            value: 'Devotional',
            _id: '6582d5a42d0ed71a708dcc6a',
          },
          {
            key: 'DIVING',
            value: 'Diving',
            _id: '6582d5a42d0ed71a708dcc6b',
          },
          {
            key: 'DOCUFICTION',
            value: 'Docufiction',
            _id: '6582d5a42d0ed71a708dcc6c',
          },
          {
            key: 'DOCUMENTARIES',
            value: 'Documentaries',
            _id: '6582d5a42d0ed71a708dcc6d',
          },
          {
            key: 'DOCUMENTARY',
            value: 'Documentary',
            _id: '6582d5a42d0ed71a708dcc6e',
          },
          {
            key: 'DOCUSOAP',
            value: 'Docusoap',
            _id: '6582d5a42d0ed71a708dcc6f',
          },
          {
            key: 'DRAMA',
            value: 'Drama',
            _id: '6582d5a42d0ed71a708dcc70',
          },
          {
            key: 'DRAMEDY',
            value: 'Dramedy',
            _id: '6582d5a42d0ed71a708dcc71',
          },
          {
            key: 'DYSTOPIAN',
            value: 'Dystopian',
            _id: '6582d5a42d0ed71a708dcc72',
          },
          {
            key: 'EDUCATION',
            value: 'Education',
            _id: '6582d5a42d0ed71a708dcc73',
          },
          {
            key: 'ENERGETIC',
            value: 'Energetic',
            _id: '6582d5a42d0ed71a708dcc74',
          },
          {
            key: 'ENSEMBLE',
            value: 'Ensemble',
            _id: '6582d5a42d0ed71a708dcc75',
          },
          {
            key: 'ENTERTAINMENT',
            value: 'Entertainment',
            _id: '6582d5a42d0ed71a708dcc76',
          },
          {
            key: 'EPIC',
            value: 'Epic',
            _id: '6582d5a42d0ed71a708dcc77',
          },
          {
            key: 'EQUESTRIAN',
            value: 'Equestrian',
            _id: '6582d5a42d0ed71a708dcc78',
          },
          {
            key: 'EROTICA',
            value: 'Erotica',
            _id: '6582d5a42d0ed71a708dcc79',
          },
          {
            key: 'ESPIONAGE',
            value: 'Espionage',
            _id: '6582d5a42d0ed71a708dcc7a',
          },
          {
            key: 'EVENT',
            value: 'Event',
            _id: '6582d5a42d0ed71a708dcc7b',
          },
          {
            key: 'FAIRYTALE',
            value: 'Fairytale',
            _id: '6582d5a42d0ed71a708dcc7c',
          },
          {
            key: 'FAMILY',
            value: 'Family',
            _id: '6582d5a42d0ed71a708dcc7d',
          },
          {
            key: 'FANTASY',
            value: 'Fantasy',
            _id: '6582d5a42d0ed71a708dcc7e',
          },
          {
            key: 'FASHION',
            value: 'Fashion',
            _id: '6582d5a42d0ed71a708dcc7f',
          },
          {
            key: 'FEEL_GOOD',
            value: 'Feel Good',
            _id: '6582d5a42d0ed71a708dcc80',
          },
          {
            key: 'FEMME_FATALE',
            value: 'Femme Fatale',
            _id: '6582d5a42d0ed71a708dcc81',
          },
          {
            key: 'FENCING',
            value: 'Fencing',
            _id: '6582d5a42d0ed71a708dcc82',
          },
          {
            key: 'FIGHT_SPORTS',
            value: 'Fight Sports',
            _id: '6582d5a42d0ed71a708dcc83',
          },
          {
            key: 'FILM_NOIR',
            value: 'Film Noir',
            _id: '6582d5a42d0ed71a708dcc84',
          },
          {
            key: 'FITNESS',
            value: 'Fitness',
            _id: '6582d5a42d0ed71a708dcc85',
          },
          {
            key: 'FOOD',
            value: 'Food',
            _id: '6582d5a42d0ed71a708dcc86',
          },
          {
            key: 'FOOTBALL',
            value: 'Football',
            _id: '6582d5a42d0ed71a708dcc87',
          },
          {
            key: 'FOREIGN',
            value: 'Foreign',
            _id: '6582d5a42d0ed71a708dcc88',
          },
          {
            key: 'FRIENDSHIP',
            value: 'Friendship',
            _id: '6582d5a42d0ed71a708dcc89',
          },
          {
            key: 'GAME',
            value: 'Game',
            _id: '6582d5a42d0ed71a708dcc8a',
          },
          {
            key: 'GAME_SHOW',
            value: 'Game show',
            _id: '6582d5a42d0ed71a708dcc8b',
          },
          {
            key: 'GANGSTER',
            value: 'Gangster',
            _id: '6582d5a42d0ed71a708dcc8c',
          },
          {
            key: 'GOLF',
            value: 'Golf',
            _id: '6582d5a42d0ed71a708dcc8d',
          },
          {
            key: 'GORY',
            value: 'Gory',
            _id: '6582d5a42d0ed71a708dcc8e',
          },
          {
            key: 'GRITTY',
            value: 'Gritty',
            _id: '6582d5a42d0ed71a708dcc8f',
          },
          {
            key: 'GYMNASTICS',
            value: 'Gymnastics',
            _id: '6582d5a42d0ed71a708dcc90',
          },
          {
            key: 'HANDBALL',
            value: 'Handball',
            _id: '6582d5a42d0ed71a708dcc91',
          },
          {
            key: 'HAPPY',
            value: 'Happy',
            _id: '6582d5a42d0ed71a708dcc92',
          },
          {
            key: 'HEALTH_&amp;_WELLNESS',
            value: 'Health &amp; Wellness',
            _id: '6582d5a42d0ed71a708dcc93',
          },
          {
            key: 'HEARTFELT',
            value: 'Heartfelt',
            _id: '6582d5a42d0ed71a708dcc94',
          },
          {
            key: 'HIGH_SCHOOL',
            value: 'High School',
            _id: '6582d5a42d0ed71a708dcc95',
          },
          {
            key: 'HISTORIC',
            value: 'Historic',
            _id: '6582d5a42d0ed71a708dcc96',
          },
          {
            key: 'HOCvalue',
            value: 'Hocvalue',
            _id: '6582d5a42d0ed71a708dcc97',
          },
          {
            key: 'HOLLYWOOD',
            value: 'Hollywood',
            _id: '6582d5a42d0ed71a708dcc98',
          },
          {
            key: 'HORROR',
            value: 'Horror',
            _id: '6582d5a42d0ed71a708dcc99',
          },
          {
            key: 'HORROR_COMEDY',
            value: 'Horror Comedy',
            _id: '6582d5a42d0ed71a708dcc9a',
          },
          {
            key: 'INDIE_FILM',
            value: 'Indie Film',
            _id: '6582d5a42d0ed71a708dcc9b',
          },
          {
            key: 'INFOTAINMENT',
            value: 'Infotainment',
            _id: '6582d5a42d0ed71a708dcc9c',
          },
          {
            key: 'INSPIRING',
            value: 'Inspiring',
            _id: '6582d5a42d0ed71a708dcc9d',
          },
          {
            key: 'INTIMATE',
            value: 'Intimate',
            _id: '6582d5a42d0ed71a708dcc9e',
          },
          {
            key: 'INVESTIGATIVE',
            value: 'Investigative',
            _id: '6582d5a42d0ed71a708dcc9f',
          },
          {
            key: 'JUDO',
            value: 'Judo',
            _id: '6582d5a42d0ed71a708dcca0',
          },
          {
            key: 'KARATE',
            value: 'Karate',
            _id: '6582d5a42d0ed71a708dcca1',
          },
          {
            key: 'KHO_KHO',
            value: 'KHO KHO',
            _id: '6582d5a42d0ed71a708dcca2',
          },
          {
            key: 'KIDS',
            value: 'Kids',
            _id: '6582d5a42d0ed71a708dcca3',
          },
          {
            key: 'LAW_ENFORCEMENT',
            value: 'Law Enforcement',
            _id: '6582d5a42d0ed71a708dcca4',
          },
          {
            key: 'LAWN_BOWLS',
            value: 'Lawn bowls',
            _id: '6582d5a42d0ed71a708dcca5',
          },
          {
            key: 'LEGAL',
            value: 'Legal',
            _id: '6582d5a42d0ed71a708dcca6',
          },
          {
            key: 'LIFESTYLE',
            value: 'Lifestyle',
            _id: '6582d5a42d0ed71a708dcca7',
          },
          {
            key: 'MARATHON',
            value: 'MARATHON',
            _id: '6582d5a42d0ed71a708dcca8',
          },
          {
            key: 'MARATHON_SWIMMING',
            value: 'Marathon Swimming',
            _id: '6582d5a42d0ed71a708dcca9',
          },
          {
            key: 'MASALA',
            value: 'Masala',
            _id: '6582d5a42d0ed71a708dccaa',
          },
          {
            key: 'MELLOW',
            value: 'Mellow',
            _id: '6582d5a42d0ed71a708dccab',
          },
          {
            key: 'MIND-BENDING',
            value: 'Mind-Bending',
            _id: '6582d5a42d0ed71a708dccac',
          },
          {
            key: 'MODERN_PENTATHLON',
            value: 'Modern pentathlon',
            _id: '6582d5a42d0ed71a708dccad',
          },
          {
            key: 'MOTOR_SPORTS',
            value: 'MOTOR SPORTS',
            _id: '6582d5a42d0ed71a708dccae',
          },
          {
            key: 'MUSIC',
            value: 'Music',
            _id: '6582d5a42d0ed71a708dccaf',
          },
          {
            key: 'MUSICAL',
            value: 'Musical',
            _id: '6582d5a42d0ed71a708dccb0',
          },
          {
            key: 'MYSTERY',
            value: 'Mystery',
            _id: '6582d5a42d0ed71a708dccb1',
          },
          {
            key: 'MYTHOLOGY',
            value: 'Mythology',
            _id: '6582d5a42d0ed71a708dccb2',
          },
          {
            key: 'NATURE',
            value: 'Nature',
            _id: '6582d5a42d0ed71a708dccb3',
          },
          {
            key: 'NEWS',
            value: 'News',
            _id: '6582d5a42d0ed71a708dccb4',
          },
          {
            key: 'NOSTALGIA',
            value: 'Nostalgia',
            _id: '6582d5a42d0ed71a708dccb5',
          },
          {
            key: 'OFFBEAT',
            value: 'Offbeat',
            _id: '6582d5a42d0ed71a708dccb6',
          },
          {
            key: 'PATRIOTISM',
            value: 'Patriotism',
            _id: '6582d5a42d0ed71a708dccb7',
          },
          {
            key: 'PEACEFUL',
            value: 'Peaceful',
            _id: '6582d5a42d0ed71a708dccb8',
          },
          {
            key: 'PERFORMANCE_DRIVEN',
            value: 'Performance Driven',
            _id: '6582d5a42d0ed71a708dccb9',
          },
          {
            key: 'PERIOD',
            value: 'Period',
            _id: '6582d5a42d0ed71a708dccba',
          },
          {
            key: 'PERIOD_PIECES',
            value: 'Period Pieces',
            _id: '6582d5a42d0ed71a708dccbb',
          },
          {
            key: 'POLITICS',
            value: 'Politics',
            _id: '6582d5a42d0ed71a708dccbc',
          },
          {
            key: 'PSYCHOLOGICAL',
            value: 'Psychological',
            _id: '6582d5a42d0ed71a708dccbd',
          },
          {
            key: 'QUIRKY',
            value: 'Quirky',
            _id: '6582d5a42d0ed71a708dccbe',
          },
          {
            key: 'RACING',
            value: 'Racing',
            _id: '6582d5a42d0ed71a708dccbf',
          },
          {
            key: 'REALISTIC',
            value: 'Realistic',
            _id: '6582d5a42d0ed71a708dccc0',
          },
          {
            key: 'REALITY',
            value: 'Reality',
            _id: '6582d5a42d0ed71a708dccc1',
          },
          {
            key: 'REVENGE',
            value: 'Revenge',
            _id: '6582d5a42d0ed71a708dccc2',
          },
          {
            key: 'RHYTHMIC_GYMNASTICS',
            value: 'Rhythmic Gymnastics',
            _id: '6582d5a42d0ed71a708dccc3',
          },
          {
            key: 'ROM_COM',
            value: 'Rom Com',
            _id: '6582d5a42d0ed71a708dccc4',
          },
          {
            key: 'ROMANCE',
            value: 'Romance',
            _id: '6582d5a42d0ed71a708dccc5',
          },
          {
            key: 'ROWING',
            value: 'Rowing',
            _id: '6582d5a42d0ed71a708dccc6',
          },
          {
            key: 'RUGBY',
            value: 'Rugby',
            _id: '6582d5a42d0ed71a708dccc7',
          },
          {
            key: 'RUGBY_SEVENS',
            value: 'Rugby Sevens',
            _id: '6582d5a42d0ed71a708dccc8',
          },
          {
            key: 'RURAL',
            value: 'Rural',
            _id: '6582d5a42d0ed71a708dccc9',
          },
          {
            key: 'RUSTIC',
            value: 'Rustic',
            _id: '6582d5a42d0ed71a708dccca',
          },
          {
            key: 'SAILING',
            value: 'Sailing',
            _id: '6582d5a42d0ed71a708dcccb',
          },
          {
            key: 'SATIRES',
            value: 'Satires',
            _id: '6582d5a42d0ed71a708dcccc',
          },
          {
            key: 'SCIENCE',
            value: 'Science',
            _id: '6582d5a42d0ed71a708dcccd',
          },
          {
            key: 'SCI-FI',
            value: 'Sci-Fi',
            _id: '6582d5a42d0ed71a708dccce',
          },
          {
            key: 'SENTIMENTAL',
            value: 'Sentimental',
            _id: '6582d5a42d0ed71a708dcccf',
          },
          {
            key: 'SHOOTING',
            value: 'Shooting',
            _id: '6582d5a42d0ed71a708dccd0',
          },
          {
            key: 'SILLY',
            value: 'Silly',
            _id: '6582d5a42d0ed71a708dccd1',
          },
          {
            key: 'SKATEBOARDING',
            value: 'Skateboarding',
            _id: '6582d5a42d0ed71a708dccd2',
          },
          {
            key: 'SKETCH_COMEDY',
            value: 'Sketch Comedy',
            _id: '6582d5a42d0ed71a708dccd3',
          },
          {
            key: 'SLICE_OF_LIFE',
            value: 'Slice Of Life',
            _id: '6582d5a42d0ed71a708dccd4',
          },
          {
            key: 'SLICK',
            value: 'Slick',
            _id: '6582d5a42d0ed71a708dccd5',
          },
          {
            key: 'SLOWBURN',
            value: 'Slowburn',
            _id: '6582d5a42d0ed71a708dccd6',
          },
          {
            key: 'SNOOKER',
            value: 'Snooker',
            _id: '6582d5a42d0ed71a708dccd7',
          },
          {
            key: 'SOAPY',
            value: 'Soapy',
            _id: '6582d5a42d0ed71a708dccd8',
          },
          {
            key: 'SOCIAL_MESSAGE',
            value: 'Social Message',
            _id: '6582d5a42d0ed71a708dccd9',
          },
          {
            key: 'SOFTBALL',
            value: 'Softball',
            _id: '6582d5a42d0ed71a708dccda',
          },
          {
            key: 'SOUTH_DUBBED',
            value: 'South Dubbed',
            _id: '6582d5a42d0ed71a708dccdb',
          },
          {
            key: 'SPIRITUAL',
            value: 'Spiritual',
            _id: '6582d5a42d0ed71a708dccdc',
          },
          {
            key: 'SPOOF',
            value: 'Spoof',
            _id: '6582d5a42d0ed71a708dccdd',
          },
          {
            key: 'SPORT',
            value: 'Sport',
            _id: '6582d5a42d0ed71a708dccde',
          },
          {
            key: 'SPORT_CLIMBING',
            value: 'Sport climbing',
            _id: '6582d5a42d0ed71a708dccdf',
          },
          {
            key: 'SPORTS',
            value: 'Sports',
            _id: '6582d5a42d0ed71a708dcce0',
          },
          {
            key: 'SPY',
            value: 'Spy',
            _id: '6582d5a42d0ed71a708dcce1',
          },
          {
            key: 'SQUASH',
            value: 'Squash',
            _id: '6582d5a42d0ed71a708dcce2',
          },
          {
            key: 'STAND-UP_COMEDY',
            value: 'Stand-Up Comedy',
            _id: '6582d5a42d0ed71a708dcce3',
          },
          {
            key: 'STEAMY',
            value: 'Steamy',
            _id: '6582d5a42d0ed71a708dcce4',
          },
          {
            key: 'SUPERHERO',
            value: 'Superhero',
            _id: '6582d5a42d0ed71a708dcce5',
          },
          {
            key: 'SUPERNATURAL',
            value: 'Supernatural',
            _id: '6582d5a42d0ed71a708dcce6',
          },
          {
            key: 'SURFING',
            value: 'Surfing',
            _id: '6582d5a42d0ed71a708dcce7',
          },
          {
            key: 'SUSPENSE',
            value: 'Suspense',
            _id: '6582d5a42d0ed71a708dcce8',
          },
          {
            key: 'SWIMMING',
            value: 'Swimming',
            _id: '6582d5a42d0ed71a708dcce9',
          },
          {
            key: 'SYMPATHETIC',
            value: 'Sympathetic',
            _id: '6582d5a42d0ed71a708dccea',
          },
          {
            key: 'TABLE_TENNIS',
            value: 'Table Tennis',
            _id: '6582d5a42d0ed71a708dcceb',
          },
          {
            key: 'TAEKWONDO',
            value: 'Taekwondo',
            _id: '6582d5a42d0ed71a708dccec',
          },
          {
            key: 'TALK_SHOW',
            value: 'Talk Show',
            _id: '6582d5a42d0ed71a708dcced',
          },
          {
            key: 'TEARJERKER',
            value: 'Tearjerker',
            _id: '6582d5a42d0ed71a708dccee',
          },
          {
            key: 'TEEN_COMEDIES',
            value: 'Teen Comedies',
            _id: '6582d5a42d0ed71a708dccef',
          },
          {
            key: 'TEEN_DRAMAS',
            value: 'Teen Dramas',
            _id: '6582d5a42d0ed71a708dccf0',
          },
          {
            key: 'TENNIS',
            value: 'Tennis',
            _id: '6582d5a42d0ed71a708dccf1',
          },
          {
            key: 'THRILLER',
            value: 'Thriller',
            _id: '6582d5a42d0ed71a708dccf2',
          },
          {
            key: 'TRAGEDY',
            value: 'Tragedy',
            _id: '6582d5a42d0ed71a708dccf3',
          },
          {
            key: 'TRAMPOLINE',
            value: 'Trampoline',
            _id: '6582d5a42d0ed71a708dccf4',
          },
          {
            key: 'TRAMPOLINE_GYMNASTICS',
            value: 'Trampoline Gymnastics',
            _id: '6582d5a42d0ed71a708dccf5',
          },
          {
            key: 'TRAVEL',
            value: 'Travel',
            _id: '6582d5a42d0ed71a708dccf6',
          },
          {
            key: 'TRIATHLON',
            value: 'Triathlon',
            _id: '6582d5a42d0ed71a708dccf7',
          },
          {
            key: 'TWIST_AND_TURNS',
            value: 'Twist and Turns',
            _id: '6582d5a42d0ed71a708dccf8',
          },
          {
            key: 'UNDERSTATED',
            value: 'Understated',
            _id: '6582d5a42d0ed71a708dccf9',
          },
          {
            key: 'URBAN',
            value: 'Urban',
            _id: '6582d5a42d0ed71a708dccfa',
          },
          {
            key: 'VIOLENT',
            value: 'Violent',
            _id: '6582d5a42d0ed71a708dccfb',
          },
          {
            key: 'VISUALLY-STRIKING',
            value: 'Visually-Striking',
            _id: '6582d5a42d0ed71a708dccfc',
          },
          {
            key: 'VOLLEYBALL',
            value: 'Volleyball',
            _id: '6582d5a42d0ed71a708dccfd',
          },
          {
            key: 'WAR',
            value: 'War',
            _id: '6582d5a42d0ed71a708dccfe',
          },
          {
            key: 'WATER_POLO',
            value: 'Water polo',
            _id: '6582d5a42d0ed71a708dccff',
          },
          {
            key: 'WEDDING',
            value: 'Wedding',
            _id: '6582d5a42d0ed71a708dcd00',
          },
          {
            key: 'WEIGHTLIFTING',
            value: 'Weightlifting',
            _id: '6582d5a42d0ed71a708dcd01',
          },
          {
            key: 'WESTERN',
            value: 'Western',
            _id: '6582d5a42d0ed71a708dcd02',
          },
          {
            key: 'WILDLIFE/NATURE',
            value: 'Wildlife/Nature',
            _id: '6582d5a42d0ed71a708dcd03',
          },
          {
            key: 'WITTY',
            value: 'Witty',
            _id: '6582d5a42d0ed71a708dcd04',
          },
          {
            key: 'WOMEN_ORIENTED',
            value: 'Women Oriented',
            _id: '6582d5a42d0ed71a708dcd05',
          },
          {
            key: 'WORKOUT',
            value: 'Workout',
            _id: '6582d5a42d0ed71a708dcd06',
          },
          {
            key: 'WRESTLING',
            value: 'Wrestling',
            _id: '6582d5a42d0ed71a708dcd07',
          },
          {
            key: 'WWE',
            value: 'WWE',
            _id: '6582d5a42d0ed71a708dcd08',
          },
          {
            key: 'YOGA',
            value: 'Yoga',
            _id: '6582d5a42d0ed71a708dcd09',
          },
          {
            key: 'YOUTHFUL',
            value: 'Youthful',
            _id: '6582d5a42d0ed71a708dcd0a',
          },
          {
            key: 'v_gener',
            value: 'test gener',
            _id: '65928946c9d25a0953f68277',
          },
        ],
        createdBy: '653a3a3ed3f6eaf4a9c88f0f',
        updatedBy: '6576b5f238fd83d2b4da64ea',
        status: 'ACTIVE',
        __v: 0,
        createdAt: '2023-12-20T11:53:09.086Z',
        updatedAt: '2024-01-02T18:11:54.833Z',
      },
      {
        _id: '6582d5a42d0ed71a708dcd0b',
        configKey: 'BROADCAST_CHANNEL',
        configValue: [
          {
            key: '10TV',
            value: '10TV',
            _id: '6582d5a42d0ed71a708dcd0c',
          },
          {
            key: '360_TUNEBOX',
            value: '360 TUNEBOX',
            _id: '6582d5a42d0ed71a708dcd0d',
          },
          {
            key: '9X_JALWA',
            value: '9X Jalwa',
            _id: '6582d5a42d0ed71a708dcd0e',
          },
          {
            key: '9X_JHAKAAS',
            value: '9X Jhakaas',
            _id: '6582d5a42d0ed71a708dcd0f',
          },
          {
            key: '9X_TASHAN',
            value: '9X Tashan',
            _id: '6582d5a42d0ed71a708dcd10',
          },
          {
            key: '9XM',
            value: '9XM',
            _id: '6582d5a42d0ed71a708dcd11',
          },
          {
            key: 'AAJ_TAK',
            value: 'Aaj Tak',
            _id: '6582d5a42d0ed71a708dcd12',
          },
          {
            key: 'AAMAR_BANGLA',
            value: 'Aamar Bangla',
            _id: '6582d5a42d0ed71a708dcd13',
          },
          {
            key: 'AATH',
            value: 'Aath',
            _id: '6582d5a42d0ed71a708dcd14',
          },
          {
            key: 'ABN_ANDHRA_JYOTHI',
            value: 'ABN Andhra Jyothi',
            _id: '6582d5a42d0ed71a708dcd15',
          },
          {
            key: 'AMRITA_TV',
            value: 'Amrita TV',
            _id: '6582d5a42d0ed71a708dcd16',
          },
          {
            key: 'ANIMAX',
            value: 'Animax',
            _id: '6582d5a42d0ed71a708dcd17',
          },
          {
            key: 'AP_24X7',
            value: 'AP 24x7',
            _id: '6582d5a42d0ed71a708dcd18',
          },
          {
            key: 'ARRE',
            value: 'Arre',
            _id: '6582d5a42d0ed71a708dcd19',
          },
          {
            key: 'ASHIMA_PICTURES',
            value: 'Ashima Pictures',
            _id: '6582d5a42d0ed71a708dcd1a',
          },
          {
            key: 'AXN',
            value: 'AXN',
            _id: '6582d5a42d0ed71a708dcd1b',
          },
          {
            key: 'B4U_MOVIES',
            value: 'B4U Movies',
            _id: '6582d5a42d0ed71a708dcd1c',
          },
          {
            key: 'B4U_MUSIC',
            value: 'B4U Music',
            _id: '6582d5a42d0ed71a708dcd1d',
          },
          {
            key: 'BLOOMBERGQUINT',
            value: 'BloombergQuint',
            _id: '6582d5a42d0ed71a708dcd1e',
          },
          {
            key: 'BOOGLE_BOLLYWOOD',
            value: 'Boogle Bollywood',
            _id: '6582d5a42d0ed71a708dcd1f',
          },
          {
            key: 'BTVI',
            value: 'BTVi',
            _id: '6582d5a42d0ed71a708dcd20',
          },
          {
            key: 'CAFÉ_MARATHI',
            value: 'Café Marathi',
            _id: '6582d5a42d0ed71a708dcd21',
          },
          {
            key: 'CAPTAIN_TV',
            value: 'Captain TV',
            _id: '6582d5a42d0ed71a708dcd22',
          },
          {
            key: 'CHARDIKLA_TIME_TV',
            value: 'Chardikla Time TV',
            _id: '6582d5a42d0ed71a708dcd23',
          },
          {
            key: 'COMBAT_GO',
            value: 'Combat Go',
            _id: '6582d5a42d0ed71a708dcd24',
          },
          {
            key: 'COSMOS_MAYA',
            value: 'Cosmos Maya',
            _id: '6582d5a42d0ed71a708dcd25',
          },
          {
            key: 'COSMOSMAYA',
            value: 'Cosmosmaya',
            _id: '6582d5a42d0ed71a708dcd26',
          },
          {
            key: 'CVR_NEWS',
            value: 'CVR News',
            _id: '6582d5a42d0ed71a708dcd27',
          },
          {
            key: 'DILLI_AAJ_TAK',
            value: 'Dilli Aaj Tak',
            _id: '6582d5a42d0ed71a708dcd28',
          },
          {
            key: 'DOCUBOX',
            value: 'DOCUBOX',
            _id: '6582d5a42d0ed71a708dcd29',
          },
          {
            key: 'EPIC',
            value: 'EPIC',
            _id: '6582d5a42d0ed71a708dcd2a',
          },
          {
            key: 'ES_24',
            value: 'ES 24',
            _id: '6582d5a42d0ed71a708dcd2b',
          },
          {
            key: 'EUROCHANNEL',
            value: 'Eurochannel',
            _id: '6582d5a42d0ed71a708dcd2c',
          },
          {
            key: 'FASHIONBOX',
            value: 'FASHIONBOX',
            _id: '6582d5a42d0ed71a708dcd2d',
          },
          {
            key: 'FASTNFUNBOX',
            value: 'FASTNFUNBOX',
            _id: '6582d5a42d0ed71a708dcd2e',
          },
          {
            key: 'FIGHTBOX',
            value: 'FIGHTBOX',
            _id: '6582d5a42d0ed71a708dcd2f',
          },
          {
            key: 'FILMBOX_ART_HOUSE',
            value: 'FILMBOX ART HOUSE',
            _id: '6582d5a42d0ed71a708dcd30',
          },
          {
            key: 'FLOWERS_TV',
            value: 'Flowers TV',
            _id: '6582d5a42d0ed71a708dcd31',
          },
          {
            key: 'FOOD_FOOD',
            value: 'Food Food',
            _id: '6582d5a42d0ed71a708dcd32',
          },
          {
            key: 'FRONT_ROW',
            value: 'Front Row',
            _id: '6582d5a42d0ed71a708dcd33',
          },
          {
            key: 'GAMETOON',
            value: 'GAMETOON',
            _id: '6582d5a42d0ed71a708dcd34',
          },
          {
            key: 'GOODTIMES',
            value: 'GoodTimes',
            _id: '6582d5a42d0ed71a708dcd35',
          },
          {
            key: 'GURBAANI_TV',
            value: 'Gurbaani TV',
            _id: '6582d5a42d0ed71a708dcd36',
          },
          {
            key: 'HMTV_NEWS',
            value: 'HMTV News',
            _id: '6582d5a42d0ed71a708dcd37',
          },
          {
            key: 'IBC_24_NEWS',
            value: 'IBC 24 NEWS',
            _id: '6582d5a42d0ed71a708dcd38',
          },
          {
            key: 'INDIA_TODAY',
            value: 'India Today',
            _id: '6582d5a42d0ed71a708dcd39',
          },
          {
            key: 'INDIA_TV',
            value: 'India TV',
            _id: '6582d5a42d0ed71a708dcd3a',
          },
          {
            key: 'ISAI_ARUVI',
            value: 'Isai Aruvi',
            _id: '6582d5a42d0ed71a708dcd3b',
          },
          {
            key: 'ITV',
            value: 'ITV',
            _id: '6582d5a42d0ed71a708dcd3c',
          },
          {
            key: 'JANAM_TV',
            value: 'Janam TV',
            _id: '6582d5a42d0ed71a708dcd3d',
          },
          {
            key: 'JAYA_MAX',
            value: 'Jaya Max',
            _id: '6582d5a42d0ed71a708dcd3e',
          },
          {
            key: 'JAYA_PLUS',
            value: 'Jaya Plus',
            _id: '6582d5a42d0ed71a708dcd3f',
          },
          {
            key: 'JAYA_TV',
            value: 'Jaya TV',
            _id: '6582d5a42d0ed71a708dcd40',
          },
          {
            key: 'KAIRALI_TV',
            value: 'Kairali TV',
            _id: '6582d5a42d0ed71a708dcd41',
          },
          {
            key: 'KALAIGNAR_TV',
            value: 'Kalaignar TV',
            _id: '6582d5a42d0ed71a708dcd42',
          },
          {
            key: 'KERALA_VISION',
            value: 'Kerala Vision',
            _id: '6582d5a42d0ed71a708dcd43',
          },
          {
            key: 'KEW_MEDIA',
            value: 'KEW Media',
            _id: '6582d5a42d0ed71a708dcd44',
          },
          {
            key: 'KIX',
            value: 'KIX',
            _id: '6582d5a42d0ed71a708dcd45',
          },
          {
            key: 'KRISHNA_VANI',
            value: 'Krishna Vani',
            _id: '6582d5a42d0ed71a708dcd46',
          },
          {
            key: 'LE_PLEX_HD',
            value: 'Le Plex HD',
            _id: '6582d5a42d0ed71a708dcd47',
          },
          {
            key: 'LIONSGATE',
            value: 'Lionsgate',
            _id: '6582d5a42d0ed71a708dcd48',
          },
          {
            key: 'LIV',
            value: 'LIV',
            _id: '6582d5a42d0ed71a708dcd49',
          },
          {
            key: 'LIV_CRIME',
            value: 'LIV Crime',
            _id: '6582d5a42d0ed71a708dcd4a',
          },
          {
            key: 'LIV_MUSIC',
            value: 'LIV Music',
            _id: '6582d5a42d0ed71a708dcd4b',
          },
          {
            key: 'LIV_SERIES',
            value: 'LIV SERIES',
            _id: '6582d5a42d0ed71a708dcd4c',
          },
          {
            key: 'LIVE_CHANNELS',
            value: 'Live Channels',
            _id: '6582d5a42d0ed71a708dcd4d',
          },
          {
            key: 'MAHAA_TV',
            value: 'Mahaa TV',
            _id: '6582d5a42d0ed71a708dcd4e',
          },
          {
            key: 'MAKKAL_TV',
            value: 'Makkal TV',
            _id: '6582d5a42d0ed71a708dcd4f',
          },
          {
            key: 'MANORAMA_NEWS',
            value: 'Manorama News',
            _id: '6582d5a42d0ed71a708dcd50',
          },
          {
            key: 'MATHRUBHUMI_NEWS',
            value: 'Mathrubhumi News',
            _id: '6582d5a42d0ed71a708dcd51',
          },
          {
            key: 'MAX',
            value: 'MAX',
            _id: '6582d5a42d0ed71a708dcd52',
          },
          {
            key: 'MAX2',
            value: 'MAX2',
            _id: '6582d5a42d0ed71a708dcd53',
          },
          {
            key: 'MAZHAVIL_MANORAMA',
            value: 'Mazhavil Manorama',
            _id: '6582d5a42d0ed71a708dcd54',
          },
          {
            key: 'MEDIA_ONE_TV',
            value: 'Media One TV',
            _id: '6582d5a42d0ed71a708dcd55',
          },
          {
            key: 'MIX',
            value: 'MIX',
            _id: '6582d5a42d0ed71a708dcd56',
          },
          {
            key: 'MOV',
            value: 'MOV',
            _id: '6582d5a42d0ed71a708dcd57',
          },
          {
            key: 'MURASU_TV',
            value: 'Murasu TV',
            _id: '6582d5a42d0ed71a708dcd58',
          },
          {
            key: 'NDTV_24X7',
            value: 'NDTV 24x7',
            _id: '6582d5a42d0ed71a708dcd59',
          },
          {
            key: 'NDTV_INDIA_TV',
            value: 'NDTV India TV',
            _id: '6582d5a42d0ed71a708dcd5a',
          },
          {
            key: 'NDTV_PROFIT',
            value: 'NDTV Profit',
            _id: '6582d5a42d0ed71a708dcd5b',
          },
          {
            key: 'NEWS_7',
            value: 'News 7',
            _id: '6582d5a42d0ed71a708dcd5c',
          },
          {
            key: 'NEWS_NATION',
            value: 'News Nation',
            _id: '6582d5a42d0ed71a708dcd5d',
          },
          {
            key: 'NH_MOVIES',
            value: 'NH Movies',
            _id: '6582d5a42d0ed71a708dcd5e',
          },
          {
            key: 'NIRVAAN_ENTERTAINMENT',
            value: 'Nirvaan Entertainment',
            _id: '6582d5a42d0ed71a708dcd5f',
          },
          {
            key: 'NTV',
            value: 'NTV',
            _id: '6582d5a42d0ed71a708dcd60',
          },
          {
            key: 'OLYMPIC_CHANNEL',
            value: 'Olympic Channel',
            _id: '6582d5a42d0ed71a708dcd61',
          },
          {
            key: 'ONE_TAKE_MEDIA',
            value: 'One Take Media',
            _id: '6582d5a42d0ed71a708dcd62',
          },
          {
            key: 'ONE_TAKE_MEDIA._SKY_VISION',
            value: 'One Take Media. SKY Vision',
            _id: '6582d5a42d0ed71a708dcd63',
          },
          {
            key: 'PAL',
            value: 'PAL',
            _id: '6582d5a42d0ed71a708dcd64',
          },
          {
            key: 'PEOPLE_TV',
            value: 'People TV',
            _id: '6582d5a42d0ed71a708dcd65',
          },
          {
            key: 'PINKVILLA',
            value: 'Pinkvilla',
            _id: '6582d5a42d0ed71a708dcd66',
          },
          {
            key: 'PIX',
            value: 'PIX',
            _id: '6582d5a42d0ed71a708dcd67',
          },
          {
            key: 'PLANET_VIX',
            value: 'Planet VIX',
            _id: '6582d5a42d0ed71a708dcd68',
          },
          {
            key: 'POCKET_FILMS',
            value: 'Pocket Films',
            _id: '6582d5a42d0ed71a708dcd69',
          },
          {
            key: 'POLIMER_NEWS',
            value: 'Polimer News',
            _id: '6582d5a42d0ed71a708dcd6a',
          },
          {
            key: 'PUBLIC_TV',
            value: 'Public TV',
            _id: '6582d5a42d0ed71a708dcd6b',
          },
          {
            key: 'PUTHIYA_THALAIMURAI',
            value: 'Puthiya Thalaimurai',
            _id: '6582d5a42d0ed71a708dcd6c',
          },
          {
            key: 'PUTHUYUGAM_TV',
            value: 'Puthuyugam TV',
            _id: '6582d5a42d0ed71a708dcd6d',
          },
          {
            key: 'RAJ_NEWS_24X7',
            value: 'Raj News 24x7',
            _id: '6582d5a42d0ed71a708dcd6e',
          },
          {
            key: 'RAJ_VISSA',
            value: 'Raj Vissa',
            _id: '6582d5a42d0ed71a708dcd6f',
          },
          {
            key: 'RAJSHRI',
            value: 'Rajshri',
            _id: '6582d5a42d0ed71a708dcd70',
          },
          {
            key: 'RED_BULL_CONTENT_POOL',
            value: 'Red Bull Content Pool',
            _id: '6582d5a42d0ed71a708dcd71',
          },
          {
            key: 'REPUBLIC_TV',
            value: 'Republic TV',
            _id: '6582d5a42d0ed71a708dcd72',
          },
          {
            key: 'ROX_HD',
            value: 'Rox HD',
            _id: '6582d5a42d0ed71a708dcd73',
          },
          {
            key: 'SAB',
            value: 'SAB',
            _id: '6582d5a42d0ed71a708dcd74',
          },
          {
            key: 'SAKKATH_STUDIOS',
            value: 'Sakkath Studios',
            _id: '6582d5a42d0ed71a708dcd75',
          },
          {
            key: 'SAKSHI_TV',
            value: 'Sakshi TV',
            _id: '6582d5a42d0ed71a708dcd76',
          },
          {
            key: 'SANJEEV_KAPOOR_KHAZANA',
            value: 'Sanjeev Kapoor Khazana',
            _id: '6582d5a42d0ed71a708dcd77',
          },
          {
            key: 'SEITHIGAL_TV',
            value: 'Seithigal TV',
            _id: '6582d5a42d0ed71a708dcd78',
          },
          {
            key: 'SET',
            value: 'SET',
            _id: '6582d5a42d0ed71a708dcd79',
          },
          {
            key: 'SIGNATURE_SERIES',
            value: 'SIGNATURE SERIES',
            _id: '6582d5a42d0ed71a708dcd7a',
          },
          {
            key: 'SILLY_MONKS_NETWORK',
            value: 'Silly Monks Network',
            _id: '6582d5a42d0ed71a708dcd7b',
          },
          {
            key: 'SIRIPPOLI_TV',
            value: 'Sirippoli TV',
            _id: '6582d5a42d0ed71a708dcd7c',
          },
          {
            key: 'SIX_HD',
            value: 'SIX HD',
            _id: '6582d5a42d0ed71a708dcd7d',
          },
          {
            key: 'SIX_SD',
            value: 'SIX SD',
            _id: '6582d5a42d0ed71a708dcd7e',
          },
          {
            key: 'SIX_SIGMA_FILMS',
            value: 'Six Sigma Films',
            _id: '6582d5a42d0ed71a708dcd7f',
          },
          {
            key: 'SKY_VISION',
            value: 'Sky Vision',
            _id: '6582d5a42d0ed71a708dcd80',
          },
          {
            key: 'SONY_ESPN',
            value: 'SONY ESPN',
            _id: '6582d5a42d0ed71a708dcd81',
          },
          {
            key: 'SONY_ESPN_HD',
            value: 'SONY ESPN HD',
            _id: '6582d5a42d0ed71a708dcd82',
          },
          {
            key: 'SONY_MARATHI',
            value: 'Sony Marathi',
            _id: '6582d5a42d0ed71a708dcd83',
          },
          {
            key: 'SONY_WAH',
            value: 'Sony WAH',
            _id: '6582d5a42d0ed71a708dcd84',
          },
          {
            key: 'SONY_YAY',
            value: 'Sony YAY',
            _id: '6582d5a42d0ed71a708dcd85',
          },
          {
            key: 'SPE_NETWORK',
            value: 'SPE Network',
            _id: '6582d5a42d0ed71a708dcd86',
          },
          {
            key: 'SUDARSHAN_NEWS',
            value: 'Sudarshan News',
            _id: '6582d5a42d0ed71a708dcd87',
          },
          {
            key: 'T_NEWS',
            value: 'T News',
            _id: '6582d5a42d0ed71a708dcd88',
          },
          {
            key: 'TEN_1',
            value: 'Ten 1',
            _id: '6582d5a42d0ed71a708dcd89',
          },
          {
            key: 'TEN_1_HD',
            value: 'Ten 1 HD',
            _id: '6582d5a42d0ed71a708dcd8a',
          },
          {
            key: 'TEN_2',
            value: 'Ten 2',
            _id: '6582d5a42d0ed71a708dcd8b',
          },
          {
            key: 'TEN_3',
            value: 'Ten 3',
            _id: '6582d5a42d0ed71a708dcd8c',
          },
          {
            key: 'TEN_GOLF',
            value: 'Ten Golf',
            _id: '6582d5a42d0ed71a708dcd8d',
          },
          {
            key: 'TEN_PAK',
            value: 'Ten Pak',
            _id: '6582d5a42d0ed71a708dcd8e',
          },
          {
            key: 'TEZ',
            value: 'TEZ',
            _id: '6582d5a42d0ed71a708dcd8f',
          },
          {
            key: 'THANTHI_TV',
            value: 'Thanthi TV',
            _id: '6582d5a42d0ed71a708dcd90',
          },
          {
            key: 'THE_Q_INDIA',
            value: 'The Q India',
            _id: '6582d5a42d0ed71a708dcd91',
          },
          {
            key: 'TOONZ_MEDIA',
            value: 'Toonz Media',
            _id: '6582d5a42d0ed71a708dcd92',
          },
          {
            key: 'TRAVELXP',
            value: 'Travelxp',
            _id: '6582d5a42d0ed71a708dcd93',
          },
          {
            key: 'TRAVELXP_TAMIL',
            value: 'Travelxp Tamil',
            _id: '6582d5a42d0ed71a708dcd94',
          },
          {
            key: 'TV5_KANNADA',
            value: 'TV5 Kannada',
            _id: '6582d5a42d0ed71a708dcd95',
          },
          {
            key: 'TV5_NEWS',
            value: 'TV5 News',
            _id: '6582d5a42d0ed71a708dcd96',
          },
          {
            key: 'TVF_PLAY',
            value: 'TVF Play',
            _id: '6582d5a42d0ed71a708dcd97',
          },
          {
            key: 'V6_NEWS',
            value: 'V6 News',
            _id: '6582d5a42d0ed71a708dcd98',
          },
          {
            key: 'VANITHA_TV',
            value: 'Vanitha TV',
            _id: '6582d5a42d0ed71a708dcd99',
          },
          {
            key: 'VEGA_ENTERTAINMENT',
            value: 'Vega Entertainment',
            _id: '6582d5a42d0ed71a708dcd9a',
          },
          {
            key: 'VENDHAR_TV',
            value: 'Vendhar TV',
            _id: '6582d5a42d0ed71a708dcd9b',
          },
          {
            key: 'WE_TV',
            value: 'We TV',
            _id: '6582d5a42d0ed71a708dcd9c',
          },
          {
            key: 'WEB_TALKIES',
            value: 'Web Talkies',
            _id: '6582d5a42d0ed71a708dcd9d',
          },
          {
            key: 'WHISTLING_WOODS',
            value: 'Whistling Woods',
            _id: '6582d5a42d0ed71a708dcd9e',
          },
          {
            key: 'ZEE_MUSIC',
            value: 'Zee Music',
            _id: '6582d5a42d0ed71a708dcd9f',
          },
          {
            key: '101_INDIA',
            value: '101 India',
            _id: '6582d5a42d0ed71a708dcda0',
          },
          {
            key: 'HUNGAMA',
            value: 'Hungama',
            _id: '6582d5a42d0ed71a708dcda1',
          },
          {
            key: 'SAB',
            value: 'SAB',
            _id: '6582d5a42d0ed71a708dcda2',
          },
          {
            key: 'SONY_BBC_EARTH',
            value: 'Sony BBC Earth',
            _id: '6582d5a42d0ed71a708dcda3',
          },
          {
            key: 'SONY_SPORTS_TEN_1',
            value: 'Sony Sports Ten 1',
            _id: '6582d5a42d0ed71a708dcda4',
          },
          {
            key: 'SONY_SPORTS_TEN_2',
            value: 'Sony Sports Ten 2',
            _id: '6582d5a42d0ed71a708dcda5',
          },
          {
            key: 'SONY_SPORTS_TEN_3',
            value: 'Sony Sports Ten 3',
            _id: '6582d5a42d0ed71a708dcda6',
          },
          {
            key: 'SONY_SPORTS_TEN_4',
            value: 'Sony Sports Ten 4',
            _id: '6582d5a42d0ed71a708dcda7',
          },
          {
            key: 'SONY_SPORTS_TEN_5',
            value: 'Sony Sports Ten 5',
            _id: '6582d5a42d0ed71a708dcda8',
          },
          {
            key: 'WWE_NETWORK',
            value: 'WWE Network',
            _id: '6582d5a42d0ed71a708dcda9',
          },
        ],
        createdBy: '653a3a3ed3f6eaf4a9c88f0f',
        updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
        status: 'ACTIVE',
        __v: 0,
        createdAt: '2023-12-20T11:53:09.090Z',
        updatedAt: '2023-12-20T11:53:09.090Z',
      },
      {
        _id: '6582d5a42d0ed71a708dcecf',
        configKey: 'MOOD',
        configValue: [
          {
            key: 'ADVENTURE',
            value: 'Adventure',
            _id: '6582d5a42d0ed71a708dced0',
          },
          {
            key: 'ANTHOLOGY',
            value: 'Anthology',
            _id: '6582d5a42d0ed71a708dced1',
          },
          {
            key: 'ART_HOUSE/_AVANT_GARDE',
            value: 'Art house/ Avant garde',
            _id: '6582d5a42d0ed71a708dced2',
          },
          {
            key: 'BASED_ON_BOOKS',
            value: 'Based on Books',
            _id: '6582d5a42d0ed71a708dced3',
          },
          {
            key: 'CELEBRATORY',
            value: 'Celebratory',
            _id: '6582d5a42d0ed71a708dced4',
          },
          {
            key: 'CEREBRAL',
            value: 'Cerebral',
            _id: '6582d5a42d0ed71a708dced5',
          },
          {
            key: 'CHICK_FLICK',
            value: 'Chick Flick',
            _id: '6582d5a42d0ed71a708dced6',
          },
          {
            key: 'COMIC_BOOK',
            value: 'Comic Book',
            _id: '6582d5a42d0ed71a708dced7',
          },
          {
            key: 'COMING_OF_AGE',
            value: 'Coming of Age',
            _id: '6582d5a42d0ed71a708dced8',
          },
          {
            key: 'CREATURE',
            value: 'Creature',
            _id: '6582d5a42d0ed71a708dced9',
          },
          {
            key: 'CRIME',
            value: 'Crime',
            _id: '6582d5a42d0ed71a708dceda',
          },
          {
            key: 'CRITICALLY-ACCLAIMED',
            value: 'Critically-acclaimed',
            _id: '6582d5a42d0ed71a708dcedb',
          },
          {
            key: 'DANCE',
            value: 'Dance',
            _id: '6582d5a42d0ed71a708dcedc',
          },
          {
            key: 'DARK',
            value: 'Dark',
            _id: '6582d5a42d0ed71a708dcedd',
          },
          {
            key: 'ENSEMBLE',
            value: 'Ensemble',
            _id: '6582d5a42d0ed71a708dcede',
          },
          {
            key: 'FEEL-GOOD',
            value: 'Feel-good',
            _id: '6582d5a42d0ed71a708dcedf',
          },
          {
            key: 'GANGSTER',
            value: 'Gangster',
            _id: '6582d5a42d0ed71a708dcee0',
          },
          {
            key: 'GRITTY',
            value: 'Gritty',
            _id: '6582d5a42d0ed71a708dcee1',
          },
          {
            key: 'INDIE_FILM',
            value: 'Indie Film',
            _id: '6582d5a42d0ed71a708dcee2',
          },
          {
            key: 'INVESTIGATIVE',
            value: 'Investigative',
            _id: '6582d5a42d0ed71a708dcee3',
          },
          {
            key: 'LEGAL_/_COURTROOM',
            value: 'Legal / Courtroom',
            _id: '6582d5a42d0ed71a708dcee4',
          },
          {
            key: 'MASALA',
            value: 'Masala',
            _id: '6582d5a42d0ed71a708dcee5',
          },
          {
            key: 'MIND-BENDING',
            value: 'Mind-bending',
            _id: '6582d5a42d0ed71a708dcee6',
          },
          {
            key: 'NOSTALGIA',
            value: 'Nostalgia',
            _id: '6582d5a42d0ed71a708dcee7',
          },
          {
            key: 'OFFBEAT',
            value: 'Offbeat',
            _id: '6582d5a42d0ed71a708dcee8',
          },
          {
            key: 'PATRIOTISM',
            value: 'Patriotism',
            _id: '6582d5a42d0ed71a708dcee9',
          },
          {
            key: 'PERIOD_PIECES',
            value: 'Period Pieces',
            _id: '6582d5a42d0ed71a708dceea',
          },
          {
            key: 'POLITICS',
            value: 'Politics',
            _id: '6582d5a42d0ed71a708dceeb',
          },
          {
            key: 'PSYCHOLOGICAL',
            value: 'Psychological',
            _id: '6582d5a42d0ed71a708dceec',
          },
          {
            key: 'REALISTIC',
            value: 'Realistic',
            _id: '6582d5a42d0ed71a708dceed',
          },
          {
            key: 'REVENGE',
            value: 'Revenge',
            _id: '6582d5a42d0ed71a708dceee',
          },
          {
            key: 'SATIRE',
            value: 'Satire',
            _id: '6582d5a42d0ed71a708dceef',
          },
          {
            key: 'SLICE_OF_LIFE',
            value: 'Slice Of Life',
            _id: '6582d5a42d0ed71a708dcef0',
          },
          {
            key: 'SLICK',
            value: 'Slick',
            _id: '6582d5a42d0ed71a708dcef1',
          },
          {
            key: 'SLOWBURN',
            value: 'Slowburn',
            _id: '6582d5a42d0ed71a708dcef2',
          },
          {
            key: 'SOCIAL_MESSAGE',
            value: 'Social Message',
            _id: '6582d5a42d0ed71a708dcef3',
          },
          {
            key: 'SOUTH_DUBBED',
            value: 'South Dubbed',
            _id: '6582d5a42d0ed71a708dcef4',
          },
          {
            key: 'SPORTS',
            value: 'Sports',
            _id: '6582d5a42d0ed71a708dcef5',
          },
          {
            key: 'SUPERHERO',
            value: 'Superhero',
            _id: '6582d5a42d0ed71a708dcef6',
          },
          {
            key: 'TEARJERKER',
            value: 'Tearjerker',
            _id: '6582d5a42d0ed71a708dcef7',
          },
          {
            key: 'TWISTS_AND_TURNS',
            value: 'Twists and Turns',
            _id: '6582d5a42d0ed71a708dcef8',
          },
          {
            key: 'VIOLENCE_/_GORY',
            value: 'Violence / Gory',
            _id: '6582d5a42d0ed71a708dcef9',
          },
          {
            key: 'VISUALLY-STRIKING',
            value: 'Visually-striking',
            _id: '6582d5a42d0ed71a708dcefa',
          },
          {
            key: 'SUPERNATURAL',
            value: 'Supernatural',
            _id: '6582d5a42d0ed71a708dcefb',
          },
          {
            key: 'WITTY',
            value: 'Witty',
            _id: '6582d5a42d0ed71a708dcefc',
          },
          {
            key: 'WOMEN_ORIENTED',
            value: 'Women Oriented',
            _id: '6582d5a42d0ed71a708dcefd',
          },
          {
            key: 'YOUTHFUL',
            value: 'Youthful',
            _id: '6582d5a42d0ed71a708dcefe',
          },
          {
            key: 'KIDS_SPECIAL',
            value: 'Kids Special',
            _id: '6582d5a42d0ed71a708dceff',
          },
          {
            key: 'COMMON_MAN_/_STORIES_OF_MIDDLE_CLASS',
            value: 'Common Man / Stories of Middle Class',
            _id: '6582d5a42d0ed71a708dcf00',
          },
          {
            key: 'CULTURAL_POTPOURRI',
            value: 'Cultural Potpourri',
            _id: '6582d5a42d0ed71a708dcf01',
          },
          {
            key: 'PROGRESSIVE_WOMEN',
            value: 'Progressive Women',
            _id: '6582d5a42d0ed71a708dcf02',
          },
          {
            key: 'TALENT',
            value: 'Talent',
            _id: '6582d5a42d0ed71a708dcf03',
          },
          {
            key: 'ROYALTY',
            value: 'Royalty',
            _id: '6582d5a42d0ed71a708dcf04',
          },
          {
            key: 'FIGHT_AGAINST_INJUSTICE',
            value: 'Fight Against Injustice',
            _id: '6582d5a42d0ed71a708dcf05',
          },
          {
            key: 'STRONG_FEMALE_LEAD',
            value: 'Strong Female Lead',
            _id: '6582d5a42d0ed71a708dcf06',
          },
          {
            key: 'SHATTERING_STEREOTYPES',
            value: 'Shattering Stereotypes',
            _id: '6582d5a42d0ed71a708dcf07',
          },
          {
            key: 'FEMME_FATALE',
            value: 'Femme Fatale',
            _id: '6582d5a42d0ed71a708dcf08',
          },
          {
            key: 'UNREQUITED_LOVE',
            value: 'Unrequited Love',
            _id: '6582d5a42d0ed71a708dcf09',
          },
          {
            key: 'STORIES_AHEAD_OF_TIME',
            value: 'Stories Ahead of Time',
            _id: '6582d5a42d0ed71a708dcf0a',
          },
          {
            key: 'SINGING',
            value: 'Singing',
            _id: '6582d5a42d0ed71a708dcf0b',
          },
        ],
        createdBy: '653a3a3ed3f6eaf4a9c88f0f',
        updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
        status: 'ACTIVE',
        __v: 0,
        createdAt: '2023-12-20T11:53:09.097Z',
        updatedAt: '2023-12-20T11:53:09.097Z',
      },
      {
        _id: '6582d5a42d0ed71a708dcf38',
        configKey: 'CONTENT_SUB_CATEGORY',
        configValue: [
          {
            key: 'LIV_ORIGINAL',
            value: 'LIV Original',
            _id: '6582d5a42d0ed71a708dcf39',
          },
          {
            key: 'ACQUIRED_SHOW',
            value: 'Acquired Show',
            _id: '6582d5a42d0ed71a708dcf3a',
          },
          {
            key: 'ACQUIRED_MOVIES',
            value: 'Acquired Movies',
            _id: '6582d5a42d0ed71a708dcf3b',
          },
          {
            key: 'LIV_EXCLUSIVE_MOVIES',
            value: 'LIV Exclusive Movies',
            _id: '6582d5a42d0ed71a708dcf3c',
          },
          {
            key: 'LIVE_TV',
            value: 'Live TV',
            _id: '6582d5a42d0ed71a708dcf3d',
          },
          {
            key: 'SPORTS_SHOW',
            value: 'Sports Show',
            _id: '6582d5a42d0ed71a708dcf3e',
          },
          {
            key: 'EVENT',
            value: 'Event',
            _id: '6582d5a42d0ed71a708dcf3f',
          },
          {
            key: 'SPORT',
            value: 'Sport',
            _id: '6582d5a42d0ed71a708dcf40',
          },
          {
            key: 'TV_SHOW',
            value: 'TV Show',
            _id: '6582d5a42d0ed71a708dcf41',
          },
        ],
        createdBy: '653a3a3ed3f6eaf4a9c88f0f',
        updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
        status: 'ACTIVE',
        __v: 0,
        createdAt: '2023-12-20T11:53:09.100Z',
        updatedAt: '2023-12-20T11:53:09.100Z',
      },
      {
        _id: '6582d5a42d0ed71a708dcf0c',
        configKey: 'SUB_GENRE',
        configValue: [
          {
            key: 'ACTION',
            value: 'Action',
            _id: '6582d5a42d0ed71a708dcf0d',
          },
          {
            key: 'COMEDY',
            value: 'Comedy',
            _id: '6582d5a42d0ed71a708dcf0e',
          },
          {
            key: 'THRILLER',
            value: 'Thriller',
            _id: '6582d5a42d0ed71a708dcf0f',
          },
          {
            key: 'SUSPENSE',
            value: 'Suspense',
            _id: '6582d5a42d0ed71a708dcf10',
          },
          {
            key: 'ROMANCE',
            value: 'Romance',
            _id: '6582d5a42d0ed71a708dcf11',
          },
          {
            key: 'ROM_COM',
            value: 'Rom Com',
            _id: '6582d5a42d0ed71a708dcf12',
          },
          {
            key: 'HORROR',
            value: 'Horror',
            _id: '6582d5a42d0ed71a708dcf13',
          },
          {
            key: 'MYTHOLOGY',
            value: 'Mythology',
            _id: '6582d5a42d0ed71a708dcf14',
          },
          {
            key: 'SCI_FI',
            value: 'Sci Fi',
            _id: '6582d5a42d0ed71a708dcf15',
          },
          {
            key: 'FANTASY',
            value: 'Fantasy',
            _id: '6582d5a42d0ed71a708dcf16',
          },
          {
            key: 'EROTICA',
            value: 'Erotica',
            _id: '6582d5a42d0ed71a708dcf17',
          },
          {
            key: 'TRAGEDY',
            value: 'Tragedy',
            _id: '6582d5a42d0ed71a708dcf18',
          },
          {
            key: 'RELIGIOUS/_SPIRITUAL',
            value: 'Religious/ Spiritual',
            _id: '6582d5a42d0ed71a708dcf19',
          },
          {
            key: 'BIOPIC',
            value: 'Biopic',
            _id: '6582d5a42d0ed71a708dcf1a',
          },
          {
            key: 'BASED_ON_TRUE_EVENTS',
            value: 'Based on True Events',
            _id: '6582d5a42d0ed71a708dcf1b',
          },
          {
            key: 'MUSICAL',
            value: 'Musical',
            _id: '6582d5a42d0ed71a708dcf1c',
          },
          {
            key: 'HISTORICAL_/_PERIOD',
            value: 'Historical / Period',
            _id: '6582d5a42d0ed71a708dcf1d',
          },
          {
            key: 'DOCUMENTARY',
            value: 'Documentary',
            _id: '6582d5a42d0ed71a708dcf1e',
          },
          {
            key: 'ANIMATION',
            value: 'Animation',
            _id: '6582d5a42d0ed71a708dcf1f',
          },
          {
            key: 'DRAMA',
            value: 'Drama',
            _id: '6582d5a42d0ed71a708dcf20',
          },
          {
            key: 'FOOD_AND_TRAVEL_TV',
            value: 'Food and Travel TV',
            _id: '6582d5a42d0ed71a708dcf21',
          },
          {
            key: 'GAME_SHOW',
            value: 'Game show',
            _id: '6582d5a42d0ed71a708dcf22',
          },
          {
            key: 'HEALTH_AND_WELLNESS',
            value: 'Health and Wellness',
            _id: '6582d5a42d0ed71a708dcf23',
          },
          {
            key: 'INFOTAINMENT',
            value: 'Infotainment',
            _id: '6582d5a42d0ed71a708dcf24',
          },
          {
            key: 'LIFESTYLE_AND_CULTURE',
            value: 'Lifestyle and culture',
            _id: '6582d5a42d0ed71a708dcf25',
          },
          {
            key: 'REALITY_TV',
            value: 'Reality TV',
            _id: '6582d5a42d0ed71a708dcf26',
          },
          {
            key: 'SCIENCE_AND_NATURE_TV',
            value: 'Science and Nature TV',
            _id: '6582d5a42d0ed71a708dcf27',
          },
          {
            key: 'SOAPY',
            value: 'Soapy',
            _id: '6582d5a42d0ed71a708dcf28',
          },
          {
            key: 'STAND-UP_COMEDY',
            value: 'Stand-up Comedy',
            _id: '6582d5a42d0ed71a708dcf29',
          },
          {
            key: 'TALK_SHOW',
            value: 'Talk Show',
            _id: '6582d5a42d0ed71a708dcf2a',
          },
          {
            key: 'WAR/MILITARY',
            value: 'War/Military',
            _id: '6582d5a42d0ed71a708dcf2b',
          },
          {
            key: 'SHORT_FILM',
            value: 'Short Film',
            _id: '6582d5a42d0ed71a708dcf2c',
          },
          {
            key: 'INTERNATIONAL',
            value: 'International',
            _id: '6582d5a42d0ed71a708dcf2d',
          },
          {
            key: 'ORIGINAL',
            value: 'Original',
            _id: '6582d5a42d0ed71a708dcf2e',
          },
          {
            key: 'EXCLUSIVE',
            value: 'Exclusive',
            _id: '6582d5a42d0ed71a708dcf2f',
          },
        ],
        createdBy: '653a3a3ed3f6eaf4a9c88f0f',
        updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
        status: 'ACTIVE',
        __v: 0,
        createdAt: '2023-12-20T11:53:09.099Z',
        updatedAt: '2023-12-20T11:53:09.099Z',
      },
      {
        _id: '6582d5a42d0ed71a708dcf30',
        configKey: 'THEME',
        configValue: [
          {
            key: 'NINTIES',
            value: 'Ninties',
            _id: '6582d5a42d0ed71a708dcf31',
          },
          {
            key: 'FIFTIES_-_EIGHTIES',
            value: 'Fifties - Eighties',
            _id: '6582d5a42d0ed71a708dcf32',
          },
          {
            key: 'MILLENIUM_(2000-2010)',
            value: 'Millenium (2000-2010)',
            _id: '6582d5a42d0ed71a708dcf33',
          },
          {
            key: 'MILLENIUM_2_(2011-2015)',
            value: 'Millenium 2 (2011-2015)',
            _id: '6582d5a42d0ed71a708dcf34',
          },
          {
            key: 'LATEST_(2016-TILL_DATE)',
            value: 'Latest (2016-till date)',
            _id: '6582d5a42d0ed71a708dcf35',
          },
          {
            key: 'RETRO_(UP_TO_2000)',
            value: 'Retro (Up to 2000)',
            _id: '6582d5a42d0ed71a708dcf36',
          },
          {
            key: 'MILLENIUM_(2000-2015)',
            value: 'Millenium (2000-2015)',
            _id: '6582d5a42d0ed71a708dcf37',
          },
        ],
        createdBy: '653a3a3ed3f6eaf4a9c88f0f',
        updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
        status: 'ACTIVE',
        __v: 0,
        createdAt: '2023-12-20T11:53:09.100Z',
        updatedAt: '2023-12-20T11:53:09.100Z',
      },
    ],
    error: null,
  },
}

export const ASSET_TYPE_DEFINITION_DATA_ERROR = {
  error: {
    message: 'Content Details Fetched Successfully',
    responseCode: 400,
  },
}

export const ASSET_TYPE_DEFINITION_CREATE_ASSET = {
  _id: '659cc2ec593da03d42d85b11',
  version: '11',
  assetType: 'Season',
  assetCategory: 'BUNDLE',
  contentCategory: 'TV_SHOW',
  tollGate: {
    DESCRIPTIVE_METADATA: null,
    RIGHTS_AND_AVAILABILITY: null,
    SEO_METADATA: null,
    ADVERTISEMENT_METADATA: null,
    BUSINESS_RULES: null,
    TECHNICAL_METADATA: null,
    CONTENT_MEDIA_IMAGE: null,
    CONTENT_MEDIA_VIDEO: null,
    TRANSLATIONS: null,
    SPORTS_METADATA: null,
    MISCELLANEOUS_METADATA: null,
    QC_PROCESS: null,
    PUBLISHING: null,
    RIGHTS_EXPIRED: null,
    DEPUBLISH: null,
  },
  metaCategories: [
    'ADVERTISEMENT_METADATA',
    'BUSINESS_RULES',
    'RIGHTS_AND_AVAILABILITY',
    'SEO_METADATA',
    'TECHNICAL_METADATA',
    'CONTENT_MEDIA_IMAGE',
    'CONTENT_MEDIA_VIDEO',
    'DESCRIPTIVE_METADATA',
    'SPORTS_METADATA',
    'MISCELLANEOUS_METADATA',
  ],
  allowedParents: [],
  isStandAlonePublishing: false,
  attributes: [
    {
      metaCategoryKey: 'ADVERTISEMENT_METADATA',
      metaAttributes: [
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'advertising',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Advertising',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Advertising',
          description: 'to define whether the content is ad supported or not',
          master: '',
          options: ['AD SUPPORTED', 'Free'],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'advertising',
          _id: '659cc2ec593da03d42d85b13',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'svodAdvertising',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'SVOD Advertising',
          isB2B: false,
          defaultValue: 'false',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'SVOD Advertising',
          description: 'to show ad to svod user',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'svod_advertising',
          _id: '659cc2ec593da03d42d85b14',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'sponsorContent',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Sponsor Content ',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Sponsor Content ',
          description: 'Flag to show whether the asset is sponsored or not',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'sponsor_content',
          _id: '659cc2ec593da03d42d85b15',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'stickyAdsFlag',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Sticky Ads Flag',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'StickyAdsFlag',
          description: 'Flag to enable/disable sticky ads over an asset',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'StickyAdsFlag',
          _id: '659cc2ec593da03d42d85b16',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'adClusterId',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Ad cluster id',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Ad cluster id',
          description:
            'New mapping to serve ads on subscription packs, every content will be mapped ',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'ad_cluster_id',
          _id: '659cc2ec593da03d42d85b17',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'adTags',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Ad Tag',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Ad Tag',
          description: 'Put Ad tags for Ad targeting ',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'ad_tags',
          _id: '659cc2ec593da03d42d85b18',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'svodIsSponsorAdAvailable',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'SVOD Is Sponsor Ad Available',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'SVOD Is Sponsor Ad Available',
          description: 'similar to AVOD field: isSponsorAdAvailable',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'svod_isSponsorAdAvailable',
          _id: '659cc2ec593da03d42d85b19',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
      ],
      _id: '659cc2ec593da03d42d85b12',
    },
    {
      metaCategoryKey: 'BUSINESS_RULES',
      metaAttributes: [
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'value',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Entitlement',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'e.g. AVOD|SVOD',
          description: 'No info available/ Values are to define Free or SVOD content',
          master: 'SystemConfigurations/ENTITLEMENT',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'value',
          _id: '659cc2ec593da03d42d85b1b',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'packageId',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Package ID',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'PackageId',
          description: 'defines content availability towards subscription pan',
          master: 'EntitlementPackages',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'packageid',
          _id: '659cc2ec593da03d42d85b1c',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'SVODCountries',
          isRequired: true,
          isMultiple: true,
          addMore: false,
          label: 'SVOD Countries',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'SVOD Countries',
          description: 'Country list in which the asset is considered Premium',
          master: 'Countries',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'SVOD_countries',
          _id: '659cc2ec593da03d42d85b1d',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'partnerHoldbackList',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Partner Holdback List',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Partner Holdback List',
          description:
            'List of partners to be selected to block the asset on that particular partner catalogue',
          master: 'AffiliatePartner',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'partnerHoldbackList',
          _id: '659cc2ec593da03d42d85b1e',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'type',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Type',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Type',
          description: 'Type fiels possible value is VOD|BUNDLE',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'type',
          _id: '659cc2ec593da03d42d85b1f',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
      ],
      _id: '659cc2ec593da03d42d85b1a',
    },
    {
      metaCategoryKey: 'RIGHTS_AND_AVAILABILITY',
      metaAttributes: [
        {
          fieldType: 'DATETIME',
          dataType: 'DATE',
          fieldName: 'contractStartDate',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Availability Start Time',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Availability Start Time',
          description: 'Actual start time of the asset on the platform, This is a rights data.',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'contractStartDate',
          _id: '659cc2ec593da03d42d85b21',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'DATETIME',
          dataType: 'DATE',
          fieldName: 'contractEndDate',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Availability End Time',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Availability end time',
          description: 'Actual end time of the asset on the platform, This is a rights data',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'contractEndDate',
          _id: '659cc2ec593da03d42d85b22',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'DATETIME',
          dataType: 'DATE',
          fieldName: 'originalAirDate',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Original Air Date',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Original Air Date',
          description: 'Release Date of the content ( in theater/ OTT)',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'originalAirDate',
          _id: '659cc2ec593da03d42d85b23',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'pcVodLabel',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Parental Rating',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Parental Rating',
          description: 'Parental Rating',
          master: 'SystemConfigurations/RATING',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'pcVodLabel',
          _id: '659cc2ec593da03d42d85b24',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'isEncrypted',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Encrypted',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Encrypted',
          description: 'To define whether the content is encrypted or not-encrypted',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'isEncrypted',
          _id: '659cc2ec593da03d42d85b25',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'properties',
          isRequired: true,
          isMultiple: true,
          addMore: false,
          label: 'Allowed Countries',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Allowed Countries',
          description: 'List of countries where content can be consumed',
          master: 'Countries',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'properties',
          _id: '659cc2ec593da03d42d85b26',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'snpTags',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'SNP Tags',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'SnP_tags',
          description: 'These are tags provided by SnP as part of OTFM',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'snp_tags',
          _id: '659cc2ec593da03d42d85b27',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'subLicense',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Is SubLicenceable',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Is SubLicenceable',
          description: 'Is Content Sublicense-able',
          master: '',
          options: ['None', 'Yes', 'No'],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'sub_license',
          _id: '659cc2ec593da03d42d85b28',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'contentProvider',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Content Provider',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Content Provider',
          description: 'The content provider name of the asset',
          master: 'SystemConfigurations/CONTENT_PROVIDER',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'contentProvider',
          _id: '659cc2ec593da03d42d85b29',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'ratingForCountry',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Rating For Country ',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Rating',
          description: 'Rating for Selected Country for country_rating field',
          master: 'SystemConfigurations/RATING',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'rating_for_country',
          _id: '659cc2ec593da03d42d85b2a',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
          additionalProperties: {},
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'countryForRating',
          isRequired: true,
          isMultiple: true,
          addMore: false,
          label: 'Country for CountryRating Field',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Country',
          description: 'Country for Rating Field, country_rating field depended field',
          master: 'Countries',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'country_for_rating',
          _id: '659cc2ec593da03d42d85b2b',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
          additionalProperties: {},
        },
        {
          fieldType: 'COMPLEXTYPE',
          dataType: 'JSON',
          fieldName: 'countryRating',
          isRequired: true,
          isMultiple: true,
          addMore: true,
          label: 'Country Rating',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Country Rating',
          description:
            'Age rating of the asset country-wise, It is different from parental rating. ',
          master: '',
          options: [],
          validations: {},
          attributeRef: [
            {
              fieldName: 'ratingForCountry',
              sortOrder: 0,
              _id: '659cc2ec593da03d42d85b2d',
            },
            {
              fieldName: 'countryForRating',
              sortOrder: 1,
              _id: '659cc2ec593da03d42d85b2e',
            },
          ],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'country_rating',
          _id: '659cc2ec593da03d42d85b2c',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'DATETIME',
          dataType: 'DATE',
          fieldName: 'lastBroadcastDate',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Last Broadcasted Date',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Last Broadcasted Date',
          description:
            'THis lastbraodacast date is being sent to GA and used for reminder shows,add to watchlist event,shar',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'lastBroadcastDate',
          _id: '659cc2ec593da03d42d85b2f',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'BOOLEAN',
          fieldName: 'distribution',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Distribution',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Distribution',
          description: '12k assets are tagged with yes value, Related to Licensing',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'distribution',
          _id: '659cc2ec593da03d42d85b30',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'countryEntitlementSwitch',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Country Entitlement Switch',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Country Entitlement Switch',
          description: 'Country Entitlement Switch required',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'country_entitlement_switch',
          _id: '659cc2ec593da03d42d85b31',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'applicableGeoPolicy',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Applicable Geo Policy',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Applicable geo-policy',
          description: 'Applicable geo-policy required for geo policy',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'applicable_geo_policy',
          _id: '659cc2ec593da03d42d85b32',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'blockedCountries',
          isRequired: true,
          isMultiple: true,
          addMore: false,
          label: 'Blocked Countries',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Blocked Countries',
          description: 'blocked countries required where content is blocked',
          master: 'Countries',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          _id: '659cc2ec593da03d42d85b33',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
      ],
      _id: '659cc2ec593da03d42d85b20',
    },
    {
      metaCategoryKey: 'SEO_METADATA',
      metaAttributes: [
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'seoDescription',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'SEO Description',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'SEO Description',
          description: 'Used for SEO',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'seo_description',
          _id: '659cc2ec593da03d42d85b35',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'seoTags',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'SEO Tags',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'SEO Tags',
          description: 'Used for SEO',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'seo_tags',
          _id: '659cc2ec593da03d42d85b36',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'seoTitle',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'SEO Title',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'SEO Title',
          description: 'Used for SEO',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'seo_title',
          _id: '659cc2ec593da03d42d85b37',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'ifEnableImdbRating',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'IfEnableIMDbrating',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'If Enable IMD brating',
          description: 'Flag to enable disable IMDB rating over an asset',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'ifEnableIMDbrating',
          _id: '659cc2ec593da03d42d85b38',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'isMasterDefault',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'IsMasterDefault',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Is Master Default',
          description:
            'value should be set to Y for the master asset and N for the other language assets by Publishing team',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'isMasterDefault',
          _id: '659cc2ec593da03d42d85b39',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'seoCanonicalUrlTags',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Seo canonical url tags',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Seo canonical url tags',
          description:
            'When two pages are created for an asset, this field is used to redirect the SEO traffic to 1 page',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'seo_canonical_url_tags',
          _id: '659cc2ec593da03d42d85b3a',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'isPublishToWatchFeed',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Is Publish To Watch Feed',
          isB2B: false,
          defaultValue: 'false',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'is Publish To Watch Feed',
          description:
            ' This attribute identifies the master (original language) asset.Tagging the original language asset.',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'isPublishToWatchFeed',
          _id: '659cc2ec593da03d42d85b3b',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
      ],
      _id: '659cc2ec593da03d42d85b34',
    },
    {
      metaCategoryKey: 'TECHNICAL_METADATA',
      metaAttributes: [
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'isOnAir',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'On Air',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'On Air',
          description: 'This tag takes value Yes or No',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'isOnAir',
          _id: '659cc2ec593da03d42d85b3d',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'mpmId',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'MPM ID',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'MPM ID',
          description: 'S4Hana unique Id for an asset, related to rightsline/BF',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'mpm_id',
          _id: '659cc2ec593da03d42d85b3e',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'NUMBER',
          dataType: 'NUMBER',
          fieldName: 'contentId',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Content ID',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Content ID ',
          description: 'This is the actual id of the asset',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'contentId',
          _id: '659cc2ec593da03d42d85b3f',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'platformName',
          isRequired: true,
          isMultiple: true,
          addMore: false,
          label: 'Active Platforms',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Active Platforms',
          description: 'To define all the platforms',
          master: 'DownstreamPlatforms',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'platformName',
          _id: '659cc2ec593da03d42d85b40',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'segmentId',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Segment ID',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Segment ID',
          description: 'Segments that are created in firebase added to the asset',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'segment_id',
          _id: '659cc2ec593da03d42d85b41',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'checksum',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Checksum',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'checksum',
          description: 'checksum',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          _id: '659cc2ec593da03d42d85b42',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
      ],
      _id: '659cc2ec593da03d42d85b3c',
    },
    {
      metaCategoryKey: 'CONTENT_MEDIA_IMAGE',
      metaAttributes: [
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'landscapeThumb',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Landscape Thumb',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Landscape Thumb',
          description: 'This is an image attribute for landscape thumbnail',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'landscape_thumb',
          _id: '659cc2ec593da03d42d85b44',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'tvBackgroundImage',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'TV Background Image',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'TV Background Image',
          description: 'This is an image attribute for BG image on TV',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'tv_background_image',
          _id: '659cc2ec593da03d42d85b45',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadBackground',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Background',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead Background',
          description: 'This is an image attribute for spotlight BG',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_background',
          _id: '659cc2ec593da03d42d85b46',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadBackgroundMobile',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Background Mobile',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead Background Mobile',
          description: 'This is an image attribute for spotlight BG Mobile',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_background_mobile',
          _id: '659cc2ec593da03d42d85b47',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadForeground',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Foreground',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead Foreground',
          description: 'This is an image attribute for spotlight FG',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_foreground',
          _id: '659cc2ec593da03d42d85b48',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadForegroundMobile',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Foreground Mobile',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead Foreground Mobile',
          description: 'This is an image attribute for spotlight FG Mobile',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_foreground_mobile',
          _id: '659cc2ec593da03d42d85b49',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadLogo',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Logo',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead Logo',
          description: 'This is an image attribute for logo of an asset',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_logo',
          _id: '659cc2ec593da03d42d85b4a',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'circularImage',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Circular Image',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Circular Image',
          description:
            'This is an image attribute for circular images, but it is same as square_thumb image.',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'circular_image',
          _id: '659cc2ec593da03d42d85b4b',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'portraitThumb',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Portrait Thumb',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Portrait Thumb',
          description: 'This is an image attribute for portrait thumbnail',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'portrait_thumb',
          _id: '659cc2ec593da03d42d85b4c',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'squareThumb',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Square Thumb',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Square Thumb',
          description: 'This is an image attribute for square thumbnail',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'square_thumb',
          _id: '659cc2ec593da03d42d85b4d',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'detailCoverBg',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Detail Cover BG',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Detail Cover BG',
          description: 'This is an image attribute for detail cover BG image',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'detail_cover_bg',
          _id: '659cc2ec593da03d42d85b4e',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'detailCoverBgMobile',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Detail Cover BG - Mobile',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Detail Cover BG - Mobile',
          description: 'This is an image attribute for detail cover BG image on mobile',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'detail_cover_bg_mobile',
          _id: '659cc2ec593da03d42d85b4f',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'detailCoverBgV2',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Detail Cover BG V2',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Detail Cover BG -V2',
          description: 'This is an image attribute for new detail page',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'detail_cover_bg_V2',
          _id: '659cc2ec593da03d42d85b50',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'detailCoverBgTvV2',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Detail Cover BG TV V2',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Detail Cover BG TV V2',
          description: 'This is an image attribute for new detail page',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'detail_cover_bg_tv_v2',
          _id: '659cc2ec593da03d42d85b51',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'detailCoverBgMobileV2',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Detail Cover BG Mobile v2',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Detail Cover BG Mobile v2',
          description: 'This is an image attribute for new detail page',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'detail_cover_bg_mobile_v2',
          _id: '659cc2ec593da03d42d85b52',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'detailsLogoImage',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Details Logo Image',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Details_logo_image',
          description: 'This is an image attribute for logo',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'details_logo_image',
          _id: '659cc2ec593da03d42d85b53',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadLogoSmall',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Logo Small',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead_logo_small',
          description: 'This is an image attribute for logo',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_logo_small',
          _id: '659cc2ec593da03d42d85b54',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadLogoLarge',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Logo Large',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead_logo_large',
          description: 'This is an image attribute for logo',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_logo_large',
          _id: '659cc2ec593da03d42d85b55',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadMobileV2',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Mobile V2',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead_mobile_v2',
          description: 'This is a image attribute ',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_mobile_v2',
          _id: '659cc2ec593da03d42d85b56',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadLargeV2',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Large V2',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead_large_v2',
          description: 'This is a image attribute ',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_large_v2',
          _id: '659cc2ec593da03d42d85b57',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadLogoRectangualar',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Logo Rectangualar',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead_logo_rectangualar',
          description: 'This is an image attribute for spotlight rectangular logo',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_logo_rectangualar',
          _id: '659cc2ec593da03d42d85b58',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadMobileV1',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Mobile V1',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead_mobile_v1',
          description:
            'This is an image attribute for New Merged Spotlight foreground and background image for mobile',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_mobile_v1',
          _id: '659cc2ec593da03d42d85b59',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadWebV1',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Web V1',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead_web_v1',
          description:
            'This is an image attribute for New Merged Spotlight foreground and background image for web',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_web_v1',
          _id: '659cc2ec593da03d42d85b5a',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'mastheadTabV1',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Masthead Tab v1',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Masthead_tab_v1',
          description:
            'Image attribute for New Merged Spotlight foreground and background image for iPad & Android',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'masthead_tab_v1',
          _id: '659cc2ec593da03d42d85b5b',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'image1920x1920Clean',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Image 1920x1920 Clean',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Image_1920x1920_clean',
          description: 'This was created to support airtel b2b artwork criteria',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'image_1920x1920_clean',
          _id: '659cc2ec593da03d42d85b5c',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'image1920x548Clean',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Image 1920x548 Clean',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Image_1920x548_clean',
          description: 'This was created to support airtel b2b artwork criteria',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'image_1920x548_clean',
          _id: '659cc2ec593da03d42d85b5d',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'image1920x1080Clean',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Image 1920x1080 Clean',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Image_1920x1080_clean',
          description: 'This was created to support airtel b2b artwork criteria',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'image_1920x1080_clean',
          _id: '659cc2ec593da03d42d85b5e',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'image600x900Clean',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Image 600x900 clean',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Image_600x900_clean',
          description: 'This was created to support airtel b2b artwork criteria',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'image_600x900_clean',
          _id: '659cc2ec593da03d42d85b5f',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'poster',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Poster',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Poster',
          description: 'Option to upload image as poster',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'poster',
          _id: '659cc2ec593da03d42d85b60',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'STRING',
          fieldName: 'assetLandscapeImage',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Asset Landscape Image',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'asset_landscape_image',
          description: 'asset_landscape_image is image attribute',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'asset_landscape_image',
          _id: '659cc2ec593da03d42d85b61',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'FILE',
          dataType: 'IMAGE_TYPE',
          fieldName: 'thumbnail',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Thumbnail',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Thumbnail',
          description: 'Thumbnail is image attribute',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'thumbnail',
          _id: '659cc2ec593da03d42d85b62',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
      ],
      _id: '659cc2ec593da03d42d85b43',
    },
    {
      metaCategoryKey: 'CONTENT_MEDIA_VIDEO',
      metaAttributes: [],
      _id: '659cc2ec593da03d42d85b63',
    },
    {
      metaCategoryKey: 'DESCRIPTIVE_METADATA',
      metaAttributes: [
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'assetTitle',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Asset Title',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Enter Asset Title e.g Scam 2003: The Telgi Story',
          description: 'Title for GOB/Episode/Season',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'title',
          _id: '659cc2ec593da03d42d85b65',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'NUMBER',
          dataType: 'NUMBER',
          fieldName: 'season',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Season Number',
          isB2B: false,
          defaultValue: '0',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Enter Season Number',
          description: 'Season number of a show',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'season',
          _id: '659cc2ec593da03d42d85b66',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'defaultLang',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Default Language',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Default Language',
          description:
            'This is the default language of the content and not the Language of Production of the content',
          master: 'Languages',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'defaultLang',
          _id: '659cc2ec593da03d42d85b67',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'genres',
          isRequired: true,
          isMultiple: true,
          addMore: false,
          label: 'Genre',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Genre',
          description: 'To which genre the content belongs to',
          master: 'SystemConfigurations/GENRE',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'genres',
          _id: '659cc2ec593da03d42d85b68',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'broadcastChannel',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Broadcast Channel',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Broadcast Channel',
          description: 'The channel to which the asset belongs to like Set Sab',
          master: 'SystemConfigurations/BROADCAST_CHANNEL',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'broadcast_channel',
          _id: '659cc2ec593da03d42d85b69',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXTAREA',
          dataType: 'STRING',
          fieldName: 'searchKeywords',
          isRequired: false,
          isMultiple: true,
          addMore: false,
          label: 'Keywords',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Keywords',
          description: 'Used for searchable attributes, tray tags and ad tags for targeting ',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'searchKeywords',
          _id: '659cc2ec593da03d42d85b6a',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'longDescription',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Long Summary',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Long Summary',
          description: 'Long description of the content',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'longDescription',
          _id: '659cc2ec593da03d42d85b6b',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'shortDescription',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Short Summary',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Short Summary',
          description: 'Short description of the content',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'shortDescription',
          _id: '659cc2ec593da03d42d85b6c',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'castRole',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Cast Role',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Cast Role',
          description: 'Cast Role',
          master: '',
          options: ['Cast', 'Director', 'Producer', 'Director', 'Author'],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'castRole',
          _id: '659cc2ec593da03d42d85b6d',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
          additionalProperties: {},
        },
        {
          fieldType: 'TEXTAREA',
          dataType: 'STRING',
          fieldName: 'actualName',
          isRequired: false,
          isMultiple: true,
          addMore: false,
          label: 'Actual Name',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Actual Name',
          description: 'Actual Name of the Selected Cast',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'actualName',
          _id: '659cc2ec593da03d42d85b6e',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
          additionalProperties: {},
        },
        {
          fieldType: 'COMPLEXTYPE',
          dataType: 'JSON',
          fieldName: 'castAndCrew',
          isRequired: true,
          isMultiple: true,
          addMore: true,
          label: 'Cast & Crew',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Cast & Crew',
          description: 'Cast & Crew',
          master: '',
          options: [],
          validations: {},
          attributeRef: [
            {
              fieldName: 'castRole',
              sortOrder: 0,
              _id: '659cc2ec593da03d42d85b70',
            },
            {
              fieldName: 'actualName',
              sortOrder: 1,
              _id: '659cc2ec593da03d42d85b71',
            },
          ],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'cast_and_crew',
          _id: '659cc2ec593da03d42d85b6f',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'COMPLEXTYPE',
          dataType: 'JSON',
          fieldName: 'castAndCrew1',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Cast & Crew',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Cast & Crew',
          description: 'Cast & Crew',
          master: '',
          options: [],
          validations: {},
          attributeRef: [
            {
              fieldName: 'castRole',
              sortOrder: 0,
              _id: '659cc2ec593da03d42d85b70',
            },
            {
              fieldName: 'actualName',
              sortOrder: 1,
              _id: '659cc2ec593da03d42d85b71',
            },
          ],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'cast_and_crew',
          _id: '659cc2ec593da03d42d85b6f',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'COMPLEXTYPE',
          dataType: 'JSON',
          fieldName: 'castAndCrew2',
          isRequired: true,
          isMultiple: false,
          addMore: true,
          label: 'Cast & Crew',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Cast & Crew',
          description: 'Cast & Crew',
          master: '',
          options: [],
          validations: {},
          attributeRef: [
            {
              fieldName: 'castRole',
              sortOrder: 0,
              _id: '659cc2ec593da03d42d85b70',
            },
            {
              fieldName: 'actualName',
              sortOrder: 1,
              _id: '659cc2ec593da03d42d85b71',
            },
          ],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'cast_and_crew',
          _id: '659cc2ec593da03d42d85b6f',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'countriesOfOrigin',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Country of Origin',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Country of Origin',
          description: 'Country where content was created originally',
          master: 'Countries',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'countriesOfOrigin',
          _id: '659cc2ec593da03d42d85b72',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'mood',
          isRequired: false,
          isMultiple: true,
          addMore: false,
          label: 'Mood',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Mood',
          description: 'To which mood the content belongs to',
          master: 'SystemConfigurations/MOOD',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'mood',
          _id: '659cc2ec593da03d42d85b73',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'subGenre',
          isRequired: false,
          isMultiple: true,
          addMore: false,
          label: 'Sub Genre',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Sub-genre',
          description: 'To which sub-genre the content belongs to',
          master: 'SystemConfigurations/SUB_GENRE',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'sub_genre',
          _id: '659cc2ec593da03d42d85b74',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'theme',
          isRequired: false,
          isMultiple: true,
          addMore: false,
          label: 'Theme',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Theme',
          description: 'To which theme the content belongs to',
          master: 'SystemConfigurations/THEME',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'theme',
          _id: '659cc2ec593da03d42d85b75',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'regionOfOrigin',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Language of Production',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Language of Production',
          description:
            'This is the original language of the content and not the default language of the asset',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'region_of_origin',
          _id: '659cc2ec593da03d42d85b76',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'subCategory',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Content Sub Category',
          isB2B: false,
          defaultValue: 'None',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Content Sub Category',
          description: 'To define category - acquired, originals, live , events',
          master: 'SystemConfigurations/CONTENT_SUB_CATEGORY',
          options: [
            'Acquired Movie',
            ' Acquired Show',
            ' Event',
            ' LIV Exclusive Movie',
            ' LIV Original',
            ' Sport Show',
            ' Sport',
            ' TV Show',
          ],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'sub_category',
          _id: '659cc2ec593da03d42d85b77',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'audioLanguages',
          isRequired: true,
          isMultiple: true,
          addMore: false,
          label: 'Available Audio Languages',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Available Audio Languages',
          description:
            'Different audio_languages(ENG, TAM, HIN etc) that are available over the asset',
          master: 'Languages',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'audio_languages',
          _id: '659cc2ec593da03d42d85b78',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'highlightText',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Highlight Text',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Highlight Text',
          description:
            'A text used on upcoming assets which will be in highlighted format on the FE (eg: Coming Soon) etc',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'highlight_text',
          _id: '659cc2ec593da03d42d85b79',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'playerName',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Player Name',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Player Name',
          description: 'To deine player playing the match',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'player_name',
          _id: '659cc2ec593da03d42d85b7a',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'IsSearchable',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Is Searchable',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Is_searchable',
          description: 'Flag to enable/disable an asset to be part of the search results',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'Is_searchable',
          _id: '659cc2ec593da03d42d85b7b',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'setting',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Setting',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Setting',
          description: 'Reco: to be used for set/theme descriptions',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'setting',
          _id: '659cc2ec593da03d42d85b7c',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'sonylivExclusive',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Sony Liv Exclusive',
          isB2B: false,
          defaultValue: 'false',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Sony Liv Exclusive',
          description:
            'Reco: to be used to identify whether content is exclusively available on Sonyliv',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'sonyliv_exclusive',
          _id: '659cc2ec593da03d42d85b7d',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'sonylivOriginal',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Sonyliv Original',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Sonyliv Original',
          description: 'Reco: to be used to identify whether content is an original or not',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'sonyliv_original',
          _id: '659cc2ec593da03d42d85b7e',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'era',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Era',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Era',
          description: 'Reco: to be used for era marking as an alternative to existing era',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'era',
          _id: '659cc2ec593da03d42d85b7f',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'oneLineDesc',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'One Line Desc',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'One Line Desc',
          description: 'Reco: to be used for pitching content to user',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'one_line_desc',
          _id: '659cc2ec593da03d42d85b80',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'oneLineIconUrl',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'One Line Icon URL',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'One Line Icon URL',
          description: 'Reco: icon to be shown next to one liner description',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'one_line_icon_url',
          _id: '659cc2ec593da03d42d85b81',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'releaseFrequency',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Release Frequency ',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Release Frequency ',
          description: 'Reco - to mark frequency for episodic release',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'release_frequency',
          _id: '659cc2ec593da03d42d85b82',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'DATETIME',
          dataType: 'DATE',
          fieldName: 'releaseDate',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Release date',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Release date',
          description: 'This is Upcoming content release date, used for google watch action',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'release_date',
          _id: '659cc2ec593da03d42d85b83',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'TitleBrief',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Title Brief',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Title Brief',
          description:
            'This is used to input the title of the sports event, this is for creative title.',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'TitleBrief',
          _id: '659cc2ec593da03d42d85b84',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'extraTitle',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Extra Title',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Extra Title',
          description: 'This is used for trailers as episodic title is not present there.',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'extra_title',
          _id: '659cc2ec593da03d42d85b85',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'kidsSafe',
          isRequired: true,
          isMultiple: true,
          addMore: false,
          label: 'kids safe',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'kids safe',
          description: 'country list in which the asset can be made as kids_safe.',
          master: 'Countries',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'kids_safe',
          _id: '659cc2ec593da03d42d85b86',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'trayCustomFilter',
          isRequired: true,
          isMultiple: false,
          addMore: false,
          label: 'Tray Custom Filter',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'tray_custom_filter',
          description: 'Used in Content tray selection & display conditions',
          master: '',
          options: ['Yes', 'No'],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'tray_custom_filter',
          _id: '659cc2ec593da03d42d85b87',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'isPlayTrailer',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Is play trailer',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Is play trailer',
          description: 'Flag to enable/disable the trailer autoplay over an asset',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'is_play_trailer',
          _id: '659cc2ec593da03d42d85b88',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'isAutoPlayTrailer',
          isRequired: true,
          isMultiple: true,
          addMore: false,
          label: 'Is Auto Play Trailer',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'is_auto_play_trailer',
          description: 'is_auto_play_trailer',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          _id: '659cc2ec593da03d42d85b89',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'isAutoRedirect',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Is auto redirect',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Is auto redirect',
          description:
            'Flag to enable/disable the trailer/promotion autoplay redirection over an asset',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'is_auto_redirect',
          _id: '659cc2ec593da03d42d85b8a',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'celebrityMetaData',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Celebrity metadata',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Celebrity metadata',
          description: 'Celebrity metadata like first name, age etc.',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {
            isJsonViewer: true,
          },
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'celebrity_meta_data',
          _id: '659cc2ec593da03d42d85b8b',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'celebrityCountry',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Celebrity country',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Celebrity country',
          description: 'Country of the celebrity',
          master: 'Countries',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'celebrity_country',
          _id: '659cc2ec593da03d42d85b8c',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'upcomingContentLanguages',
          isRequired: false,
          isMultiple: true,
          addMore: false,
          label: 'Upcoming Content Languages',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Upcoming Content Languages',
          description: 'Language list of the upcoming asset',
          master: 'Languages',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'upcoming_content_languages',
          _id: '659cc2ec593da03d42d85b8d',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'upcoming',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Upcoming',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Upcoming',
          description: 'Flag to show whether the asset is upcoming or not',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'upcoming',
          _id: '659cc2ec593da03d42d85b8e',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'IMDB',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'IMDB Rating',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'IMDB Rating',
          description: 'IMDB rating of an asset',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'IMDB',
          _id: '659cc2ec593da03d42d85b8f',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
      ],
      _id: '659cc2ec593da03d42d85b64',
    },
    {
      metaCategoryKey: 'SPORTS_METADATA',
      metaAttributes: [],
      _id: '659cc2ec593da03d42d85b90',
    },
    {
      metaCategoryKey: 'MISCELLANEOUS_METADATA',
      metaAttributes: [
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'enableCommunication',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Enable Communication',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Enable Communication',
          description: 'Yes or No value required to input in this field',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'enable_communication',
          _id: '659cc2ec593da03d42d85b92',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'communication',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Communication',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Communication',
          description: 'Some FE dependencies field needs to fill the details.',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'communication',
          _id: '659cc2ec593da03d42d85b93',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'customBands',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Custom Bands',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Custom Bands',
          description: 'Field for dependencies.',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'INACTIVE',
          esField: 'custom_bands',
          _id: '659cc2ec593da03d42d85b94',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'customAction',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Custom action',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Custom action',
          description:
            'To configure deep links over an asset, To define custom actions like redirection',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'custom_action',
          _id: '659cc2ec593da03d42d85b95',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'creatives',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Creatives',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Creatives',
          description:
            'List of images over the asset and its corresponding show. This is used in B2B',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'creatives',
          _id: '659cc2ec593da03d42d85b96',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'STRING',
          fieldName: 'contentCluster',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Content Cluster',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Content Cluster',
          description: 'to define cluster of the content: SPN or NON SPN',
          master: '',
          options: ['None', 'SPN', 'Non SPN'],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'content_cluster',
          _id: '659cc2ec593da03d42d85b97',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'freePreviewMultistreamTag',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'FreePreview multistream tag',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Freepreview multistream tag',
          description: 'to define free preview duration across asset collections',
          master: '',
          options: [''],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'freepreview_multistream_tag',
          _id: '659cc2ec593da03d42d85b98',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'TEXT',
          dataType: 'STRING',
          fieldName: 'upiPayload',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Upi payload',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Upi payload',
          description: 'Payload used by the Live Event(Used for KBC play-along)',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'upi_payload',
          _id: '659cc2ec593da03d42d85b99',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'IsTestAsset',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Is test asset',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Is test asset',
          description: 'To identify test asset',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'Is_test_asset',
          _id: '659cc2ec593da03d42d85b9a',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'shareSubtitleToPartner',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Share subtitle to partner',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Share subtitle to partner',
          description: 'Subtitle share with partner',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'share_subtitle_to_partner',
          _id: '659cc2ec593da03d42d85b9b',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
        {
          fieldType: 'SELECT',
          dataType: 'BOOLEAN',
          fieldName: 'integratedSplashAvailable',
          isRequired: false,
          isMultiple: false,
          addMore: false,
          label: 'Integrated splash available',
          isB2B: false,
          defaultValue: '',
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Integrated splash available',
          description: 'Integrated splash available',
          master: '',
          options: [],
          validations: {},
          attributeRef: [],
          sortOrder: 0,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {},
          metaCategory: 'NA',
          isMultilingual: false,
          viewInlisting: 'HIDDEN',
          status: 'ACTIVE',
          esField: 'integrated_splash_available',
          _id: '659cc2ec593da03d42d85b9c',
          createdAt: '2023-11-28T11:58:43.994Z',
          updatedAt: '2023-11-28T11:58:43.994Z',
        },
      ],
      _id: '659cc2ec593da03d42d85b91',
    },
  ],
  createdBy: {
    userEmail: 'ritu.arora@kellton.com',
  },
  updatedBy: {
    userEmail: 'ritu.arora@kellton.com',
  },
  status: 'ACTIVE',
  createdAt: '2023-11-28T11:58:43.994Z',
  updatedAt: '2023-11-28T11:58:43.994Z',
}

export const ASSET_TYPE_DEFINITION_DATA = {
  message: 'Asset type data fetched successfully!',
  responseCode: 200,
  data: {
    count: 1,
    records: [
      {
        _id: '659cc2ec593da03d42d85b11',
        version: '11',
        assetType: 'Season',
        assetCategory: 'BUNDLE',
        contentCategory: 'TV_SHOW',
        tollGate: {
          DESCRIPTIVE_METADATA: null,
          RIGHTS_AND_AVAILABILITY: null,
          SEO_METADATA: null,
          ADVERTISEMENT_METADATA: null,
          BUSINESS_RULES: null,
          TECHNICAL_METADATA: null,
          CONTENT_MEDIA_IMAGE: null,
          CONTENT_MEDIA_VIDEO: null,
          TRANSLATIONS: null,
          SPORTS_METADATA: null,
          MISCELLANEOUS_METADATA: null,
          QC_PROCESS: null,
          PUBLISHING: null,
          RIGHTS_EXPIRED: null,
          DEPUBLISH: null,
        },
        metaCategories: [
          'ADVERTISEMENT_METADATA',
          'BUSINESS_RULES',
          'RIGHTS_AND_AVAILABILITY',
          'SEO_METADATA',
          'TECHNICAL_METADATA',
          'CONTENT_MEDIA_IMAGE',
          'CONTENT_MEDIA_VIDEO',
          'DESCRIPTIVE_METADATA',
          'SPORTS_METADATA',
          'MISCELLANEOUS_METADATA',
        ],
        allowedParents: [],
        isStandAlonePublishing: false,
        attributes: [
          {
            metaCategory: 'ADVERTISEMENT_METADATA',
            metaAttributes: [
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'advertising',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Advertising',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Advertising',
                description: 'to define whether the content is ad supported or not',
                master: '',
                options: ['AD SUPPORTED', 'Free'],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'advertising',
                _id: '659cc2ec593da03d42d85b13',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'svodAdvertising',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'SVOD Advertising',
                isB2B: false,
                defaultValue: 'false',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'SVOD Advertising',
                description: 'to show ad to svod user',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'svod_advertising',
                _id: '659cc2ec593da03d42d85b14',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'sponsorContent',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Sponsor Content ',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Sponsor Content ',
                description: 'Flag to show whether the asset is sponsored or not',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'sponsor_content',
                _id: '659cc2ec593da03d42d85b15',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'stickyAdsFlag',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Sticky Ads Flag',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'StickyAdsFlag',
                description: 'Flag to enable/disable sticky ads over an asset',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'StickyAdsFlag',
                _id: '659cc2ec593da03d42d85b16',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'adClusterId',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Ad cluster id',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Ad cluster id',
                description:
                  'New mapping to serve ads on subscription packs, every content will be mapped ',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'ad_cluster_id',
                _id: '659cc2ec593da03d42d85b17',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'adTags',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Ad Tag',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Ad Tag',
                description: 'Put Ad tags for Ad targeting ',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'ad_tags',
                _id: '659cc2ec593da03d42d85b18',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'svodIsSponsorAdAvailable',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'SVOD Is Sponsor Ad Available',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'SVOD Is Sponsor Ad Available',
                description: 'similar to AVOD field: isSponsorAdAvailable',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'svod_isSponsorAdAvailable',
                _id: '659cc2ec593da03d42d85b19',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
            ],
            _id: '659cc2ec593da03d42d85b12',
          },
          {
            metaCategory: 'BUSINESS_RULES',
            metaAttributes: [
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'value',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Entitlement',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'e.g. AVOD|SVOD',
                description: 'No info available/ Values are to define Free or SVOD content',
                master: 'SystemConfigurations/ENTITLEMENT',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'value',
                _id: '659cc2ec593da03d42d85b1b',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'packageId',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Package ID',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'PackageId',
                description: 'defines content availability towards subscription pan',
                master: 'EntitlementPackages',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'packageid',
                _id: '659cc2ec593da03d42d85b1c',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'SVODCountries',
                isRequired: true,
                isMultiple: true,
                addMore: false,
                label: 'SVOD Countries',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'SVOD Countries',
                description: 'Country list in which the asset is considered Premium',
                master: 'Countries',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'SVOD_countries',
                _id: '659cc2ec593da03d42d85b1d',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'partnerHoldbackList',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Partner Holdback List',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Partner Holdback List',
                description:
                  'List of partners to be selected to block the asset on that particular partner catalogue',
                master: 'AffiliatePartner',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'partnerHoldbackList',
                _id: '659cc2ec593da03d42d85b1e',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'type',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Type',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Type',
                description: 'Type fiels possible value is VOD|BUNDLE',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'type',
                _id: '659cc2ec593da03d42d85b1f',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
            ],
            _id: '659cc2ec593da03d42d85b1a',
          },
          {
            metaCategory: 'RIGHTS_AND_AVAILABILITY',
            metaAttributes: [
              {
                fieldType: 'DATETIME',
                dataType: 'DATE',
                fieldName: 'contractStartDate',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Availability Start Time',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Availability Start Time',
                description:
                  'Actual start time of the asset on the platform, This is a rights data.',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'contractStartDate',
                _id: '659cc2ec593da03d42d85b21',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'DATETIME',
                dataType: 'DATE',
                fieldName: 'contractEndDate',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Availability End Time',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Availability end time',
                description: 'Actual end time of the asset on the platform, This is a rights data',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'contractEndDate',
                _id: '659cc2ec593da03d42d85b22',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'DATETIME',
                dataType: 'DATE',
                fieldName: 'originalAirDate',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Original Air Date',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Original Air Date',
                description: 'Release Date of the content ( in theater/ OTT)',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'originalAirDate',
                _id: '659cc2ec593da03d42d85b23',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'pcVodLabel',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Parental Rating',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Parental Rating',
                description: 'Parental Rating',
                master: 'SystemConfigurations/RATING',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'pcVodLabel',
                _id: '659cc2ec593da03d42d85b24',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'isEncrypted',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Encrypted',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Encrypted',
                description: 'To define whether the content is encrypted or not-encrypted',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'isEncrypted',
                _id: '659cc2ec593da03d42d85b25',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'properties',
                isRequired: true,
                isMultiple: true,
                addMore: false,
                label: 'Allowed Countries',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Allowed Countries',
                description: 'List of countries where content can be consumed',
                master: 'Countries',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'properties',
                _id: '659cc2ec593da03d42d85b26',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'snpTags',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'SNP Tags',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'SnP_tags',
                description: 'These are tags provided by SnP as part of OTFM',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'snp_tags',
                _id: '659cc2ec593da03d42d85b27',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'subLicense',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Is SubLicenceable',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Is SubLicenceable',
                description: 'Is Content Sublicense-able',
                master: '',
                options: ['None', 'Yes', 'No'],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'sub_license',
                _id: '659cc2ec593da03d42d85b28',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'contentProvider',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Content Provider',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Content Provider',
                description: 'The content provider name of the asset',
                master: 'SystemConfigurations/CONTENT_PROVIDER',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'contentProvider',
                _id: '659cc2ec593da03d42d85b29',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'ratingForCountry',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Rating For Country ',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Rating',
                description: 'Rating for Selected Country for country_rating field',
                master: 'SystemConfigurations/RATING',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'rating_for_country',
                _id: '659cc2ec593da03d42d85b2a',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
                additionalProperties: {},
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'countryForRating',
                isRequired: true,
                isMultiple: true,
                addMore: false,
                label: 'Country for CountryRating Field',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Country',
                description: 'Country for Rating Field, country_rating field depended field',
                master: 'Countries',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'country_for_rating',
                _id: '659cc2ec593da03d42d85b2b',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
                additionalProperties: {},
              },
              {
                fieldType: 'COMPLEXTYPE',
                dataType: 'JSON',
                fieldName: 'countryRating',
                isRequired: true,
                isMultiple: true,
                addMore: true,
                label: 'Country Rating',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Country Rating',
                description:
                  'Age rating of the asset country-wise, It is different from parental rating. ',
                master: '',
                options: [],
                validations: {},
                attributeRef: [
                  {
                    fieldName: 'ratingForCountry',
                    sortOrder: 0,
                    _id: '659cc2ec593da03d42d85b2d',
                  },
                  {
                    fieldName: 'countryForRating',
                    sortOrder: 1,
                    _id: '659cc2ec593da03d42d85b2e',
                  },
                ],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'country_rating',
                _id: '659cc2ec593da03d42d85b2c',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'DATETIME',
                dataType: 'DATE',
                fieldName: 'lastBroadcastDate',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Last Broadcasted Date',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Last Broadcasted Date',
                description:
                  'THis lastbraodacast date is being sent to GA and used for reminder shows,add to watchlist event,shar',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'lastBroadcastDate',
                _id: '659cc2ec593da03d42d85b2f',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'BOOLEAN',
                fieldName: 'distribution',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Distribution',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Distribution',
                description: '12k assets are tagged with yes value, Related to Licensing',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'distribution',
                _id: '659cc2ec593da03d42d85b30',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'countryEntitlementSwitch',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Country Entitlement Switch',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Country Entitlement Switch',
                description: 'Country Entitlement Switch required',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'country_entitlement_switch',
                _id: '659cc2ec593da03d42d85b31',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'applicableGeoPolicy',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Applicable Geo Policy',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Applicable geo-policy',
                description: 'Applicable geo-policy required for geo policy',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'applicable_geo_policy',
                _id: '659cc2ec593da03d42d85b32',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'blockedCountries',
                isRequired: true,
                isMultiple: true,
                addMore: false,
                label: 'Blocked Countries',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Blocked Countries',
                description: 'blocked countries required where content is blocked',
                master: 'Countries',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                _id: '659cc2ec593da03d42d85b33',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
            ],
            _id: '659cc2ec593da03d42d85b20',
          },
          {
            metaCategory: 'SEO_METADATA',
            metaAttributes: [
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'seoDescription',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'SEO Description',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'SEO Description',
                description: 'Used for SEO',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'seo_description',
                _id: '659cc2ec593da03d42d85b35',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'seoTags',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'SEO Tags',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'SEO Tags',
                description: 'Used for SEO',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'seo_tags',
                _id: '659cc2ec593da03d42d85b36',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'seoTitle',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'SEO Title',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'SEO Title',
                description: 'Used for SEO',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'seo_title',
                _id: '659cc2ec593da03d42d85b37',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'ifEnableImdbRating',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'IfEnableIMDbrating',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'If Enable IMD brating',
                description: 'Flag to enable disable IMDB rating over an asset',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'ifEnableIMDbrating',
                _id: '659cc2ec593da03d42d85b38',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'isMasterDefault',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'IsMasterDefault',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Is Master Default',
                description:
                  'value should be set to Y for the master asset and N for the other language assets by Publishing team',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'isMasterDefault',
                _id: '659cc2ec593da03d42d85b39',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'seoCanonicalUrlTags',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Seo canonical url tags',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Seo canonical url tags',
                description:
                  'When two pages are created for an asset, this field is used to redirect the SEO traffic to 1 page',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'seo_canonical_url_tags',
                _id: '659cc2ec593da03d42d85b3a',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'isPublishToWatchFeed',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Is Publish To Watch Feed',
                isB2B: false,
                defaultValue: 'false',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'is Publish To Watch Feed',
                description:
                  ' This attribute identifies the master (original language) asset.Tagging the original language asset.',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'isPublishToWatchFeed',
                _id: '659cc2ec593da03d42d85b3b',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
            ],
            _id: '659cc2ec593da03d42d85b34',
          },
          {
            metaCategory: 'TECHNICAL_METADATA',
            metaAttributes: [
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'isOnAir',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'On Air',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'On Air',
                description: 'This tag takes value Yes or No',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'isOnAir',
                _id: '659cc2ec593da03d42d85b3d',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'mpmId',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'MPM ID',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'MPM ID',
                description: 'S4Hana unique Id for an asset, related to rightsline/BF',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'mpm_id',
                _id: '659cc2ec593da03d42d85b3e',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'NUMBER',
                dataType: 'NUMBER',
                fieldName: 'contentId',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Content ID',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Content ID ',
                description: 'This is the actual id of the asset',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'contentId',
                _id: '659cc2ec593da03d42d85b3f',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'platformName',
                isRequired: true,
                isMultiple: true,
                addMore: false,
                label: 'Active Platforms',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Active Platforms',
                description: 'To define all the platforms',
                master: 'DownstreamPlatforms',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'platformName',
                _id: '659cc2ec593da03d42d85b40',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'segmentId',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Segment ID',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Segment ID',
                description: 'Segments that are created in firebase added to the asset',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'segment_id',
                _id: '659cc2ec593da03d42d85b41',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'checksum',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Checksum',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'checksum',
                description: 'checksum',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                _id: '659cc2ec593da03d42d85b42',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
            ],
            _id: '659cc2ec593da03d42d85b3c',
          },
          {
            metaCategory: 'CONTENT_MEDIA_IMAGE',
            metaAttributes: [
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'landscapeThumb',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Landscape Thumb',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Landscape Thumb',
                description: 'This is an image attribute for landscape thumbnail',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'landscape_thumb',
                _id: '659cc2ec593da03d42d85b44',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'tvBackgroundImage',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'TV Background Image',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'TV Background Image',
                description: 'This is an image attribute for BG image on TV',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'tv_background_image',
                _id: '659cc2ec593da03d42d85b45',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadBackground',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Background',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead Background',
                description: 'This is an image attribute for spotlight BG',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_background',
                _id: '659cc2ec593da03d42d85b46',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadBackgroundMobile',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Background Mobile',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead Background Mobile',
                description: 'This is an image attribute for spotlight BG Mobile',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_background_mobile',
                _id: '659cc2ec593da03d42d85b47',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadForeground',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Foreground',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead Foreground',
                description: 'This is an image attribute for spotlight FG',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_foreground',
                _id: '659cc2ec593da03d42d85b48',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadForegroundMobile',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Foreground Mobile',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead Foreground Mobile',
                description: 'This is an image attribute for spotlight FG Mobile',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_foreground_mobile',
                _id: '659cc2ec593da03d42d85b49',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadLogo',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Logo',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead Logo',
                description: 'This is an image attribute for logo of an asset',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_logo',
                _id: '659cc2ec593da03d42d85b4a',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'circularImage',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Circular Image',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Circular Image',
                description:
                  'This is an image attribute for circular images, but it is same as square_thumb image.',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'circular_image',
                _id: '659cc2ec593da03d42d85b4b',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'portraitThumb',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Portrait Thumb',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Portrait Thumb',
                description: 'This is an image attribute for portrait thumbnail',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'portrait_thumb',
                _id: '659cc2ec593da03d42d85b4c',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'squareThumb',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Square Thumb',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Square Thumb',
                description: 'This is an image attribute for square thumbnail',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'square_thumb',
                _id: '659cc2ec593da03d42d85b4d',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'detailCoverBg',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Detail Cover BG',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Detail Cover BG',
                description: 'This is an image attribute for detail cover BG image',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'detail_cover_bg',
                _id: '659cc2ec593da03d42d85b4e',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'detailCoverBgMobile',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Detail Cover BG - Mobile',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Detail Cover BG - Mobile',
                description: 'This is an image attribute for detail cover BG image on mobile',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'detail_cover_bg_mobile',
                _id: '659cc2ec593da03d42d85b4f',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'detailCoverBgV2',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Detail Cover BG V2',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Detail Cover BG -V2',
                description: 'This is an image attribute for new detail page',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'detail_cover_bg_V2',
                _id: '659cc2ec593da03d42d85b50',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'detailCoverBgTvV2',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Detail Cover BG TV V2',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Detail Cover BG TV V2',
                description: 'This is an image attribute for new detail page',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'detail_cover_bg_tv_v2',
                _id: '659cc2ec593da03d42d85b51',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'detailCoverBgMobileV2',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Detail Cover BG Mobile v2',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Detail Cover BG Mobile v2',
                description: 'This is an image attribute for new detail page',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'detail_cover_bg_mobile_v2',
                _id: '659cc2ec593da03d42d85b52',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'detailsLogoImage',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Details Logo Image',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Details_logo_image',
                description: 'This is an image attribute for logo',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'details_logo_image',
                _id: '659cc2ec593da03d42d85b53',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadLogoSmall',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Logo Small',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead_logo_small',
                description: 'This is an image attribute for logo',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_logo_small',
                _id: '659cc2ec593da03d42d85b54',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadLogoLarge',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Logo Large',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead_logo_large',
                description: 'This is an image attribute for logo',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_logo_large',
                _id: '659cc2ec593da03d42d85b55',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadMobileV2',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Mobile V2',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead_mobile_v2',
                description: 'This is a image attribute ',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_mobile_v2',
                _id: '659cc2ec593da03d42d85b56',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadLargeV2',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Large V2',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead_large_v2',
                description: 'This is a image attribute ',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_large_v2',
                _id: '659cc2ec593da03d42d85b57',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadLogoRectangualar',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Logo Rectangualar',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead_logo_rectangualar',
                description: 'This is an image attribute for spotlight rectangular logo',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_logo_rectangualar',
                _id: '659cc2ec593da03d42d85b58',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadMobileV1',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Mobile V1',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead_mobile_v1',
                description:
                  'This is an image attribute for New Merged Spotlight foreground and background image for mobile',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_mobile_v1',
                _id: '659cc2ec593da03d42d85b59',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadWebV1',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Web V1',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead_web_v1',
                description:
                  'This is an image attribute for New Merged Spotlight foreground and background image for web',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_web_v1',
                _id: '659cc2ec593da03d42d85b5a',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'mastheadTabV1',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Masthead Tab v1',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Masthead_tab_v1',
                description:
                  'Image attribute for New Merged Spotlight foreground and background image for iPad & Android',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'masthead_tab_v1',
                _id: '659cc2ec593da03d42d85b5b',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'image1920x1920Clean',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Image 1920x1920 Clean',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Image_1920x1920_clean',
                description: 'This was created to support airtel b2b artwork criteria',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'image_1920x1920_clean',
                _id: '659cc2ec593da03d42d85b5c',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'image1920x548Clean',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Image 1920x548 Clean',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Image_1920x548_clean',
                description: 'This was created to support airtel b2b artwork criteria',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'image_1920x548_clean',
                _id: '659cc2ec593da03d42d85b5d',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'image1920x1080Clean',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Image 1920x1080 Clean',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Image_1920x1080_clean',
                description: 'This was created to support airtel b2b artwork criteria',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'image_1920x1080_clean',
                _id: '659cc2ec593da03d42d85b5e',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'image600x900Clean',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Image 600x900 clean',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Image_600x900_clean',
                description: 'This was created to support airtel b2b artwork criteria',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'image_600x900_clean',
                _id: '659cc2ec593da03d42d85b5f',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'poster',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Poster',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Poster',
                description: 'Option to upload image as poster',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'poster',
                _id: '659cc2ec593da03d42d85b60',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'STRING',
                fieldName: 'assetLandscapeImage',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Asset Landscape Image',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'asset_landscape_image',
                description: 'asset_landscape_image is image attribute',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'asset_landscape_image',
                _id: '659cc2ec593da03d42d85b61',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'FILE',
                dataType: 'IMAGE_TYPE',
                fieldName: 'thumbnail',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Thumbnail',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Thumbnail',
                description: 'Thumbnail is image attribute',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'thumbnail',
                _id: '659cc2ec593da03d42d85b62',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
            ],
            _id: '659cc2ec593da03d42d85b43',
          },
          {
            metaCategory: 'CONTENT_MEDIA_VIDEO',
            metaAttributes: [],
            _id: '659cc2ec593da03d42d85b63',
          },
          {
            metaCategory: 'DESCRIPTIVE_METADATA',
            metaAttributes: [
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'assetTitle',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Asset Title',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Enter Asset Title e.g Scam 2003: The Telgi Story',
                description: 'Title for GOB/Episode/Season',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'title',
                _id: '659cc2ec593da03d42d85b65',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'NUMBER',
                dataType: 'NUMBER',
                fieldName: 'season',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Season Number',
                isB2B: false,
                defaultValue: '0',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Enter Season Number',
                description: 'Season number of a show',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'season',
                _id: '659cc2ec593da03d42d85b66',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'defaultLang',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Default Language',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Default Language',
                description:
                  'This is the default language of the content and not the Language of Production of the content',
                master: 'Languages',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'defaultLang',
                _id: '659cc2ec593da03d42d85b67',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'genres',
                isRequired: true,
                isMultiple: true,
                addMore: false,
                label: 'Genre',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Genre',
                description: 'To which genre the content belongs to',
                master: 'SystemConfigurations/GENRE',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'genres',
                _id: '659cc2ec593da03d42d85b68',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'broadcastChannel',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Broadcast Channel',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Broadcast Channel',
                description: 'The channel to which the asset belongs to like Set Sab',
                master: 'SystemConfigurations/BROADCAST_CHANNEL',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'broadcast_channel',
                _id: '659cc2ec593da03d42d85b69',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXTAREA',
                dataType: 'STRING',
                fieldName: 'searchKeywords',
                isRequired: false,
                isMultiple: true,
                addMore: false,
                label: 'Keywords',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Keywords',
                description: 'Used for searchable attributes, tray tags and ad tags for targeting ',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'searchKeywords',
                _id: '659cc2ec593da03d42d85b6a',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'longDescription',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Long Summary',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Long Summary',
                description: 'Long description of the content',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'longDescription',
                _id: '659cc2ec593da03d42d85b6b',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'shortDescription',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Short Summary',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Short Summary',
                description: 'Short description of the content',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'shortDescription',
                _id: '659cc2ec593da03d42d85b6c',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'castRole',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Cast Role',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Cast Role',
                description: 'Cast Role',
                master: '',
                options: ['Cast', 'Director', 'Producer', 'Director', 'Author'],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'castRole',
                _id: '659cc2ec593da03d42d85b6d',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
                additionalProperties: {},
              },
              {
                fieldType: 'TEXTAREA',
                dataType: 'STRING',
                fieldName: 'actualName',
                isRequired: false,
                isMultiple: true,
                addMore: false,
                label: 'Actual Name',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Actual Name',
                description: 'Actual Name of the Selected Cast',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'actualName',
                _id: '659cc2ec593da03d42d85b6e',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
                additionalProperties: {},
              },
              {
                fieldType: 'COMPLEXTYPE',
                dataType: 'JSON',
                fieldName: 'castAndCrew',
                isRequired: true,
                isMultiple: true,
                addMore: true,
                label: 'Cast & Crew',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Cast & Crew',
                description: 'Cast & Crew',
                master: '',
                options: [],
                validations: {},
                attributeRef: [
                  {
                    fieldName: 'castRole',
                    sortOrder: 0,
                    _id: '659cc2ec593da03d42d85b70',
                  },
                  {
                    fieldName: 'actualName',
                    sortOrder: 1,
                    _id: '659cc2ec593da03d42d85b71',
                  },
                ],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'cast_and_crew',
                _id: '659cc2ec593da03d42d85b6f',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'COMPLEXTYPE',
                dataType: 'JSON',
                fieldName: 'castAndCrew1',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Cast & Crew',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Cast & Crew',
                description: 'Cast & Crew',
                master: '',
                options: [],
                validations: {},
                attributeRef: [
                  {
                    fieldName: 'castRole',
                    sortOrder: 0,
                    _id: '659cc2ec593da03d42d85b70',
                  },
                  {
                    fieldName: 'actualName',
                    sortOrder: 1,
                    _id: '659cc2ec593da03d42d85b71',
                  },
                ],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'cast_and_crew',
                _id: '659cc2ec593da03d42d85b6f',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'COMPLEXTYPE',
                dataType: 'JSON',
                fieldName: 'castAndCrew2',
                isRequired: true,
                isMultiple: false,
                addMore: true,
                label: 'Cast & Crew',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Cast & Crew',
                description: 'Cast & Crew',
                master: '',
                options: [],
                validations: {},
                attributeRef: [
                  {
                    fieldName: 'castRole',
                    sortOrder: 0,
                    _id: '659cc2ec593da03d42d85b70',
                  },
                  {
                    fieldName: 'actualName',
                    sortOrder: 1,
                    _id: '659cc2ec593da03d42d85b71',
                  },
                ],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'cast_and_crew',
                _id: '659cc2ec593da03d42d85b6f',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'countriesOfOrigin',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Country of Origin',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Country of Origin',
                description: 'Country where content was created originally',
                master: 'Countries',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'countriesOfOrigin',
                _id: '659cc2ec593da03d42d85b72',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'mood',
                isRequired: false,
                isMultiple: true,
                addMore: false,
                label: 'Mood',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Mood',
                description: 'To which mood the content belongs to',
                master: 'SystemConfigurations/MOOD',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'mood',
                _id: '659cc2ec593da03d42d85b73',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'subGenre',
                isRequired: false,
                isMultiple: true,
                addMore: false,
                label: 'Sub Genre',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Sub-genre',
                description: 'To which sub-genre the content belongs to',
                master: 'SystemConfigurations/SUB_GENRE',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'sub_genre',
                _id: '659cc2ec593da03d42d85b74',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'theme',
                isRequired: false,
                isMultiple: true,
                addMore: false,
                label: 'Theme',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Theme',
                description: 'To which theme the content belongs to',
                master: 'SystemConfigurations/THEME',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'theme',
                _id: '659cc2ec593da03d42d85b75',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'regionOfOrigin',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Language of Production',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Language of Production',
                description:
                  'This is the original language of the content and not the default language of the asset',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'region_of_origin',
                _id: '659cc2ec593da03d42d85b76',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'subCategory',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Content Sub Category',
                isB2B: false,
                defaultValue: 'None',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Content Sub Category',
                description: 'To define category - acquired, originals, live , events',
                master: 'SystemConfigurations/CONTENT_SUB_CATEGORY',
                options: [
                  'Acquired Movie',
                  ' Acquired Show',
                  ' Event',
                  ' LIV Exclusive Movie',
                  ' LIV Original',
                  ' Sport Show',
                  ' Sport',
                  ' TV Show',
                ],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'sub_category',
                _id: '659cc2ec593da03d42d85b77',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'audioLanguages',
                isRequired: true,
                isMultiple: true,
                addMore: false,
                label: 'Available Audio Languages',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Available Audio Languages',
                description:
                  'Different audio_languages(ENG, TAM, HIN etc) that are available over the asset',
                master: 'Languages',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'audio_languages',
                _id: '659cc2ec593da03d42d85b78',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'highlightText',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Highlight Text',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Highlight Text',
                description:
                  'A text used on upcoming assets which will be in highlighted format on the FE (eg: Coming Soon) etc',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'highlight_text',
                _id: '659cc2ec593da03d42d85b79',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'playerName',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Player Name',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Player Name',
                description: 'To deine player playing the match',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'player_name',
                _id: '659cc2ec593da03d42d85b7a',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'IsSearchable',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Is Searchable',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Is_searchable',
                description: 'Flag to enable/disable an asset to be part of the search results',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'Is_searchable',
                _id: '659cc2ec593da03d42d85b7b',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'setting',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Setting',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Setting',
                description: 'Reco: to be used for set/theme descriptions',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'setting',
                _id: '659cc2ec593da03d42d85b7c',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'sonylivExclusive',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Sony Liv Exclusive',
                isB2B: false,
                defaultValue: 'false',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Sony Liv Exclusive',
                description:
                  'Reco: to be used to identify whether content is exclusively available on Sonyliv',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'sonyliv_exclusive',
                _id: '659cc2ec593da03d42d85b7d',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'sonylivOriginal',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Sonyliv Original',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Sonyliv Original',
                description: 'Reco: to be used to identify whether content is an original or not',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'sonyliv_original',
                _id: '659cc2ec593da03d42d85b7e',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'era',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Era',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Era',
                description: 'Reco: to be used for era marking as an alternative to existing era',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'era',
                _id: '659cc2ec593da03d42d85b7f',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'oneLineDesc',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'One Line Desc',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'One Line Desc',
                description: 'Reco: to be used for pitching content to user',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'one_line_desc',
                _id: '659cc2ec593da03d42d85b80',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'oneLineIconUrl',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'One Line Icon URL',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'One Line Icon URL',
                description: 'Reco: icon to be shown next to one liner description',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'one_line_icon_url',
                _id: '659cc2ec593da03d42d85b81',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'releaseFrequency',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Release Frequency ',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Release Frequency ',
                description: 'Reco - to mark frequency for episodic release',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'release_frequency',
                _id: '659cc2ec593da03d42d85b82',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'DATETIME',
                dataType: 'DATE',
                fieldName: 'releaseDate',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Release date',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Release date',
                description: 'This is Upcoming content release date, used for google watch action',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'release_date',
                _id: '659cc2ec593da03d42d85b83',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'TitleBrief',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Title Brief',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Title Brief',
                description:
                  'This is used to input the title of the sports event, this is for creative title.',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'TitleBrief',
                _id: '659cc2ec593da03d42d85b84',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'extraTitle',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Extra Title',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Extra Title',
                description: 'This is used for trailers as episodic title is not present there.',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'extra_title',
                _id: '659cc2ec593da03d42d85b85',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'kidsSafe',
                isRequired: true,
                isMultiple: true,
                addMore: false,
                label: 'kids safe',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'kids safe',
                description: 'country list in which the asset can be made as kids_safe.',
                master: 'Countries',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'kids_safe',
                _id: '659cc2ec593da03d42d85b86',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'trayCustomFilter',
                isRequired: true,
                isMultiple: false,
                addMore: false,
                label: 'Tray Custom Filter',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'tray_custom_filter',
                description: 'Used in Content tray selection & display conditions',
                master: '',
                options: ['Yes', 'No'],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'tray_custom_filter',
                _id: '659cc2ec593da03d42d85b87',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'isPlayTrailer',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Is play trailer',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Is play trailer',
                description: 'Flag to enable/disable the trailer autoplay over an asset',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'is_play_trailer',
                _id: '659cc2ec593da03d42d85b88',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'isAutoPlayTrailer',
                isRequired: true,
                isMultiple: true,
                addMore: false,
                label: 'Is Auto Play Trailer',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'is_auto_play_trailer',
                description: 'is_auto_play_trailer',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                _id: '659cc2ec593da03d42d85b89',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'isAutoRedirect',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Is auto redirect',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Is auto redirect',
                description:
                  'Flag to enable/disable the trailer/promotion autoplay redirection over an asset',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'is_auto_redirect',
                _id: '659cc2ec593da03d42d85b8a',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'celebrityMetaData',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Celebrity metadata',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Celebrity metadata',
                description: 'Celebrity metadata like first name, age etc.',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {
                  isJsonViewer: true,
                },
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'celebrity_meta_data',
                _id: '659cc2ec593da03d42d85b8b',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'celebrityCountry',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Celebrity country',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Celebrity country',
                description: 'Country of the celebrity',
                master: 'Countries',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'celebrity_country',
                _id: '659cc2ec593da03d42d85b8c',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'upcomingContentLanguages',
                isRequired: false,
                isMultiple: true,
                addMore: false,
                label: 'Upcoming Content Languages',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Upcoming Content Languages',
                description: 'Language list of the upcoming asset',
                master: 'Languages',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'upcoming_content_languages',
                _id: '659cc2ec593da03d42d85b8d',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'upcoming',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Upcoming',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Upcoming',
                description: 'Flag to show whether the asset is upcoming or not',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'upcoming',
                _id: '659cc2ec593da03d42d85b8e',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'IMDB',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'IMDB Rating',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'IMDB Rating',
                description: 'IMDB rating of an asset',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'IMDB',
                _id: '659cc2ec593da03d42d85b8f',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
            ],
            _id: '659cc2ec593da03d42d85b64',
          },
          {
            metaCategory: 'SPORTS_METADATA',
            metaAttributes: [],
            _id: '659cc2ec593da03d42d85b90',
          },
          {
            metaCategory: 'MISCELLANEOUS_METADATA',
            metaAttributes: [
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'enableCommunication',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Enable Communication',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Enable Communication',
                description: 'Yes or No value required to input in this field',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'enable_communication',
                _id: '659cc2ec593da03d42d85b92',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'communication',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Communication',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Communication',
                description: 'Some FE dependencies field needs to fill the details.',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'communication',
                _id: '659cc2ec593da03d42d85b93',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'customBands',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Custom Bands',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Custom Bands',
                description: 'Field for dependencies.',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'INACTIVE',
                esField: 'custom_bands',
                _id: '659cc2ec593da03d42d85b94',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'customAction',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Custom action',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Custom action',
                description:
                  'To configure deep links over an asset, To define custom actions like redirection',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'custom_action',
                _id: '659cc2ec593da03d42d85b95',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'creatives',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Creatives',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Creatives',
                description:
                  'List of images over the asset and its corresponding show. This is used in B2B',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'creatives',
                _id: '659cc2ec593da03d42d85b96',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'STRING',
                fieldName: 'contentCluster',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Content Cluster',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Content Cluster',
                description: 'to define cluster of the content: SPN or NON SPN',
                master: '',
                options: ['None', 'SPN', 'Non SPN'],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'content_cluster',
                _id: '659cc2ec593da03d42d85b97',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'freePreviewMultistreamTag',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'FreePreview multistream tag',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Freepreview multistream tag',
                description: 'to define free preview duration across asset collections',
                master: '',
                options: [''],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'freepreview_multistream_tag',
                _id: '659cc2ec593da03d42d85b98',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'TEXT',
                dataType: 'STRING',
                fieldName: 'upiPayload',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Upi payload',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Upi payload',
                description: 'Payload used by the Live Event(Used for KBC play-along)',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'upi_payload',
                _id: '659cc2ec593da03d42d85b99',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'IsTestAsset',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Is test asset',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Is test asset',
                description: 'To identify test asset',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'Is_test_asset',
                _id: '659cc2ec593da03d42d85b9a',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'shareSubtitleToPartner',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Share subtitle to partner',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Share subtitle to partner',
                description: 'Subtitle share with partner',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'share_subtitle_to_partner',
                _id: '659cc2ec593da03d42d85b9b',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
              {
                fieldType: 'SELECT',
                dataType: 'BOOLEAN',
                fieldName: 'integratedSplashAvailable',
                isRequired: false,
                isMultiple: false,
                addMore: false,
                label: 'Integrated splash available',
                isB2B: false,
                defaultValue: '',
                affiliatePartner: [],
                inheritanceRule: 'NONE',
                placeholder: 'Integrated splash available',
                description: 'Integrated splash available',
                master: '',
                options: [],
                validations: {},
                attributeRef: [],
                sortOrder: 0,
                createdBy: '653a3a3ed3f6eaf4a9c88f0f',
                updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
                additionalProperties: {},
                metaCategory: 'NA',
                isMultilingual: false,
                viewInlisting: 'HIDDEN',
                status: 'ACTIVE',
                esField: 'integrated_splash_available',
                _id: '659cc2ec593da03d42d85b9c',
                createdAt: '2023-11-28T11:58:43.994Z',
                updatedAt: '2023-11-28T11:58:43.994Z',
              },
            ],
            _id: '659cc2ec593da03d42d85b91',
          },
        ],
        createdBy: {
          userEmail: 'ritu.arora@kellton.com',
        },
        updatedBy: {
          userEmail: 'ritu.arora@kellton.com',
        },
        status: 'ACTIVE',
        createdAt: '2023-11-28T11:58:43.994Z',
        updatedAt: '2023-11-28T11:58:43.994Z',
      },
    ],
  },
  error: null,
}

export const ASSET_TYPE_DEFINITION_DATA_NO_ATTRIBUTES = {
  message: 'Asset type data fetched successfully!',
  responseCode: 200,
  data: {
    count: 1,
    records: [
      {
        _id: '659cc2ec593da03d42d85b11',
        version: '11',
        assetType: 'Season',
        assetCategory: 'BUNDLE',
        contentCategory: 'TV_SHOW',
        tollGate: {
          DESCRIPTIVE_METADATA: null,
          RIGHTS_AND_AVAILABILITY: null,
          SEO_METADATA: null,
          ADVERTISEMENT_METADATA: null,
          BUSINESS_RULES: null,
          TECHNICAL_METADATA: null,
          CONTENT_MEDIA_IMAGE: null,
          CONTENT_MEDIA_VIDEO: null,
          TRANSLATIONS: null,
          SPORTS_METADATA: null,
          MISCELLANEOUS_METADATA: null,
          QC_PROCESS: null,
          PUBLISHING: null,
          RIGHTS_EXPIRED: null,
          DEPUBLISH: null,
        },
        metaCategories: [
          'ADVERTISEMENT_METADATA',
          'BUSINESS_RULES',
          'RIGHTS_AND_AVAILABILITY',
          'SEO_METADATA',
          'TECHNICAL_METADATA',
          'CONTENT_MEDIA_IMAGE',
          'CONTENT_MEDIA_VIDEO',
          'DESCRIPTIVE_METADATA',
          'SPORTS_METADATA',
          'MISCELLANEOUS_METADATA',
        ],
        allowedParents: [],
        isStandAlonePublishing: false,
        attributes: [],
        createdBy: {
          userEmail: 'ritu.arora@kellton.com',
        },
        updatedBy: {
          userEmail: 'ritu.arora@kellton.com',
        },
        status: 'ACTIVE',
        createdAt: '2023-11-28T11:58:43.994Z',
        updatedAt: '2023-11-28T11:58:43.994Z',
      },
    ],
  },
}
export const API_MOCK_AUDIT_TRAIL = {
  message: 'Audit Trails fetched successfully!',
  responseCode: 200,
  data: {
    count: 4,
    records: [
      {
        _id: '658e7b2c7b9f854bcc987944',
        contentId: '',
        assetId: '',
        assetState: '',
        requestId: 'bf6e05fb-72ee-48dd-82e2-3bb709626488',
        type: 'User',
        event: 'Create User A',
        activity: 'User insert failed try again.',
        requestAction: '/api/v1/user/add',
        apiResponse: 'Failed',
        level: 'Info',
        logCategory: 'Change',
        module: 'BOLT_MODULE',
        subModule: 'ROLE_AND_USER_MANAGEMENT',
        dataModel: 'Users',
        dataModelId: '',
        userName: 'Test User Name',
        userId: '654dca4be34dab5b60111783',
        oldData: {},
        newData: {},
        roleName: 'Test Role Name',
        ip: '::1',
        location: '::1',
        source: 'APP',
        userAgent:
          'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
        createdAt: '2023-12-29T07:54:20.324Z',
        updatedAt: '2023-12-29T07:54:20.324Z',
        __v: 0,
      },
      {
        _id: '658e7b197b9f854bcc98793e',
        contentId: '',
        assetId: '',
        assetState: '',
        requestId: '5a7acb0f-7b55-4ed7-bff4-98c233e25123',
        type: 'User',
        event: 'Create User B',
        activity: 'User insert failed try again.',
        requestAction: '/api/v1/user/add',
        apiResponse: 'Failed',
        level: 'Info',
        logCategory: 'Change',
        module: 'BOLT_MODULE',
        subModule: 'ROLE_AND_USER_MANAGEMENT',
        dataModel: 'Users',
        dataModelId: '',
        userName: 'Test User Name',
        oldData: {},
        newData: {},
        userId: '654dca4be34dab5b60111783',
        roleName: 'Test Role Name',
        ip: '::1',
        location: '::1',
        source: 'APP',
        userAgent:
          'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
        createdAt: '2023-12-29T07:54:01.532Z',
        updatedAt: '2023-12-29T07:54:01.532Z',
        __v: 0,
      },
      {
        _id: '658e7a747b9f854bcc987936',
        contentId: '',
        assetId: '',
        assetState: '',
        requestId: '445cf29d-4364-4164-a737-7020a86eb635',
        type: 'User',
        event: 'Create User C',
        activity: 'User created successfully!',
        requestAction: '/api/v1/user/add',
        apiResponse: 'Success',
        level: 'Info',
        logCategory: 'Change',
        module: 'BOLT_MODULE',
        subModule: 'ROLE_AND_USER_MANAGEMENT',
        dataModel: 'Users',
        dataModelId: '658e7a747b9f854bcc987934',
        oldData: {},
        newData: {
          firstName: 'DECTWONINE',
          userName: 'DECTWONINE',
          lastName: 'DECTWONINE',
          userEmail: 'dejnjee@sonyliv.com',
          cyberarkId: 'djdjjdd@sonyliv.com',
          roleId: '653a3a42d3f6eaf4a9c88f17',
          createdBy: '654dca4be34dab5b60111783',
          updatedBy: '654dca4be34dab5b60111783',
          status: 'ACTIVE',
          _id: '658e7a747b9f854bcc987934',
          createdAt: '2023-12-29T07:51:16.165Z',
          updatedAt: '2023-12-29T07:51:16.165Z',
          __v: 0,
        },
        userName: 'Test User Name',
        userId: '654dca4be34dab5b60111783',
        roleName: 'Test Role Name',
        ip: '::1',
        location: '::1',
        source: 'APP',
        userAgent:
          'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
        createdAt: '2023-12-29T07:51:16.409Z',
        updatedAt: '2023-12-29T07:51:16.409Z',
        __v: 0,
      },
      {
        _id: '658e7a627b9f854bcc98792e',
        contentId: '',
        assetId: '',
        assetState: '',
        requestId: '8ffa8283-4441-46c4-8eac-c639b34c3277',
        type: 'User',
        event: 'Create User D',
        activity: 'User insert failed try again.',
        requestAction: '/api/v1/user/add',
        apiResponse: 'Failed',
        level: 'Info',
        logCategory: 'Change',
        module: 'BOLT_MODULE',
        subModule: 'ROLE_AND_USER_MANAGEMENT',
        oldData: {},
        newData: {},
        dataModel: 'Users',
        dataModelId: '',
        userName: 'Test User Name',
        userId: '654dca4be34dab5b60111783',
        roleName: 'Test Role Name',
        ip: '::1',
        location: '::1',
        source: 'APP',
        userAgent:
          'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
        createdAt: '2023-12-29T07:50:58.460Z',
        updatedAt: '2023-12-29T07:50:58.460Z',
        __v: 0,
      },
    ],
  },
  error: null,
}

export const markDoneStatusMock = [
  {
    _id: '65b87eb90d13e843bebbb788',
    markDoneStatus: 'NO_ACTION',
    metaCategoryKey: 'RIGHTS_AND_AVAILABILITY',
    metaCategoryName: 'Rights and Availability',
  },
  {
    _id: '65b87eb90d13e843bebbb78b',
    markDoneStatus: 'PASS',
    metaCategoryKey: 'BUSINESS_RULES',
    metaCategoryName: 'Business Rules',
  },
  {
    _id: '65b87eb90d13e843bebbb78e',
    markDoneStatus: 'NO_ACTION',
    metaCategoryKey: 'CONTENT_MEDIA_VIDEO',
    metaCategoryName: 'Content Media: Video',
  },
  {
    _id: '65b87eb90d13e843bebbb78d',
    markDoneStatus: 'FAIL',
    metaCategoryKey: 'CONTENT_MEDIA_IMAGE',
    metaCategoryName: 'Content Media: Image',
  },
  {
    _id: '65b87eb80d13e843bebbb787',
    markDoneStatus: 'NO_ACTION',
    metaCategoryKey: 'DESCRIPTIVE_METADATA',
    metaCategoryName: 'Descriptive Metadata',
  },
  {
    _id: '65b87eb90d13e843bebbb78c',
    markDoneStatus: 'NO_ACTION',
    metaCategoryKey: 'TECHNICAL_METADATA',
    metaCategoryName: 'Technical Metadata',
  },
  {
    _id: '65b87eb90d13e843bebbb78a',
    markDoneStatus: 'NO_ACTION',
    metaCategoryKey: 'ADVERTISEMENT_METADATA',
    metaCategoryName: 'Advertisement Metadata',
  },
  {
    _id: '65b87eb90d13e843bebbb789',
    markDoneStatus: 'NO_ACTION',
    metaCategoryKey: 'SEO_METADATA',
    metaCategoryName: 'SEO Metadata',
  },
  {
    _id: '65b87eb90d13e843bebbb78f',
    markDoneStatus: 'NO_ACTION',
    metaCategoryKey: 'SPORTS_METADATA',
    metaCategoryName: 'Sports Metadata',
  },
  {
    _id: '65b87eb90d13e843bebbb790',
    markDoneStatus: 'NO_ACTION',
    metaCategoryKey: 'MISCELLANEOUS_METADATA',
    metaCategoryName: 'Miscellaneous Metadata',
  },
]

export const CONTENT_DETAIL_VIEW_STATE = {
  data: {
    _id: '65bb7e187a6a8fbce40313d1',
    blitzId: '1790005649',
    assetType: 'Show',
    contentId: 1790005649,
    assetCategory: 'GOB',
    contentCategory: 'TV_SHOW',
    state: 'DRAFT',
    parentId: null,
    status: 'ACTIVE',
    createdAt: '2024-02-01T11:18:48.428Z',
    updatedAt: '2024-02-01T11:18:48.428Z',
    createdBy: 'akshit.khajuria@kellton.com',
    updatedBy: 'akshit.khajuria@kellton.com',
    ContentMetaCategory: [
      {
        _id: '65bb7e187a6a8fbce40313d6',
        contentId: '65bb7e187a6a8fbce40313d1',
        metaCategory: 'RIGHTS_AND_AVAILABILITY',
        markDoneStatus: 'NO_ACTION',
        attributes: {
          contentProvider: 'SPE',
          contractEndDate: '2026-10-12T18:29:00.0000000Z',
          contractStartDate: '2024-01-18T06:30:00.0000000Z',
          countryRating: [
            { ratingForCountry: '16+', countryForRating: ['_aq'] },
            { ratingForCountry: 'U/A_7+', countryForRating: ['_aq'] },
          ],
          originalAirDate: '2024-01-18T06:30:00.0000000Z',
          pcVodLabel: 'U/A_12+',
          properties: ['_bd', '_bt', '_mv', '_np', '_pk', '_lk', '_in'],
          snpTags: ['Moderate Language'],
          subLicense: false,
          applicableGeoPolicy: '123',
          availableWatchDays: '12',
          blockedCountries: ['_as'],
          distribution: true,
          downloadAllowedWatchCount: '14',
          downloadedContentExpiryHoursAfterFirstWatch: '12',
          isEncrypted: true,
          lastBroadcastDate: null,
          maxDownloadAllowedCount: '14',
        },
        lockedBy: [],
        lockedAt: null,
        createdBy: '659e489c81ca34d2450b0741',
        updatedBy: '659e489c81ca34d2450b0741',
        isDeleted: false,
        __v: 0,
        createdAt: '2024-02-01T11:18:48.437Z',
        updatedAt: '2024-02-01T11:18:48.437Z',
      },
    ],
  },
}

export const CONTENT_VIEW_DATA = {
  metaCategoryLabel: 'Descriptive Metadata',
  metaCategory: 'DESCRIPTIVE_METADATA',
  metaAttributes: [
    {
      attribute: {
        inheritanceRule: 'NONE',
        validations: {
          dateGreaterThanEquals: {
            dataSource: '',
            customValues: '',
            scope: 'parent',
            sourceAttribute: 'contractStartDate',
            standard: true,
          },
        },
        viewInListing: 'VISIBLE',
        _id: '65828f7815aba6365262dd84',
        sortOrder: 1,
        createdAt: '2023-11-28T11:58:43.994Z',
        updatedAt: '2023-11-28T11:58:43.994Z',
        fieldType: 'DATETIME',
        dataType: 'DATE',
        fieldName: 'contractStartDate',
        isRequired: true,
        isMultiple: false,
        label: 'Availability Start Time',
        isB2B: false,
        affiliatePartner: [],
        placeholder: 'Availability Start Time',
        description: 'Actual start time of the asset on the platform, This is a rights data.',
        options: [],
        attributeRef: [],
        metaCategory: 'NA',
        createdBy: '653a3a3ed3f6eaf4a9c88f0f',
        updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
        status: 'ACTIVE',
        addMore: false,
        isMultilingual: false,
        isDeleted: false,
        esField: 'contractStartDate',
        __v: 0,
        value: '2024-01-18T06:30:00.0000000Z',
      },
      fieldName: 'contractStartDate',
      fieldType: 'DATETIME',
      label: 'Availability Start Time',
      sortOrder: 1,
      addMore: false,
      isRequired: true,
    },
    {
      attribute: {
        inheritanceRule: 'NONE',
        validations: {
          dateLessThanEquals: {
            dataSource: '',
            customValues: '',
            scope: 'parent',
            sourceAttribute: 'contractEndDate',
            standard: true,
          },
        },
        viewInListing: 'VISIBLE',
        _id: '65828fd815aba6365262dda6',
        sortOrder: 2,
        createdAt: '2023-11-28T11:58:43.994Z',
        updatedAt: '2023-11-28T11:58:43.994Z',
        fieldType: 'DATETIME',
        dataType: 'DATE',
        fieldName: 'contractEndDate',
        isRequired: true,
        isMultiple: false,
        label: 'Availability End Time',
        isB2B: false,
        affiliatePartner: [],
        placeholder: 'Availability end time',
        description: 'Actual end time of the asset on the platform, This is a rights data',
        options: [],
        attributeRef: [],
        metaCategory: 'NA',
        createdBy: '653a3a3ed3f6eaf4a9c88f0f',
        updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
        status: 'ACTIVE',
        addMore: false,
        isMultilingual: false,
        isDeleted: false,
        esField: 'contractEndDate',
        __v: 0,
        value: '2026-10-12T18:29:00.0000000Z',
      },
      fieldName: 'contractEndDate',
      fieldType: 'DATETIME',
      label: 'Availability End Time',
      sortOrder: 2,
      addMore: false,
      isRequired: true,
    },
  ],
  _id: '65bb7e187a6a8fbce40313d6',
  markDoneStatus: 'NO_ACTION',
  metaCategoryKey: 'RIGHTS_AND_AVAILABILITY',
  metaCategoryName: 'Rights and Availability',
}

export const ASSET_OTHER_DETAILS = {
  contractEndDate: '2026-10-12T18:29:00.0000000Z',
  parentDetails: null,
  contractStartDate: '2024-01-18T06:30:00.0000000Z',
  grandParentDetails: null,
}

export const API_MOCK_GLOBAL_SEARCH = {
  message: 'Data successfully fetched',
  responseCode: 200,
  data: {
    count: 1,
    records: [
      {
        _id: '653a3a41d3f6eaf4a9c88f16',
        title: 'trending in sports',
        type: 'Container',
        layout: 'Card Skinned',
        state: 'DRAFT',
        segment: 'segment 1',
        creationDate: '2024-12-01T14:00:05Z',
        lastUpdatedDate: '2024-12-01T14:00:05Z',
        lastFeaturedDate: '2024-12-01T14:00:05Z',
      },
    ],
  },
}
