import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { MUISelectModal } from '../../../Models'

const MUISelectChange: FC<MUISelectModal> = ({
  options = [],
  error,
  lable,
  name,
  control,
  rules,
  labelKey = 'label',
  valueKey = 'value',
  required = false,
  disabled = false,
  defaultValueIndex = NaN,
  onChange,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <FormControl
            className='c-select'
            fullWidth
            error={!!error?.[name]}
            sx={{ textAlign: 'left' }}
          >
            <InputLabel id='demo-simple-select-label'>
              <span>
                {lable}
                {required && <span style={{ color: '#D4351C' }}>*</span>}
              </span>
            </InputLabel>
            <Select
              className='c-select-menu-item'
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              data-testid={lable}
              label={
                <span>
                  {lable}
                  {required && <span style={{ color: '#D4351C' }}>*</span>}
                </span>
              }
              inputProps={{ id: name }}
              {...field}
              defaultValue={options?.length > 0 ? options?.[defaultValueIndex]?.value : ''}
              IconComponent={KeyboardArrowDownIcon}
              disabled={disabled}
              value={field?.value}
              onChange={onChange}
            >
              {options?.length > 0 &&
                options?.map((item: any, index: number) => {
                  return (
                    <MenuItem
                      disabled={item?.disable || false}
                      key={`${item[valueKey]}_${index}`}
                      value={item[valueKey]}
                    >
                      {item[labelKey]}
                    </MenuItem>
                  )
                })}
            </Select>
            <FormHelperText>{error?.[name]?.message as string}</FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}

export default MUISelectChange
