function MagnifyGlass({ height, width }: { height: number; width: number }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
    >
      <g clipPath='url(#clip0_3413_12063)'>
        <path
          d='M13.3043 12.052H12.6478L12.4151 11.8277C13.4123 10.665 13.9275 9.0788 13.645 7.3929C13.2544 5.08412 11.3266 3.24043 8.9998 2.95806C5.48475 2.5262 2.52646 5.48276 2.95857 8.99575C3.24111 11.3211 5.08588 13.2479 7.39601 13.6382C9.0829 13.9206 10.6701 13.4057 11.8334 12.4091L12.0578 12.6416V13.2977L15.5895 16.8273C15.9302 17.1678 16.4869 17.1678 16.8276 16.8273C17.1683 16.4868 17.1683 15.9304 16.8276 15.5899L13.3043 12.052ZM8.3184 12.052C6.24926 12.052 4.57899 10.3827 4.57899 8.31474C4.57899 6.24682 6.24926 4.57752 8.3184 4.57752C10.3875 4.57752 12.0578 6.24682 12.0578 8.31474C12.0578 10.3827 10.3875 12.052 8.3184 12.052Z'
          fill='#B1B4B6'
        />
      </g>
      <defs>
        <clipPath id='clip0_3413_12063'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MagnifyGlass
