export const MANAGE_WIDGET = {
  WIDGET_CATEGORIES: [
    { key: 'data', title: 'Data' },
    { key: 'presentation', title: 'Presentation' },
    { key: 'behaviour', title: 'Behaviour' },
    { key: 'checklist', title: 'Checklist' },
  ],
  WIDGET_DATA_ENGINE: [
    { key: 'manual', title: 'Manual' },
    { key: 'automated', title: 'Automated' },
    { key: 'recommendation', title: 'Recommendation' },
  ],
  PRESENTATION_TABS: [
    { key: 'image', title: 'Metadata' },
    { key: 'images', title: 'Images' },
  ],
  PRESENTATION_METADATA: [
    { key: 'title', title: 'Title' },
    { key: 'subLayoutType', title: 'Sub Layout Type' },
    { key: 'showWidgetTitle', title: 'Show Widget Title' },
    { key: 'overrideWidgetTitle', title: 'Override Widget Title' },
  ],
  BEHAVIOR: [
    { key: 'segment', title: 'Segment' },
    { key: 'showWidgetTitle', title: 'Show Widget Title' },
    { key: 'overrideWidgetTitle', title: 'Override Widget Title' },
    { key: 'countryRating', title: 'Country Rating' },
  ],
  FEATURE_LATER: [
    { key: 'featuringTitle', title: 'Featuring Title' },
    { key: 'featuringStartDateAndTime', title: 'Featuring Start Date and Time' },
    { key: 'featuringEndDateAndTime', title: 'Featuring End Date and Time' },
    { key: 'reccuringSchedule ', title: 'Reccuring Schedule' },
  ],
}

export const PARAMETER_CATEGORIES = {
  DATA: 'Data',
  PRESENTATION: 'Presentation',
  BEHAVIOUR: 'Behaviour',
  CHECKLIST: 'Checklist',
}

export const DATA_ENGINE_TYPE = {
  MANUAL: 'Manual',
  AUTOMATED: 'Automated',
  RECOMMENDATION: 'Recommendation',
}

export const FORM_FIELD = {
  CONTENT_CATEGORY: 'contentCategory',
  ASSET_CATEGORY: 'assetCategory',
  ASSET_TYPE: 'assetType',
  RANGE_BY: 'rangeBy',
  BLITZ_ATTRIBUTES: 'blitzAttributes',
}

export const CATEGORY_LABEL = {
  BLITZ_ASSET_CATEGORY: 'blitzAssetCategory',
  SELECT_BY_BLITZ_ATTRIBUTE: 'selectByBlitzAttribute',
  EXCLUDE_BY_BLITZ_ATTRIBUTE: 'excludeByBlitzAttribute',
  RANGE_BY_BLITZ_ATTRIBUTE: 'rangeByBlitzAttribute',
}
