function BulbIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none'>
      <path
        d='M12.0178 5.17285C8.62091 5.17285 5.8457 7.94806 5.8457 11.3449C5.8457 13.121 6.62276 14.8306 7.93266 15.9851C8.90953 16.9619 8.82073 19.0489 8.79852 19.0711C8.79852 19.1821 8.82073 19.2709 8.90953 19.3597C8.97614 19.4263 9.08715 19.4707 9.17595 19.4707H14.8374C14.9484 19.4707 15.0372 19.4263 15.1038 19.3597C15.1704 19.2931 15.2148 19.1821 15.2148 19.0711C15.2148 19.0489 15.1038 16.9619 16.0807 15.9851C16.1029 15.9629 16.1251 15.9407 16.1473 15.9185C17.435 14.7418 18.1898 13.0766 18.1898 11.3449C18.1898 7.94806 15.4146 5.17285 12.0178 5.17285ZM15.57 15.4078C15.5478 15.43 15.5034 15.4744 15.5034 15.4966C14.6376 16.4291 14.4821 17.961 14.4599 18.6937H9.55338C9.53118 17.961 9.37577 16.3625 8.4433 15.4078C7.26661 14.3865 6.57836 12.899 6.57836 11.3227C6.57836 8.32549 8.99834 5.90551 11.9956 5.90551C14.9928 5.90551 17.4128 8.32549 17.4128 11.3227C17.4128 12.899 16.7467 14.3865 15.57 15.4078Z'
        fill='#402080'
      />
      <path
        d='M11.9956 6.74902C11.7958 6.74902 11.6182 6.92664 11.6182 7.12645C11.6182 7.32627 11.7958 7.50388 11.9956 7.50388C14.238 7.50388 16.0363 9.32442 16.0363 11.5446C16.0363 11.7444 16.2139 11.922 16.4137 11.922C16.6135 11.922 16.7912 11.7444 16.7912 11.5446C16.8134 8.90259 14.6598 6.74902 11.9956 6.74902Z'
        fill='#402080'
      />
      <path
        d='M14.4599 19.8926H9.5311C9.02046 19.8926 8.59863 20.3144 8.59863 20.825C8.59863 21.3357 9.02046 21.7575 9.5311 21.7575H14.4377C14.9705 21.7353 15.3923 21.3357 15.3923 20.825C15.3923 20.3144 14.9705 19.8926 14.4599 19.8926ZM14.4599 20.9805H9.5311C9.4423 20.9805 9.35349 20.9139 9.35349 20.8028C9.35349 20.6918 9.42009 20.6252 9.5311 20.6252H14.4377C14.5265 20.6252 14.6153 20.6918 14.6153 20.8028C14.6153 20.9139 14.5487 20.9805 14.4599 20.9805Z'
        fill='#402080'
      />
      <path
        d='M13.728 22.1348H10.2645C9.75386 22.1348 9.33203 22.5566 9.33203 23.0672C9.33203 23.5779 9.75386 23.9997 10.2645 23.9997H13.728C14.2386 23.9997 14.6604 23.5779 14.6604 23.0672C14.6604 22.5344 14.2386 22.1348 13.728 22.1348ZM13.728 23.2226H10.2645C10.1757 23.2226 10.0869 23.156 10.0869 23.045C10.0869 22.934 10.1535 22.8674 10.2645 22.8674H13.728C13.8168 22.8674 13.9056 22.934 13.9056 23.045C13.9056 23.156 13.8168 23.2226 13.728 23.2226Z'
        fill='#402080'
      />
      <path
        d='M11.9956 3.33025C12.2176 3.33025 12.373 3.15264 12.373 2.95282V0.377428C12.373 0.177613 12.1954 0 11.9956 0C11.7958 0 11.6182 0.177613 11.6182 0.377428V2.95282C11.6182 3.15264 11.7958 3.33025 11.9956 3.33025Z'
        fill='#402080'
      />
      <path
        d='M18.2785 1.90931C18.1009 1.7761 17.8789 1.84271 17.7679 1.99812L16.347 4.12948C16.2138 4.30709 16.2582 4.55131 16.4358 4.66232C16.5024 4.70672 16.569 4.72893 16.6356 4.72893C16.7688 4.72893 16.8798 4.66232 16.9464 4.55131L18.3673 2.41995C18.5005 2.26454 18.4561 2.02032 18.2785 1.90931Z'
        fill='#402080'
      />
      <path
        d='M7.4888 4.64036C7.5554 4.64036 7.62201 4.61816 7.68861 4.57375C7.86623 4.46274 7.91063 4.21852 7.79962 4.04091L6.42312 1.88735C6.31211 1.70974 6.06789 1.66533 5.89028 1.77634C5.71267 1.88735 5.66826 2.13157 5.77927 2.30918L7.15577 4.46274C7.24458 4.59595 7.35559 4.64036 7.4888 4.64036Z'
        fill='#402080'
      />
      <path
        d='M4.80258 6.99365L2.56021 5.77256C2.3826 5.68376 2.13838 5.75036 2.04957 5.92798C1.93856 6.10559 2.00517 6.34981 2.20498 6.43861L4.44735 7.6597C4.51395 7.68191 4.55836 7.70411 4.62496 7.70411C4.75817 7.70411 4.89138 7.6375 4.95799 7.50429C5.04679 7.32668 4.98019 7.08246 4.80258 6.99365Z'
        fill='#402080'
      />
      <path
        d='M21.9649 5.92798C21.8761 5.75036 21.6319 5.68376 21.4543 5.77256L19.1897 6.99365C19.0121 7.08246 18.9455 7.32668 19.0343 7.50429C19.1009 7.6375 19.2341 7.70411 19.3673 7.70411C19.4339 7.70411 19.5005 7.68191 19.5449 7.6597L21.8095 6.43861C21.9871 6.34981 22.0537 6.10559 21.9649 5.92798Z'
        fill='#402080'
      />
    </svg>
  )
}

export default BulbIcon
export const EyeIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <g clipPath='url(#clip0_3432_36894)'>
        <path
          d='M8.4974 2.66699C5.16406 2.66699 2.3174 4.74033 1.16406 7.66699C2.3174 10.5937 5.16406 12.667 8.4974 12.667C11.8307 12.667 14.6774 10.5937 15.8307 7.66699C14.6774 4.74033 11.8307 2.66699 8.4974 2.66699ZM8.4974 11.0003C6.6574 11.0003 5.16406 9.50699 5.16406 7.66699C5.16406 5.82699 6.6574 4.33366 8.4974 4.33366C10.3374 4.33366 11.8307 5.82699 11.8307 7.66699C11.8307 9.50699 10.3374 11.0003 8.4974 11.0003ZM8.4974 5.66699C7.39073 5.66699 6.4974 6.56033 6.4974 7.66699C6.4974 8.77366 7.39073 9.66699 8.4974 9.66699C9.60406 9.66699 10.4974 8.77366 10.4974 7.66699C10.4974 6.56033 9.60406 5.66699 8.4974 5.66699Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_3432_36894'>
          <rect width='16' height='16' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const AssetLinkIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g clipPath='url(#clip0_2501_50534)'>
        <path
          d='M13 13.9999V19.9999C13 20.5499 13.45 20.9999 14 20.9999H20C20.55 20.9999 21 20.5499 21 19.9999V13.9999C21 13.4499 20.55 12.9999 20 12.9999H14C13.45 12.9999 13 13.4499 13 13.9999ZM4 20.9999H10C10.55 20.9999 11 20.5499 11 19.9999V13.9999C11 13.4499 10.55 12.9999 10 12.9999H4C3.45 12.9999 3 13.4499 3 13.9999V19.9999C3 20.5499 3.45 20.9999 4 20.9999ZM3 3.99992V9.99992C3 10.5499 3.45 10.9999 4 10.9999H10C10.55 10.9999 11 10.5499 11 9.99992V3.99992C11 3.44992 10.55 2.99992 10 2.99992H4C3.45 2.99992 3 3.44992 3 3.99992ZM15.95 2.39992L11.7 6.63992C11.31 7.02992 11.31 7.65992 11.7 8.04992L15.95 12.2999C16.34 12.6899 16.97 12.6899 17.36 12.2999L21.61 8.04992C22 7.65992 22 7.02992 21.61 6.63992L17.37 2.39992C16.98 2.00992 16.34 2.00992 15.95 2.39992Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_2501_50534'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const MediaInfoIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M12.0002 2.66699L13.2135 5.09366C13.2668 5.20033 13.1868 5.33366 13.0668 5.33366H11.7468C11.4935 5.33366 11.2602 5.19366 11.1535 4.96699L10.0002 2.66699H8.66683L9.88016 5.09366C9.9335 5.20033 9.8535 5.33366 9.7335 5.33366H8.4135C8.16016 5.33366 7.92683 5.19366 7.82016 4.96699L6.66683 2.66699H5.3335L6.54683 5.09366C6.60016 5.20033 6.52016 5.33366 6.40016 5.33366H5.08016C4.82683 5.33366 4.5935 5.19366 4.48016 4.96699L3.3335 2.66699H2.66683C1.9335 2.66699 1.3335 3.26699 1.3335 4.00033V12.0003C1.3335 12.7337 1.9335 13.3337 2.66683 13.3337H13.3335C14.0668 13.3337 14.6668 12.7337 14.6668 12.0003V3.33366C14.6668 2.96699 14.3668 2.66699 14.0002 2.66699H12.0002Z'
        fill='#402080'
      />
    </svg>
  )
}
