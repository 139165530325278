import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { ReactNode, SyntheticEvent, useState } from 'react'
import { Control } from 'react-hook-form'

import { FieldValidationRulesModal } from '../../../Models'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

interface MUIAutocompleteMultiSelectModal {
  label: string
  LabelIcon?: ReactNode
  options: any
  labelKey: string
  control?: Control
  name?: string
  disabled?: boolean
  error?: any
  onInputChange?: any
  rules?: FieldValidationRulesModal
  value?: any
  width?: string
  limitTags?: number
  required?: boolean
  multiple?: boolean
  isOptionEqualToValue?: boolean
  highlighSelectedField?: boolean
  id?: string
  valueKey?: string
  ref?: any
  small?: boolean
  chipProps?: any
  className?: string
}

const MUIMultiselectAsset: React.FC<MUIAutocompleteMultiSelectModal> = ({
  label,
  LabelIcon,
  options,
  labelKey = 'value',
  name = '',
  error,
  onInputChange,
  value,
  multiple = false,
  width = '100%',
  limitTags = 1,
  required = false,
  id = '',
  ref,
  valueKey = 'key',
  highlighSelectedField = true,
  small = false,
  className = '',
  chipProps = {},
}: MUIAutocompleteMultiSelectModal) => {
  const updatedOptions = [...options]
  // if (multiple) {
  //   updatedOptions = [{ [valueKey]: 'all', [labelKey]: 'Select All' }, ...options]
  // }
  const [inputValue, setInputValue] = useState('')
  const handleSearchChange = (_event: any, value: string, reason: string) => {
    if (reason !== 'reset') {
      setInputValue(value)
    }
  }

  const handleInputChanges = (ev: SyntheticEvent, value: any) => {
    // checking if the field is being used as multi-select
    if (multiple && Array.isArray(value)) {
      // checking if selectAll is being selected.
      const isSelectAll = value.some((item: any) => item[valueKey] === 'all')
      value = isSelectAll ? [...options] : value
    }
    onInputChange(ev, value)
  }

  /**
   * Handles the `onPaste` event for an HTMLInputElement.
   *
   * This function parses the pasted text, extracts countries,
   * any country not match will be added to error
   * and updates the state with the combined and unique countries pasted.
   * @param e - The React `ClipboardEvent` object triggered by the `onPaste` event.
   */
  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    // Prevent default behavior to prevent handleInputChange from triggering.
    e.preventDefault()
    const pastedData: string = e.clipboardData.getData('Text') ?? ''
    // pasted data should only have unique keys
    const pastedDataArray = [...new Set([...pastedData.split(',').map((item) => item.trim())])]
    const selectedValueArray = [...value]
    const errorValueArray = []
    for (let i = 0; i < pastedDataArray.length; i++) {
      const selectedOptions = options.find(({ key }: { key: string }) =>
        key.includes(pastedDataArray[i]),
      )
      if (selectedOptions) {
        selectedValueArray.push(selectedOptions)
      } else {
        errorValueArray.push(pastedDataArray[i])
      }
    }
    // setup error if pasted information don't have country.
    if (errorValueArray.length) {
      error[name] = { message: `Incorrect values: ${errorValueArray.join(',')}` }
      return
    }
    onInputChange(
      e,
      Object.values(selectedValueArray.reduce((acc, obj) => ({ ...acc, [obj._id]: obj }), {})),
    )
  }

  return (
    <Autocomplete
      {...(multiple
        ? {
            inputValue,
            onInputChange: handleSearchChange,
            onClose: () => {
              setInputValue('')
            },
          }
        : {
            onInputChange: onInputChange,
          })}
      className={className}
      multiple={multiple}
      id={id}
      disableClearable={small}
      ChipProps={chipProps}
      options={updatedOptions}
      disableCloseOnSelect={multiple}
      limitTags={limitTags}
      getLimitTagsText={(more: number) =>
        `+${more} ${small ? '' : 'other'}${!small && more > 1 ? 's' : ''}`
      }
      getOptionLabel={(option: any) => option?.[labelKey] ?? option}
      getOptionKey={(option: any) => option?.[valueKey]}
      value={value === '' ? null : value}
      onChange={handleInputChanges}
      onPaste={handleOnPaste}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          key={option?._id}
          style={highlighSelectedField ? {} : { backgroundColor: selected ? 'transparent' : '' }}
        >
          {multiple && (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          {option?.[labelKey] ?? option}
        </li>
      )}
      fullWidth
      sx={{ width: width }}
      ListboxProps={{ style: { maxHeight: '300px' } }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          placeholder={small && !value.length ? 'All' : ''}
          error={!!error?.[name]}
          helperText={error?.[name]?.message as string}
          ref={ref ? ref : null}
          data-testid={'text-' + id}
          name={name}
          label={
            !small ? (
              <div className='d-flex align-items-center'>
                {LabelIcon && <div className='d-flex align-items-center'>{LabelIcon}</div>}
                <div style={{ marginLeft: 5 }}>
                  <span>
                    {label}
                    {required && <span style={{ color: '#D4351C' }}>*</span>}
                  </span>
                </div>
              </div>
            ) : (
              ''
            )
          }
        />
      )}
      popupIcon={<KeyboardArrowDownIcon />}
    />
  )
}

export default MUIMultiselectAsset
