import React, { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { DialogProvider } from '../Components/Shared'
import Loader from '../Components/Shared/Loader'
import MUIDrawer from '../Components/Shared/MUI-Drawer'
import { PAGE_NAME } from '../Constants'
import { DrawerData as initialDrawerData } from '../Constants/Constant/SidebarConstent'
import { BOLT_MODULE } from '../Constants/Roles'
import { useAppDispatch, useAppSelector } from '../Store/Hooks'
// import { getAppConfig } from '../Store/Slice/appConfig.slice'

const DefaultLayout: React.FC = () => {
  const [drawerData, setDrawerData] = useState(initialDrawerData)
  const dispatch = useAppDispatch()
  // const { contentCategories, auditTrails } = useAppSelector((state) => state.appConfig)

  // useEffect(() => {
  //   dispatch(getAppConfig() as any)
  // }, [dispatch])

  // useEffect(() => {
  //   const updateDrawerData = () => {
  //     const updatedData: any = initialDrawerData.map((page) => {
  //       let pageCopy: any = { ...page }
  //       // if (page.lable === PAGE_NAME.ManageContentLabel) {
  //       //   pageCopy = {
  //       //     ...page,
  //       //     child:
  //       //       Array.isArray(contentCategories) && contentCategories.length > 0
  //       //         ? [
  //       //             ...contentCategories.map((category: any) => ({
  //       //               lable: category.value,
  //       //               id: category._id,
  //       //               path: `manage-content/${category.key.toLowerCase().replace(/ /g, '-')}`,
  //       //               role: BOLT_MODULE.MANAGE_CONTENT.key,
  //       //             })),
  //       //           ]
  //       //         : [...page.child],
  //       //   }
  //       // }
  //       // if (page.lable === PAGE_NAME.AuditLogsLabel) {
  //       //   pageCopy = {
  //       //     ...page,
  //       //     child:
  //       //       Array.isArray(auditTrails) && auditTrails.length > 0
  //       //         ? [
  //       //             ...auditTrails.map((auditLogCategory: any) => ({
  //       //               lable: auditLogCategory.value,
  //       //               id: auditLogCategory._id,
  //       //               path: `audit-logs/${auditLogCategory.key.toLowerCase().replace(/ /g, '-')}`,
  //       //               role: BOLT_MODULE.AUDIT_TRAILS.key,
  //       //             })),
  //       //           ]
  //       //         : [...page.child],
  //       //   }
  //       // }
  //       if (pageCopy?.child?.length) {
  //         pageCopy.path = pageCopy.child[0].path
  //       }
  //       return pageCopy
  //     })
  //     setDrawerData(updatedData)
  //   }

  //   // if (contentCategories?.length || auditTrails?.length) {
  //     updateDrawerData()
  //   // }
  // }, [])

  return (
    <>
      <MUIDrawer drawerData={drawerData}>
        <Suspense fallback={<Loader />}>
          <DialogProvider>
            <div className='root'>
              <Loader />
              <Outlet />
            </div>
          </DialogProvider>
        </Suspense>
      </MUIDrawer>
    </>
  )
}

export default DefaultLayout
