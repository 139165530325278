import { redirect } from 'react-router-dom'
import { DrawerData as initialDrawerData } from '../../Constants/Constant/SidebarConstent'
import { getLocalStorage } from '../../Helpers/Storage'
import { checkModuleAccess } from '../../Helpers/Util'

export function authenticationGuard(): string | Response {
  const { token = '' } = getLocalStorage('auth_config') as any
  if (!token) return redirect('/login')
  if (token) {
    for (let i = 0; i < initialDrawerData?.length; i++) {
      const pathArray = window.location.pathname.split('/')
      if (initialDrawerData[i].path === `/${pathArray[1]}`) {
        if (!checkModuleAccess(initialDrawerData[i]?.role)) {
          return redirect('/access-denied')
        }
      }
    }
  }
  return token
}
