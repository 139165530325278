function AccordianCloseIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g path='url(#clip0_686_14463)'>
        <path
          d='M7.29324 15.6476L12.007 10.9338L16.7207 15.6476C17.1945 16.1214 17.9599 16.1214 18.4337 15.6476C18.9075 15.1738 18.9075 14.4084 18.4337 13.9346L12.8574 8.35828C12.3836 7.88448 11.6182 7.88448 11.1444 8.35828L5.56811 13.9346C5.0943 14.4084 5.0943 15.1738 5.56811 15.6476C6.04191 16.1092 6.81943 16.1214 7.29324 15.6476Z'
          fill='#858789'
        />
      </g>
      <defs>
        <clipPath id='clip0_686_14463'>
          <rect width='24' height='24' fill='white' transform='matrix(-1 0 0 -1 24 24)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AccordianCloseIcon
