import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { CONTAINER_DEFINITION_CRUD } from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

const initialState: any = {
  tollgatesList: [],
  parametersList: [],
  selectedParametersList: {},
  showRightDrawer: false,
  drawerTitle: '',
  rightDrawerParameterData: {},
  rightDrawerParameterDataIndex: 0,
  containerDefinitionValidationValues: [],
}

const containerDefinitionSlice = createSlice({
  name: 'containerDefinition',
  initialState,
  reducers: {
    resetInitialState: (state) => {
      state.tollgatesList = []
      state.parametersList = []
      state.selectedParametersList = {}
      state.showRightDrawer = false
      state.containerDefinitionViewData = {}
      state.drawerTitle = ''
      state.rightDrawerParameterData = {}
      state.containerDefinitionValidationValues = []
    },
    setTollgatesList: (state, action: PayloadAction<any>) => {
      const { data = [] } = action.payload || {}
      state.tollgatesList = data
    },
    setParametersList: (state, action: PayloadAction<any>) => {
      const { data = {} } = action.payload || {}
      const { records = [] } = data
      state.parametersList = records.filter((e: any) => e.status === 'ACTIVE')
    },
    setSelectedParametersList: (state, action: PayloadAction<any>) => {
      state.selectedParametersList = { ...state.selectedParametersList, ...action.payload }
    },
    clearSetSelectedParametersList: (state) => {
      state.selectedParametersList = []
    },
    setShowRightDrawer: (state, action: PayloadAction<any>) => {
      state.showRightDrawer = action.payload
    },
    setRightDrawerParameterData: (state, action: PayloadAction<any>) => {
      state.rightDrawerParameterData = action.payload
    },
    setRightDrawerTitle: (state, action: PayloadAction<any>) => {
      state.drawerTitle = action.payload
    },
    setContainerDefinitionViewState: (state, action: PayloadAction<any>) => {
      const { data = {} } = action.payload || {}
      state.containerDefinitionViewData = data
    },
    setRightDrawerParameterDataIndex: (state, action: PayloadAction<any>) => {
      state.rightDrawerParameterDataIndex = action.payload
    },
    setContainerDefinitionValidationValues: (state, action: PayloadAction<any>) => {
      state.containerDefinitionValidationValues = action.payload
    },
    setContainerStatus: (state, action: PayloadAction<any>) => {
      const { data = {} } = action.payload || {}
      state.containerStatus = data
    },
  },
})

export const {
  resetInitialState,
  setTollgatesList,
  setParametersList,
  setSelectedParametersList,
  clearSetSelectedParametersList,
  setShowRightDrawer,
  setRightDrawerParameterData,
  setRightDrawerTitle,
  setRightDrawerParameterDataIndex,
  setContainerDefinitionValidationValues,
  setContainerDefinitionViewState,
  setContainerStatus,
} = containerDefinitionSlice.actions

const containerDefinitionSelector = (state: { containerDefinition: any }) =>
  state.containerDefinition

export const selectTollgatesList = createSelector(
  containerDefinitionSelector,
  (state) => state.tollgatesList,
)

export const selectParametersList = createSelector(
  containerDefinitionSelector,
  (state) => state.parametersList,
)

export const selectSelectedParametersList = createSelector(
  containerDefinitionSelector,
  (state) => state.selectedParametersList,
)
export const selectcontainerDefinitionViewData = createSelector(
  containerDefinitionSelector,
  (state) => state.containerDefinitionViewData,
)

export const selectShowRightDrawer = createSelector(
  containerDefinitionSelector,
  (state) => state.showRightDrawer,
)

export const selectRightDrawerTitle = createSelector(
  containerDefinitionSelector,
  (state) => state.drawerTitle,
)

export const selectRightDrawerParameterDataIndex = createSelector(
  containerDefinitionSelector,
  (state) => state.rightDrawerParameterDataIndex,
)

export const selectRightDrawerParameterData = createSelector(
  containerDefinitionSelector,
  (state) => state.rightDrawerParameterData,
)

export const selectContainerStatus = createSelector(
  containerDefinitionSelector,
  (state) => state.containerStatus,
)

export default containerDefinitionSlice

export const getContainerDefinitionsListAPICall = createAsyncThunk(
  'containerDefinition/getContainerDefinitionsList',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = CONTAINER_DEFINITION_CRUD.LISTING(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const getTollgatesListAPICall = createAsyncThunk(
  'containerDefinition/getTollgatesList',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = CONTAINER_DEFINITION_CRUD.TOLLGATES_LISTING(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setTollgatesList(data))
    dispatch(setLoader(false))
    return data
  },
)

export const getParametersListAPICall = createAsyncThunk(
  'containerDefinition/getParametersList',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = CONTAINER_DEFINITION_CRUD.PARAMETER_LISTING(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setParametersList(data))
    dispatch(setLoader(false))
    return data
  },
)

export const createContainerDefinitionAPICall = createAsyncThunk(
  'containerDefinition/createContainerDefinition',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = CONTAINER_DEFINITION_CRUD.CREATE(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setParametersList(data))
    dispatch(setLoader(false))
    return data
  },
)

export const updateContainerDefinitionAPICall = createAsyncThunk(
  'containerDefinition/updateContainerDefinition',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = CONTAINER_DEFINITION_CRUD.UPDATE(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const getContainerDefinitionByIdAPICall = createAsyncThunk(
  'containerDefinition/getContainerDefinitionById',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = CONTAINER_DEFINITION_CRUD.GET(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setContainerDefinitionViewState(data))
    dispatch(setLoader(false))
    return data
  },
)

export const updateContainerDefinitionStatusAPICall = createAsyncThunk(
  'containerDefinition/updateContainerDefinitionStatus',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = CONTAINER_DEFINITION_CRUD.UPDATE_STATUS(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
