import React from 'react'

export const RoleManagementContent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <g clipPath='url(#clip0_656_18422)'>
        <path
          d='M4 15H10C10.55 15 11 14.55 11 14C11 13.45 10.55 13 10 13H4C3.45 13 3 13.45 3 14C3 14.55 3.45 15 4 15ZM4 19H10C10.55 19 11 18.55 11 18C11 17.45 10.55 17 10 17H4C3.45 17 3 17.45 3 18C3 18.55 3.45 19 4 19ZM4 11H10C10.55 11 11 10.55 11 10C11 9.45 10.55 9 10 9H4C3.45 9 3 9.45 3 10C3 10.55 3.45 11 4 11ZM3 6C3 6.55 3.45 7 4 7H10C10.55 7 11 6.55 11 6C11 5.45 10.55 5 10 5H4C3.45 5 3 5.45 3 6ZM14 5H20C20.55 5 21 5.45 21 6V18C21 18.55 20.55 19 20 19H14C13.45 19 13 18.55 13 18V6C13 5.45 13.45 5 14 5Z'
          fill='#3E3F40'
        />
      </g>
      <defs>
        <clipPath id='clip0_656_18422'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RoleManagementContent
