import React from 'react'

const AddFeaturedIcon: React.FC = () => {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='4' y='4' width='48' height='48' rx='24' fill='#ECE9F2' />
      <rect x='4' y='4' width='48' height='48' rx='24' stroke='#E2DEEC' strokeWidth='8' />
      <g clipPath='url(#clip0_2305_41316)'>
        <path
          d='M34.6667 23.8333V33.8333C34.6667 34.2917 34.2917 34.6667 33.8333 34.6667H23.8333C23.375 34.6667 23 35.0417 23 35.5C23 35.9583 23.375 36.3333 23.8333 36.3333H34.6667C35.5833 36.3333 36.3333 35.5833 36.3333 34.6667V23.8333C36.3333 23.375 35.9583 23 35.5 23C35.0417 23 34.6667 23.375 34.6667 23.8333Z'
          fill='#402080'
        />
        <path
          d='M31.3327 19.6667H21.3327C20.416 19.6667 19.666 20.4167 19.666 21.3334V31.3334C19.666 32.2501 20.416 33.0001 21.3327 33.0001H31.3327C32.2493 33.0001 32.9993 32.2501 32.9993 31.3334V21.3334C32.9993 20.4167 32.2493 19.6667 31.3327 19.6667ZM25.4993 31.3334H21.3327V27.1667H25.4993V31.3334ZM31.3327 31.3334H27.166V27.1667H31.3327V31.3334ZM31.3327 25.5001H21.3327V21.3334H31.3327V25.5001Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_2305_41316'>
          <rect width='20' height='20' fill='white' transform='translate(18 18)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AddFeaturedIcon
