import React from 'react'

const DragableIcon: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M16.2377 7.46603L16.2377 9.17487L12.847 9.16309C12.3874 9.16309 12.0103 9.54021 12.0103 9.99983C12.0103 10.4594 12.3874 10.8366 12.847 10.8366L16.2377 10.8248L16.2377 12.5336C16.2377 12.899 16.6856 13.0875 16.9507 12.8224L19.4786 10.2945C19.6436 10.1295 19.6436 9.87019 19.4786 9.7052L16.9507 7.1773C16.6856 6.91213 16.2377 7.10069 16.2377 7.46603ZM7.4662 3.7621L9.17504 3.7621L9.16326 7.1528C9.16326 7.61242 9.54038 7.98954 10 7.98954C10.4596 7.98954 10.8367 7.61242 10.8367 7.1528L10.825 3.7621L12.5338 3.7621C12.8991 3.7621 13.0877 3.31426 12.8225 3.0491L10.2946 0.52119C10.1296 0.356199 9.87036 0.356199 9.70537 0.52119L7.17747 3.0491C6.9123 3.31426 7.10086 3.7621 7.4662 3.7621ZM3.76227 12.5336L3.76226 10.8248L7.15297 10.8366C7.61259 10.8366 7.98971 10.4594 7.98971 9.99983C7.98971 9.54021 7.61259 9.16309 7.15297 9.16309L3.76227 9.17487L3.76226 7.46603C3.76226 7.10069 3.31443 6.91213 3.04927 7.1773L0.52136 9.7052C0.356368 9.87019 0.356368 10.1295 0.52136 10.2945L3.04927 12.8224C3.31443 13.0875 3.76227 12.899 3.76227 12.5336ZM12.5338 16.2376H10.825L10.8367 12.8469C10.8367 12.3872 10.4596 12.0101 10 12.0101C9.54038 12.0101 9.16326 12.3872 9.16326 12.8469L9.17504 16.2376H7.4662C7.10086 16.2376 6.9123 16.6854 7.17747 16.9506L9.70537 19.4785C9.87036 19.6435 10.1296 19.6435 10.2946 19.4785L12.8225 16.9506C13.0877 16.6854 12.8991 16.2376 12.5338 16.2376Z'
        fill='#402080'
      />
      <defs>
        <clipPath id='clip0_5961_9093'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DragableIcon
